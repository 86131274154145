import React, {Component} from "react";
import {formatDate} from "../../../../../helper";
export default class LaterActions extends Component {

    constructor() {

        super();
        this.state = {
            "openSetNewDate": false,
        };

    }

    setNewDueDate(e) {

        const taskDate = new Date(this.taskDate.value + " " + this.taskTime.value);
        this.props.setNewDeadline.call(
            this,
            taskDate.getTime() - new Date().getTime(),
            e
        );
        this.setState({"openSetNewDate": false});

    }

    openSetNewDate(e) {

        e.stopPropagation();
        this.setState((prevState) => ({"openSetNewDate": !prevState.openSetNewDate}));

    }

    preventPropagation(e) {

        e.stopPropagation();

    }

    render() {

        const {task} = this.props;
        let laterMenu;
        let setNewDateCont;
        if (this.state.openSetNewDate) {

            const taskTime = formatDate(new Date(task.date));

            setNewDateCont = (
                <div
                    className="action-cont pos-rel"
                    onClick={this.preventPropagation.bind(this)}
                    style={{
                        "display": "block",
                        "zIndex": "1",
                        "right": "430px",
                        "top": "-30px",
                        "width": "max-content",
                    }}
                >
                    <div className="action-list later-list">
                        <div className="action set-new-date overflow-auto">
                            <div className="width-55 disp-inline-block">
                                <div className="font-12 m-b-6 text-gray">
                                    DATE
                                </div>
                                <div>
                                    <input
                                        ref={(input) => {

                                            this.taskDate = input;

                                        }}
                                        type="date"
                                        className="font-17 full-width m-b-10"
                                        defaultValue={taskTime.date}
                                        placeholder="None"
                                    />
                                </div>
                            </div>
                            <div className="m-l-15 disp-inline-block">
                                <div className="font-12 m-b-6 text-gray">
                                    TIME
                                </div>
                                <div>
                                    <input
                                        ref={(input) => {

                                            this.taskTime = input;

                                        }}
                                        type="time"
                                        className="font-17 full-width m-b-10"
                                        defaultValue={taskTime.time}
                                        placeholder="None"
                                    />
                                </div>
                            </div>
                            <div
                                className="blue-btn big pull-right m-t-10 m-r-10 m-b-10"
                                onClick={this.setNewDueDate.bind(this)}
                            >
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            );

        }
        // eslint-disable-next-line prefer-const
        laterMenu = (
            <div
                className="action-cont pos-rel"
                style={{"display": "block", "zIndex": "1"}}
            >
                <div className="action-list later-list detail">
                    <div
                        className="action"
                        onClick={this.props.setNewDeadline.bind(this, 5 * 60 * 1000)}
                    >
                        Remind Me in 5 Minutes
                    </div>
                    <div
                        className="action"
                        onClick={this.props.setNewDeadline.bind(this, 60 * 60 * 1000)}
                    >
                        Remind Me in an Hour
                    </div>
                    <div
                        className="action"
                        onClick={this.props.setNewDeadline.bind(this, 24 * 60 * 60 * 1000)}
                    >
                        Remind Me Tomorrow
                    </div>
                    <div className="action" onClick={this.openSetNewDate.bind(this)}>
                        Set New Due Date
                        {" "}
                        <span
                            className="fa fa-angle-right vertical-baseline"
                            aria-hidden="true"
                        />
                        {setNewDateCont}
                    </div>
                </div>
            </div>
        );

        return <div className="pos-abs">
            {laterMenu}
        </div>;

    }

}
