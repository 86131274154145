import { useState } from "react";
import TaskActions from "./taskActions/TaskActions";
import PropTypes from "prop-types";

const TaskList = ({ tasks = [] }) => {
  const [isTaskActionOpen, setTaskActionOpen] = useState();

  const onSetNewDeadline = (task, delay, e) => {
    // e.stopPropagation();
    // task.date = new Date(new Date().getTime() + delay);
    // this.props.delayTask.call(this, task);
    // this.setState({"isTaskActionOpen": null});
  };
  
  return (
    <div className="task-cont">
      <div className="task-list">
        {tasks.map((item, i) => {
          const taskDate = item.date ? new Date(item.date) : null;
          const initials =
            (item.assignTo.firstName
              ? item.assignTo.firstName[0].toUpperCase()
              : "") +
            (item.assignTo.lastName
              ? item.assignTo.lastName[0].toUpperCase()
              : "");
          let userLetterImageCls = "org-letter-img";
          let taskDateCont = (
            <div className="org-assignee-list-img user_img">{initials}</div>
          );
          if (item.assignTo.image) {
            userLetterImageCls = "";
            taskDateCont = (
              <img
                src={item.assignTo.image}
                className="org-assignee-list-img"
              />
            );
          }

          const taskActionCont =
            isTaskActionOpen && isTaskActionOpen._id === item._id ? (
              <TaskActions
                task={item}
                setNewDeadline={onSetNewDeadline}
                // onTaskDone={this.props.completeTask.bind(this, item)}
              />
            ) : (
              ""
            );

          if (!item.isCompleted) {
            return (
              <div key={i} className={`inline-flex ${userLetterImageCls}`}>
                <div className="title">
                  <span>{item.title}</span>
                  <span className="org-tooltip org-tooltip-top-left-aligned org-tooltip-dark">
                    {taskDateCont}
                    <div className="org-tooltip-text">
                      Assigned to {item.assignTo.firstName}{" "}
                      {item.assignTo.lastName}
                    </div>
                  </span>
                </div>
                <div className="vertical-center p-r-5 right-align">
                  <span
                    onClick={() => setTaskActionOpen(item)}
                    className={`icon small-card fa ${
                      item.type === "comment"
                        ? "fa-comment"
                        : item.type === "task"
                        ? "fa-check-circle-o"
                        : item.type === "call"
                        ? "fa-phone"
                        : item.type === "email"
                        ? "fa-envelope"
                        : item.type === "meet"
                        ? "fa-users"
                        : ""
                    }`}
                  />
                </div>
                {taskActionCont}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

TaskList.propTypes = {
  tasks: PropTypes.array,
  editAssigned: PropTypes.bool,
  setEditAssigned: PropTypes.func,
};

export default TaskList;
