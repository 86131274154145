import React, {Component} from "react";
export default class Memberprofile extends Component {


    componentWillMount() {

    }

    setInitialState() {

        this.state = {
            "selectedTab": "1",
            "isProfilePicture": null
        };
    
    }

    constructor(props) {

        super(props);
        this.setInitialState();
    
    }

    closeMemberProfile() {

        this.props.closeMemberProfile.call(this);
    
    }


    render() {

        let profileCont = null, {isProfilePicture} = this.state, profilePicture, body;
        const {member} = this.props;
        if (isProfilePicture) {

            profilePicture = <img id="profilePicture" className="add-btn-big" />;
        
        } else {

            if (member && member.email && member.email.image) {

                profilePicture = <img src={member && member.email && member.email.image ? member.email.image : ""} id="profilePicture" className="add-btn-big" />;
            
            } else {

                const initials = (member && member.email && member.email.firstName ? member.email.firstName[0].toUpperCase() : "") + (member && member.email && member.email.lastName ? member.email.lastName[0].toUpperCase() : "");
                profilePicture = <div className="add-btn-big">
                    {initials}
                </div>;
            
            }

        }
        if (this.state.selectedTab == "1") {

            let firstName, lastName;
            if (member && member.email && member.email.firstName) {

                firstName = <div>
                    <div className="text-gray-bold-small m-t-20 m-b-10">
                        First Name
                    </div>
                    <div className="text-black m-b-25">
                        {member && member.email ? member.email.firstName : "&nbsp;"}
                    </div>
                </div>;
            
            }

            if (member && member.email && member.email.lastName) {

                lastName = <div>
                    <div className="text-gray-bold-small m-t-10 m-b-10">
                        Last Name
                    </div>
                    <div className="text-black m-b-25">
                        {member && member.email ? member.email.lastName : "&nbsp;"}
                    </div>
                </div>;
            
            }
            body = <div className="body overflow-auto">
                <div className="pos-rel disp-inline-block m-t-20 center-align">
                    {profilePicture}
                </div>
                <div className="control-box width-70 pull-right">
                    {firstName}
                    {lastName}
                    <div>
                        <div className="text-gray-bold-small m-t-10 m-b-10">
                            Email Address
                        </div>
                        <div className="text-black m-b-25">
                            {member && member.email ? member.email?.email : "&nbsp;"}
                        </div>
                    </div>
                </div>
            </div>;
        
        }


        if (this.props.isProfileOpen) {

            profileCont =
                <div className="backdrop">
                    <div className="popup small p-b-20">
                        <div className="header border-0 height-auto">
                            <span>
                                Profile
                            </span>
                            <i className="close fa fa-times" aria-hidden="true" onClick={this.closeMemberProfile.bind(this)} />
                        </div>
                        {body}

                    </div>
                </div>;

        }

        return (<div>
            {profileCont}
        </div>);
    
    }

}
