import { useEffect, useRef } from "react";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useOutsideAlerter(ref, func) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function useAddCheckList(func, card = {}, add = false) {
  useEffect(() => {
    function handleEnterPress(event) {
      if (event.key === "Enter" ) {
        func(card);
      }
    }

    // Bind the event listener
    if (add) {
      document.addEventListener("keydown", handleEnterPress);
    } else {
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("keydown", handleEnterPress);
      };
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [card._id, add]);
}

export function useClickEnter(use, func) {
  const addEnterClick = (e) => {
    if (e.key === "Enter") {
      func(false);
    }
  };
  if (use) {
    document.addEventListener("keydown", addEnterClick);
  } else {
    document.removeEventListener("keydown", addEnterClick);
  }
}
