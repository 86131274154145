import PropTypes from "prop-types";

const Pdf = ({ card, attachment, isLoggedIn = false, isPublic = false, deleteContent }) => {
  const handleDueDateClick = (id) => {
    const ele = document.getElementById(id);
    if (ele.className.indexOf("due-show-tooltip") === -1) {
      ele.className += " due-show-tooltip";
    } else {
      ele.className = ele.className.replace(" due-show-tooltip", "");
    }
  };

  return (
    <div className="org-upload-pdf" key={`attachment${attachment._id}`}>
      <div className="org-upload-doc-container left-align org-inline-flex-col-center font-12 p-0 m-t-10">
        <div className="org-doc-image m-r-10">
          <img src="../../../../../assets/images/text-document.svg" alt="text-doc" />
        </div>
        <div>
          <a href={attachment.url} download target="_blank" rel="noreferrer">
            {attachment.fileName}
          </a>
          <p className="text-black font-10">
            {attachment.fileSize ? `${attachment.fileSize} Uploaded` : ""}
          </p>
        </div>
      </div>
      <div className="desc-cont normal org-flex-row-end org-action-container">
        {isLoggedIn && !isPublic && (
          <span
            onClick={() =>
              handleDueDateClick(`delete_media_option_${attachment._id}`)
            }
            id={`delete_media_option_${attachment._id}`}
            className="org-card-options org-tooltip org-options"
          >
            <div className="org-tooltip-due-date">
              <div className="action-list">
                <div
                  className="action"
                  onClick={() => deleteContent(card._id, attachment._id)}
                >
                  Delete
                </div>
              </div>
            </div>
          </span>
        )}
      </div>
    </div>
  );
};

Pdf.propTypes = {
  attachment: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isPublic: PropTypes.bool,
  card: PropTypes.object,
  deleteContent: PropTypes.func,
};

export default Pdf;
