import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import PropTypes from "prop-types";
import {actions as profileActions} from "../../../../store/reducers/profile"
import "./styles.scss";

const CurrentPlan = ({ plan = {} }) => {
    const dispatch = useDispatch();

    const getustomerPortalUrl = () => {
        dispatch({
            type: profileActions.GET_PORTAL_URL
        })
    }
    return (
        <div>
            {plan.color &&
            <div
                className={`profile-plan`}
                style={{ border: `1px solid ${plan.color}` }}
            >
                <div className="header" style={{ background: plan.color }}>
                    {plan.title}
                </div>
                <div className="content">
                    <div className="title">
                        <p>{plan.price === "Free" ? plan.price : "$" + `${plan.price}`}</p>
                        {plan.price !== "Free" ? (
                            <small>per user/month</small>
                        ) : (
                            <div style={{ height: 20, width: "100%" }} />
                        )}
                    </div>
                    <div className="hint">{plan.for}</div>
                    <ul className="features">
                        {plan.features.map((feature, index) => (
                            <li key={index}>
                                <FaCheck /> {feature}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        }
        <button className="btn submit-button" style={{width: "100%"}} onClick={getustomerPortalUrl} >
            Change Plan
        </button>
    </div>
    );
};

CurrentPlan.propTypes = {
    plan: PropTypes.object,
};

export default CurrentPlan;
