import React, { Component } from "react";
import { connect } from "react-redux";
import {actions} from "../../../../store/reducers/boards";
import PropTypes from "prop-types";
import robot from "../../../../assets/images/robot.png";
import {withRouter} from "react-router-dom"

class LeaveBoardNotification extends Component {

  closeNotification = () => {
    this.props.dispatch({
      type: actions.SET_STATE,
      payload: {
        showLeaveBoardNotification: false,
        message: null,
        errorMessage: null
      }
    });
  }

  render() {
    const {errorMessage, message, showLeaveBoardNotification} = this.props.boards
    return (
      <div
        className="action-cont p-b-10 p-r-10 width-240px"
        style={{
          zIndex: 11,
          display: showLeaveBoardNotification ? "block" : "none",
          top: "0",
          right: "0",
        }}
      >
        <div className="header">
          <div className="disp-inline-block pull-left m-l-10">
            <div className="disp-inline-block dot dot-1" />
            <div className="disp-inline-block dot dot-2" />
            <div className="disp-inline-block dot dot-3" />
            <div className="disp-inline-block dot dot-4" />
          </div>
          <span
            className="close fa fa-times"
            onClick={this.closeNotification}
          />
        </div>
        <div className="message">
          <img
            style={{ width: "20px", verticalAlign: "sub" }}
            src={robot}
            alt="robot-img"
          />
          {message || errorMessage }
        </div>
        {!errorMessage &&
        <div className="right-align">
          <button
            className="blue-btn small font-12 height-auto line-height-20"
            onClick={this.closeNotification}
          >
            Close
          </button>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  boards: state.boards,
  user: state.user,
});


LeaveBoardNotification.propTypes = {
  board: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(LeaveBoardNotification));
