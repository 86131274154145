import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { daysFromCurrentDate } from "../../../../../helper";
import { doneTheTask } from "../../../../../store/actions/lists";
import moment from "moment";

const Badge = ({ card }) => {
  const { user } = useSelector((state) => ({
    user: state.user,
    activeBoard: state.user.activeBoard,
    boardType: state.user.activeBoard.boardType,
  }));
  const cardNoteLength =
    card.comments && card.comments.length
      ? card.comments.filter((element) => {
          if (element.type == "note") return element;
        }).length
      : 0;
  const dispatch = useDispatch();

  const [hoverDueDate, setHoverDueDate] = useState(false);

  const onDoneTheTask = (selectedCardDetail) => {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      dispatch(doneTheTask(tasks, cardId, board));
    }
  };

  const extendDueDateHour = (selectedCardDetail) => {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      const taskLists = tasks.map((task) => {
        const { date } = task;
        const newDate = moment(date).add(1, "hour");
        task.date = newDate;
        return task;
      });
      // this.props.activateTask.call(this, taskLists, cardId, board);
    }
  };

  const extendDueDateDay = (selectedCardDetail) => {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      const taskLists = tasks.map((task) => {
        const { date } = task;
        const newDate = moment(date).add(1, "day");
        task.date = newDate;
        return task;
      });
      // this.props.activateTask.call(this, taskLists, cardId, board);
    }
  };

  const extendDueDateWeek = (selectedCardDetail) => {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      const taskLists = tasks.map((task) => {
        const { date } = task;
        const newDate = moment(date).add(1, "week");
        task.date = newDate;
        return task;
      });
      // this.props.activateTask.call(this, taskLists, cardId, board);
    }
  };

  const setNewDueDate = (item) => {
    const { isLoggedIn, isPublic } = user;
    if (!isLoggedIn || isPublic) {
      return;
    }
    window.history.pushState(
      null,
      null,
      "/#/board/" + item.board + "/column/" + item.column + "/card/" + item._id
    );
    // this.props.openCardDetail.call(this, item, true);
  };

  const activateTask = (selectedCardDetail) => {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      // this.props.activateTask.call(this, tasks, cardId, board);
    }
  };

  return (
    <div>
      <span className="badge">
        {`${daysFromCurrentDate(card.createdAt)} d`}
      </span>
      {cardNoteLength > 0 && (
        <>
          <span className="badge">
            <span className="fa fa-file-text" />
            <span className="count m-l-5">{cardNoteLength}</span>
          </span>
          {card.comments?.length - cardNoteLength && (
            <span className="badge ">
              <span className="fa fa-comment" />
              <span className="count m-l-5">
                {card.comments.length - cardNoteLength}
              </span>
            </span>
          )}
          {card.date ? (
            <span
              onClick={this.handleDueDateClick.bind(
                this,
                `due_date_option_${card._id}`
              )}
              onMouseEnter={() => hoverDueDate(true)}
              onMouseLeave={() => setHoverDueDate(false)}
              id={`due_date_option_${card._id}`}
              className="p-5 org-round-edges-15 text-white org-tooltip"
              style={{ backgroundColor: hoverDueDate ? "#4a6a59" : "#85b59c" }}
            >
              <div className="org-tooltip-due-date">
                {!card.isCompleted ? (
                  <div className="action-list">
                    <div className="action" onClick={doneTheTask}>
                      Done
                    </div>
                    <div className="action" onClick={extendDueDateHour}>
                      Remind me in an hour
                    </div>
                    <div className="action" onClick={extendDueDateDay}>
                      Remind me tomorrow
                    </div>
                    <div className="action" onClick={extendDueDateWeek}>
                      Remind me in a week
                    </div>
                    <div className="action" onClick={setNewDueDate}>
                      Set New Due Date
                    </div>
                  </div>
                ) : (
                  <div className="action-list">
                    <div className="action" onClick={activateTask}>
                      Change to Not Done
                    </div>
                  </div>
                )}
              </div>
              {card.isCompleted ? (
                <img
                  className="middle tick-shape done-task"
                  src="../src/assets/images/shape.svg"
                />
              ) : (
                <img
                  className="middle done-task"
                  src="../../../assets/images/clock.svg"
                />
              )}
              {moment(card.dueDate).format("MMM DD, YYYY")}
            </span>
          ) : null}
        </>
      )}
    </div>
  );
};

Badge.propTypes = {
  card: PropTypes.object,
  editCardDesc: PropTypes.bool,
  setEditCardDesc: PropTypes.func,
};

export default Badge;
