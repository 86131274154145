import CheckListItem from "./checkList";
import React, {useRef} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteCheckList,
  updateCheckList,
} from "../../../../../store/actions/lists";
import {actions as cardsActions} from "../../../../../store/reducers/cards";

const CheckList = ({
  card,
  checkLists = [],
  isLoggedIn = false,
  isPublic = false,
  canEditCheckList = false
}) => {
  const dispatch = useDispatch();
  const {checkListAdded = false} = useSelector((state) => ({
      checkListAdded: state.cards.checkListAdded,
  }));

  const previousCheckList = usePrevious(checkLists);

  function usePrevious(value) {
    const ref = useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  React.useEffect(() => {
    if (checkListAdded && checkLists.length < previousCheckList.length) {
      dispatch({
        type: cardsActions.SET_STATE,
        payload: {
          checkListAdded: false
        }
      });
    }
  }, [checkLists]);

  const handleDueDateClick = (id) => {
    const ele = document.getElementById(id);
    if (ele.className.indexOf("due-show-tooltip") === -1) {
      ele.className += " due-show-tooltip";
    } else {
      ele.className = ele.className.replace(" due-show-tooltip", "");
    }
  };

  const onDeleteCardCheckList = (checkListId, type) => {
    if (!canEditCheckList) {
      return;
    }
    dispatch(deleteCheckList({id: checkListId}, card, type));
  };
  const editCardCheckList = (title, isCompleted, id) => {
    if (!canEditCheckList) {
      return;
    }
    dispatch(
      updateCheckList(
        {
          id,
          title,
          isCompleted,
        },
        card
      )
    );
  };
  return (
    <>
      {checkLists.map((data, key) => (
        <CheckListItem
          key={data._id}
          data={data}
          isClosable
          isFocused={checkListAdded && key == (checkLists.length-1)}
          card={card}
          isLoggedIn={isLoggedIn}
          isPublic={isPublic}
          editCardCheckList={editCardCheckList}
          deleteCardCheckList={onDeleteCardCheckList}
          canEditCheckList={canEditCheckList}
        />
      ))}
      {canEditCheckList && checkLists.length > 1 && (
        <div className="desc-cont normal org-flex-row-end org-action-container">
          {isLoggedIn && !isPublic && (
            <span
              onClick={() =>
                handleDueDateClick(`delete_media_option_checklist${card._id}`)
              }
              id={`delete_media_option_checklist${card._id}`}
              className="org-card-options org-tooltip org-options"
            >
              <div className="org-tooltip-due-date">
                <div className="action-list">
                  <div
                    className="action"
                    onClick={() => onDeleteCardCheckList(null, "bulk")}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
      )}
    </>
  );
};

CheckList.propTypes = {
  checkLists: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  isPublic: PropTypes.bool,
  card: PropTypes.object,
};

export default CheckList;
