import React, {Component} from "react";
import {
    formatDate
} from "../../../../../../helper";
export default class TaskActions extends Component {



    constructor() {

        super();
        this.state = {
            "toggleLaterMenu": false,
            "openSetNewDate": false,
        };

    }

    UNSAFE_componentWillMount() {

    }

    setNewDueDate(e) {

        const taskDate = new Date(this.taskDate.value + " " + this.taskTime.value);
        this.props.setNewDeadline.call(this, taskDate.getTime() - (new Date().getTime()), e);
        this.setState({"openSetNewDate": false});

    }

    onTaskDone(e) {

        e.stopPropagation();
        this.props.onTaskDone.call(this);

    }

    onTaskLater(e) {

        e.stopPropagation();
        this.setState((prevState) => ({"toggleLaterMenu": !prevState.toggleLaterMenu}));

    }

    openSetNewDate(e) {

        e.stopPropagation();
        this.setState((prevState) => ({"openSetNewDate": !prevState.openSetNewDate}));

    }

    preventPropagation(e) {

        e.stopPropagation();

    }

    render() {

        const {top, left, task} = this.props;
        let laterMenu, laterAction;
        if (this.state.toggleLaterMenu) {

            let setNewDateCont;
            if (this.state.openSetNewDate) {

                const taskTime = formatDate(new Date(task.date));

                setNewDateCont = <div className="action-cont pos-rel" onClick={this.preventPropagation.bind(this)} style={{"display": "block", "zIndex": "1", "left": "-11px", "top": "0px", "width": "max-content"}}>
                    <div className="action-list later-list">
                        <div className="action set-new-date overflow-auto">
                            <div className="">
                                <div className="font-12 m-b-6 text-gray">
                                    DATE
                                </div>
                                <div>
                                    <input
                                    ref={(input) => {

                                        this.taskDate = input;

                                    }} type="date" className="font-17 m-b-10" defaultValue={taskTime.date} placeholder="None" />
                                </div>
                            </div>
                            <div className="">
                                <div className="font-12 m-b-6 text-gray">
                                    TIME
                                </div>
                                <div>
                                    <input
                                    ref={(input) => {

                                        this.taskTime = input;

                                    }} type="time" className="font-17 m-b-10" defaultValue={taskTime.time} placeholder="None" />
                                </div>
                            </div>
                            <div className="blue-btn big pull-right m-t-10 m-r-10 m-b-10" onClick={this.setNewDueDate.bind(this)}>
                                Save
                            </div>
                        </div>
                    </div>
                </div>;

            }
            laterMenu = <div className="action-cont pos-rel" style={{"display": "block", "zIndex": "1", "marginLeft": "-110px"}}>
                <div className="action-list later-list">
                    <div className="action" onClick={this.props.setNewDeadline.bind(this, (5 * 60 * 1000))}>
                        Remind Me in 5 Minutes
                    </div>
                    <div className="action" onClick={this.props.setNewDeadline.bind(this, (60 * 60 * 1000))}>
                        Remind Me in an Hour
                    </div>
                    <div className="action" onClick={this.props.setNewDeadline.bind(this, (24 * 60 * 60 * 1000))}>
                        Remind Me Tomorrow
                    </div>
                    <div className="action" onClick={this.openSetNewDate.bind(this)}>
                        Set New Due Date
                        <span className="fa fa-angle-right vertical-baseline" aria-hidden="true" />
                        {setNewDateCont}
                    </div>
                </div>
            </div>;

        }
        if (task.date) {

            laterAction = <div className="action" onClick={this.onTaskLater.bind(this)}>
                Later
                <span className="fa fa-angle-down vertical-baseline" aria-hidden="true" />
            </div>;

        }

        return (<div className="pos-abs" style={{"right": "40"}}>
            <div className="action-cont pos-rel small" style={{"display": "block", "top": top, "left": left, "zIndex": "1"}}>
                <div className="action-list">
                    <div className="action" onClick={this.onTaskDone.bind(this)}>
                        Done
                    </div>
                    {laterAction}
                </div>
            </div>
            {laterMenu}
        </div>
        );

    }

}
