import "./style.scss";
const Divider = ({ title, className }) => {
  return (
    <div className={`divider-container ${className}`}>
      <div className="border" />
      <span className="content">
        {title}
      </span>
      <div className="border" />
    </div>
  );
};

export default Divider;
