import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import { actions as registerActions } from "../../../../store/reducers/register";

const CheckToken = ({ history }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    // get params
    let search = history.location.search;
    if (search) {
      search = search.split("&token=");
      if (search.length === 2) {
        const token = search[1];
        const email = search[0].substring(7);
        dispatch({
          type: registerActions.VERIFY_TOKEN,
          payload: { token, email, history }
        })
      }
    }
  }, []);
  return <div />;
};

CheckToken.propTypes = {
  history: PropTypes.object,
};

export default withRouter(CheckToken);
