import { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import PrivateLayout from "../components/layouts/private";
import HomePageContainer from "./Board";

const BoardRoutes = ({ user = {}, match, history = {}, dispatch }) => {
  useEffect(() => {
    if (user.boardList.length > 0 && history.location.pathname) {
      const idx = user.boardList.findIndex((item) => history.location.pathname.includes(item._id) && !item.isDeleted);
      const notdeleted = user.boardList.filter((item) => !item.isDeleted)
      if (idx >= 1 && notdeleted.length > 0) {
        // dispatch(getBoardDetail({boardId: notdeleted[0]._id},user.boardList))
        history.push(`/board/${notdeleted[notdeleted.length-1]._id}`);
      }
    }
  }, [user.boardList.length]);


  useEffect(() => {
    if(user.newboard && user.newboard._id) {
      history.push(`/board/${user.newboard._id}`)
      dispatch({type: 'SET_STATE', payload: {newboard: null}})
    }
  }, [user.newboard])
  return (
    <Switch>
      <PrivateLayout>
        <Route
          exact
          path={`${match.url}/:boardId`}
          component={HomePageContainer}
        />
        <Route
          exact
          path={`${match.url}/:boardId/column/:columnId/card/:cardId`}
          component={HomePageContainer}
        />
      </PrivateLayout>
    </Switch>
  )
}
const mapStateToProps = (state) => ({ user: state.user })
export default connect(mapStateToProps)(withRouter(BoardRoutes))
