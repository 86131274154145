import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { get, orderBy } from "lodash";
import {
  showEditBoard,
  openBoardOptions,
  showDeleteBoard,
  hideDeleteBoard,
  closeBoardOptions,
  openAddBoard,
} from "../../store/actions/UIActions";
import {
  editBoardName,
  updateBoardList,
  getBoardDetail
} from "../../store/actions/lists";
import BoardActions from "../Board/boardActions/boardActions";
import AddNewBoard from "../Board/addBoard";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OutsideAlerter from "../../widget/Modal";
import DeleteBoardAlert from "../Board/boardHeader/deleteBoardAlert";

const SlideLeftMenu = ({ history, match }) => {
  const { boardList, activeBoard, user, showAddBoard } = useSelector((state) => ({
    boardList: state.user.boardList,
    activeBoard: state.user.activeBoard,
    user: state.user,
    showAddBoard: state.user.showAddBoard,
  }));

  const dispatch = useDispatch();

  const onShowEditBoard = (board) => {
    const isUserLoggedIn = get(user, "isUserLoggedin", false);
    const isPublic = get(user, "isPublic", false);
    if (isUserLoggedIn && !isPublic) {
      dispatch(showEditBoard(board));
    }
  };

  const onBlurEditBoardMain = (e) => {
    if (e.target.value) {
      dispatch(editBoardName(user.activeBoard, user.boardList, e.target.value));
    } else {
      dispatch(
        editBoardName(user.activeBoard, user.boardList, user.activeBoard.name)
      );
    }
  };

  const onOpenBoardOptions = (item, e) => {
    e.stopPropagation();
    if (user.isBoardActionOpen && (user.board && user.board._id) === item._id) {
      return;
    }
    const boardItemEle = document
      .getElementById(`org-board-item_${item._id}`)
      .getBoundingClientRect();
    const right = Math.ceil(boardItemEle.right) - 20;
    const top = Math.ceil(boardItemEle.top + boardItemEle.height / 2);
    dispatch(openBoardOptions(right, top, item));
  };

  const onOpenAddBoard = () => {
    const isUserLoggedIn = get(user, "isUserLoggedin", false);
    if (!isUserLoggedIn) {
      return;
    }
    dispatch(closeBoardOptions());
    dispatch(openAddBoard());
  };

  const redirectToBoard = (board) => {
    if (board._id !== match.params.id) {
      history.push(`/board/${board._id}`);
      dispatch(getBoardDetail(board, boardList))
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    result = orderBy(result, ['priority', 'createdAt'], ['asc', 'desc']);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((item, key) => {
      return {
        ...item,
        priority: key
      }
    });
  };

  const onShowDeleteBoard = (board) => {
    dispatch(showDeleteBoard(board));
    dispatch(closeBoardOptions());
  };

  const deleteBoard = (boardToDelete) => {
    dispatch({ type: "SET_STATE", payload: { boardToDelete } });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const lists = reorder(
      boardList,
      result.source.index,
      result.destination.index
    );

    const boards = lists.map((list) => list._id);
    if (boards.length === 0) return;

    dispatch({ type: "GET_BOARD_SUCCESS", payload: { data: { boards: lists } } });
    dispatch(updateBoardList(boards))
  };
  return (
    <div className="board-list-menu">
      <div className="m-t-60">
        <span className="menu-title">BOARDS</span>
        <div
          className="disp-inline-block add-btn pull-right"
          onClick={() => onOpenAddBoard()}
        >
          {" "}
          +{" "}
        </div>
        <DragDropContext
        onDragEnd={onDragEnd}>
          <Droppable
            type="COLUMN"
            droppableId="sidebar"
            ignoreContainerClipping
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="board-list"
              >
                {orderBy(boardList, ['priority', 'createdAt'], ['asc', 'desc']).map((item, index) => {
                  if (!item.isDeleted && !item.createdFrom) {
                    return (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(dragProvided) => (
                          <div
                            ref={dragProvided.innerRef}
                            id={`org-board-item_${item._id}`}
                            className="org-board-item"
                            key={item._id}
                            style={{ position: "relative" }}
                            onClick={() => redirectToBoard(item)}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <div
                              className={`name ${
                                item._id === activeBoard?._id && "selected"
                              } ${
                                user.isBoardActionOpen && user.board._id === item._id
                                  ? "org-board-options-open"
                                  : ""
                              }`}
                            >
                              <span
                                style={{
                                  display:
                                    user.editBoard && item._id === user.editBoard._id
                                      ? "none"
                                      : "inline-block",
                                  wordBreak: "break-all",
                                }}
                                onDoubleClick={() => onShowEditBoard(item)}
                              >
                                {item.name}
                              </span>
                              <input
                                key={`${item._id} ${item.name}`}
                                className="edit-text"
                                style={{
                                  display:
                                    user.editBoard && item._id === user.editBoard._id
                                      ? "inline-block"
                                      : "none",
                                }}
                                type="text"
                                defaultValue={item.name}
                                onBlur={onBlurEditBoardMain}
                              />
                              {(item.isCopying || item.owner === user.loggedInUser.email)&&
                              <span
                                className="options fa fa-ellipsis-h"
                                aria-hidden="true"
                                onClick={(e) => onOpenBoardOptions(item, e)}
                              />}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <OutsideAlerter handleClose={() => dispatch(closeBoardOptions())}>
        <BoardActions
          top={user.boardActionTop}
          left={user.boardActionLeft}
          board={user.board}
          isBoardActionOpen={user.isBoardActionOpen}
          closeBoardOptions={() => dispatch(closeBoardOptions())}
          showDeleteBoard={onShowDeleteBoard}
        />
      </OutsideAlerter>
      {showAddBoard && <AddNewBoard />}
      {user.showDeleteBoard &&
        <DeleteBoardAlert
        handleClose={() => dispatch(hideDeleteBoard())}
        deleteBoard={deleteBoard}
        board={user.board}
        boardName={user.board && user.board.name}
        />}
    </div>
  );
};

SlideLeftMenu.propTypes = {
  closeMenu: PropTypes.func,
  openAddBoard: PropTypes.func,
  isSideMenuOpen: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(SlideLeftMenu);
