import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import VideoUrl from "./videoUrl";
import Image from "./image";
import EmbedUrl from "./embedUrl";
import { deleteContent } from '../../../../../store/actions/lists'
import { canEditBoard } from "../../../../../helper";
import Pdf from "./pdf";

const Attachments = ({ attachments = [], card = {} }) => {
  const { user } = useSelector((state) => ({ user: state.user }));
  const dispatch = useDispatch();
  const isUserLoggedIn = get(user, "isUserLoggedin", false);
  const isPublic = get(user, "isPublic", false);
  const canEditCard = canEditBoard(user.currentMember, user.isUserLoggedin, user.canEditCard);

  const onDeleteContent = (cardId, attachmentId = "") => {
    if (cardId) {
      dispatch(deleteContent(cardId, attachmentId));
    }
  };

  return (
    <div>
      {attachments.map((attachment) => {
        return (
          <div key={attachment._id}>
            {attachment.type.indexOf("videoUrl") !== -1 && (
              <VideoUrl
                attachment={attachment}
                card={card}
                isLoggedIn={isUserLoggedIn}
                isPublic={isPublic}
                canEditCard={canEditCard}
                deleteContent={onDeleteContent}
              />
            )}
            {attachment.type.indexOf("image") !== -1 && (
              <Image
              attachment={attachment}
              card={card}
              isLoggedIn={isUserLoggedIn}
              isPublic={isPublic}
              canEditCard={canEditCard}
              deleteContent={onDeleteContent}
              />
            )}
            {attachment.type.indexOf("embedUrl") !== -1 && (
              <EmbedUrl
                attachment={attachment}
                card={card}
                isLoggedIn={isUserLoggedIn}
                isPublic={isPublic}
                canEditCard={canEditCard}
                deleteContent={onDeleteContent}
              />
            )}
            {attachment.type.indexOf("application/pdf") !== -1 && (
              <Pdf
              attachment={attachment}
              card={card}
              isLoggedIn={isUserLoggedIn}
              isPublic={isPublic}
              canEditCard={canEditCard}
              deleteContent={onDeleteContent}
            />
            )}
          </div>
        );
      })}
    </div>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array,
  card: PropTypes.object,
};

export default Attachments;
