import PropTypes from "prop-types";
import Microlink from "@microlink/react";

const EmbedUrl = ({ card, attachment, isLoggedIn = false, isPublic = false, canEditCard=false,deleteContent }) => {
  const handleDueDateClick = (id) => {
    const ele = document.getElementById(id);
    if (ele.className.indexOf("due-show-tooltip") === -1) {
      ele.className += " due-show-tooltip";
    } else {
      ele.className = ele.className.replace(" due-show-tooltip", "");
    }
  };

  const link = attachment.url.indexOf("http") === 0 ? attachment.url:`http://${attachment.url}`;

  return (
    <div key={`attachment${attachment._id}`}>
        <Microlink
        style={{display: "flex", flexDirection: "column", marginTop: "10px"}}
                   url={link} contrast lazy={{ threshold: 0.5 }} media={['image', 'logo']} />
        <div className="desc-cont normal org-flex-row-end org-action-container">
        {isLoggedIn && !isPublic && canEditCard && (
          <span
            onClick={() =>
              handleDueDateClick(`delete_media_option_${attachment._id}`)
            }
            id={`delete_media_option_${attachment._id}`}
            className="org-card-options org-tooltip org-options"
          >
            <div className="org-tooltip-due-date">
              <div className="action-list">
                <div
                  className="action"
                  onClick={() => deleteContent(card._id, attachment._id)}
                >
                  Delete
                </div>
              </div>
            </div>
          </span>
        )}
      </div>
    </div>
  );
};

EmbedUrl.propTypes = {
  attachment: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isPublic: PropTypes.bool,
  canEditCard: PropTypes.bool,
  card: PropTypes.object,
  deleteContent: PropTypes.func,
};

export default EmbedUrl;
