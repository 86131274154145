import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { updateUser } from "../../../store/actions/user"
import avatar from "../../../assets/images/Profile_Icon.svg";
import { useState } from 'react';

const validationSchema = Yup.object().shape({
    lastName: Yup.string()
        .min(2, "Too short")
        .max(50, "Too long")
        .required("Field Required"),
    firstName: Yup.string()
        .min(2, "Too short")
        .max(50, "Too long")
        .required("Field Required"),
    email: Yup.string().email("Invalid email address").required("Required Field"),
});

const Infos = ({handleClose}) => {
    const dispatch = useDispatch()
    const { user = {} } = useSelector((state) => ({ user: state.user?.loggedInUser }));
    const [profilePic, setProfilePic] = useState(null);
    const initialValues = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
    };

    const onSelectProfilePic = (e) => {
        const files = e.target.files;
        if (files.length === 0) return;
        setProfilePic(files[0]);
        const reader = new FileReader();
        reader.onload = function (event) {
            const profilePicture = document.getElementById("change-profile-avatar");
            profilePicture.setAttribute("src", event.target.result);
        };
        reader.readAsDataURL(e.currentTarget.files[0]);
    };

    const onUpdateUser = (values) => {
        const formData = new FormData();
        if (profilePic) {
            formData.append("picture", profilePic);
        }
        formData.append("firstName", values.firstName);
        formData.append("lastName", values.lastName);
        formData.append("notifyAssigned", user.notifyAssigned);
        formData.append("notifymentioned", user.notifymentioned);
        formData.append("notifyReminder", user.notifyReminder);
        formData.append("notifyDailyDigest", user.notifyDailyDigest);
        formData.append("notifyDesktop", user.notifyDesktop);
        dispatch(updateUser(formData));
    };

    const isError = (submitCount, error) => {
        return submitCount > 0 ? error : false;
    };

    return (
        <div className={styles.infosContainer}>
            <div className={styles.imgContainer}>
                <div style={{width: "125px", height: "125px", borderRadius: "50%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src={user.image ? user.image : avatar} id="change-profile-avatar" />
                </div>
                <input
                    type="file"
                    accept="image/*"
                    id="profile-img-upload"
                    name="profile-img-upload"
                    className={styles.inputFile}
                    onChange={onSelectProfilePic}
                />
                <label htmlFor="profile-img-upload">
                    <button className={`default-button ${styles.labelButton}`}>
                        Change Photo
                    </button>
                </label>
            </div>
            <div className={styles.formContainer}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onUpdateUser}
                >
                    {({ values, errors, submitCount, handleChange, handleBlur, handleSubmit }) => {
                        return (
                            <Form className="formik-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        className={`${isError(submitCount, errors.firstName) && "error"
                                            }`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                    />

                                    <span className="field-error">{isError(submitCount, errors.firstName)}</span>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        className={`${isError(submitCount, errors.lastName) && "error"
                                            }`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                    />

                                    <span className="field-error">{isError(submitCount, errors.lastName)}</span>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="text"
                                        name="email"
                                        disabled
                                        className={`${errors.email && "error"
                                            }`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <span className="field-error">{errors.email && errors.email}</span>
                                </div>

                                <div className={styles.footer}>
                                    <button
                                        className={`btn default-button`}
                                        onClick={handleClose}
                                        type="button"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={`btn submit-button`}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

Infos.propTypes = {
    handleClose: PropTypes.func.isRequired
}

export default Infos;
