import PropTypes from "prop-types";

const SearchBtn = ({ showCancelSearchBtn = null, onSearchCard }) => {
  return (
    <div
      className={`${
        showCancelSearchBtn ? "cancel-btn fa fa-times" : "search-btn"
      }`}
      onClick={onSearchCard}
    />
  );
};

SearchBtn.propTypes = {
  showCancelSearchBtn: PropTypes.string.isRequired,
  onSearchCard: PropTypes.func.isRequired
};

export default SearchBtn
