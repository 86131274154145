import React from "react";
import { connect } from "react-redux";
import { logout } from "../../store/actions/user";
import PropTypes from "prop-types";

const ProfileActions = (props) => {
  const onLogout = () => {
    props.logout();
  };

  return (
    <div>
      {props.isProfileActionOpen && (
        <div
          className="action-cont profile"
          style={{
            zIndex: 2,
            display: "block",
            top: "56px",
            right: window.innerWidth - props.left - 30,
          }}
        >
          <div className="action-list">
            <div className="action" onClick={props.openProfile}>
              My Profile
            </div>
            {/* <div className="action" onClick={this.props.openProfileSetting.bind(this,'planBilling')}>Plans & Billing</div>
          <div className="action" onClick={this.props.openProfileSetting.bind(this,'userPermission')}>Users & Permissions</div> */}
            <div className="action" onClick={onLogout}>
              Log Out
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = {
  logout,
};

ProfileActions.propTypes = {
  isProfileActionOpen: PropTypes.bool,
  logout: PropTypes.func,
  openProfile: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActions);
