import { withRouter } from "react-router-dom";
import Header from "../../header/credentialModalHeader";
const CheckEmailPage = ({ history }) => {
  return (
    <div style={{ display: "block" }}>
      <Header />
      <div className="register-plans-container">
        <div className="page-title m-b-10 m-t-10 center-align text-black font-25 text-bold">
          Check your email
        </div>
        <div className="m-b-10 m-t-10 center-align text-black font-15 text-bold">
          We sent you an email to reset your password
        </div>
        <div className="m-b-10 m-t-10 center-align">
          <button className="back-to-login" onClick={() => history.push("/")}>
            Back To Login Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CheckEmailPage);
