import { Layout } from 'antd';
import LeftSideMenu from './LeftSide';
import {useSelector} from "react-redux";
import {get} from "lodash";

const { Content } = Layout;

export default function PrivateLayout({children}) {
    const {isSideMenuOpen = false} = useSelector((state) => ({
        isSideMenuOpen: get(state.user, "isSideMenuOpen", false),
    }));

    return (
      <Layout style={{ height: '100vh' }}>
        <LeftSideMenu />
        <Layout className={"site-layout " + (isSideMenuOpen ? "opened" : "")}>
          <Content>
            {children}
          </Content>
        </Layout>
      </Layout>
    )
}
