import React, { useEffect, useState } from "react";
import "./styles.css";
import SocialLogin from "../SocialLogin";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormHeader from "../FormHeader";
import { actions as registerActions } from "../../../../store/reducers/register";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "../../../spinner/spinner";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
};

const registerSchema = Yup.object().shape({
  lastName: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("Required Field"),
  firstName: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("Required Field"),
  password: Yup.string().min(8, "Too short").required("Required Field"),
  email: Yup.string().email("Invalid email address").required("Required Field"),
});

const UserRegistration = ({ propss, history, setRegistrationState }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { register } = useSelector((state) => ({
    user: state.user,
    register: state.register,
  }));
  const onUserRegister = (values) => {
    dispatch({
      type: registerActions.CREATE_NEW_USER,
      payload: {
        ...values,
        history,
      },
    });
  };

  useEffect(() => {
    if (register?.errorMessage) {
      setErrorMessage(register.errorMessage);
    }
  }, [register]);
  const isError = (submitCount, error) => {
    return submitCount > 0 ? error : false;
  };

  const isEmailError = (submitCount, value, error) => {
    return submitCount > 0 || value ? error : false;
  };

  return (
    <>
      <FormHeader headerText={"Sign Up"} />
      <Formik
        initialValues={initialValues}
        validationSchema={registerSchema}
        onSubmit={onUserRegister}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          submitCount,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <div className="form-container">
              <Form className="formik-form" onSubmit={handleSubmit}>
                <div className="form-field-container">
                  <label className="form-label" htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className={`input-field ${
                      isError(submitCount, errors.firstName) && "error"
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />
                  <span className="field-error">
                    {isError(submitCount, errors.firstName)}
                  </span>
                </div>

                <div className="form-field-container">
                  <label className="form-label" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className={`input-field ${
                      isError(submitCount, errors.lastName) && "error"
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                  <span className="field-error">
                    {isError(submitCount, errors.lastName)}
                  </span>
                </div>

                <div className="form-field-container">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    className={`input-field ${
                      isEmailError(submitCount, values.email, errors.email) &&
                      "error"
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className="field-error">
                    {isEmailError(submitCount, values.email, errors.email)}
                  </span>
                </div>

                <div className="form-field-container">
                  <label className="form-label" htmlFor="lastName">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className={`input-field ${
                      isError(submitCount, errors.password) && "error"
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span className="field-error">
                    {isError(submitCount, errors.password)}
                  </span>
                  {errorMessage && (
                    <span className="form-error">{errorMessage}</span>
                  )}
                </div>
                <button className="signup-button" type="submit">
                  Sign Up
                </button>
                <div className="login-text">
                  <Link to={`/login`}>
                    Already have an Orgzee account? Log in
                  </Link>
                </div>
                <SocialLogin
                  setErrorMsg={setErrorMessage}
                  props={propss}
                  history={history}
                  isGoogleRegister={true}
                  setRegistrationState={setRegistrationState}
                />
              </Form>
              <Spinner show={register?.loading} />
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default withRouter(UserRegistration);
