import { actions } from "../reducers/register";

import { all, takeEvery, put, call } from "redux-saga/effects";
import * as services from "../services/register";
import { NotificationManager } from "react-notifications";

export function* CREATE_NEW_USER({ payload }) {
  const { email, password, history, firstName, lastName } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      showErrorWarning: false,
    },
  });
  const response = yield call(
    services.register,
    email,
    password,
    firstName,
    lastName
  );
  if (response === true) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: false,
        registerData: {
          email,
          password,
          firstName,
          lastName,
        },
      },
    });
    history.push("/register/email-sent");
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: true,
        errorMessage: response.message
          ? response.message
          : "Error occured! please try again.",
      },
    });
  }
}

export function* RESEND_VERIFICATION_MAIL({ payload }) {
  const { email } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      showErrorWarning: false,
    },
  });
  const response = yield call(services.resendEmailVerification, email);
  if (response === true) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: false,
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: true,
        errorMessage: "Error occured! please try again.",
      },
    });
  }
}

export function* VERIFY_TOKEN({ payload }) {
  const { email, token, history } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      showErrorWarning: false,
    },
  });
  const response = yield call(services.verifyToken, email, token);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: false,
        newUser: response.user,
      },
    });
    localStorage.setItem("registerData", JSON.stringify({ email, token }));
    history.push("/register/plans");
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
    NotificationManager.error("Error occured! please try again.");
    history.push("/register/new-account");
  }
}

export function* GET_ALL_PLANS({ payload }) {
  const { history } = payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      showErrorWarning: false,
    },
  });
  const response = yield call(services.getAllPlans);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: false,
        plans: response,
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
    NotificationManager.error("Error occured! please try again.");
    history.push("/register/new-account");
  }
}

export function* CREATE_SUBSCRIPTION({ payload }) {
  const { history } = payload;
  delete payload.history;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      showErrorWarning: false,
    },
  });
  const response = yield call(services.createSubscription, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: false,
      },
    });
    localStorage.setItem("authorization", response.accessToken);
    const { email } = JSON.parse(localStorage.getItem("registerData"));
    window.gtag("event", "new-user", {
      event_category: "email",
      event_label: email,
    });
    history.push("/register/welcome");
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
    NotificationManager.error("Error occured! please try again.");
    history.push("/register/new-account");
  }
}

export function* REGISTER_WITH_FACEBOOK({ payload }) {
  const { email, history, firstName, lastName, accessToken, facebookId } =
    payload;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      showErrorWarning: false,
    },
  });
  const response = yield call(
    services.registerWithFacebook,
    email,
    firstName,
    lastName,
    accessToken,
    facebookId
  );
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: false,
      },
    });
    history.push(`/register/confirm?email=${email}&token=${response.token}`);
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: true,
        errorMessage: "Error occured! please try again.",
      },
    });
  }
}

export function* IS_EXISTS_FALSE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isExisting: false,
    },
  });
}
export function* REGISTER_WITH_GOOGLE({ payload }) {
  const { history } = payload;
  delete payload.history;
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      showErrorWarning: false,
    },
  });
  const response = yield call(services.registerWithGoogle, payload);
  if (response.status === "success") {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: false,
      },
    });
    if (response?.isExisting) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          isExisting: response?.isExisting,
        },
      });
    } else {
      history.push(
        `/register/confirm?email=${payload.profileObj.email}&token=${response.token}`
      );
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        showErrorWarning: true,
        errorMessage: response.message
          ? response.message
          : "Error occured! please try again.",
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_NEW_USER, CREATE_NEW_USER),
    takeEvery(actions.VERIFY_TOKEN, VERIFY_TOKEN),
    takeEvery(actions.GET_ALL_PLANS, GET_ALL_PLANS),
    takeEvery(actions.CREATE_SUBSCRIPTION, CREATE_SUBSCRIPTION),
    takeEvery(actions.REGISTER_WITH_FACEBOOK, REGISTER_WITH_FACEBOOK),
    takeEvery(actions.REGISTER_WITH_GOOGLE, REGISTER_WITH_GOOGLE),
    takeEvery(actions.IS_EXISTS_FALSE, IS_EXISTS_FALSE),
    takeEvery(actions.RESEND_VERIFICATION_MAIL, RESEND_VERIFICATION_MAIL),
  ]);
}
