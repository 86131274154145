import React, { Component } from "react";

export default class Member extends Component {
  constructor() {
    super();
    this.state = {
      members: [],
      showInviteForm: false,
      inviteeMember: "",
      filteredMembers: [],
      role: "VIEWER"
    };
  }

  componentDidMount() {
    const { members } = this.props;
    this.setState({
      members,
      inviteBtnDisaled: true,
      filteredMembers: [],
    });
  }

  closeMember() {
    this.props.closeMember.call(this);
  }

  showInviteForm(inviteeMemberName, inviteeMemberEmail) {
    this.setState({
      showInviteForm: true,
      inviteeMemberName,
      inviteeMemberEmail,
      inviteBtnDisaled: !inviteeMemberEmail,
    });
  }

  hideInviteForm() {
    this.setState({ showInviteForm: false, members: this.props.members });
  }

  onMemberSearch(e) {
    let { members } = this.props,
      filteredMembers = [];
    if (e.target.value) {
      const value = e.target.value.toLowerCase();
      filteredMembers = members.filter((member, i) => {
        const fullName1 = `${member.firstName} ${member.lastName}`.toLowerCase();
        const fullName2 = `${member.lastName} ${member.firstName}`.toLowerCase();
        return (
          fullName1.includes(value) ||
          fullName2.includes(value) ||
          member.email?.includes(value)
        );
      });
    } else {
      filteredMembers = [];
    }
    if (e.currentTarget.value && !filteredMembers.length) {
      filteredMembers.push({ invite: true, name: e.currentTarget.value });
    }
    this.setState({ filteredMembers });
  }

  onInviteeDetailChange(e) {
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email.value
      ) &&
      this.name.value
    ) {
      this.setState({ inviteBtnDisaled: false });
    } else {
      this.setState({ inviteBtnDisaled: true });
    }
  }

  inviteMember(e) {
    const email = this.email ? this.email.value : null;
    const name = this.name ? this.name.value : null;
    if (email && name) {
      this.props.inviteMember.call(this, email, name, this.state.role);
      this.closeMember();
    }
  }

  selectRole = (e) => {
    this.setState({role: e.target.value})
  }

  render() {
    const {
      inviteeMemberName,
      inviteeMemberEmail,
      inviteBtnDisaled,
    } = this.state;
    if (this.props.isMemberOpen) {
        return (
          <div
            className="action-cont add-team-member"
            style={{
              zIndex: 2,
              display: this.props.isMemberOpen ? "block" : "none",
            }}
          >
            <div className="header">
              <span>Add Team Members</span>
              <span
                className="close fa fa-times"
                onClick={this.closeMember.bind(this)}
              />
            </div>
            <div className="text-box-cont">
            <div className="label">NAME</div>
              <input
                ref={(input) => {
                  this.name = input;
                }}
                type="text"
                className="text-box m-b-15"
                placeholder="Enter name here."
                defaultValue={inviteeMemberName}
                onChange={this.onInviteeDetailChange.bind(this)}
              />
              {!inviteeMemberEmail ? <div className="label">EMAIL</div> : null}
              <input
                style={{
                  display: inviteeMemberEmail ? "none" : "inline-block",
                }}
                ref={(input) => {
                  this.email = input;
                }}
                type="text"
                className="text-box m-b-15"
                defaultValue={inviteeMemberEmail}
                placeholder="Enter email address here."
                onChange={this.onInviteeDetailChange.bind(this)}
              />
              <select className="text-box m-b-15" onChange={this.selectRole}>
                <option value={"ADMIN"}>Admin</option>
                <option value={"MEMBER"}>Member</option>
                <option value={"VIEWER"} selected>Viewer</option>
              </select>
              <div className="m-b-5per m-r-5per">
                <button
                  className="blue-btn pull-right"
                  onClick={this.inviteMember.bind(this)}
                  disabled={inviteBtnDisaled}
                >
                  Invite
                </button>
                <div
                  className="gray-btn pull-right"
                  onClick={this.closeMember.bind(this)}
                >
                  Cancel
                </div>
                <div className="clear-all" />
              </div>
            </div>
          </div>
        )
    }
    return <></>;
  }
}
