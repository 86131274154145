import React, { Component } from "react";
import { connect } from "react-redux";
import { hideDeleteNotification } from "../../../../store/actions/UIActions";
import { undoDeleteBoard } from "../../../../store/actions/lists";
import PropTypes from "prop-types";

class DeleteNotification extends Component {
  undoDeleteBoard() {
    if (this.props.board) {
      this.props.undoDeleteBoard(this.props.board._id);
    }
  }

  hideDeleteNotification() {
    this.props.hideDeleteNotification();
  }

  render() {
    return (
      <div
        className="action-cont p-b-10 p-r-10 width-240px"
        style={{
          zIndex: 11,
          display: this.props.showDeleteNotification ? "block" : "none",
          top: "0",
          right: "0",
        }}
      >
        <div className="header">
          <div className="disp-inline-block pull-left m-l-10">
            <div className="disp-inline-block dot dot-1" />
            <div className="disp-inline-block dot dot-2" />
            <div className="disp-inline-block dot dot-3" />
            <div className="disp-inline-block dot dot-4" />
          </div>
          <span
            className="close fa fa-times"
            onClick={this.hideDeleteNotification.bind(this)}
          />
        </div>
        <div className="message">
          <img
            style={{ width: "20px", verticalAlign: "sub" }}
            src="../../../assets/images/robot.png"
            alt="robot-img"
          />
          The <b>{this.props.board ? this.props.board.name : ""}</b> was
          deleted.
        </div>
        <div className="right-align">
          <button
            className="blue-btn small font-12 height-auto line-height-20"
            onClick={this.undoDeleteBoard.bind(this)}
          >
            Undo
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  board: state.user.board,
  user: state.user,
});

const mapDispatchToProps = {
  hideDeleteNotification,
  undoDeleteBoard,
};

DeleteNotification.propTypes = {
  board: PropTypes.object,
  user: PropTypes.object,
  showDeleteNotification: PropTypes.bool,
  hideDeleteNotification: PropTypes.func,
  undoDeleteBoard: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteNotification);
