import {useEffect, useState} from "react";
import * as Yup from "yup";
import {withRouter} from "react-router-dom"
import {CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { actions as registerActions } from "../../../../store/reducers/register";
import countries from "../../../../assets/countries";
import amexCard from "../../../../assets/images/amex-card.png"
import discoverCard from "../../../../assets/images/discover-card.png"
import masterCard from "../../../../assets/images/master-card.png"
import visaCard from "../../../../assets/images/visa-card.png"

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      height:"50px",
      border:"1px solid rgba(0,0,0,0.2)",
      borderRadius:"5px",
      '::placeholder': {
        color: '#e3e3e3',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};


const FormConainer = ({next, chosenPlan, chosenMethod, history}) => {
  const dispatch = useDispatch()
  const stripe = useStripe();
  const elements = useElements();
  const [registerData, setRegisterData] = useState({})

  useEffect(()=>{
    setRegisterData(JSON.parse(localStorage.getItem("registerData")))
    const elem = document.getElementById("drift-frame-controller");
    if(elem) {
      elem.style.display = "none"
    }
  }, [])

  const submitPayment = async (values) => {

    if (!stripe || !elements) {

      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const {token, error} = await stripe.createToken(cardElement)
    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', token);
      dispatch({
        type: registerActions.CREATE_SUBSCRIPTION,
        payload: {
          ...values,
          source: token.id,
          chosenPlan,
          chosenMethod,
          history
        }
      })
    }

  };
  return (
    <Formik onSubmit={submitPayment} initialValues={{ name: "", country: "" }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={registerData.email}
              disabled
            />
            {errors.email && touched.email && errors.email}
          </div>
          <div className="form-group card-infos billing-addresss">
            <label htmlFor="name">Card information</label>
            <div className="cardNumber">
              <CardNumberElement options={ELEMENT_OPTIONS} />
              <div className="cards-images">
                <img src={visaCard} alt="visaCard" />
                <img src={masterCard} alt="masterCard" />
                <img src={amexCard} alt="amexCard" />
                <img src={discoverCard} alt="discoverCard" />
              </div>
            </div>
            <div className="city-zip">
            <CardExpiryElement options={ELEMENT_OPTIONS} />
            <CardCvcElement options={ELEMENT_OPTIONS} />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="name">Name on Card</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            {errors.name && touched.name && errors.name}
          </div>
          <div className="form-group billing-addresss">
            <label htmlFor="country">Billing address</label>
            <select
              type="text"
              name="country"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              placeholder="Select Your Country"
            >
              <option value="">Select Your Country</option>
              {countries.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="address1"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address1}
              placeholder="Address line 1"
            />
            <input
              type="text"
              name="address2"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address2}
              placeholder="Address line 2"
            />
            <div className="city-zip">
              <input
                type="text"
                name="city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                placeholder="City"
              />
              <input
                type="text"
                name="zip"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zip}
                placeholder="ZIP"
              />
            </div>
            <input
              type="text"
              name="state"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              placeholder="State"
            />
          </div>
          <button className="submit-button" type="submit" disabled={!errors}>
            Subscribe
          </button>
          <p className="description">
          By confirming your subscription, you allow MavsterLabs LLC to charge your card for this payment and future payments in accordance with their terms.
          </p>
        </form>
      )}
    </Formik>
  );
};

export default withRouter(FormConainer);
