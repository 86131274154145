import React, { Component } from "react";
import {connect} from "react-redux"
import {actions as cardsActions, actions as cardActions} from "../../../../../store/reducers/cards"
import {addCheckList} from "../../../../../store/actions/lists";

class CheckList extends Component {
  constructor(args) {
    super(args);
    this.wrapperRef = React.createRef();
    this.state = {
      // showInput: false,
      showInput: this.props.isFocused,
      title: "",
    };
  }

  // this.showInput();
  componentDidMount() {
    if (this.wrapperRef.current) {
      this.wrapperRef.current.focus();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  hideInput() {
    this.setState({
      showInput: false,
    });
    this.updateCheckList(
      this.state.title,
      this.props.data.isCompleted,
      "onBlur"
    );
  }

  showInput() {
    this.setState({
      showInput: true,
      title: this.props.data.title,
      isChecked: false
    });
    this.props.dispatch({
      type: cardActions.SET_STATE,
      payload: {
        enableAddCheckList: false
      }
    })
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  onToggleCheck() {
    const { isLoggedIn, isPublic } = this.props;
    if (!isLoggedIn || isPublic) {
      return;
    }
    this.updateCheckList(
      this.props.data.title,
      !this.props.data.isCompleted,
      "onToggleCheck"
    );
  }

  updateCheckList(title, isCompleted, action) {
    if (
      action == "onBlur" &&
      !this.state.title &&
      this.props.title === this.state.title
    ) {
      return;
    }
    this.props.editCardCheckList(title, isCompleted, this.props.data._id);
  }

  deleteCheckList() {
    this.props.deleteCardCheckList(this.props.data._id, "single");
  }

  onEnter(callback, e) {
    if (e.which == 13) {
      callback.call(this, e);
    }
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.hideInput();
    }
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.hideInput();
        this.props.dispatch({
            type: cardsActions.SET_STATE,
            payload: {
                enableAddCheckList: true
            }
        })
        this.props.dispatch(addCheckList(this.props.card))
    }
  };

  handleFocus = (event) => event.target.select();

  render() {
    const { data, isClosable, isLoggedIn, isPublic, canEditCheckList, isFocused } = this.props;
    const { showInput } = this.state;
    return (
      <div
        className={`checklist org-checkbox ${
          data.isCompleted ? "org-selected-checkbox" : ""
        } p-5 p-r-30 p-l-0`}
      >
        <input
          className="styled-checkbox"
          id="styled-checkbox-1"
          type="checkbox"
          checked={data.isCompleted}
        />
        <label htmlFor="styled-checkbox-1" className="org-disp-flex">
          <span
            onClick={this.onToggleCheck.bind(this)}
            className="org-checkbox-custom-input"
          />
          <div>
            {showInput && isLoggedIn && !isPublic ? (
              <input
                ref={this.wrapperRef}
                type="text"
                autoFocus={isFocused}
                name="title"
                value={this.state.title}
                onChange={this.onChange.bind(this)}
                onKeyPress={(e) => this.onKeyPress(e)}
                placeholder="Enter checklist"
                onBlur={() => this.hideInput()}
                onFocus={this.handleFocus}
              />
            ) : (
              <p
                className="checklist-title"
                onClick={() => this.showInput()}
                style={{
                  fontSize: "14px",
                  display: "inline",
                  cursor: "pointer",
                  textDecoration:
                    data.title && data.isCompleted ? "line-through" : "none",
                  color: !data.title || data.isCompleted ? "#C0C0C0" : "black",
                }}
              >
                {data.title || "Enter checklist"}
              </p>
            )}
          </div>
          {canEditCheckList && isClosable && isLoggedIn && !isPublic && (
            <span
              className="org-checklist-cross org-cross-icon org-icon org-hover-blue-cross"
              onClick={this.deleteCheckList.bind(this)}
            />
          )}
        </label>
      </div>
    );
  }
}

export default connect(()=>({}))(CheckList);
