import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";
import { MdOutlineArrowBack } from "react-icons/md"
import { GrApple } from "react-icons/gr";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import Divider from "../../../../shared/divider";
import FormConainer from "./form";
import { actions as registerActions } from "../../../../store/reducers/register";
import robot from "../../../../assets/images/robot-white-complete.png";
import "./style.scss";

const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_PUBLIC_KEY);


const Payment = ({ history }) => {
  const dispatch = useDispatch();
  const { chosenPlan = {}, chosenMethod = "monthly", totalPrice } = useSelector(
    (state) => ({
      chosenPlan: state.register.chosenPlan,
      chosenMethod: state.register.chosenMethod,
      totalPrice: state.register.totalPrice,
    })
  );
  useEffect(() => {
    const elem = document.getElementById("drift-frame-controller");
    if(elem) {
      elem.style.display = "none"
    }
    if (!chosenPlan.id) {
      history.push("/register/plans");
    }
    return () => {
      const elem = document.getElementById("drift-frame-controller");
    if(elem) {
      elem.style.display = "block"
    }
    }
  }, []);
  const onChangeBills = (method) => {
    dispatch({
      type: registerActions.SET_STATE,
      payload: {
        chosenPlan: {
          ...chosenPlan,
          totalPrice:
            method === "monthly"
              ? chosenPlan.price
              : chosenPlan.price * 12 * 0.75,
          chosenMethod: method,
        },
        totalPrice:
          method === "monthly"
            ? chosenPlan.price
            : chosenPlan.price * 12 * 0.75,
        chosenMethod: method,
      },
    });
  };

  const nextStep = () => {
    dispatch({
      type: registerActions.SET_STATE,
      payload: {
        currentStep: 4,
      },
    });
  };

  const goBack = () => {
    history.goBack()
  };
  return (
    <div style={{ display: "block" }}>
      {chosenPlan.id && (
        <div className="payment-form">
          <div className="infos">
            <div className="logo-container">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://orgzee.com/", "_blank")}
                className="logo"
                src={robot}
              />
            </div>
            <div className="header">
              <MdOutlineArrowBack onClick={() => goBack()} />
            </div>
            <div className="content">
              <div className="cards">
                <div className="card">
                  {chosenMethod === "monthly" ? (
                    <IoIosCheckmarkCircleOutline
                      onClick={() => onChangeBills("monthly")}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      onClick={() => onChangeBills("monthly")}
                    />
                  )}
                  <div className="infos-container">
                    <div className="hint">Billed Monthly</div>
                    <div className="price">${chosenPlan.price.toFixed(2)}</div>
                  </div>
                </div>
                <div className="card">
                  {chosenMethod === "yearly" ? (
                    <IoIosCheckmarkCircleOutline
                      onClick={() => onChangeBills("yearly")}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      onClick={() => onChangeBills("yearly")}
                    />
                  )}

                  <div className="infos-container">
                    <div className="hint">Billed Annually</div>
                    <div className="price">
                      <div>${(chosenPlan.price * 0.75).toFixed(2)}</div>
                      <div className="discount">Save 25%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="for">{chosenPlan.for}</div>
              <div className="features">
                {chosenPlan.features.map((feature, index) => (
                  <li key={index}>
                    <FaCheck /> {feature}
                  </li>
                ))}
              </div>
              <hr />
              <div className="results">
                <div className="plan">
                  <div className="name">Orgzee {chosenPlan.title} Plan</div>
                  <div className="price">${totalPrice.toFixed(2)}</div>
                </div>
                <div className="quantity">
                  <div>Qty</div> <div>1</div>
                  <div>Billed {chosenMethod}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="form">
            <div className="header"></div>
            <div className="content">
              {/* <button className="apple-pay">
                <AppleIcon />
                Pay
              </button> */}
              <Divider title="Or pay with card" className="divider" />
              <Elements stripe={stripePromise}>
                <FormConainer next={nextStep} chosenPlan={chosenPlan} chosenMethod={chosenMethod} />
              </Elements>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Payment.propTypes = {
  history: PropTypes.object,
};

export default Payment;
