import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from "lodash";
import SlideLeftMenu from '../../../../containers/header/slideLeftMenu';

const { Sider } = Layout;

function LeftSideMenu() {
  const {isSideMenuOpen = false} = useSelector((state) => ({
    isSideMenuOpen: get(state.user, "isSideMenuOpen", false),
  }))

  return (
    <Sider
        theme={"light"}
        className="left-site-layout left-side-bar"
        collapsible
        collapsed={!isSideMenuOpen}
        collapsedWidth={0}
        zeroWidthTriggerStyle={{display: "none"}}
        width={250}
        >
      <div className="left-site-layout_logo" style={{ paddingLeft: "10px" }}>
        <img src='../../../../assets/images/robot_white.png' />
        <span className="text-white text-logo">Orgzee</span>
      </div>
      <SlideLeftMenu />
    </Sider>
  )
}

export default withRouter(LeftSideMenu)
