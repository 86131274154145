/* eslint-disable react/jsx-first-prop-new-line */
import { createStore, applyMiddleware, compose } from "redux";
/* eslint-enable global-require, react/jsx-first-prop-new-line */
import thunk from "redux-thunk";
import logger from "redux-logger";
import createSagaMiddleware from 'redux-saga'

import rootReducer from "./reducers";
import sagas from "./sagas"

const orgzeeRootReducer = (state = {}, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    state = {};
  }

  return rootReducer(state, action);
};
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
if(process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}
const middleware = middlewares.filter(Boolean);

function configureStore(initialState) {
  const store = createStore(
    orgzeeRootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      window.devToolsExtension ? window.devToolsExtension() : (f) => f
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      /* eslint-disable global-require */
      const nextReducer = require("./reducers").default;
      /* eslint-enable global-require */
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const store = configureStore();
sagaMiddleware.run(sagas)
export default store;
