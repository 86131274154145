import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import googleLogo from "../../../assets/images/google-logo.png";
import { FcGoogle } from "react-icons/fc";
import facebookLogo from "../../../assets/images/facebook-logo.png";
import linkedinLogo from "../../../assets/images/linkedin-logo.png";
import { GOOGLE_CLIENT_ID } from "../../../constants";
import { actions as registerActions } from "../../../store/reducers/register";
import { get } from "lodash";
import "./styles.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const SocialLogin = ({
  props,
  history,
  isGoogleRegister,
  pwdLoginWithRegister,
  setRegistrationState,
}) => {
  const [userEmail, setUserEmail] = useState("");
  const { register } = useSelector((state) => ({
    user: state.user,
    register: state.register,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (register?.isExisting) {
      setRegistrationState((prev) => ({
        ...prev,
        checkEmailRegistered: false,
        registerNewUser: false,
        userEmail: userEmail,
        loginUsingGoogle: true,
      }));
      dispatch({
        type: registerActions.IS_EXISTS_FALSE,
        payload: {
          history,
        },
      });
    }
  }, [register]);

  const responseGoogleLogin = async (resp) => {
    await axios({
      method: "post",
      url: "auth/login/google",
      data: {
        ...resp,
      },
    })
      .then((response) => {
        const jwt_token = response.data.token;
        localStorage.setItem("authorization", jwt_token);
        props.getUser().then((res) => {
          const boardId = get(res, "data.data.boards[0]", null);
          if (boardId) {
            history?.push(`/board/${boardId}`);
          }
        });
      })
      .catch((error) => {
        history.push("/register");
      });
  };

  const responseGoogleRegister = async (resp) => {
    const response = dispatch({
      type: registerActions.REGISTER_WITH_GOOGLE,
      payload: {
        history,
        ...resp,
      },
    });
    setUserEmail(response?.payload?.profileObj?.email);
  };

  return (
    <>
      <p className="other-login-text">Or continue with:</p>
      <div className="social-icon-container">
        {/* <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
          // callback={responseFacebook}
          fields="name,email,picture"
          scope="public_profile, email"
          render={(renderProps) => (
            <img
              className="social-logo"
              src={facebookLogo}
              alt="facebookLogo"
              onClick={renderProps.onClick}
            />
          )}
        /> */}
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Sign in with Google"
          onSuccess={
            isGoogleRegister && !pwdLoginWithRegister
              ? responseGoogleRegister
              : responseGoogleLogin
          }
          onFailure={() => {}}
          render={(renderProps) => (
            <button className="social-logo" onClick={renderProps.onClick}>
              <FcGoogle fontSize={"25px"} />
              Google
            </button>
          )}
        />
        {/* <img src={linkedinLogo} className="social-logo" alt="linkedinLogo" /> */}
      </div>
    </>
  );
};

export default SocialLogin;
