import {useEffect} from "react";
import { useSelector } from "react-redux";
import {withRouter} from "react-router-dom"
import vector from "../../../../assets/images/vector.png";
import robot from "../../../../assets/images/Orgzee_robot_success.png";
import "./style.scss";
// Orgzee_robot_success
const SuccessSubsription = ({history}) => {
  const { chosenPlan = {} } = useSelector((state) => ({
    chosenPlan: state.register.chosenPlan,
  }));
  const getStarted = () => {
    const sharedBoardId = window.localStorage.getItem("shared-board-id");
    if(sharedBoardId) {
      window.localStorage.removeItem("shared-board-id")
      window.location.hash = `#/shared/board/${sharedBoardId}`
      window.location.reload()
      return
    }
    window.location.reload();
  };
  useEffect(()=>{
    if (!chosenPlan.id) {
      history.push("/");
    }
  }, [])

  return (
    <div>
      <div className="subscription-success-container">
        <p className="desc">
          You have successfully <br /> subscribed to our
        </p>
        <h1>{chosenPlan.title} plan!</h1>
        <div>
          <button onClick={getStarted}>Get Started</button>
          <img src={robot} alt="Orgzee_robot_success" />
        </div>
      </div>
      <img src={vector} className="vector-image" />
    </div>
  );
};

export default withRouter(SuccessSubsription);
