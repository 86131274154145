import { actions } from "../reducers/boards";

import { all, takeEvery, put, call } from "redux-saga/effects";
import * as services from "../services/board";
import {getBoardList} from "../actions/lists"

export function* COPY_BOARD({payload}) {
  const response = yield call(services.copyBoard, payload);
  if (response.status !== "error") {
    const newboards = response.data.boards
    const newboard = newboards[newboards.length - 1];
    yield put({
      type: actions.SET_STATE,
      payload: {
        showCopyNotification: true,
        message: "Board Copied",
        isSharedView: false
      },
    });
    yield put({
      type: 'SET_STATE',
      payload: {
        boardList: newboards,
        newboard
      }
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showCopyNotification: true,
        errorMessage: response.message,
      },
    });
  }
}

export function* LEAVE_BOARD({payload}) {
  const history = payload.history;
  delete payload.history;
  yield put({
    type: "SET_STATE",
    payload: {isLoading: true}
  })
  const response = yield call(services.leaveBoard, payload.boardId);
  if(response.status !== "error") {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showLeaveBoardNotification: true,
        message: "You leaved the board successfully",
        showLeaveModal: false
      },
    });
    history.push(`/board/${payload.redirectTo}`)
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        errorMessage: response.message,
      },
    });
  }
}

export function* DELETE_BOARD({payload}) {
    yield put({
        type: actions.SET_STATE,
        payload: {
          message: null,
          errorMessage: null
        }
      })
    const response = yield call(services.deleteBoard, payload.newBoardId);
    if(response.error) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          message: response.message,
          errorMessage: response.message
        }
      })
    }
    yield put(getBoardList())
}

export function* REMOVE_MEMBER({payload}) {
  yield put({
    type: "SET_STATE",
    payload: {isLoading: true}
  })

  const response = yield call(services.removeMember, payload.member, payload.board._id);
  if (response.status !== "error") {
    const res = yield call(services.getCurrentBoard, payload.board._id)
    const board = yield call(sortColumn, res.data);
    yield put({
      type: "SET_STATE",
      payload: {
        activeBoard: board,
        isSharedView: false,
        isLoading: false
      },
    });
  } else {
    yield put({
      type: "SET_STATE",
      payload: {
        showCopyNotification: true,
        errorMessage: response.message,
        isLoading: false
      },
    });
  }
}

function sortColumn(board) {
  if (board) {
    board.columns.sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });
  }
  return board;
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.COPY_BOARD, COPY_BOARD),
    takeEvery(actions.DELETE_BOARD, DELETE_BOARD),
    takeEvery(actions.LEAVE_BOARD, LEAVE_BOARD),
    takeEvery(actions.REMOVE_MEMBER, REMOVE_MEMBER),
  ]);
}
