import { actions } from "../reducers/profile";

import { all, takeEvery, put, call } from "redux-saga/effects";
import * as services from "../services/profile.js";
import { setGloabalLoading } from "../reducers/profile";

export function* GET_CURRENT_CUSTOMER() {
  yield put(setGloabalLoading(true));
  const response = yield call(services.getCurrentCustomer);
  if (response.status !== "error") {
    yield put({
      type: actions.SET_STATE,
      payload: {
        customer: response.customer,
        invoices: response.invoices,
        plan: response.plan,
        showErrorMessage: false
      },
    });
  } else {
    yield put(setGloabalLoading(false));
    yield put({
      type: actions.SET_STATE,
      payload: {
        showErrorMessage: true,
        errorMessage: response.message,
      },
    });
  }
}

export function* GET_PORTAL_URL() {
  yield put(setGloabalLoading(true));
  const response = yield call(services.getustomerPortalUrl);
  if (response.status !== "error") {
    window.location.replace(response.redirectUrl.url);
    yield put(setGloabalLoading(false));
  } else {
    yield put(setGloabalLoading(false));
    yield put({
      type: actions.SET_STATE,
      payload: {
        showErrorMessage: true,
        errorMessage: response.message,
      },
    });
  }
}

export function* GET_UPDATE_PAYMENT_URL() {
  yield put(setGloabalLoading(true));
  const response = yield call(services.getustomerPortalUrl);
  if (response.status !== "error") {
    window.location.replace(response.redirectUrl.url + "/payment-methods");
    yield put(setGloabalLoading(false));
  } else {
    yield put(setGloabalLoading(false));
    yield put({
      type: actions.SET_STATE,
      payload: {
        showErrorMessage: true,
        errorMessage: response.message,
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CURRENT_CUSTOMER, GET_CURRENT_CUSTOMER),
    takeEvery(actions.GET_PORTAL_URL, GET_PORTAL_URL),
    takeEvery(actions.GET_UPDATE_PAYMENT_URL, GET_UPDATE_PAYMENT_URL),
  ]);
}
