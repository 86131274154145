import MainModal from "../../components/modal";
import PropTypes from 'prop-types';
import Tabs from "../../components/tabs"
import { useState } from "react";
import Infos from "./infos";
import UpdatePassword from "./password";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Alert } from 'antd';
import Notifications from "./notifications";
import Subscription from "./subscription";

const listTabs = [
    {
        key: 1,
        title: "Profile"
    },
    {
        key: 2,
        title: "Password"
    },
    {
        key: 3,
        title: "Notifications"
    },
    {
        key: 4,
        title: "Subscription"
    },
]

const ProfileModal = ({ open, handleClose }) => {
    const {message = "", status = ""} = useSelector((state) => ({
        status: state.user.status,
        message: state.user.message
    }));

    const [currentTab, setCurrentTab] = useState(listTabs[0]);
    const dispatch = useDispatch();

    useEffect(()=> {
        dispatch({
            type: "SET_STATE",
            payload: {status: "", message: ""}
        });
        return () => {
        dispatch({
            type: "SET_STATE",
            payload: {status: "", message: ""}
        });
        };
    }, [currentTab]);

    return (
        <MainModal open={open} handleClose={handleClose} title={"Account Settings"}>
            <>
            <Tabs list={listTabs} current={currentTab} open={setCurrentTab} />
            {status === "success" && <Alert message={message} type="success" showIcon />}
            {status === "error" && <Alert message={message} type="error" showIcon />}
            <div className={styles.content}>
                {currentTab.key === 1 && <Infos handleClose={handleClose} />}
                {currentTab.key === 2 && <UpdatePassword handleClose={handleClose} />}
                {currentTab.key === 3 && <Notifications handleClose={handleClose} />}
                {currentTab.key === 4 && <Subscription handleClose={handleClose} />}
            </div>
            </>
        </MainModal>
    );
};

ProfileModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.bool.isRequired
};

export default ProfileModal;