import MainModal from "../../../../components/modal";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import styles from "./style.module.scss";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useState } from "react";
import { actions } from "../../../../store/reducers/boards";
import { withRouter } from "react-router-dom";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too short")
        .max(50, "Too long")
        .required("Field Required"),
});

const CopyBoardComponent = ({isOpen, handleClose, history = {}}) => {
    const {activeBoard = {}} = useSelector((state) => ({activeBoard: state.user.activeBoard}))
    const dispatch = useDispatch()
    const initialValues = {
        name: `Copy - ${activeBoard.name}`,
        titles: true,
        descriptions: true,
        labels: true,
        attachments: true,
        checklists: true
    };
    const [selectAll, setSelectAll] = useState(true)

    const onSubmit = (values) => {
        if(activeBoard.isCopying) {
            dispatch({
                type: actions.COPY_BOARD,
                payload: {
                    history,
                    boardId: activeBoard._id,
                    ...values
                }
            })
        }
        history.push(`/board/`);
        handleClose();
    };

    const isError = (submitCount, error) => {
        return submitCount > 0 ? error : false;
    };

    const handleSelectAll = (e, setFieldValue) => {
        const checked = e.target.checked;
        setSelectAll(checked)
        setFieldValue("titles", checked);
        setFieldValue("descriptions", checked);
        setFieldValue("labels", checked);
        setFieldValue("title", checked);
        setFieldValue("attachments", checked);
        setFieldValue("checklists", checked);
    }

    return (
        <MainModal open={isOpen} handleClose={handleClose} title={"Copy Board"}>
            <div className={styles.CopyBoardComponent}>
                <p className={styles.description}>
                    Select the card and board attributes to copy over to the new board.
                </p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, submitCount, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                        return (
                            <Form className="formik-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className={`${isError(submitCount, errors.name) && "error"
                                            }`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    <span className="field-error">{isError(submitCount, errors.name)}</span>
                                </div>
                                <div className="form-group checkboxgroup">
                                    <input
                                        type="checkbox"
                                        name="select"
                                        id="select"
                                        onChange={(e) => handleSelectAll(e, setFieldValue)}
                                        checked={selectAll}
                                    />
                                    <label htmlFor="lastName">Select All</label>
                                </div>
                                <div className={styles.checkboxs}>
                                    <div className="form-group checkboxgroup">
                                        <input
                                            type="checkbox"
                                            name="titles"
                                            id="titles"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.titles}
                                        />
                                        <label htmlFor="lastName">Titles</label>
                                    </div>

                                    <div className="form-group checkboxgroup">
                                        <input
                                            type="checkbox"
                                            name="labels"
                                            id="labels"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.labels}
                                        />
                                        <label htmlFor="lastName">Labels</label>
                                    </div>

                                    <div className="form-group checkboxgroup">
                                        <input
                                            type="checkbox"
                                            name="descriptions"
                                            id="descriptions"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.descriptions}
                                        />
                                        <label htmlFor="lastName">Descriptions</label>
                                    </div>

                                    <div className="form-group checkboxgroup">
                                        <input
                                            type="checkbox"
                                            name="attachments"
                                            id="attachments"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.attachments}
                                        />
                                        <label htmlFor="lastName">Attachments</label>
                                    </div>

                                    <div className="form-group checkboxgroup">
                                        <input
                                            type="checkbox"
                                            name="checklists"
                                            id="checklists"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.checklists}
                                        />
                                        <label htmlFor="lastName">Checklists</label>
                                    </div>
                                </div>

                                <div className={"form-footer"}>
                                    <button
                                        className={`btn default-button`}
                                        onClick={handleClose}
                                        type="button"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className={`btn submit-button`}
                                        type="submit"
                                    >
                                        Copy Board
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </MainModal>
    );
};

CopyBoardComponent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

CopyBoardComponent.defaultProps = {
    isOpen: true,
    handleClose: () => {}
}

export default withRouter(CopyBoardComponent);