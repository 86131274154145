import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ListsActions from "../../../../../store/actions/lists";
import * as UIActions from "../../../../../store/actions/UIActions";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...ListsActions, ...UIActions }, dispatch),
});

class CardActions extends Component {
  getOrderedCards(card, isTop = true) {
    const { user } = this.props;
    const { activeBoard } = user;
    const columns =
      activeBoard && activeBoard.columns ? activeBoard.columns : [];
    const { _id: cardId, column: colId } = card;
    const column = columns.find((clmn) => clmn._id == colId);
    let updatedList = [];
    if (column) {
      const { cards } = column;
      updatedList = isTop
        ? this.cardShiftTop(cards, cardId)
        : this.cardShiftBottom(cards, cardId);
      updatedList.map((list, index) => {
        list.position = index;
        return list;
      });
    }
    return updatedList;
  }

  addToTop(card) {
    const updatedList = this.getOrderedCards(card);
    if (updatedList.length > 1) {
      const { board, column: colId } = card;
      this.props.handlePopup();
      this.props.actions.moveCardToTopOrBottom(updatedList, colId, board);
    }
  }

  addToBottom(card) {
    const updatedList = this.getOrderedCards(card, false);
    if (updatedList.length > 1) {
      const { board, column: colId } = card;
      this.props.handlePopup();
      this.props.actions.moveCardToTopOrBottom(updatedList, colId, board);
    }
  }

  cardShiftTop(cards, cardId) {
    return cards.sort((x, y) => {
      return x._id == cardId ? -1 : y._id == cardId ? 1 : 0;
    });
  }

  cardShiftBottom(cards, cardId) {
    return cards.sort((x, y) => {
      return y._id == cardId ? -1 : x._id == cardId ? 1 : 0;
    });
  }

  deleteCard(card) {
    this.props.actions.deleteCard(card);
  }

  setHeaderColor(card, e) {
    let headerColor = "transparent";
    if (
      e.currentTarget.style.backgroundColor &&
      card.headerColor != e.currentTarget.style.backgroundColor
    ) {
      headerColor = e.currentTarget.style.backgroundColor;
    }
    this.props.handlePopup();
    this.props.setHeaderColor.call(this, card, { headerColor });
  }

  showHideMoveCardToBoardModal = (bool, card) => {
    this.props.handlePopup();
    this.props.actions.showHideMoveCardToBoardModal(bool, card);
  }

  render() {
    const { card } = this.props;
    return (
      <div className="card-delete action-list">
        <div
          className="action"
          onClick={() => {
            this.props.handlePopup();
            this.props.addCheckList(card);
          }}
        >
          Add Checklist
        </div>
        <div
          className="action"
          onClick={() => {
            this.props.handlePopup();
            this.props.actions.addDuplicateCard(card);
          }}
        >
          Add Duplicate
        </div>
        <div
          className="action"
          onClick={() => this.showHideMoveCardToBoardModal(true, card)}
        >
          Move to board
        </div>
        <div className="action" onClick={() => this.addToTop(card)}>
          Move to top
        </div>
        <div className="action" onClick={() => this.addToBottom(card)}>
          Move to bottom
        </div>
        <div className="action">
          <div>Set Color</div>
          <div className="disp-inline-block pull-left m-l-10">
            <div
              className="disp-inline-block dot"
              style={{
                backgroundColor: "tranparent",
                border: "0.1px solid grey",
              }}
              onClick={this.setHeaderColor.bind(this, this.props.card)}
            >
              {this.props.card &&
              this.props.card.headerColor === "transparent" ? (
                <i
                  className="fa fa-check"
                  style={{ color: "red" }}
                  aria-hidden="true"
                />
              ) : (
                ""
              )}
            </div>
            <div
              className="disp-inline-block dot"
              style={{ backgroundColor: "#2A985B" }}
              onClick={this.setHeaderColor.bind(this, this.props.card)}
            >
              {(this.props.card && this.props.card.headerColor) ===
              "rgb(42, 152, 91)" ? (
                <i className="fa fa-check" aria-hidden="true" />
              ) : (
                ""
              )}
            </div>
            <div
              className="disp-inline-block dot"
              style={{ backgroundColor: "#FB9D00" }}
              onClick={this.setHeaderColor.bind(this, this.props.card)}
            >
              {(this.props.card && this.props.card.headerColor) ===
              "rgb(251, 157, 0)" ? (
                <i className="fa fa-check" aria-hidden="true" />
              ) : (
                ""
              )}
            </div>
            <div
              className="disp-inline-block dot"
              style={{ backgroundColor: "#9E49D0" }}
              onClick={this.setHeaderColor.bind(this, this.props.card)}
            >
              {(this.props.card && this.props.card.headerColor) ===
              "rgb(158, 73, 208)" ? (
                <i className="fa fa-check" aria-hidden="true" />
              ) : (
                ""
              )}
            </div>
            <div
              className="disp-inline-block dot"
              style={{ backgroundColor: "#ff0000" }}
              onClick={this.setHeaderColor.bind(this, this.props.card)}
            >
              {(this.props.card && this.props.card.headerColor) ===
              "rgb(255, 0, 0)" ? (
                <i className="fa fa-check" aria-hidden="true" />
              ) : (
                ""
              )}
            </div>
            <div
              className="disp-inline-block dot"
              style={{ backgroundColor: "#4990E2" }}
              onClick={this.setHeaderColor.bind(this, this.props.card)}
            >
              {(this.props.card && this.props.card.headerColor) ===
              "rgb(73, 144, 226)" ? (
                <i className="fa fa-check" aria-hidden="true" />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="action" onClick={this.deleteCard.bind(this, card)}>
          Delete
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardActions);
