import React from "react";
import PropTypes from "prop-types";
import { Modal as AntdModal } from "antd";
import styles from "./styles.module.scss";

const MainModal = ({ open = false, title = "", children, handleClose }) => {
  return (
    <AntdModal
      visible={open}
      onCancel={handleClose}
      footer={null}
      destroyOnClose
      maskTransitionName=""
      transitionName=""
      maskClosable={true}
      className={styles.modal}
      width="fit-content"
      style={{
        padding: 0,
      }}
    >
      <div className={styles.paper}>
        <div className={styles.header}>
          <h1>{title}</h1>
        </div>
        {children}
      </div>
    </AntdModal>
  );
};

MainModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default MainModal;
