import PropTypes from "prop-types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Card from "../card";

const CardsContainer = ({ column = {}, isSharedView = false, canDragAndDrop = false }) => {
  const { cards = [] } = column;
  return (
    <>
      <Droppable droppableId={column._id} ignoreContainerClipping type="CARD">
        {(provided) => (
          <div
            ref={provided.innerRef}
            className="board-container"
            {...provided.droppableProps}
          >
            <div className="droppable-area-cl" style={{ minHeight: "140px" }}>
              <div className="inner-droppable-area">
                {cards.map((card, index) => {
                  return (
                    <Draggable
                      key={card._id}
                      draggableId={card._id}
                      index={index}
                      isDropDisabled={isSharedView}
                      isDragDisabled={!canDragAndDrop}
                    >
                      {(dragProvided) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <Card
                            item={{ assignTo: {}, ...card }}
                            column={column}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
};

CardsContainer.propTypes = {
  column: PropTypes.object,
  canDragAndDrop: PropTypes.bool
};

export default CardsContainer;
