import { useDispatch } from "react-redux";
import MainModal from "../../../../components/modal";
import { deleteBoard } from "../../../../store/actions/lists";
import styles from "./style.module.scss";

export default function DeleteBoardAlert({ handleClose, boardName, board}) {
    const dispatch = useDispatch();
    const onDeleteBoard = () => {
        handleClose()
        dispatch(deleteBoard(board))
    }
    return (
        <MainModal open title={`Delete the '${boardName}' board?`} handleClose={handleClose}>
            <div className={styles.deleteBoardAlert}>
                <p>This will delete the board and any task associated with it.</p>
                <div className={"form-footer"}>
                    <button
                        className={`btn default-button`}
                        onClick={handleClose}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        className={`btn submit-button`}
                        onClick={onDeleteBoard}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </MainModal>
    )
}