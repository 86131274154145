import React, { Component } from "react";
import Header from "../header/credentialModalHeader";
import { connect } from "react-redux";
import * as UserActions from "../../store/actions/user";
import Spinner from "../spinner/spinner";
import { withRouter } from "react-router-dom";
import { get } from "lodash";

class ChangePassword extends Component {
  constructor(args) {
    super(args);
    this.state = {
      isConfirmPasswordValid: true,
      isPasswordValid: true,
    };
  }

  changePassword() {
    const { newPassword, oldPassword } = this.state;
    const { email } = this.props;
    if (newPassword && newPassword.trim().length > 0 && email) {
      this.props
        .changePassword(email, oldPassword, newPassword)
        .then((resp) => {
          const status = get(resp, "status");
          const boardId = get(resp, "data.boards[0]");
          if (status === 200 && boardId) {
            this.props.history.push(`/board/${boardId}`);
          }
        });
    }
  }

  onPasswordChange(e) {
    if (e.currentTarget.id === "oldPassword") {
      this.setState({ oldPassword: e.currentTarget.value });
    } else if (e.currentTarget.id === "newPassword") {
      this.setState({ newPassword: e.currentTarget.value });
      this.setState({
        isPasswordValid: /[0-9a-zA-Z!@#$%^&*]{6,}$/.test(e.currentTarget.value),
      });
    } else if (e.currentTarget.id === "confirmPassword") {
      this.setState({ confirmPassword: e.currentTarget.value });
      this.state.confirmPassword = e.currentTarget.value;
      this.state.isConfirmPasswordValid =
        e.currentTarget.value === this.state.newPassword;
    }
    if (this.state.newPassword) {
      this.setState({ isFormSubmittable: true });
    } else {
      this.setState({ isFormSubmittable: false });
    }
  }

  render() {
    return (
      <div className="backdrop" style={{ display: "block" }}>
        <div className="popup fullscreen">
          <div className="body center-align fullscreen">
            <Header />
            <div className="control-box">
              <div className="center-align m-t-20">
                <img src="assets/images/robot.png" />{" "}
              </div>
              <div className="m-b-10 m-t-10 center-align text-black font-25">
                Welcome to Orgzee
              </div>
              <div className="m-b-10 m-t-10 center-align text-black font-14">
                Just create your password and you're ready to go!
              </div>
              {/* <div className="center-align m-t-20 m-b-10"><input className="inputbox full-width" type="password"  placeholder="Old Password" id="oldPassword" onKeyUp={this.onPasswordChange.bind(this)}/></div> */}
              <div className="center-align m-b-10">
                <input
                  className={
                    "inputbox full-width " +
                    (this.state.isPasswordValid ? "" : "error")
                  }
                  type="password"
                  placeholder="New Password"
                  id="newPassword"
                  onKeyUp={this.onPasswordChange.bind(this)}
                />
              </div>
              {/* <div className="center-align m-b-10"><input className={"inputbox full-width " + (this.state.isConfirmPasswordValid?'':'error')} type="password"  placeholder="Confirm Password" id="confirmPassword" onKeyUp={this.onPasswordChange.bind(this)}/></div> */}
              <div className="m-b-10 m-t-10 center-align text-black font-13">
                {" "}
                Your new password must have atleast 6 characters.
              </div>
              <div
                className={
                  "blue-btn full-width m-l-0 m-r-0 p-l-0 p-r-0 sign-in " +
                  (this.state.isFormSubmittable ? " " : "disabled")
                }
                onClick={this.changePassword.bind(this)}
              >
                Go
              </div>
            </div>
          </div>
        </div>
        <Spinner show={this.props.user.isLoading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  email: get(state, "user.data.user.email"),
});
const mapDispatchToProps = { ...UserActions };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangePassword));
