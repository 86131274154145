import React, { Component } from "react";
import * as ListsActions from "../../../../../../store/actions/lists";
import * as UserActions from "../../../../../../store/actions/user";
import * as UIActions from "../../../../../../store/actions/UIActions";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment'
class TaskActions extends Component {
  constructor() {
    super();
  }

  doneTheTask(selectedCardDetail) {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      this.props.doneTheTask.call(this, tasks, cardId, board);
    }
  }

  extendDueDateHour(selectedCardDetail) {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      const taskLists = tasks.map((task) => {
        const { date } = task;
        const newDate = moment(date).add(1, "hour");
        task.date = newDate;
        return task;
      });
      this.props.activateTask.call(this, taskLists, cardId, board);
    }
  }

  extendDueDateDay(selectedCardDetail) {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      const taskLists = tasks.map((task) => {
        const { date } = task;
        const newDate = moment(date).add(1, "day");
        task.date = newDate;
        return task;
      });
      this.props.activateTask.call(this, taskLists, cardId, board);
    }
  }

  extendDueDateWeek(selectedCardDetail) {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      const taskLists = tasks.map((task) => {
        const { date } = task;
        const newDate = moment(date).add(1, "week");
        task.date = newDate;
        return task;
      });
      this.props.activateTask.call(this, taskLists, cardId, board);
    }
  }

  activateTask(selectedCardDetail) {
    const { tasks = [], board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      this.props.activateTask.call(this, tasks, cardId, board);
    }
  }

  setNewDueDate(item) {
    const { user } = this.props;
    const isUserLoggedIn = get(user, "isUserLoggedin", false);
    const isPublic = get(user, "isPublic", false);
    if (!isUserLoggedIn || isPublic) {
      return;
    }
    window.history.pushState(
      null,
      null,
      `/board/${item.board}/column/${item.column}/card/${item._id}`
    );
    this.props.openCardDetail.call(this, item, true);
  }

  render() {
    const {item } = this.props
    return (
      <div className="action-list">
        <div
          className="action"
          onClick={() => this.doneTheTask(item)}
        >
          Done
        </div>
        <div
          className="action"
          onClick={() => this.extendDueDateHour(item)}
        >
          Remind me in an hour
        </div>
        <div
          className="action"
          onClick={() => this.extendDueDateDay(item)}
        >
          Remind me tomorrow
        </div>
        <div
          className="action"
          onClick={() => this.extendDueDateWeek(item)}
        >
          Remind me in a week
        </div>
        <div
          className="action"
          onClick={() => this.setNewDueDate(item)}
        >
          Set New Due Date
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    UI: state.UI,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ListsActions,
      ...UserActions,
      ...UIActions,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaskActions));
