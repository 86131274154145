import axios from "axios";
export async function copyBoard(data) {
  return axios({
    method: "post",
    url: "board/copy-board",
    headers: {
      Authorization: localStorage.getItem("authorization"),
    },
    data
  }).then((resp) => resp.data).catch((error)=> {
    return error.response.data;
  })
}

export async function deleteBoard(boardId) {
    return axios({
        method: "delete",
        url: `board/${boardId}`,
        headers: { Authorization: localStorage.getItem("authorization") },
      })
}

export async function leaveBoard(boardId) {
  return axios({
      method: "get",
      url: `board/leave?boardId=${boardId}`,
      headers: { Authorization: localStorage.getItem("authorization") },
    }).then((res) => res.data).catch((err) => err.response.data)
}

export async function removeMember(member, board) {
  return axios({
      method: "get",
      url: `board/remove-member`,
      data: {member, board},
      headers: { Authorization: localStorage.getItem("authorization") },
    }).then((res) => {
      return res.data
    }).catch((err) => err.response.data)
}

export async function getCurrentBoard(id) {
  return axios({
    method: "get",
    url: `board/get-board/${id}`,
  }).then((resp) => resp.data).catch((error)=> {
    return error.response.data;
  })
}