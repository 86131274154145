import { useRef, useEffect, useState } from "react";
import RenderBreadCrum from "./renderBreadCrum";
import BoardMembers from "./boardMembers";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleShowTitleOnly,
} from "../../../store/actions/lists";
import { get } from "lodash";
import { showEditBoardMain } from "../../../store/actions/UIActions";
import { editBoardName } from "../../../store/actions/lists";
import { toggleBoardSettings } from "../../../store/actions/UIActions";
import BoardSettingMenu from "./boardSettings";
import Member from "../../member/Member";
import BoardSettings from "../BoardSettings/BoardSettings";
import BoardHistory from "../../boardHistory/BoardHistory";
import Memberprofile from "../../member/MemberProfile";
import CopyBoardComponent from "./copyBoard";
import CopyBoardNotification from "./copyBoardNotification";
import ShareBoardModal from "../ShareBoardModal/ShareBoardModal"
import { canEditBoard } from "../../../helper";
import DeleteBoardAlert from "./deleteBoardAlert";
import { actions } from "../../../store/reducers/boards";

const BoardHeader = ({ ...props }) => {
  const {
    user,
    showCopyNotification = true,
    openDeleteBoard = false
  } = useSelector((state) => ({
    user: state.user,
    showCopyNotification: state.boards.showCopyNotification,
    openDeleteBoard: state.boards.openDeleteBoard
  }));
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [isMemberOpen, setMemberOpen] = useState(false);
  const [memberTop, setMemberTop] = useState(null);
  const [isBoardHistoryOpen, setBoardHistoryOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [isMemberProfileOpen, setMemberProfilOpen] = useState(false);
  const [openCopyBoard, setOpenCopyBoard] = useState(false);

  useEffect(() => {
    if (user.editBoardMain && user.activeBoard) {
      inputRef.current.focus();
      inputRef.current.select()
    }
  }, [user.editBoardMain]);

  const handleToggle = () => {
    const showTitleOnly = !user.activeBoard.showTitleOnly;
    dispatch(toggleShowTitleOnly(showTitleOnly, user.activeBoard._id));
  };

  const setOpenDeleteBoard = (open) => {
    dispatch({
      type: actions.SET_STATE,
      payload: {
        openDeleteBoard: open
      }
    })
  }


  const onEditBoard = () => {
      canEditBoard(user.currentMember, user.isUserLoggedin, user.isSharedView)
    if (canEditBoard(user.currentMember, user.isUserLoggedin, user.isSharedView)) {
      dispatch(showEditBoardMain(user.activeBoard));
    }
  };

  // const onBlurEditBoardMain = () => {
  //   dispatch(showEditBoardMain(null));
  // }

  const onBlurEditBoardMain = (e) => {
    if (e.target.value) {
      dispatch(editBoardName(user.activeBoard, user.boardList, e.target.value));
    }
  };

  const handlePopup = (id, e) => {
    const clickedId = e.target.id;
    if (clickedId !== "org_board_options") {
      return;
    }
    setTimeout(() => {
      const ele = document.getElementById(id);
      if (ele.className.indexOf("org-show-tooltip") === -1) {
        ele.className += "org-show-tooltip";
      } else {
        ele.className = ele.className.replace("org-show-tooltip", "");
      }
    }, 300);
  };

  const toggleBoardHistory = () => {
    if (isBoardHistoryOpen) {
      setBoardHistoryOpen(false);
    } else {
      setBoardHistoryOpen(true);
    }
  };

  const toggleBoardShareModal = () => {
    dispatch({
      type: "SET_STATE",
      payload: {toggleShareBoardModal: true}
    })
  };

  const closeBoardShareModal = () => {
    dispatch({
      type: "SET_STATE",
      payload: {toggleShareBoardModal: false}
    })
  };

  const openMemberList = (e) => {
    setMemberOpen(true);
    setMemberTop(e.clientY - 20);
  };

  const inviteMember = (email, name, role) => {
    if (email && name && get(props, 'user.activeBoard._id')) {
      props.inviteMember(email, name, role, get(props, 'user.activeBoard._id'));
    }
  };

  const onKeyDown = (e)=>{
    if (e.key === "Enter") {
      onBlurEditBoardMain(e)
    }
  }

  const canAddUser = () => {
    if (user.isUserLoggedin && !get(user, "isSharedView", false) && user.activeBoard) {
      const members = user.activeBoard.members;
      return members.findIndex((mbr) => mbr.role == "ADMIN" && mbr.email?.email == user.loggedInUser.email) >= 0
    }
    return false;
  }

  return (
    <>
      <div
        className="boards"
        id="boards-filters"
        style={{
          padding: "15px",
          marginTop: props.marginTop,
          background: "white",
        }}
      >
        <div className="m-l-10" style={{ display: "inline-block" }}>
          <RenderBreadCrum user={user} />
          <div
            style={{
              display: user.editBoardMain ? "none" : "inline-block",
            }}
            onClick={onEditBoard}
            className={`current-board-name ${get(user, "isSharedView", false) ? "no-hover-border":""}`}
          >
            {user.activeBoard?.name?.trim() ? user.activeBoard?.name : "Unnamed Board"}
          </div>
        </div>
        {user.editBoardMain && user.activeBoard &&
          <input
            key="loaded"
            className="edit-text-main current-board-name"
            ref={inputRef}
            onClick={onEditBoard}
            style={{
              display:
              user.editBoardMain && user.activeBoard ? "inline-block" : " none",
            }}
            onBlur={onBlurEditBoardMain}
            type="text"
            defaultValue={user.activeBoard?.name}
            onKeyDown={onKeyDown}
        />}
        <div className="board-button-group">
          <div className="disp-inline-block pos-rel text-grey show-tiles-only ">
            <div className="align-center">
              <span style={{ verticalAlign: "inherit" }}>Show Title only</span>
              <span style={{ marginLeft: "5px" }}>
                {user.activeBoard && user.activeBoard?.showTitleOnly ? (
                  <img
                    src="../../assets/images/toggle-button.svg"
                    className="fa-toggle-on"
                    onClick={handleToggle}
                  />
                ) : (
                  <img
                    src="../../assets/images/toggle-button-off.svg"
                    className="fa-toggle-off"
                    onClick={handleToggle}
                  />
                )}
              </span>
            </div>
          </div>
          <BoardMembers
            setSelectedMember={setSelectedMember}
            selectedMember={selectedMember}
            isMemberProfileOpen={isMemberProfileOpen}
            setMemberProfilOpen={setMemberProfilOpen}
          />
          {canAddUser() && (
            <div className="add-btn" onClick={openMemberList}>
              <img
                src="../../../assets/images/add-user-to-board.svg"
                style={{ height: "30px", width: "30px" }}
              />
            </div>
          )}
          {get(user, "isUserLoggedin", false) &&
            !get(user, "isSharedView", false) && (
              <BoardSettingMenu
                isCopying={get(user, "activeBoard.isCopying", false)}
                isPublic={get(user, "activeBoard.isCopying", false)}
                board={get(user, 'activeBoard')}
                isCurrentBoardPublic={get(user, "activeBoard.isPublic")}
                toggleBoardHistory={toggleBoardHistory}
                toggleBoardShareModal={toggleBoardShareModal}
                handlePopup={handlePopup}
                toggleBoardSettings={() => dispatch(toggleBoardSettings(true))}
                showDeleteBoard={() => setOpenDeleteBoard(true)}
                setOpenCopyBoard={setOpenCopyBoard}
              />
            )}
          {isMemberOpen && (
            <Member
              closeMember={() => setMemberOpen(false)}
              isMemberOpen={isMemberOpen}
              top={memberTop}
              inviteMember={inviteMember}
              members={
                user.activeBoard && user.registeredAppUsers
                  ? user.registeredAppUsers
                  : []
              }
            />
          )}
          {user.toggleBoardSettings && (
            <BoardSettings
              toggleBoardSettings={user.toggleBoardSettings}
              handleClose={() => dispatch(toggleBoardSettings(false))}
            />
          )}
          <BoardHistory
            isBoardHistoryOpen={isBoardHistoryOpen}
            toggleBoardHistory={toggleBoardHistory}
            boardHistoryList={user.boardHistoryList}
          />
        </div>
      </div>
      <CopyBoardComponent isOpen={openCopyBoard} handleClose={() => setOpenCopyBoard(false)} />
      <Memberprofile
        isProfileOpen={isMemberProfileOpen}
        member={selectedMember}
        closeMemberProfile={() => setMemberProfilOpen(false)}
      />
      {user.toggleShareBoardModal && <ShareBoardModal activeBoard={user.activeBoard} isPublic={user.activeBoard.isPublic} toggleShareBoardModal={true} handleClose={closeBoardShareModal} />}
      {showCopyNotification && <CopyBoardNotification /> }
      {openDeleteBoard &&
        <DeleteBoardAlert
          boardName={user.activeBoard.name}
          board={user.activeBoard}
          handleClose={()=> setOpenDeleteBoard(false)}
        />
        }
    </>
  );
};

export default BoardHeader;
