import React, { Component } from "react";
import RichTextEditor from "react-rte";
import moment from "moment";

export default class BoardHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardHistoryList: this.props.boardHistoryList|| [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.boardHistoryList &&
      JSON.stringify(nextProps.boardHistoryList) !==
        JSON.stringify(this.props.boardHistoryList)
    ) {
      this.setState({ boardHistoryList: nextProps.boardHistoryList.reverse() });
    }
  }

  getActionText = (history) => {
    let text, preposition;
    if (history.action == "add") {
      text = "added";
      preposition = "to";
    }
    if (history.action == "edit") {
      text = "edited";
      preposition = "under";
    }
    if (history.action == "delete") {
      text = "deleted";
      preposition = "from";
    }
    if (history.action == "add_tag") {
      text = "added tag";
      preposition = "to";
    }
    if (history.action == "remove_tag") {
      text = "removed tag";
      preposition = "from";
    }
    if (history.action == "add_column") {
      text = "added column";
      preposition = "";
    }
    if (history.action == "remove_column") {
      text = "removed column";
      preposition = "";
    }
    if (history.action == "edit_column") {
      text = "changed column name to";
      preposition = "from";
    }
    if (history.action == "edit_board") {
      text = "changed board name to";
      preposition = "";
    }
    if (history.action == "add_task") {
      text = "assigned task";
      preposition = "to";
    }
    if (history.action == "add_attachment") {
      text = "added an attachment to";
      preposition = "under";
    }
    if (history.action == "complete_task") {
      text = "completed task";
      preposition = "under card";
    }
    if (history.action == "add_comment") {
      text = "commented";
      preposition = "on card";
    }
    if (history.action == "add_note") {
      text = "add note";
      preposition = "under card";
    }
    if (history.action == "add_member") {
      text = "is now board member";
      preposition = "";
    }
    if (history.action == "mention") {
      text = "is mentioned";
      preposition = "under";
    }
    if (history.action == "done_task") {
      text = "done";
      preposition = "";
    }
    return { text, preposition };
  };

  getComponentText = (history) => {
    let text = "";
    if (history.componentType == "card") {
      text = history.component.title;
    }
    if (
      history.componentType == "tag" ||
      history.componentType == "task" ||
      history.componentType == "comment" ||
      history.componentType == "note" ||
      history.componentType == "board" ||
      history.action == "add_column" ||
      history.action == "remove_column" ||
      history.action == "mention"
    ) {
      text = history.title;
    }
    if (history.componentType == "note") {
      text = RichTextEditor.createValueFromString(history.title, "html")
        .getEditorState()
        .getCurrentContent()
        .getPlainText()
        .trim();
    }
    if (history.action == "edit_column") {
      text = history.component.name;
    }

    return text;
  };

  getParentComponentText = (history) => {
    let text = "";
    if (history.componentType == "card") {
      text = history.parentComponent.name
        ? history.parentComponent.name
        : "Column Name Here";
    }
    if (history.componentType == "tag" || history.componentType == "mention") {
      text = history.component.title
        ? history.component.title
        : "Enter Card Title";
    }
    if (history.action == "add_task") {
      text =
        history.component.assignTo && history.component.assignTo.firstName
          ? history.component.assignTo.firstName
          : "";
    }
    if (history.action == "complete_task") {
      text = history.parentComponent.title
        ? history.parentComponent.title
        : "Enter Card Title";
    }
    if (history.action == "add_comment") {
      text = history.title ? history.parentComponent.title : "Enter Card Title";
    }
    if (history.action == "add_note") {
      text = history.parentComponent.title
        ? history.parentComponent.title
        : "Enter Card Title";
    }
    if (history.action == "edit_column") {
      text = history.title;
    }
    return text;
  };

  _renderBoardHistory = () => {
    const { isBoardHistoryOpen } = this.props;
    const toggleHistory = isBoardHistoryOpen ? "showHistory" : "hideHistory";
    return (
      <div
        className={"action-cont boardHistory left-align " + toggleHistory}
        style={{
          display: "block",
          zIndex: "10",
          boxShadow: "2px 4px 3px 0px rgb(204,204,204)",
          borderTop: "none",
        }}
      >
        <div className="head">
          <span>History</span>
          <span
            onClick={() => this.props.toggleBoardHistory()}
            className="close org-cross-icon org-icon"
            aria-hidden="true"
          />
        </div>
        <div
          style={{ height: "500px", overflow: "auto" }}
          className="full-width"
        >
          {this.state.boardHistoryList.length ? (
            this.state.boardHistoryList.map((history, index) => {
              return (
                <div
                  key={index}
                  className="disp-table border-bottom-lightgray action p-5"
                >
                  <div className="member-prof-cont">
                    {history.user.image ? (
                      <img src={history.user.image} className="member-prof " />
                    ) : (
                      <div className="member-prof ">
                        {history.user?.firstName ? history.user?.firstName[0] : "-" + history.user?.lastName ? history.user?.lastName[0] : "-"}
                      </div>
                    )}
                  </div>
                  <div className="disp-inline-block font-15 m-b-6 color-gray-dark">
                    <span>
                      <b>{history.user.firstName} </b>
                    </span>
                    <span>{this.getActionText(history).text} </span>
                    <span>
                      <u>{this.getComponentText(history)}</u>{" "}
                    </span>
                    <span>{this.getActionText(history).preposition} </span>
                    <span>{this.getParentComponentText(history)}</span>
                  </div>
                  <div className="font-12">
                    <span>
                      {moment(history.date).format("MMM DD,YYYY [at] HH:mm A")}
                    </span>
                  </div>
                  {history.action == "add_attachment" ? (
                    <img
                      src={history.title}
                      style={{
                        marginTop: "10",
                        marginBottom: "10",
                        float: "left",
                        width: "60%",
                      }}
                    />
                  ) : null}
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              No Board History Yet
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return this._renderBoardHistory();
  }
}
