import React, {Component} from "react";

export default class CreateBoardFromCard extends Component {
    render() {

        const {top, left} = this.props;
        return (
            <div
                className="action-cont"
                style={{"display": "block", "top": top, "left": left, "zIndex": "5"}}
            >
                <div className="header font-13">
                    <span>
                        Create new board from this card?
                    </span>
                </div>
                <div className="action-list">
                    <div
                        className="action"
                        onClick={(e) => {

                            e.stopPropagation();
                            this.props.createBoardFromCard.call(this);

                        }}
                    >
                        Yes
                    </div>
                    <div
                        className="action"
                        onClick={(e) => {

                            e.stopPropagation();
                            this.props.closeModal.call(this);

                        }}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        );

    }

}
