import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import store from "./store";
import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

// const path = location.hash.slice(1);
// if (path) {
//   location.hash = '';
//   history.replaceState({}, '', `${window.location.origin}/${path}`);
// }

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
