import React, { Component } from "react";
import NewLogo from "../../assets/images/logo-new.png";
import blueLogo from "../../assets/images/orgzee-logo-blue.png"

export default function Header({isBlue = false}) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        display: "flex",
        alignItems: "center",
        left: 0,
        width: "100%",
        height: 55,
        backgroundColor: "rgb(247,247,247)",
        paddingLeft: 20,
      }}
    >
      <img
        style={{ cursor: "pointer" }}
        onClick={() => window.open("https://orgzee.com/", "_blank")}
        className="logo"
        src={isBlue ? blueLogo : NewLogo}
        alt="NewLogo"
      />
    </div>
  )
}
