import PropTypes from "prop-types";

const FilterMemberItem = ({
  memberInfo = {},
  filters = [],
  index,
  removeFilter,
}) => {
  return (
    <div className="filter-cont">
      {memberInfo.image ? (
        <img className="filter-member" src={memberInfo.image} />
      ) : (
        <div className="filter-member">
          {(memberInfo.firstName ? memberInfo.firstName[0] : "") +
            (memberInfo.lastName ? memberInfo.lastName[0] : "")}
        </div>
      )}
      {filters.map((filter, i) => (
        <div key={i} className="disp-inline-block tag">
          <span className="m-r-10">
            {filter === "SM"
              ? "Mentioned"
              : filter === "SA"
              ? "Assigned"
              : filter === "SC"
              ? "Created"
              : ""}
          </span>
          <span
            className=" fa fa-times"
            onClick={() => removeFilter(index, i)}
          />
        </div>
      ))}
    </div>
  );
};

FilterMemberItem.propTypes = {
  memberInfo: PropTypes.object,
  filterMembertype: PropTypes.string,
  removeFilter: PropTypes.func,
  filters: PropTypes.array,
  index: PropTypes.number,
  i: PropTypes.number,
};

export default FilterMemberItem;
