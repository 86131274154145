import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TaskActions from "./taskActions/TaskActions";
import { activateTask } from "../../../../../store/actions/lists";
import moment from "moment";
import PropTypes from "prop-types";

const DueDate = ({ tasks = [], item = {} }) => {
  const dispatch = useDispatch();
  const [taskDone, setTaskDone] = useState();
  const [dueDate, setDueDate] = useState();
  const [colorCode, setColorCode] = useState("#85b59c");
  const [dateHover, setDateHover] = useState(false);
  useEffect(() => {
    tasks.map((task) => {
      setDueDate(task.date);
      setTaskDone(!!task.isCompleted);
    });
  }, [tasks]);

  useEffect(() => {
    if (dueDate) {
      let today = moment().format("YYYY-MM-DD HH:mm");
      today = moment(today, "YYYY-MM-DD HH:mm");
      const dueDateFormatted = moment(dueDate, "YYYY-MM-DD HH:mm");
      const dateDiff = dueDateFormatted.diff(today, "days");
      if (dateDiff >= 1 && dateDiff < 7) {
        setColorCode(dateHover ? "#d19d47" : "#f4b959");
      } else if (dateDiff < 1) {
        setColorCode(dateHover ? "#963e49" : "#e16b7a");
      }
      if (taskDone) {
        setColorCode(dateHover ? "#7f7f7f" : "#9b9b9b");
      }
    } else {
      setColorCode(dateHover ? "#4a6a59" : "#85b59c");
    }
  }, [dueDate, dateHover]);

  const handleDueDateClick = (id) => {
    const ele = document.getElementById(id);
    if (ele.className.indexOf("due-show-tooltip") === -1) {
      ele.className += " due-show-tooltip";
    } else {
      ele.className = ele.className.replace(" due-show-tooltip", "");
    }
  };

  const notActivateTask = (selectedCardDetail) => {
    const { board, _id: cardId } = selectedCardDetail;
    if (tasks.length > 0 && cardId) {
      dispatch(activateTask(tasks, cardId, board));
    }
  };

  return (
    <div>
      {dueDate ? (
        <span
          onClick={() => handleDueDateClick(`due_date_option_${item._id}`)}
          onMouseEnter={() => setDateHover(true)}
          onMouseLeave={() => setDateHover(false)}
          id={`due_date_option_${item._id}`}
          className="p-5 org-round-edges-15 text-white org-tooltip"
          style={{ backgroundColor: colorCode }}
        >
          <div className="org-tooltip-due-date" style={{ width: 163 }}>
            {!taskDone ? (
              <TaskActions item={item} />
            ) : (
              <div className="action-list">
                <div className="action" onClick={() => notActivateTask(item)}>
                  Change to Not Done
                </div>
              </div>
            )}
          </div>
          {taskDone ? (
            <img
              className="middle tick-shape done-task"
              src="../../../../../assets/images/shape.svg"
            />
          ) : (
            <img
              className="middle done-task"
              src="../../../../../assets/images/clock.svg"
            />
          )}
          {moment(dueDate).format("MMM DD, YYYY")}
        </span>
      ) : null}
    </div>
  );
};

DueDate.propTypes = {
  tasks: PropTypes.array,
  item: PropTypes.object,
};

export default DueDate;
