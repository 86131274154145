import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../header";
import { SOCKET_URL } from "../../constants";
import BoardHeader from "./boardHeader";
import InviteNotification from "./inviteMemberNotification/InviteMemberNotification";
import Spinner from "../spinner/spinner";
import * as listsActions from "../../store/actions/lists";
import * as userActions from "../../store/actions/user";
import * as uiActions from "../../store/actions/UIActions";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import CardDetail from "./Cards/cardDetail/cardDetail";
import { io } from "socket.io-client";
import { canEditBoard } from "../../helper"
import "react-datepicker/dist/react-datepicker.min.css";

class HomePageContainer extends React.Component {
  static propTypes = {
    moveCard: PropTypes.func.isRequired,
    moveList: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.scrollHeightOffset = 0;
    this.state = {
      showInviteNotification: false,
      notificationActionTitle: "",
    };
  }

  UNSAFE_componentWillMount() {
    if (
      window.Notification &&
      Notification &&
      Notification.permission === "default"
    ) {
      Notification.requestPermission();
    }
  }

  componentDidMount() {
    const board = this.getBoardID();
    const { loggedInUser } = this.props.user;
    const boardLists = loggedInUser.boards || [];
    this.checkBoardIsValid(board, boardLists, loggedInUser);
    document.body.addEventListener("click", this.handleCloseTooltip, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user = {} } = nextProps;
    const { itemsDeleted = {} } = user;
    const { item = [] } = itemsDeleted;
    const { user: prevUser = {} } = this.props;
    if (itemsDeleted !== prevUser.itemsDeleted && item.length > 0) {
      let description = "";
      if (itemsDeleted.type === "label") {
        description = "Label deleted.";
      } else if (itemsDeleted.type === "card") {
        description = "Card deleted.";
      } else if (itemsDeleted.type === "column") {
        description = "Column deleted.";
      } else if (itemsDeleted.type === "allCards") {
        description = "All cards deleted.";
      }
      this.handleNotificationAction("Undo", description);
      setTimeout(() => {
        this.hideNotification();
      }, 10000);
    }
    const { reloadList, boardToDelete } = nextProps.user;
    if (reloadList) {
      this.props.dispatch({
        type: "SET_STATE",
        payload: { reloadList: false },
      });
      this.getBoardList();
    }

    if (boardToDelete) {
      this.props.dispatch({
        type: "SET_STATE",
        payload: { boardToDelete: null },
      });
      this.deleteBoard(boardToDelete);
    }
  }

  UNSAFE_componentWillUpdate() {
    const arr = document.querySelectorAll(".card-cont>div:first-child");
    if (arr.length) {
      [...arr].forEach((elem) => {
        elem.style.minHeight = "100px";
      });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleCloseTooltip);
  }

  handleCloseTooltip() {
    const element = document.getElementsByClassName("org-show-tooltip");
    if (element.length > 0) {
      element[0].className = element[0].className.replace(
        "org-show-tooltip",
        ""
      );
    }
  }

  handleNotificationAction(actionTitle = "", title = "") {
    this.setState({
      showInviteNotification: true,
      notificationActionTitle: actionTitle,
      notificationTitle: title,
    });
  }

  acceptInvite() {
    if (
      window.location.href.split("?")[1] &&
      window.location.href.split("?")[1].split("&")[0] &&
      window.location.href.split("?")[1].split("&")[1].split("=")[0] == "board"
    ) {
      if (
        window.location.href.split("?")[1].split("&")[0].split("=")[0] ==
        "invitee"
      ) {
        this.props.showRegisterModal.call(
          this,
          window.location.href.split("?")[1].split("&")[0].split("=")[1]
        );
        sessionStorage.setItem(
          "addInviteeDetail",
          JSON.stringify({
            board: window.location.href
              .split("?")[1]
              .split("&")[1]
              .split("=")[1],
            email: window.location.href
              .split("?")[1]
              .split("&")[0]
              .split("=")[1],
          })
        );
      } else if (
        window.location.href.split("?")[1].split("&")[0].split("=")[0] ==
        "member"
      ) {
        const board = window.location.href
          .split("?")[1]
          .split("&")[1]
          .split("=")[1];
        const email = window.location.href
          .split("?")[1]
          .split("&")[0]
          .split("=")[1];
        sessionStorage.setItem(
          "addMemberDetail",
          JSON.stringify({
            board: window.location.href
              .split("?")[1]
              .split("&")[1]
              .split("=")[1],
            email: window.location.href
              .split("?")[1]
              .split("&")[0]
              .split("=")[1],
          })
        );
        this.props.addMember.call(this, email, board);
      }
      const { history } = this.props;
      history.push("/login");
    }
  }

  getBoardID = () => {
    let url1 = this.props.match.url;
    if (url1 && url1.length > 0) {
      url1 = url1.substring(7);
      if (url1.indexOf("/")>0) {
        return url1.substring(0, url1.indexOf("/"));
      }
      return url1;
    }
    const url = get(this.props, "location.pathname");
    const split = url.split("/board/");
    return split.pop();
  };

  checkBoardIsValid = async (board, boardLists, loggedInUser) => {
    this.props.isValidBoard(board, boardLists).then((resp) => {
      const socket = io(SOCKET_URL);
      const isPublic = get(resp, "data.data.isPublic", false);
      this.acceptInvite();

      this.props.registerSocketListeners(socket, loggedInUser);
      // TODO: here it was: loggedInUser._id && !isPublic
      if (loggedInUser._id) {
        const boardId = this.getBoardID();
        this.props.getBoardTypes(boardId);
        this.props.getAllUser();
      }
      this.getBoardList();
      document.getElementsByTagName("html")[0].click();
    });
  };

  getBoardDetail(board, boardList) {
    const _this = this;
    this.props.getBoardDetail(board, boardList).then(() => {
      // TODO: update this section after showing the list of columns
      if (!_this.props.user.activeBoard) {
        return;
      }
      const {params = {}} = this.props.match
      const isBoardId = params.boardId;
      const isColumnId = params.columnId;
      const isCardId = params.cardId;

      if (isBoardId && isColumnId && isCardId ) {
        const currentCardId = isCardId;
        const currentColumnId = isColumnId;
        const currentColumn = _this.props.user.activeBoard.columns.find(
          (column) => column._id === currentColumnId
        );
        const currentCard = currentColumn.cards.find(
          (card) => card._id === currentCardId
        );
        _this.props.openCardDetail.call(this, currentCard);
      } else {
        const { history } = this.props;
        history.push(`/board/${board._id}`);
      }
    });
    const isLoggedIn = get(this.props, "user.loggedInUser", false);
    if (isLoggedIn) {
      this.props.getBoardHistory.call(this, board._id);
    }
  }

  getBoardList() {
    const _this = this;
    const boardId = this.getBoardID();
    this.props.getBoardList(boardId).then((resp) => {
      const boardLists = get(resp, "data.data.boards", []);
      if (!sessionStorage.getItem("addMemberDetail")) {
        const filterBoardList = boardLists.filter((brd) => !brd.isDeleted);
        if (boardId) {
          const currentBoard = filterBoardList.find(
            (brd) => brd._id === boardId
          ) || { _id: boardId };
          _this.getBoardDetail(currentBoard, _this.props.user.boardList);
        } else {
          _this.getBoardDetail(filterBoardList[0], _this.props.user.boardList);
        }
      } else {
        const { board } = JSON.parse(sessionStorage.getItem("addMemberDetail"));
        _this.getBoardDetail({ _id: board }, _this.props.user.boardList);
      }
      sessionStorage.removeItem("addInviteeDetail");
      sessionStorage.removeItem("addMemberDetail");
    });
  }

  handleUpdateUI = () => {
    const columnLists = this.getElementsByClassName(
      document,
      "inner-droppable-area"
    ).map((column) => column.scrollHeight);
    const maxHeight =
      columnLists.length > 0 ? Math.max.apply(Math, columnLists) : 0;
    const droppableAreas = this.getElementsByClassName(
      document,
      "droppable-area-cl"
    );
    const addColumnBtn = document.getElementById("add-column-btn");
    for (let i = 0; i < droppableAreas.length; i++) {
      droppableAreas[i].style.height = `${maxHeight}px`;
    }
    if (addColumnBtn) {
      addColumnBtn.style.height = `${maxHeight}px`;
    }
  };

  getElementsByClassName(node, classname) {
    const a = [];
    const re = new RegExp("(^| )" + classname + "( |$)");
    const els = node.getElementsByTagName("*");
    for (let i = 0, j = els.length; i < j; i++) {
      if (re.test(els[i].className)) a.push(els[i]);
    }
    return a;
  }

  logout() {
    this.props.logout().then((resp) => {
      const status = get(resp, "data.status");
      if (status !== "error") {
        const { history } = this.props;
        history.push("/login");
      }
    });
  }

  goToRegister = () => {
    const { history } = this.props;
    history.push("/register");
  };

  deleteBoard = (board) => {
    const _this = this;
    const { history } = this.props;
    if (_this.props.user.data.status !== "error") {
      if (board._id === _this.props.user.activeBoard._id) {
        const filterBoardList = _this.props.user.boardList.filter((item) => {
          if (!item.isDeleted) return item;
        });
        if (filterBoardList.length) {
          history.push(`/board/${filterBoardList[0]._id}`);
        } else {
          _this.props.showBlankBoard();
          history.push("/login");
        }
      }
    }
  };

  hideNotification() {
    this.setState({ showInviteNotification: false });
  }

  openVideoModal(videoId = "", type = "", e) {
    let videoUrl = `//youtube.com/embed/${videoId}`;
    if (type == "vimeo") {
      videoUrl = `//player.vimeo.com/video/${videoId}`;
    }
    this.setState({
      isPhotoModalOpen: videoUrl,
      isVideo: true,
    });
  }

  createBoardFromCard = async (card) => {
    const response = await listsActions.createBoardFromCard.call(this, card);
    if (response.data && response.data.data && response.data.data.board) {
      const { history } = this.props;
      history.push("/board/" + response.data.data.board);
      this.getBoardDetail(
        { _id: response.data.data.board },
        this.props.user.boardList
      );
    }
  };

  removeAttachment(card, attachment, comment) {
    this.props.removeAttachment.call(this, card, attachment, comment);
    this.setState({
      showInviteNotification: true,
      notificationTitle: "Attachment removed!",
    });
  }

  addCardAttachment(image, cardId, isCardImage, isUpdate) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("card", cardId);
    formData.append("isCardImage", isCardImage);
    this.props.addCardAttachment.call(this, formData, isUpdate);
  }

  openPhotoModal(e) {
    this.setState({ isPhotoModalOpen: e.currentTarget.getAttribute("src") });
  }

  closePhotoModal() {
    this.setState({ isPhotoModalOpen: false, isVideo: false });
  }

  hideCardDetail() {
    this.props.hideCardDetail()
    window.history.pushState(null, null, `#/board/${this.props.user.activeBoard._id}`);
  }

  render() {
    const { itemsDeleted = {}, labelLists = [], labelCreate } = this.props.user;
    return (
      <div>
        <Spinner show={this.props.user.isLoading} />
        {!this.props.user.isSharedView && this.props.user.activeBoard && (
          <div className="container" style={{position: "fixed", zIndex: 1, width: "100%"}}>
            <Header />
            <BoardHeader {...this.props} marginTop='57px'/>
            {/* this._renderBody(editBoardNameMain, memberlist, activeBoardColumns)*/}
            {/* <BoardContainer initial={data.large} /> */}
            <InviteNotification
              title={this.state.notificationTitle}
              itemsDeleted={itemsDeleted}
              board={this.props.user.activeBoard}
              showInviteNotification={this.state.showInviteNotification}
              hideNotification={this.hideNotification.bind(this)}
              undoDelete={this.props.undoDelete.bind(this)}
              undoDeleteCard={this.props.undoDeleteCard.bind(this)}
              undoDeleteColumn={this.props.undoDeleteColumn.bind(this)}
              undoDeleteAllCards={this.props.undoDeleteAllCards.bind(this)}
              handleNotificationAction={this.handleNotificationAction.bind(
                this
              )}
              notificationActionTitle={this.state.notificationActionTitle}
            />
            {this.props.user.selectedCardDetail &&
            <CardDetail
              key={
                this.props.user.selectedCardDetail
                  ? this.props.user.selectedCardDetail._id
                  : ""
              }
              selectedCardDetail={this.props.user.selectedCardDetail}
              openDueDate={this.props.user.openDueDate}
              boardType={
                this.props.user.activeBoard &&
                this.props.user.activeBoard.boardType
                  ? this.props.user.activeBoard.boardType
                  : null
              }
              boardMember={
                this.props.user.activeBoard &&
                this.props.user.activeBoard.members
                  ? this.props.user.activeBoard.members
                  : null
              }
              activeBoard={
                this.props.user.activeBoard ? this.props.user.activeBoard : null
              }
              userListByName={
                this.props.user.userListByName
                  ? this.props.user.userListByName
                  : null
              }
              editCardDetail={this.props.editCardDetail.bind(this)}
              addTask={this.props.addTask.bind(this)}
              addCardComment={this.props.addCardComment.bind(this)}
              completeTask={this.props.completeTask.bind(this)}
              openVideoModal={this.openVideoModal.bind(this)}
              loggedInUser={this.props.user.loggedInUser}
              updateCardNote={this.props.updateCardNote.bind(this)}
              deleteCardNote={this.props.deleteCardNote.bind(this)}
              getUserByName={this.props.getUserByName.bind(this)}
              getLabelByName={this.props.getLabelByName.bind(this)}
              doneTheTask={this.props.doneTheTask.bind(this)}
              activateTask={this.props.activateTask.bind(this)}
              createNewLabel={this.props.createNewLabel.bind(this)}
              updateAssignee={this.props.updateAssignee.bind(this)}
              updateLabel={this.props.updateLabel.bind(this)}
              handleNotificationAction={this.handleNotificationAction.bind(
                this
              )}
              hideNotification={this.hideNotification.bind(this)}
              deleteAttachments={this.props.deleteAttachments.bind(this)}
              itemsDeleted={itemsDeleted}
              labelLists={labelLists}
              labelCreate={labelCreate}
              delayTask={this.props.delayTask.bind(this)}
              createBoardFromCard={this.createBoardFromCard.bind(this)}
              addCardAttachment={this.addCardAttachment.bind(this)}
              removeAttachment={this.removeAttachment.bind(this)}
              openPhotoModal={this.openPhotoModal.bind(this)}
              boardHistoryList={
                this.props.user.boardHistoryList
                  ? this.props.user.boardHistoryList
                  : []
              }
              closeCardDetail={this.hideCardDetail.bind(this)}
              isUserLoggedIn={this.props.user.isUserLoggedin}
              isPublic={this.props.user.isPublic}
              canEditCard={canEditBoard(this.props.user.currentMember, this.props.user.isUserLoggedin, false)}
            />}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user, UI: state.UI });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...listsActions,
      ...userActions,
      ...uiActions,
      dispatch,
    },
    dispatch
  );
};

HomePageContainer.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getBoardList: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  getBoardDetail: PropTypes.func.isRequired,
  isValidBoard: PropTypes.func,
  registerSocketListeners: PropTypes.func,
  getAllUser: PropTypes.func,
  getBoardTypes: PropTypes.func,
  getBoardHistory: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePageContainer));
