import axios from "axios";
export async function register(email, password, firstName, lastName) {
  return axios({
    method: "post",
    url: "register/new",
    data: {
      email,
      password,
      firstName,
      lastName,
    },
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      return error?.response?.data;
    });
}

export async function resendEmailVerification(email) {
  return axios({
    method: "post",
    url: "/register/resend-email",
    data: { email },
  })
    .then(() => true)
    .catch(() => {
      return false;
    });
}

export async function verifyToken(email, token) {
  return axios({
    method: "post",
    url: "register/confirm",
    data: {
      email,
      token,
    },
  })
    .then((res) => res.data)
    .catch(() => {
      return false;
    });
}

export async function getAllPlans() {
  return axios({
    method: "get",
    url: "register/plans",
  })
    .then((response) => response.data)
    .catch(() => {
      return false;
    });
}

export async function createSubscription(data) {
  const { email, token } = JSON.parse(localStorage.getItem("registerData"));
  return axios({
    method: "post",
    url: "payment/create-subscription",
    data: { ...data, email, token },
  })
    .then((response) => response.data)
    .catch(() => {
      return false;
    });
}

export async function registerWithFacebook(
  email,
  firstName,
  lastName,
  accessToken,
  facebookId
) {
  return axios({
    method: "post",
    url: "register/facebook",
    data: {
      email,
      accessToken,
      firstName,
      lastName,
      facebookId,
    },
  })
    .then((response) => response.data)
    .catch(() => {
      return false;
    });
}

export async function registerWithGoogle(data) {
  return axios({
    method: "post",
    url: "register/google",
    data,
  })
    .then((response) => response.data)
    .catch((error) => {
      return error?.response?.data;
    });
}
