import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types"
import Spinner from "../spinner/spinner";
import ResetEmailForm from "./components/forgot";
import CheckEmailPage from "./components/checkEmail"

const ResetPasswordEmail = ({ match }) => {
  const { user } = useSelector((state) => ({ user: state.user, register: state.register }));
  useEffect(() => {
    document.getElementsByTagName("BODY")[0].style["overflow-y"] = "auto";
    document.getElementsByTagName("BODY")[0].style["overflow-x"] = "auto";
    return () => {
      document.getElementsByTagName("BODY")[0].style["overflow-y"] = "hidden";
      document.getElementsByTagName("BODY")[0].style["overflow-x"] = "hidden";
    };
  }, []);

  return (
    <>
    <Spinner show={user.loading} />
    <Switch>
      <Route
        exact
        path={`${match.url}/reset`}
        component={ResetEmailForm}
      />
      <Route
        exact
        path={`${match.url}/email-sent`}
        component={CheckEmailPage}
      />
      <Redirect to={`${match.url}/reset`} />
    </Switch>
    </>
  );
};

ResetPasswordEmail.propTypes = {
  match: PropTypes.object,
}

export default withRouter(ResetPasswordEmail);
