const initialState = {
  currentStep: 1,
  data: {},
  showErrorWarning: false,
  plans: [],
  registerData: {},
};

export const actions = {
  SET_STATE: "register/SET_STATE",
  CREATE_NEW_USER: "register/CREATE_NEW_USER",
  VERIFY_TOKEN: "register/VERIFY_TOKEN",
  GET_ALL_PLANS: "register/GET_ALL_PLANS",
  CREATE_SUBSCRIPTION: "register/CREATE_SUBSCRIPTION",
  REGISTER_WITH_FACEBOOK: "register/REGISTER_WITH_FACEBOOK",
  REGISTER_WITH_GOOGLE: "register/REGISTER_WITH_GOOGLE",
  IS_EXISTS_FALSE: "register/IS_EXISTS_FALSE",
  RESEND_VERIFICATION_MAIL: "register/RESEND_VERIFICATION_MAIL",
};

function registerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default registerReducer;
