import PropTypes from "prop-types";
import ColumnHeader from "../columnHeader";
import CardsContainer from "../cardsContainer";
import { useDispatch, useSelector } from "react-redux";
import { addCard } from "../../../../store/actions/lists";
import { useEffect, useState } from "react";
import { canEditBoard } from "../../../../helper";

const Column = ({ column = {}, canDragAndDrop = false }) => {
  const { user = {} } = useSelector((state) => ({ user: state.user }));
  const [canEdit, setCanEdit] = useState(false);
  const dispatch = useDispatch()
  const onAddCard = () => {
    if(!canEdit) return;
    const { activeBoard } = user || {};
    dispatch(addCard(activeBoard, column, column && column.cards ? column.cards.length: 0));
  };

  useEffect(() =>{
    setCanEdit(canEditBoard(user.currentMember, user.isUserLoggedin, user.isSharedView))
  }, [])
  return (
    <div className="column-container">
      <div className="inner-container">
        <div className={"column-cont"}>
          <ColumnHeader column={column} user={user} />
        </div>
        <div className="card-cont ccard-cont" >
          <CardsContainer column={column} isSharedView={user.isSharedView} canDragAndDrop={canDragAndDrop} />
        </div>
        {(canEdit && column && column.cards.length>0) && <div style={{textAlign: "center", marginTop:'5px'}}>
          <img onClick={onAddCard} style={{cursor: 'pointer'}} src="../../../../assets/images/add-column-icon.svg" />
        </div>}
      </div>
      </div>
  );
};

Column.propTypes = {
  column: PropTypes.object,
  canDragAndDrop: PropTypes.bool
};

export default Column;
