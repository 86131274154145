import { withRouter } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../store/reducers/sharedBoards";
import SharedHeader from "./header";
import BoardHeader from "../containers/Board/boardHeader"
import ColumnContainer from "../containers/Board/columnsContainer";
import CardDetail from "../containers/Board/Cards/cardDetail/cardDetail"
import Header from "../containers/header";

function SharedBoardView({ dispatch, history, match, isUserLoggedin = {},
    activeBoard = {},
    selectedCardDetail = {},
    user = {},
    boardList = [] }) {

    useEffect(() => {
        dispatch({
            type: "SET_STATE",
            payload: {
                isSharedView: true
            }
        })
        dispatch({
            type: actions.GET_SHARED_BOARD,
            payload: {
                history,
                id: match.params.id,
                isLoggedIn: isUserLoggedin,
            }
        })
        return () => {
            dispatch({
                type: "SET_STATE",
                payload: {
                    isSharedView: false
                }
            })
        }
    }, [])

    useEffect(() => {
        const idx = boardList.findIndex((item) => item._id === match.params.id)>=0;
        if(idx) {
            history.push(`/board/${match.params.id}`)
        }
    }, [boardList])
    const onHideCardDetails = () => {
        history.push(`/shared/board/${activeBoard._id}`)
        dispatch({type: "SET_STATE", payload: {
            selectedCardDetail: {}
        }})
    }

    const onOpenPhotoModal = (v) => {
        console.log(v)
    }


    return <div className="container">
        {  
            isUserLoggedin ? 
            (
                <>
                    <div style={{zIndex: '10'}}>
                        <Header/>
                    </div>
                    <div style={{marginTop:'57px'}}>
                        <SharedHeader isUserLoggedin={isUserLoggedin} isCopying={activeBoard.isCopying} />
                    </div>
                </>
            ):
            <SharedHeader isUserLoggedin={isUserLoggedin} isCopying={activeBoard.isCopying} />
        }        
        <BoardHeader />
        {selectedCardDetail?._id &&
        <CardDetail
            key={selectedCardDetail ? selectedCardDetail._id: ""}
            selectedCardDetail={selectedCardDetail}
            openDueDate={false}
            boardType={activeBoard &&activeBoard.boardType? activeBoard.boardType: null}
            boardMember={activeBoard &&activeBoard.members? activeBoard.members: null}
            activeBoard={activeBoard ? activeBoard : null}
            boardHistoryList={[]}
            closeCardDetail={onHideCardDetails}
            isUserLoggedIn={isUserLoggedin}
            isPublic={user.isPublic}
            canEditCard={false}
            openPhotoModal={onOpenPhotoModal}
        />}
        <ColumnContainer marginTop={'-8rem'}/>
    </div>
}

export default connect((state) => ({
    isUserLoggedin: state.user.isUserLoggedin,
    activeBoard: state.user.activeBoard,
    selectedCardDetail: state.user.selectedCardDetail,
    user: state.user,
    boardList: state.user.boardList
}))(withRouter(SharedBoardView))