import { useState } from "react";
import PropTypes from "prop-types";
import ProfileActions from "./profileActions";
import ProfileModal from "../profile"

const RightSideHeader = ({
  isUserLoggedIn = false,
  loggedInUser = {},
  isPublic = false,
  setProfileOpen
}) => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [isProfileActionOpen, setProfileActionOpen] = useState(false);
  const [openProfileModal, setProfileModalOpen] = useState(false);

  const onOpen = (e) => {
    if (isUserLoggedIn) {
      setLeft(e.clientX);
      setTop(e.clientY);
      setProfileActionOpen(!isProfileActionOpen);
    }
  };

  const openProfileSetting = () => {
    console.log("openProfileSetting");
  }

  const openProfile = () => {
    setProfileModalOpen(true)
  }

  const closeProfileModal = () => {
    setProfileModalOpen(false)
  }

  return (
      <div>
        <div className="subheader3" onClick={onOpen} >
          {loggedInUser.image ? (
            <span
              className="cover-image avatar avatar-align"
              style={{
                backgroundImage: `url("${loggedInUser.image}")`,
              }}
            />
          ) : (
            <img
              className="avatar avatar-align"
              src="../../assets/images/avatar.png"
            />
          )}
          <span className="user-name">
            {`${loggedInUser.firstName} ${loggedInUser.lastName}`}
          </span>
          <span className="arrow-down fa fa-caret-up" />
        </div>

        <ProfileActions
          left={left}
          top={top}
          isProfileActionOpen={isProfileActionOpen}
          openProfile={openProfile}
          openProfileSetting={openProfileSetting}
        />
        {
          openProfileModal && <ProfileModal
            open={openProfileModal}
            handleClose={closeProfileModal}
          />
        }
      </div>
    )
};

RightSideHeader.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  setProfileOpen: PropTypes.func
};

export default RightSideHeader
