import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  deleteColumn,
  deleteAllCards,
  addCard,
  editColumnName,
  editConfetti,
} from "../../../../store/actions/lists";
import { useOutsideAlerter, useClickEnter } from "../../../../hooks";
import { canEditBoard } from "../../../../helper";
import { Checkbox } from "antd";

const ColumnHeader = ({ column = {}, user = {} }) => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [enableConfetti, setEnableConfetti] = useState(
    column.confetti || false
  );
  const [columnName, setColumnName] = useState(
    column.name || "Enter Column Name"
  );
  const inputRef = useRef();
  const titleRef = useRef();
  useEffect(() => {
    setCanEdit(
      canEditBoard(user.currentMember, user.isUserLoggedin, user.isSharedView)
    );
  }, []);
  useEffect(() => {
    if (showEdit) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    if (!showEdit && column._id === titleRef.current.id) {
      onEditColumnName();
    }
    useClickEnter(showEdit && column._id === titleRef.current.id, setShowEdit);
  }, [showEdit]);

  const handlePopup = (id) => {
    setTimeout(() => {
      const ele = document.getElementById(id);
      if (ele) {
        if (ele.className.indexOf("org-show-tooltip") === -1) {
          ele.className += "org-show-tooltip";
        } else {
          ele.className = ele.className.replace("org-show-tooltip", "");
        }
      }
    }, 300);
  };

  const openEdit = () => {
    if (canEdit) {
      setShowEdit(true);
    }
  };

  const onDeleteColumn = () => {
    if (!canEdit) return;
    const boardList = user && user.boardList ? user.boardList : [];
    const activeBoard = user.activeBoard || {};
    dispatch(deleteColumn(boardList, activeBoard, column));
  };

  const onDeleteAllCards = () => {
    if (!canEdit) return;
    if (column._id) {
      dispatch(deleteAllCards(column));
    }
  };

  const onAddCard = () => {
    if (!canEdit) return;
    const { activeBoard } = user || {};
    dispatch(addCard(activeBoard, column, 0));
  };

  const onEditColumnName = () => {
    if (!canEdit) return;
    setShowEdit(false);
    if (columnName === column.name) {
      return;
    }
    if (columnName) {
      const { activeBoard } = user || {};
      dispatch(editColumnName(columnName, column, activeBoard));
    } else {
      setColumnName(column.name || "Enter Column Name");
    }
  };

  const onEnableConfetti = (e) => {
    if (!canEdit) return;
    setEnableConfetti(e.target.checked);

    dispatch(editConfetti(e.target.checked, column));
  };

  useOutsideAlerter(titleRef, setShowEdit);

  return (
    <div className="column-header ">
      <div className="column-header-fixed">
        <span className="card-length">
          {column.cards ? column.cards.length : ""}
        </span>
        <div className="fixed-column-name" id={`${column._id}`} ref={titleRef}>
          {showEdit ? (
            <input
              className="edit-text-column-name"
              defaultValue={column.name || "Enter Column Name"}
              onChange={(e) => setColumnName(e.target.value)}
              onBlur={() => onEditColumnName()}
              ref={inputRef}
            />
          ) : (
            <div
              className={`user-experience ${canEdit ? "no-hover-border" : ""}`}
              onClick={() => openEdit()}
            >
              {column.name && column.name.trim()
                ? column.name
                : "Enter Column Name"}
            </div>
          )}
          {canEdit && (
            <div
              id={`column_option_${column._id}`}
              className="options org-tooltip org-options ct-dropdown-optine "
              aria-hidden="true"
              onClick={(e) => handlePopup(`column_option_${column._id}`, e)}
            >
              <div className="org-tooltip-text-on-click">
                <div className="action-list">
                  <div className="action" onClick={onDeleteColumn}>
                    Delete Column
                  </div>
                  {column.cards && column.cards.length ? (
                    <div className="action" onClick={onDeleteAllCards}>
                      Delete All Cards
                    </div>
                  ) : null}
                  <div className="action ct-main-select">
                    <label>
                      <Checkbox onChange={onEnableConfetti}>Enable Confetti</Checkbox>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {canEdit && (
        <div className="add-btn org-add" onClick={onAddCard}>
          <img src="../../../assets/images/add-column-icon.svg" />
        </div>
      )}
    </div>
  );
};

ColumnHeader.propTypes = {
  column: PropTypes.object,
  user: PropTypes.object,
};

export default ColumnHeader;
