import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  addCardAttachment,
  addVideoUrl,
  addCheckList,
} from "../../../../../store/actions/lists";
import { useOutsideAlerter } from "../../../../../hooks";
import { actions as cardsActions } from "../../../../../store/reducers/cards";
import { canEditBoard } from "../../../../../helper";
import { Popover, message } from "antd";
import LabelList from "../labelList";

const CardFile = ({ card, editCardDesc, setEditCardDesc }) => {
  const { user } = useSelector((state) => ({
    user: state.user,
    activeBoard: state.user.activeBoard,
    boardType: state.user.activeBoard.boardType,
    isUserLoggedIn: state.user.isUserLoggedin,
    isPublic: state.user.isPublic,
    cardsReducer: state.cards,
  }));
  const dispatch = useDispatch();
  const imageRef = useRef();
  const embedRef = useRef();
  const videoRef = useRef();
  const [showVideoTxtFld, setShowVideoTxtFld] = useState(false);
  const [showEmbedTxtFld, setShowEmbedTxtFld] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [showMediaBtns, setShowMediaBtns] = useState(false);

  const handleImageUpload = (e) => {
    if (card._id) {
      const { files } = e.target;
      if (files[0] && files[0].size < 25 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("image", files[0]);
        formData.append("card", card._id);
        formData.append("isCardImage", true);
        dispatch(addCardAttachment(formData));
      } else if (files[0] && files[0].name) {
        message.error(`You can only upload files of 25MB or less.`);
      }
    }
  };

  const handleVideoUpload = (e) => {
    const videoUrl = e.target.value;
    const pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    const pattern2 =
      /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
    if (videoUrl?.trim().length > 0 && card._id) {
      let flag = false;
      if (pattern1.test(videoUrl)) {
        flag = true;
      } else if (pattern2.test(videoUrl)) {
        flag = true;
      }
      if (flag) {
        dispatch(addVideoUrl(videoUrl, "video/videoUrl", card._id));
      } else {
        setErrorMessage("Please enter a valid youtube or vimeo url.");
        setTimeout(() => {
          setErrorMessage(null);
        }, 4000);
      }
    }
    setShowVideoTxtFld(false);
  };

  const uploadEmbedUrl = (e) => {
    const embedUrl = e.target.value;
    if (embedUrl.trim().length > 0 && card._id) {
      const regex =
        /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
      if (!regex.test(embedUrl)) {
        setErrorMessage("Please enter a valid embed url.");
        setTimeout(() => {
          setErrorMessage("Please enter a valid embed url.");
        }, 4000);
      } else {
        dispatch(addVideoUrl(embedUrl, "video/embedUrl", card._id));
      }
    }
    setShowEmbedTxtFld(false);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      uploadEmbedUrl(e);
    }
  };

  const blurEmbed = () => {
    embedRef.current.blur();
  };

  const blurVideo = () => {
    videoRef.current.blur();
  };

  const uploadCheckList = () => {
    dispatch({
      type: cardsActions.SET_STATE,
      payload: {
        enableAddCheckList: true,
      },
    });
    dispatch(addCheckList(card));
  };

  useOutsideAlerter(embedRef, blurEmbed);
  useOutsideAlerter(videoRef, blurVideo);
  return (
    <div className="m-t-10">
      {showVideoTxtFld || showEmbedTxtFld ? (
        <>
          {showVideoTxtFld && (
            <input
              type="text"
              name="video-upload"
              className="media-upload-fld"
              placeholder="Paste a video link"
              onBlur={handleVideoUpload}
              ref={videoRef}
            />
          )}
          {showEmbedTxtFld && (
            <input
              type="text"
              name="embed-upload"
              className="media-upload-fld"
              placeholder="Paste a link"
              ref={embedRef}
              onKeyPress={(e) => onKeyPress(e)}
              onBlur={(e) => uploadEmbedUrl(e)}
            />
          )}
        </>
      ) : (
        <>
          {canEditBoard(
            user.currentMember,
            user.isUserLoggedin,
            user.isSharedView
          ) && (
            <span
              onClick={() => {
                if (showMediaBtns) {
                  dispatch({
                    type: cardsActions.SET_STATE,
                    payload: {
                      checkListAdded: false,
                    },
                  });
                }
                setShowMediaBtns(!showMediaBtns);
              }}
              className={`org-upload-menu m-r-5 ${
                showMediaBtns ? "close-icon" : "plus-icon"
              }`}
            />
          )}
          <span
            className={`org-menu-list ${
              showMediaBtns ? "org-menu-expand" : "org-menu-shrink"
            }`}
          >
            <span
              onClick={() => setEditCardDesc(!editCardDesc)}
              className="org-upload-menu description-icon m-r-5"
            />
            <label htmlFor={`handleImageUpload-${card._id}`}>
              <span className="org-upload-menu image-icon m-r-5">
                <input
                  ref={imageRef}
                  id={`handleImageUpload-${card._id}`}
                  type="file"
                  onChange={(e) => handleImageUpload(e)}
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </span>
            </label>
            <span
              className="org-upload-menu video-icon m-r-5"
              onClick={() => setShowVideoTxtFld(true)}
            />
            <span
              className="org-upload-menu embed-icon m-r-5"
              onClick={() => setShowEmbedTxtFld(true)}
            />
            <span
              className="org-upload-menu checkbox-icon m-r-5"
              onClick={uploadCheckList}
            />
            <Popover
              content={<LabelList selectedCardDetail={card} />}
              trigger="click"
              placement="bottomRight"
              open={isOpen}
              overlayClassName="no-padding-content"
              onOpenChange={() => setIsOpen(true)}
              destroyTooltipOnHide
            >
              <span className="org-upload-menu label-icon m-r-5" />
            </Popover>
          </span>
        </>
      )}
      <div
        className="error-message"
        style={{ display: errorMessage ? "block" : "none" }}
      >
        {errorMessage}
      </div>
    </div>
  );
};

CardFile.propTypes = {
  card: PropTypes.object,
  editCardDesc: PropTypes.bool,
  setEditCardDesc: PropTypes.func,
};

export default CardFile;
