const initialState = {
    board: {},
};

export const actions = {
    SET_STATE: "sharedBoard/SET_STATE",
    GET_SHARED_BOARD: "sharedBoard/GET_SHARED_BOARD",
};

function sharedBoardReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default sharedBoardReducer;
