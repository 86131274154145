import axios from "axios";
import { BASE_URL } from "../../constants";
let authorization = localStorage.getItem("authorization");

export function registerUser(
  email,
  firstName,
  lastName,
  isGoogle,
  profilePic,
  tokenId
) {
  return (dispatch) => {
    dispatch({ type: "REGISTER_USER_START" });
    return axios({
      method: "post",
      url: BASE_URL + "auth/register",
      data: {
        email: email.toLowerCase(),
        firstName,
        lastName,
        isGoogle,
        profilePic,
        tokenId,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          window.gtag("event", "new-user", {
            event_category: "email",
            event_label: email,
          });
          if (isGoogle) {
            if (response.data.token) {
              authorization = response.data.token;
              localStorage.setItem("authorization", authorization);
            }
            dispatch({ type: "FETCH_USER_SUCCESS", payload: response.data });
            dispatch({
              type: "GOOGLE_AUTH_SUCCESS",
              payload: response.data,
              registeredEmail: email,
              tokenId,
            });
          } else {
            dispatch({
              type: "REGISTER_USER_SUCCESS",
              payload: response.data,
              registeredEmail: email,
            });
          }
        } else {
          dispatch({ type: "REGISTER_USER_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "REGISTER_USER_FAIL" });
        return error;
      });
  };
}

export function sendOTP(email) {
  return (dispatch) => {
    dispatch({ type: "SEND_OTP_START" });
    return axios({
      method: "post",
      url: BASE_URL + "auth/sendOTP",
      data: {
        email: email.toLowerCase(),
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "SEND_OTP_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "SEND_OTP_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "SEND_OTP_FAIL" });
        return error;
      });
  };
}
export function confirmOTP(email, OTP, newPassword) {
  return (dispatch) => {
    dispatch({ type: "CONFIRM_OTP_START" });
    return axios({
      method: "post",
      url: BASE_URL + "auth/confirmOTP",
      data: {
        email: email.toLowerCase(),
        OTP,
        new_password: newPassword,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "CONFIRM_OTP_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "CONFIRM_OTP_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "CONFIRM_OTP_FAIL" });
        return error;
      });
  };
}

export function isUserLoggedin() {
  return (dispatch) => {
    if (localStorage.getItem("authorization")) {
      dispatch({ type: "USER_LOGGED_IN" });
    } else {
      localStorage.setItem("authorization", "");
      dispatch({ type: "USER_LOGGED_OUT" });
    }
  };
}
export function fetchUserViaEmail(email) {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_START" });
    return axios({
      method: "post",
      url: BASE_URL + "register/user",
      data: {
        email: email.toLowerCase(),
      },
    })
      .then((response) => {
        dispatch({ type: "FETCH_USER_END" });
        return response;
      })
      .catch((error) => {
        dispatch({ type: "FETCH_USER_FAIL" });
        return error.response;
      });
  };
}

export function fetchUser(email, password, remindMe, isGoogle, tokenId) {
  return (dispatch) => {
    dispatch({ type: "FETCH_USER_START" });
    return axios({
      method: "post",
      url: BASE_URL + "auth/login",
      data: {
        email: email.toLowerCase(),
        password,
        remindMe,
        isGoogle,
        tokenId,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          if (response.data.token) {
            authorization = response.data.token;
            localStorage.setItem("authorization", authorization);
          }
          dispatch({ type: "FETCH_USER_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "FETCH_USER_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "FETCH_USER_FAIL" });
        return error;
      });
  };
}
export function logout() {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_START" });
    return axios({
      method: "post",
      url: BASE_URL + "auth/logout",
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        localStorage.setItem("authorization", "");
        dispatch({ type: "USER_LOGGED_OUT", payload: response.data });
        window.location.replace(window.location.origin);
        return response;
      })
      .catch((error) => {
        dispatch({ type: "USER_LOGGED_OUT" });
        return error;
      });
  };
}

export function getUser() {
  return (dispatch) => {
    dispatch({ type: "GET_USER_START" });
    return axios({
      method: "get",
      url: BASE_URL + "auth/user",
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        const loggedInData =
          response.data && response.data.data ? response.data.data : {};
        if (response.data.status !== "error" && !loggedInData.isInactive) {
          dispatch({ type: "GET_USER_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "GET_USER_ERROR", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "GET_USER_ERROR" });
        localStorage.setItem("authorization", "");
        return error;
      });
  };
}

export function getAllUser() {
  return (dispatch) => {
    dispatch({ type: "GET_ALL_USER_START" });
    return axios({
      method: "get",
      url: BASE_URL + "auth/all",
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "GET_ALL_USER_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "GET_ALL_USER_ERROR", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_ALL_USER_ERROR" });
        if (error.response && error.response.status === 401) {
          localStorage.setItem("authorization", "");
        }
      });
  };
}

export function updateUser(data) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_START" });
    return axios({
      method: "post",
      url: BASE_URL + "auth/user",
      headers: {
        Authorization: localStorage.getItem("authorization"),
        "Content-Type": "multipart/form-data",
      },
      data: data,
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "UPDATE_USER_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "UPDATE_USER_ERROR", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_USER_ERROR", payload: error });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", "");
        }
      });
  };
}

export function changePassword(email, oldPassword, newPassword, callback) {
  return (dispatch) => {
    dispatch({ type: "CHANGE_PASSWORD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "auth/change-password",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        email: email.toLowerCase(),
        old_password: oldPassword,
        new_password: newPassword,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "CHANGE_PASSWORD_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "CHANGE_PASSWORD_FAIL", payload: response.data });
        }
        if (callback) {
          callback(response);
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "CHANGE_PASSWORD_FAIL" });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", "");
        }
        return error;
      });
  };
}

export function inviteMember(email, name, role, board) {
  return (dispatch) => {
    dispatch({ type: "INVITE_MEMBER_START" });
    return axios({
      method: "post",
      url: BASE_URL + "board/invited-member",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        email: email.toLowerCase(),
        name,
        role,
        board,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "INVITE_MEMBER_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "INVITE_MEMBER_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "INVITE_MEMBER_FAIL" });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", "");
        }
      });
  };
}
export function addMember(email, board) {
  return (dispatch) => {
    dispatch({ type: "ADD_MEMBER_START" });
    return axios({
      method: "post",
      url: BASE_URL + "board/add-member",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        email: email.toLowerCase(),
        board,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "ADD_MEMBER_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "ADD_MEMBER_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "ADD_MEMBER_FAIL" });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", "");
        }
      });
  };
}
export function removeMember(email, board) {
  return (dispatch) => {
    dispatch({ type: "REMOVE_MEMBER_START" });
    return axios({
      method: "delete",
      url: BASE_URL + "board/removeMember",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        email: email.toLowerCase(),
        board,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "REMOVE_MEMBER_SUCCESS", email });
        } else {
          dispatch({ type: "REMOVE_MEMBER_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "REMOVE_MEMBER_FAIL" });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", "");
        }
      });
  };
}

export function getUserByName(username, board) {
  return (dispatch) => {
    dispatch({ type: "GET_ALL_USER_START" });
    return axios({
      method: "get",
      url: `${BASE_URL}user/search/${board}/${username}`,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          const users =
            response.data.data && response.data.data.users
              ? response.data.data.users
              : [];
          dispatch({ type: "GET_ALL_USER_BY_NAME", payload: users });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_ALL_USER_ERROR" });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", "");
        }
      });
  };
}
