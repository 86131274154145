import React, { Component } from "react";

export default class MentionInput extends Component {
  constructor(props) {
    super(props);
    this.input = null;
    this.state = {
      openOptions: false,
      mentionedEmail: [],
    };
  }

  openOptions(e) {
    e.stopPropagation();
    this.setState({ openOptions: true });
  }

  onFocus = (event) => {
    event.currentTarget.style.height = event.currentTarget.scrollHeight + "px";
    event.target.select()
  };

  onSelect(e) {
    e.preventDefault();
    const selectedValue = JSON.parse(
      e.currentTarget.getAttribute("data-value")
    );
    this.setState({
      selectedValue: selectedValue.value
        ? selectedValue.value
        : this.props.isMember && selectedValue["email"]["email"]
        ? selectedValue["email"]["email"]
        : "",
      openOptions: false,
    });
    this.input.value =
      this.input.value.substring(
        0,
        this.input.value.substring(0, this.input.selectionEnd).lastIndexOf("@")
      ) +
      "@" +
      selectedValue["email"]["firstName"] +
      " " +
      selectedValue["email"]["lastName"] +
      "[" +
      selectedValue["email"]["email"] +
      "]" +
      " " +
      this.input.value
        .substring(
          this.input.value
            .substring(0, this.input.selectionEnd)
            .lastIndexOf("@"),
          this.input.value.length
        )
        .replace(
          this.input.value.substring(
            this.input.value
              .substring(0, this.input.selectionEnd)
              .lastIndexOf("@"),
            this.input.selectionEnd
          ),
          ""
        );
    this.setState({ openOptions: false });
    this.state.mentionedEmail.push(selectedValue["email"]["email"]);
    this.input.focus();
  }

  removeMentions(e) {
    if (e.key == "Backspace") {
      if (
        e.currentTarget.value.substring(
          e.currentTarget.selectionEnd - 1,
          e.currentTarget.selectionEnd
        ) == "@"
      ) {
        this.setState({ openOptions: false });
      }
      if (
        e.currentTarget.value
          .substring(0, e.currentTarget.selectionEnd)
          .lastIndexOf("@") !== -1 &&
        e.currentTarget.value
          .substring(
            e.currentTarget.value
              .substring(0, e.currentTarget.selectionEnd)
              .lastIndexOf("@"),
            e.currentTarget.selectionEnd
          )
          .split(" ").length == 3 &&
        e.currentTarget.value.substring(
          e.currentTarget.selectionEnd - 1,
          e.currentTarget.selectionEnd
        ) == " "
      ) {
        e.preventDefault();
        e.currentTarget.value =
          e.currentTarget.value.substring(
            0,
            e.currentTarget.value
              .substring(0, e.currentTarget.selectionEnd)
              .lastIndexOf("@")
          ) +
          e.currentTarget.value.substring(
            e.currentTarget.selectionEnd,
            e.currentTarget.value.length
          );
      } else if (
        e.currentTarget.value
          .substring(0, e.currentTarget.selectionEnd)
          .lastIndexOf("@") !== -1 &&
        e.currentTarget.value
          .substring(
            e.currentTarget.value
              .substring(0, e.currentTarget.selectionEnd)
              .lastIndexOf("@"),
            e.currentTarget.selectionEnd
          )
          .indexOf(" ") == -1
      ) {
        e.preventDefault();
        e.currentTarget.value =
          e.currentTarget.value.substring(
            0,
            e.currentTarget.value
              .substring(0, e.currentTarget.selectionEnd)
              .lastIndexOf("@")
          ) +
          e.currentTarget.value.substring(
            e.currentTarget.value
              .substring(
                e.currentTarget.value
                  .substring(0, e.currentTarget.selectionEnd)
                  .lastIndexOf("@"),
                e.currentTarget.value.length
              )
              .split(" ", 2)
              .join(" ").length +
              e.currentTarget.value
                .substring(0, e.currentTarget.selectionEnd)
                .lastIndexOf("@") +
              1,
            e.currentTarget.length
          );
      } else if (
        e.currentTarget.value
          .substring(0, e.currentTarget.selectionEnd)
          .lastIndexOf("@") !== -1 &&
        e.currentTarget.value
          .substring(
            e.currentTarget.value
              .substring(0, e.currentTarget.selectionEnd)
              .lastIndexOf("@"),
            e.currentTarget.selectionEnd
          )
          .indexOf(" ") !== -1 &&
        e.currentTarget.value
          .substring(
            e.currentTarget.value
              .substring(0, e.currentTarget.selectionEnd)
              .lastIndexOf("@"),
            e.currentTarget.selectionEnd
          )
          .split(" ").length == 2
      ) {
        e.preventDefault();
        e.currentTarget.value =
          e.currentTarget.value.substring(
            0,
            e.currentTarget.value
              .substring(0, e.currentTarget.selectionEnd)
              .lastIndexOf("@")
          ) +
          e.currentTarget.value.substring(
            e.currentTarget.value
              .substring(
                e.currentTarget.value
                  .substring(0, e.currentTarget.selectionEnd)
                  .lastIndexOf("@"),
                e.currentTarget.value.length
              )
              .split(" ", 2)
              .join(" ").length +
              e.currentTarget.value
                .substring(0, e.currentTarget.selectionEnd)
                .lastIndexOf("@") +
              1,
            e.currentTarget.length
          );
      }
    }
    if (e.key == "Delete") {
      e.preventDefault();
    }
  }

  triggerMentions(e) {
    if (
      e.currentTarget.value &&
      e.currentTarget.value.charAt(e.currentTarget.selectionEnd - 1) == "@"
    ) {
      this.openOptions.call(this, e);
    }
    if (!e.currentTarget.value) {
      this.setState({ openOptions: false });
    }
    this.forceUpdate();
    if (this.props.onKeyUp) {
      this.props.onKeyUp.call(this, e);
    }
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur.call(this, e);
    }
    this.setState({ openOptions: false });
  }

  render() {
    let options, photo, input;
    const {
      className,
      placeholder,
      isShowPhoto,
      isMember,
    } = this.props;
    const _this = this;
    const optionsList = this.props.options.map((item, i) => {
      if (_this.input) {
        if (
          (
            item["email"]["firstName"] +
            " " +
            item["email"]["lastName"]
          ).indexOf(
            _this.input.value.substring(
              _this.input.value
                .substring(0, _this.input.selectionEnd)
                .lastIndexOf("@") + 1,
              _this.input.selectionEnd
            )
          ) !== -1
        ) {
          if (isShowPhoto) {
            if (item.email && item.email.image) {
              photo = <img src={item.email.image} className="profile-small" />;
            } else if (item.email) {
              photo = (
                <span className="profile-small">
                  {(item.email.firstName
                    ? item.email.firstName[0]
                    : item.email.email[0]) +
                    (item.email.lastName
                      ? item.email.lastName[0]
                      : item.email.email[1])}
                </span>
              );
            } else {
              photo = <span className="profile-small" />;
            }
          }
          return (
            <div
              className="left-align"
              key={i}
              data-value={JSON.stringify(item ? item : null)}
              onMouseDown={this.onSelect.bind(this)}
            >
              {photo}
              <span className="text mention-item vertical-super">
                {item.value
                  ? item.value
                  : (isMember &&
                    item["email"]["firstName"] === undefined &&
                    item["email"]["lastName"] === undefined
                      ? item["email"]["email"]
                      : item["email"]["firstName"] +
                        " " +
                        item["email"]["lastName"]) + ""}
              </span>
            </div>
          );
        }
      }
    });

    if (this.state.openOptions && optionsList.filter((list) => list).length > 0) {
      options = <div className="options pos-abs mentions">{optionsList}</div>;
    }
    if (this.props.input) {
      input = (
        <input
          type="text"
          style={this.props.style}
          ref={(input) => {
            this.input = input;
          }}
          placeholder={placeholder}
          className={className}
          onKeyDown={this.removeMentions.bind(this)}
          onKeyUp={this.triggerMentions.bind(this)}
          onChange={this.props.onChange ? this.props.onChange.bind(this) : null}
          defaultValue={this.props.defaultValue}
          onBlur={this.onBlur.bind(this)}
          onClick={this.props.onClick ? this.props.onClick.bind(this) : null}
          onSelect={this.props.onSelect ? this.props.onSelect.bind(this) : null}
        />
      );
    } else {
      input = (
        <textarea
          style={this.props.style}
          ref={(input) => {
            this.input = input;
          }}
          placeholder={placeholder}
          className={className}
          onKeyDown={this.removeMentions.bind(this)}
          onKeyUp={this.triggerMentions.bind(this)}
          onChange={this.props.onChange ? this.props.onChange.bind(this) : null}
          defaultValue={this.props.defaultValue}
          onBlur={this.onBlur.bind(this)}
          onFocus={this.onFocus}
          onClick={this.props.onClick ? this.props.onClick.bind(this) : null}
          onSelect={this.props.onSelect ? this.props.onSelect.bind(this) : null}
        />
      );
    }

    return (
      <div className="pos-rel drop-down-cont">
        {input}
        {options}
      </div>
    );
  }
}
