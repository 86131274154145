import {useState} from "react"
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import {useDispatch} from "react-redux";
import {actions as profileActions} from "../../../../store/reducers/profile";

const Invoices = ({ invoices = [] }) => {
    const dispatch = useDispatch();

    const getustomerPortalUrl = () => {
        dispatch({
            type: profileActions.GET_PORTAL_URL
        })
    }

    const [total, setTotal] = useState(5);
    const getDate = (date) => {
        return moment(date).format('MMM DD, YYYY');
    };

    return (
        <div className={styles.Invoices} >
            <div className={styles.header}>
                Invoices
            </div>
            <div className={styles.content} >
                <div className={styles.titles}>
                    <div>
                        Date
                    </div>
                    <div>
                        Invoice
                    </div>
                </div>
                {invoices.slice(0, total).map((item) => (
                    <div className={styles.titles} key={item.invoiceId} onClick={() => window.open(item.hostedInvoiceUrl, "_blank")}>
                        <div>
                            {`${getDate(item.updatedAt)}`}
                        </div>
                        <div>
                            {`$ ${item.amountPaid / 100}`}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.updateInvoices} onClick={()=> setTotal(total + 5)}>
                <a href="javascript:void(0);" onClick={getustomerPortalUrl}>View More Invoices</a>
            </div>
        </div>
    );
};

Invoices.propTypes = {
    invoices: PropTypes.array,
};


export default Invoices;
