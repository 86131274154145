import { useEffect, useRef, useState } from "react";
import { get } from "lodash";
import { connect, useDispatch } from "react-redux";
import {
  editCardDetail,
  addCheckList,
  duplicateCard,
  getBoardDetails,
} from "../../../../store/actions/lists";
import {
  showHideMoveCardToBoardModal,
  toggleCardSelection,
} from "../../../../store/actions/UIActions";
import PropTypes from "prop-types";
import LabelAssigned from "./assigned";
import CardTitle from "./cardTitle";
import CardDesc from "./cardDesc";
import CardFile from "./cardFiles";
import Badge from "./badge";
import Attachments from "./attachemnts";
import CardActions from "./cardActions/cardActions";
import CheckList from "./checkList";
import TaskList from "./taskList";
import DueDate from "./dueDate";
import MoveCardToBoard from "../../Cards/MoveCardToBoard";
import { canEditBoard } from "../../../../helper";
import { uploadFiles } from "../../../../src/helper";
import { Popover, message } from "antd";
import LabelList from "./labelList";
// import { showEditCardName } from "../../../store/actions/UIActions";

const Card = ({
  item,
  activeBoard,
  user,
  column = {},
  toggleCardSelection,
  ...props
}) => {
  const [editCardDesc, setEditCardDesc] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const isHasPermissionToEdit = canEditBoard(
    user.currentMember,
    user.isUserLoggedin,
    user.isSharedView
  );
  const [isActive, setIsActive] = useState("");
  // const divRef = useRef();
  const handlePopup = (id) => {
    const ele = document.getElementById(id);
    if (!ele) {
      return;
    }
    if (ele.className.indexOf("org-show-tooltip") === -1) {
      ele.className += "org-show-tooltip";
    } else {
      ele.className = ele.className.replace("org-show-tooltip", "");
    }
  };

  // useEffect(() => {
  //   // const handleOutsideClick = (event) => {
  //   //   // if (divRef.current && !divRef.current.contains(event.target)) {
  //   //   //   setIsActive('');
  //   //   // }
  //   // };
  //   document.addEventListener('mousedown', handleOutsideClick);
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick);
  //   };
  // }, []);

  const openEditCardDesc = (val) => {
    if (isHasPermissionToEdit) {
      setEditCardDesc(val);
    }
  };

  const setHeaderColor = (cardInfo, value) => {
    const isUserLoggedIn = get(user, "isUserLoggedin", false);
    const isPublic = get(user, "isPublic", false);
    if (!isUserLoggedIn || isPublic) {
      return;
    }
    if (value.headerColor) {
      dispatch(
        editCardDetail(cardInfo, {
          headerColor: value.headerColor,
        })
      );
    }
  };

  const onAddCheckList = (card) => {
    dispatch(addCheckList(card));
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (progress > 0) {
      return;
    }
    if (ev.dataTransfer.files) {
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];
        if (file && file.size < 25 * 1024 * 1024) {
          const formData = new FormData();
          formData.append("image", file);
          formData.append("card", item._id);
          formData.append("isCardImage", false);
          setProgress(1);
          uploadFiles(formData, (event) => {
            setProgress(
              Number.parseFloat((100 * event.loaded) / event.total).toFixed(2)
            );
          })
            .then((response) => {
              setIsHovered(false);
              setProgress(0);
              if (response.data.status !== "error") {
                dispatch({
                  type: "ADD_CARD_ATTACHMENT_SUCCESS",
                  payload: response.data,
                  board: response.data.data,
                  lists: response.data.data.columns,
                });
              } else {
                dispatch({
                  type: "ADD_CARD_ATTACHMENT_FAIL",
                  payload: response.data,
                });
              }
            })
            .catch(() => {
              setIsHovered(false);
              setProgress(0);
              dispatch({ type: "ADD_CARD_ATTACHMENT_FAIL" });
            });
        } else {
          setIsHovered(false);
          message.error(`You can only upload files of 25MB or less.`);
        }
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={`card ${isHovered ? "is-drophover" : ""}`} id={item._id}>
      <div
        className={`card-header ${isHovered ? "is-drophover" : ""}`}
        style={{ backgroundColor: item.headerColor }}
      />
      <div
        className="org-card-drag-drop-container font-12 org-flex-col-center"
        onDragLeave={() => setIsHovered(false)}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {progress === 0 ? (
          <div className="org-progress-bar-container">
            <p className="p-5">Drop files to upload.</p>
          </div>
        ) : (
          <div className="org-progress-bar-container">
            <div className="org-progress-bar">
              <div
                className="org-progress-level"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="p-5">Uploading ({progress}%)</p>
          </div>
        )}
      </div>
      <div>
        <div
          onMouseLeave={() => toggleCardSelection()}
          onMouseEnter={() => toggleCardSelection(item._id)}
          className={`card-detail label-list`}
          id={`${item._id}_drag-zone`}
          style={{
            opacity: isHovered ? 0.2 : 1,
          }}
          onDragOver={() => {
            setIsHovered(true);
          }}
          data-index={item._id}
        >
          <CardTitle card={item} canEditBoard={isHasPermissionToEdit} />
          {isHasPermissionToEdit && (
            <span
              id={`card_option_${item._id}`}
              className="org-card-options org-tooltip org-options "
              aria-hidden="true"
              onClick={() => handlePopup(`card_option_${item._id}`)}
            >
              <div className="org-tooltip-text-on-click">
                <CardActions
                  card={item}
                  column={column}
                  board={activeBoard}
                  setHeaderColor={setHeaderColor}
                  handlePopup={() => handlePopup(`card_option_${item._id}`)}
                  addCheckList={onAddCheckList}
                />
              </div>
            </span>
          )}
          {!activeBoard.showTitleOnly && (
            <>
              <Attachments card={item} attachments={item.attachment} />
              <CardDesc
                card={item}
                editCardDesc={editCardDesc}
                setEditCardDesc={openEditCardDesc}
                isHasPermissionToEdit={isHasPermissionToEdit}
              />
              {item.checkLists.length > 0 && (
                <CheckList
                  card={item}
                  checkLists={item.checkLists}
                  isLoggedIn={user.isUserLoggedin}
                  isPublic={user.isPublic}
                  canEditCheckList={isHasPermissionToEdit}
                />
              )}

              {item.labelAssigned && item.labelAssigned.length > 0 && (
                <LabelAssigned
                  labelAssigned={item.labelAssigned}
                  labelToggleDetails={user.labelToggleDetails}
                />
              )}
              {item.tasks && item.tasks.length > 0 && (
                <TaskList tasks={item.tasks} />
              )}
              <span className="duration">
                {item.duration ? item.duration : ""}
              </span>

              <CardFile
                card={item}
                editCardDesc={editCardDesc}
                setEditCardDesc={openEditCardDesc}
              />
              <div className="badge-cont left-align">
                <Badge
                  card={item}
                  editCardDesc={editCardDesc}
                  setEditCardDesc={openEditCardDesc}
                />
                <DueDate item={item} tasks={item.tasks} />
              </div>
            </>
          )}
        </div>
      </div>
      {user.showHideMoveCardModal && user.card._id === item._id && (
        <MoveCardToBoard
          boardDetails={user.boardList}
          card={user.card}
          showHideMoveCardModal={true}
          handleClose={props.showHideMoveCardToBoardModal}
          getBoardDetail={props.getBoardDetails}
          moveCardToBoard={props.duplicateCard}
          activeBoard={activeBoard._id}
        />
      )}
    </div>
  );
};

Card.propTypes = {
  user: PropTypes.object,
  item: PropTypes.object,
  editCard: PropTypes.object,
  boardType: PropTypes.object,
  activeBoard: PropTypes.object,
  column: PropTypes.object,
  dispatch: PropTypes.funct,
};

const mapStateToProps = (state) => ({
  ...state.user,
  user: state.user,
  boardType: state.user?.activeBoard?.boardType,
  editCard: state.user.editCard,
  activeBoard: state.user.activeBoard,
});

const mapDispatchToProps = {
  duplicateCard,
  getBoardDetails,
  showHideMoveCardToBoardModal,
  toggleCardSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Card);
