import React, { useState } from "react";
import * as Yup from "yup";
import { fetchUser, getUser } from "../../../../store/actions/user";
import { get } from "lodash";
import SocialLogin from "../SocialLogin";
import FormHeader from "../FormHeader";
import { Form, Formik } from "formik";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Spinner from "../../../spinner/spinner";
import "./styles.css";

const initialValues = {
  password: "",
};
const loginSchema = Yup.object().shape({
  password: Yup.string().required("Required Field"),
});

const PasswordLogin = ({
  userEmail,
  propss,
  user,
  history,
  setRegistrationState,
  pwdLoginWithRegister,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const updateErrorMessage = (message = "") => {
    setErrorMessage("");
    if (!message || message.trim().length === 0) {
      return;
    }
    setErrorMessage(message);
  };

  const onUserLogin = ({ password }) => {
    props
      .fetchUser(userEmail, password)
      .then((resp) => {
        const isInactive = get(resp, "data.user.isInactive", true);
        const errorCode = get(resp, "data.message", "");
        const status = get(resp, "data.status");
        if (isInactive === false && status !== "error") {
          return props.getUser();
        }
        updateErrorMessage(errorCode);
        return isInactive;
      })
      .then((res) => {
        const sharedBoardId = window.localStorage.getItem("shared-board-id");
        const boardId = get(res, "data.data.boards[0]", null);
        const errorMessage = get(res, "data.message", "");
        if (sharedBoardId && res !== true && boardId) {
          window.localStorage.removeItem("shared-board-id");
          window.location.hash = `#/shared/board/${sharedBoardId}`;
          window.location.reload();
          return;
        } else if (boardId) {
          history.push(`/board/${boardId}`);
          return;
        }
        errorMessage && updateErrorMessage("INVALID_EMAIL");
      });
  };

  const isError = (submitCount, error) => {
    return submitCount > 0 ? error : false;
  };

  function handlePwdInput(event) {
    if (errorMessage) {
      setErrorMessage("");
    }
  }
  return (
    <>
      <FormHeader headerText={"Log in to continue"} />

      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={onUserLogin}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          submitCount,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <div className="form-container">
              <Form className="formik-form" onSubmit={handleSubmit}>
                <div className="form-field-container">
                  <label className="form-label" htmlFor="lastName">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className={`input-field ${
                      isError(submitCount, errors.password) && "error"
                    }`}
                    onChange={(event) => {
                      handleChange(event);
                      handlePwdInput(event);
                    }}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span className="field-error">
                    {isError(submitCount, errors.password)}
                  </span>
                </div>
                {errorMessage && (
                  <span className="form-error">{errorMessage}</span>
                )}
                <button className="signup-button" type="submit">
                  Log in
                </button>
                <div className="text-container">
                  <div
                    className="different-login"
                    onClick={() =>
                      setRegistrationState((prev) => ({
                        ...prev,
                        loginUsingPassword: false,
                        checkEmailRegistered: true,
                      }))
                    }
                  >
                    Use a different login method
                  </div>
                  <div
                    className="forgot-pwd"
                    onClick={() => history.push("/forget-password")}
                  >
                    Forgot password?
                  </div>
                </div>
                <SocialLogin
                  setErrorMsg={setErrorMessage}
                  props={propss}
                  history={history}
                  pwdLoginWithRegister={pwdLoginWithRegister}
                />
              </Form>
            </div>
          );
        }}
      </Formik>
      <Spinner show={user.isLoading} />
    </>
  );
};

const mapDispatchToProps = {
  fetchUser,
  getUser,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PasswordLogin));
