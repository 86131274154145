import React, { Component } from "react";
import { connect } from "react-redux";

class BoardActions extends Component {
  closeBoardOptions() {
    this.props.closeBoardOptions.call(this);
  }

  showDeleteBoard() {
    if (this.props.board) {
      this.props.showDeleteBoard.call(this, this.props.board);
    }
  }

  canDeleteBoard = () => {
    const {board = {}, user = {}} = this.props;
    return board.owner === user.loggedInUser.email;
  }

  render() {
    return (
      <div
        className="action-cont org-board-actions"
        style={{
          display: this.props.isBoardActionOpen ? "block" : "none",
          top: this.props.top,
          left: this.props.left,
          zIndex: 2,
        }}
      >
        <div className="header">
          <span>Boards Actions</span>
          <span
            className="close fa fa-times"
            onClick={this.closeBoardOptions.bind(this)}
          />
        </div>
        {this.canDeleteBoard() && <div className="action-list">
          <div className="action">Copy Board</div>
          <div className="action" onClick={this.showDeleteBoard.bind(this)}>
            Delete Board
          </div>
        </div>}
      </div>
    );
  }
}
export default connect((state) => ({user: state.user}))(BoardActions);