import { useEffect } from "react";
import "./App.css";
import "./App.scss";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PropTypes from "prop-types";
// import Base from './containers/Base/Base';
import Otp from "./containers/register/Otp";
import Register from "./containers/register";
import ChangePassword from "./containers/changePassword/changePassword";
import ForgetPasswordComponent from "./containers/forgetPassword";
import ResetPassword from "./containers/forgetPassword/resetPassword";
import { connect } from "react-redux";
import { isValidBoard } from "./store/actions/lists";
import { getUser } from "./store/actions/user";
import Spinner from "./containers/spinner/spinner";
import SharedBoardView from "./sharedBoard";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import DefaultPage from "./containers/defaultPage";
import BoardRoutes from "./containers/boardRoutes";
import { LoginStartup } from "./containers/loginUser";

function App({ user, getUserDetails }) {
  useEffect(() => {
    getUserDetails();
    checkIfResetPassword();
  }, []);
  const checkIfResetPassword = () => {
    const href = window.location.href;
    if (
      href.includes("?email=") &&
      href.includes("&code=") &&
      !href.includes("#/reset-password")
    ) {
      const reset = href.substring(href.indexOf("?email"));
      window.location.replace(`${window.origin}/#/reset-password${reset}`);
    }
  };
  return (
    <Router className="App">
      <Switch>
        {user?.loadingCurrentUser ? (
          <Spinner show />
        ) : (
          <>
            {user.isUserLoggedin ? (
              <PrivateRoutes user={user.loggedInUser} />
            ) : (
              <PublicRoutes />
            )}
          </>
        )}
      </Switch>
      <NotificationContainer />
    </Router>
  );
}

function PrivateRoutes() {
  return (
    <Switch>
      <Route exact path="/shared/board/:id" component={SharedBoardView} />
      <Route
        exact
        path="/shared/board/:id/column/:columnId/card/:cardId"
        component={SharedBoardView}
      />
      <Route exact path={"/"} component={DefaultPage} />
      <Route path={"/board"} component={BoardRoutes} />
      <Redirect to="/" />
    </Switch>
  );
}

function PublicRoutes() {
  return (
    <Switch>
      <Route exact path="/login" component={LoginStartup} />
      <Route path="/register" component={Register} />

      <Route path="/forget-password" component={ForgetPasswordComponent} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route exact path="/change-password" component={ChangePassword} />
      <Route path="/otp" component={Otp} />
      <Route path="/shared/board/:id" component={SharedBoardView} />
      <Route
        path="/shared/board/:id/column/:columnId/card/:cardId"
        component={SharedBoardView}
      />
      <Route render={() => <Redirect to="/register" />} />
    </Switch>
  );
}

App.propTypes = {
  user: PropTypes.object.isRequired,
  getUserDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = {
  getUserDetails: getUser,
  isValidBoard,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
