import axios from "axios";

import {BASE_URL} from "../../constants";

export function resendOTP(email) {

    return axios({
        "method": "post",
        "url": BASE_URL + "auth/resendOTP",
        "data": {
            "email": email.toLowerCase()
        }
    });

}