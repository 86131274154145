
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../../../header/credentialModalHeader";
import orgzeeBlue from "../../../../assets/images/Orgzee_robot_success.png";
import { actions as registerActions } from "../../../../store/reducers/register";

const CheckEmailPage = ({ history }) => {
  const dispatch = useDispatch();
  const { registerData = {} } = useSelector((state) => ({ registerData: state.register.registerData }));
  if (!registerData.email) {
    history.push("/register")
  }
  const onUserRegister = () => {
    dispatch({
      type: registerActions.RESEND_VERIFICATION_MAIL,
      payload: {
        ...registerData,
        history
      },
    });
  };
  return (
    <div style={{ display: "flex", justifyContent: "center", padding: 20 }}>
      <Header isBlue={true}/>
      <div className="register-plans-container register-email-check" style={{width: 750}}>
        <div className="image-container-blue">
          <img src={orgzeeBlue} alt="orgzeeBlue" />
        </div>
        <div className="page-title m-b-10 m-t-10 center-align text-black font-25 text-bold">
          Verify your email
        </div>
        <div className="m-b-10 m-t-10 center-align font-15">
          <p>
            To use Orgzee, click the verification button in the email we sent to <b className="text-bold">{registerData.email}</b>. This helps keep your account secure.
          </p>
          <p>Don’t see the email in your inbox or spam folder? <mark onClick={onUserRegister}>Resend it.</mark></p>
          <p>
            Wrong address? <mark onClick={() => history.push("/")}>Log out</mark> to sign in with a different email. If you
            mistyped your email when signing up, create a new account.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CheckEmailPage);
