import React, {Component} from "react";

export default class NoteActions extends Component {
    render() {

        const {top, left} = this.props;
        return (
            <div
                className="action-cont"
                style={{"display": "block", "top": top, "left": left, "zIndex": "5"}}
            >
                <div className="action-list">
                    <div className="action" onClick={this.props.editNote.bind(this)}>
                        Edit Note
                    </div>
                    <div
                        className="action"
                        onClick={this.props.deleteCardNote.bind(this)}
                    >
                        Delete Note
                    </div>
                </div>
            </div>
        );
    }

}
