import React from "react";
import "./styles.css";
import SocialLogin from "../SocialLogin";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormHeader from "../FormHeader";
import Spinner from "../../../spinner/spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router";

const initialValues = {
  email: "",
};
const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required Field"),
});

const EmailChecker = ({
  onLogin,
  isLoading,
  errorMsg,
  setErrorMsg,
  propss,
  history,
  currentPath,
  setRegistrationState,
}) => {
  const onUserLogin = (values) => {
    onLogin(values);
  };
  const isEmailError = (submitCount, value, error) => {
    return submitCount > 0 || value ? error : false;
  };

  return (
    <>
      <FormHeader
        headerText={
          currentPath.includes("/login")
            ? "Log in to continue"
            : "Sign up to continue"
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={onUserLogin}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          submitCount,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <Form className="formik-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className={`${
                    isEmailError(submitCount, values.email, errors.email) &&
                    "error"
                  }`}
                  disabled={isLoading}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter your email"
                />
                <span className="field-error email-error">
                  {isEmailError(submitCount, values.email, errors.email)}
                </span>
                {errorMsg && <span className="form-error">{errorMsg}</span>}
              </div>
              <p className="privacy-text">
                By signing {currentPath.includes("/login") ? "in" : "up"}, you
                accept our{" "}
                <a href="https://orgzee.com/terms-of-service/">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="https://orgzee.com/privacy-policy/">Privacy Policy</a>{" "}
                .
              </p>
              <button
                className="signup-button"
                type={isLoading ? "button" : "submit"}
              >
                {currentPath.includes("/login") ? "Continue" : "Sign up"}
              </button>
              {currentPath.includes("/register/new-account") && (
                <div className="create-account">
                  <Link to={`/login`}>
                    Already have an Orgzee account? Log in
                  </Link>
                </div>
              )}
              {currentPath.includes("/register") ? (
                <SocialLogin
                  setErrorMsg={setErrorMsg}
                  props={propss}
                  history={history}
                  isGoogleRegister={true}
                  setRegistrationState={setRegistrationState}
                />
              ) : (
                <SocialLogin
                  setErrorMsg={setErrorMsg}
                  props={propss}
                  history={history}
                />
              )}
              {currentPath.includes("/login") && (
                <div className="create-account">
                  <Link to={`/register`}>Or create an account</Link>
                </div>
              )}
              <Spinner show={isLoading} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

EmailChecker.propTypes = {
  match: PropTypes.object,
};

export default withRouter(EmailChecker);
