const initialState = {
    activeBoard: {},
    showCopyNotification: false
  };
  
  export const actions = {
    SET_STATE: "boards/SET_STATE",
    COPY_BOARD: "boards/COPY_BOARD",
    DELETE_BOARD: "boards/DELETE_BOARD",
    LEAVE_BOARD: "boards/LEAVE_BOARD",
    REMOVE_MEMBER: "board/REMOVE_MEMBER"
  };
  
  function boardsReducer(state = initialState, action) {
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  
  export default boardsReducer;
  