import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import MainModal from "../../../components/modal";
import { actions } from "../../../store/reducers/boards";
import { useHistory } from "react-router";
import { Alert } from "antd";
import LeaveBoardNotification from "./leaveBoardNotification";
import { get } from "lodash";

const BoardSettingMenu = ({
  isCopying,
  isCurrentBoardPublic,
  toggleBoardHistory,
  toggleBoardShareModal,
  handlePopup,
  toggleBoardSettings,
  showDeleteBoard,
  board,
  isPublic,
  setOpenCopyBoard
}) => {
  const { loggedInUser, user = {}, boardList =[], errorMessage, showLeaveModal = false, showLeaveBoardNotification = false } = useSelector((state) => ({
    loggedInUser: state.user.loggedInUser,
    boardList: state.user.boardList,
    errorMessage: state.boards.errorMessage,
    showLeaveModal: state.boards.showLeaveModal,
    showLeaveBoardNotification: state.boards.showLeaveBoardNotification,
    user: state.user,
   }))
  const history = useHistory()
  const dispatch = useDispatch();
  const openCopyBoard = () => {
    if (isPublic) setOpenCopyBoard(true)
  }
  const [canShare, setCanShare] = useState(false)
  const [canEditSettings, setCanEditSettings] = useState(false)
  useEffect(() => {
    return () => {
      openLeaveBoard(false)
      dispatch({
        type: actions.SET_STATE,
        payload: {
          errorMessage: false
        }
      })
    }
  }, [])
  useEffect(() => {
    if(board) {
      const member = board.members.find((elem) => elem.email.email == loggedInUser._id);
      setCanShare((member && member.role == "ADMIN") || (board.isCopying && board.isPublic));
      checkCanSettingBoard()
    }

  }, [board])

  const onLeaveBoard = () => {
    const redirectTo = (boardList.find((item) => item._id != board._id) || {})._id
    dispatch({
      type: actions.LEAVE_BOARD,
      payload: {boardId: board._id, redirectTo, history}
    })
  }

  const openLeaveBoard = (v) => {
    dispatch({
      type: actions.SET_STATE,
      payload: {showLeaveModal: v}
    })
  }

  const checkCanSettingBoard = () => {
    setCanEditSettings(false);
    if (user.isUserLoggedin && !get(user, "isSharedView", false)) {
      const members = user.activeBoard.members;
      const find = members.findIndex((mbr) => mbr.role == "ADMIN" && mbr.email?.email == user.loggedInUser.email);
      setCanEditSettings(find >= 0);
      return;
    }
  }

  return (
    <div
      id="org_board_options"
      onClick={(e) => handlePopup("org_board_options", e)}
      className="org-board-options org-tooltip org-options m-r-10 "
    >
      <div className="org-tooltip-text-on-click">
        <div className="action-list">
          {canEditSettings && <div
            onClick={(e) => {
              handlePopup("org_board_options", e);
              toggleBoardSettings(true);
            }}
            className="action"
          >
            Board settings
          </div>}
          <div className={`action ${!isCopying ? "disabled-link" : ""}`} onClick={openCopyBoard}>
            Copy board
          </div>
          {canShare && <div
            className={`action ${!isCurrentBoardPublic ? "disabled-link" : ""}`}
            onClick={(e) => {
              handlePopup("org_board_options", e);
              toggleBoardShareModal(true);
            }}
          >
            Share board
          </div>}
          {loggedInUser._id == board?.owner && <div className="action" onClick={() => showDeleteBoard(true)}>Delete board</div>}
          {loggedInUser._id != board?.owner && <div className="action" onClick={() => openLeaveBoard(true)}>Leave board</div>}
          <div className="action" onClick={() => toggleBoardHistory()}>
            View history
          </div>
        </div>
      </div>
      {showLeaveModal &&
        <MainModal open title="Leave Board" handleClose={() => openLeaveBoard(false)}>
          <div>
            <p style={{marginBottom: 40}}>Are you sure to leave the board: <b>{board.name}</b></p>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
            <div className={"form-footer"}>
              <button
                className={`btn default-button`}
                onClick={() => openLeaveBoard(false)}
                type="button"
              >
                Cancel
              </button>
              <button
                className={`btn submit-button`}
                onClick={() => onLeaveBoard()}
              >
                Leave
              </button>
            </div>
          </div>
        </MainModal>
      }
      {showLeaveBoardNotification && <LeaveBoardNotification /> }
    </div>
  );
};

BoardSettingMenu.propTypes = {
  isCopying: PropTypes.bool,
  isPublic: PropTypes.bool,
  isCurrentBoardPublic: PropTypes.bool,
  toggleBoardHistory: PropTypes.func,
  toggleBoardShareModal: PropTypes.func,
  handlePopup: PropTypes.func,
  toggleBoardSettings: PropTypes.func,
  setOpenCopyBoard: PropTypes.func
};

export default BoardSettingMenu;
