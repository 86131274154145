import React, {Component} from "react";
import moment from "moment";
import MultiSelectDropdown from "../../../../widget/Dropdown/multiSelectDropdown";
import MentionInput from "../../../../widget/mentions/mentions";
import NoteActions from "./noteActions/NoteActions";
import LaterActions from "./laterActions/LaterActions";
import CommentActions from "./commentActions/CommentActions";
import CreateBoardFromCard from "./createBoardFromCard/CreateBoardFromCard";
import ConfirmDialog from "../../../confirmDialog/ConfirmDialog";
import {
    timeDifference,
    formatTime,
    formatDate,
    parseVideo,
} from "../../../../helper";
import RichTextEditor from "react-rte";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get } from "lodash";
import Microlink from "@microlink/react";
import { mentionText } from "../../../../components/common";

const defaultCreateLabelColor = "#7ed321";

export default class CardDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            "editDescription": false,
            "editCardName": false,
            "editCardGoal": false,
            "editCardAddress": false,
            "editCardPhone": false,
            "editCardEmail": false,
            "editCardWebsite": false,
            "selectedTab": "task",
            "cardNoteValue": RichTextEditor.createEmptyValue(),
            "isNoteActionOpen": false,
            "isCommentActionOpen": false,
            "isCreateBoardFromCard": false,
            "addNoteBtnDisabled": true,
            "addAddressBtnDisabled": true,
            "editCardNoteValue": RichTextEditor.createEmptyValue(),
            "addNoteAttachmentList": [],
            "assigneeSelected": [],
            "labelSelected": [],
            "userListByName": [],
            "labelLists": [],
            "listId": null,
            "searchLabel": "",
            "labelColor": defaultCreateLabelColor,
            "assigneeName": "",
            "activeDropdown": "",
            "dueDate": null,
            "colorList": [
                "#f44336",
                "#FF9800",
                "#FFEB3B",
                "#795548",
                "#2196f3",
                "#00bcd4",
                "#aed581",
                "#000000",
                "#2e7d32",
                "#546e7a",
                "#81d4fa",
            ],
            "showColorPanel": false,
            "labelEditMode": false,
            "laterPopUp": false,
            "dateHover": false,
            "showLabelDeletePopup": null,
            "showLabelColorPopup": null,
            "dueDatePicker": moment(new Date()),
            "editDueTime": false,
            "dueTimePicker": "00:00",
            "showRepeatDueOption": false,
            "repeatDueDate": "None",
        };
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.dateOutsideClick = this.dateOutsideClick.bind(this);
        this.labelOutsideClick = this.labelOutsideClick.bind(this);

    }

    UNSAFE_componentWillMount() {

        const {openDueDate} = this.props;
        if (openDueDate) {

            this.rightPanelAction(this.dateOutsideClick, "dueDate");

        }

    }

    componentDidMount() {

        const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocompleteAddress"),
            {"types": ["geocode"]}
        );
        autocomplete.addListener(
            "place_changed",
            this.onAddressSelected.bind(this)
        );
        this.setAssigneeIds();
        this.setLabelIds();

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        const {
            userListByName,
            labelLists,
            labelCreate,
            boardHistoryList,
        } = nextProps;
        console.log(this.props.boardHistoryList &&
            boardHistoryList &&
            boardHistoryList.length != this.props.boardHistoryList.length, "label created board history");
        if (
            this.props.boardHistoryList &&
      boardHistoryList &&
      boardHistoryList.length != this.props.boardHistoryList.length
        )
        if (this.props.userListByName !== userListByName) {

            this.setState({userListByName});

        }
        if (this.props.labelLists !== labelLists) {

            this.setState({labelLists});

        }
        if (labelCreate !== this.props.labelCreate && labelCreate._id) {
            console.log("label created", labelCreate._id);
            this.updateLabelList(labelCreate._id);

        }

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.editDescription) {

            this.description.input.focus();

        }
        if (this.state.editCardName) {

            this.cardName.input.focus();

        }
        if (this.state.editCardGoal) {

            this.cardGoal.focus();

        }
        if (this.state.editCardPhone) {

            this.cardPhone.focus();

        }
        if (this.state.editCardEmail) {

            this.cardEmail.focus();

        }
        if (this.state.editCardWebsite) {

            this.cardWebsite.focus();

        }
        if (!this.state.isCardNoteValueEdit) {

            const cardNoteValue = RichTextEditor.createValueFromString(
                this.props.selectedCardDetail
                    ? this.props.selectedCardDetail.note
                        ? this.props.selectedCardDetail.note.value
                        : ""
                    : "",
                "html"
            );
            console.log('cardNoteValue : ',cardNoteValue);
                this.setState({cardNoteValue});
        }
        if (this.state.editComment) {

            this.editCommentValue.input.focus();

        }
        if (this.state.editCardAddress && !prevState.editCardAddress) {

            const autocomplete = new google.maps.places.Autocomplete(
                document.getElementById("autocompleteAddress"),
                {"types": ["geocode"]}
            );
            autocomplete.addListener(
                "place_changed",
                this.onAddressSelected.bind(this)
            );

        }
        this.auto_grow(this.description.input);

    }

    setLabelIds() {

        const {selectedCardDetail} = this.props;
        const labelAssigned =
      selectedCardDetail && selectedCardDetail.labelAssigned
          ? selectedCardDetail.labelAssigned
          : [];
        const labelSelected = [];
        labelAssigned.forEach((lbl) => {

            labelSelected.push(lbl._id);

        });
        this.setState({
            labelSelected,
        });

    }

    setAssigneeIds() {
        const {selectedCardDetail} = this.props;
        const tasks =
      selectedCardDetail && selectedCardDetail.tasks
          ? selectedCardDetail.tasks
          : [];
        const assigneeSelected = [];
        let dueDate = null;
        tasks.forEach((task) => {

            assigneeSelected.push(task.assignTo.email);
            if (task.date) {

                dueDate = task.date;

            }

        });
        let dueDatePicker = moment(new Date());
        let dueTimePicker = "00:00";
        if (moment(dueDate).isValid()) {

            dueTimePicker = moment(dueDate).format("HH:mm");
            dueDatePicker = moment(dueDate);

        }
        this.setState({
            assigneeSelected,
            dueTimePicker,
            dueDatePicker,
            dueDate,
        });
    }

    updateLabelList(labelID) {

        const {labelSelected = []} = this.state;
        if (labelSelected.findIndex((lbl) => lbl == labelID) == -1) {

            labelSelected.push(labelID);
            this.setState(
                {
                    labelSelected,
                },
                () => {

                    this.saveRightPanelData();

                }
            );

        }

    }

    onAddressSelected() {}

    isNumberKey(evt) {

        const charCode = evt.which ? evt.which : event.keyCode;
        if (charCode < 48 || charCode > 57) {

            evt.preventDefault();

        }

    }

    onChangeTextEditor() {}

    closeCardDetail() {
        if(!this.props.canEditCard) {
            window.history.pushState(null, null, "/#/board/" + this.props.selectedCardDetail.board);
        }
        this.props.closeCardDetail.call(this);
    }

    showEditDescription() {
        if(!this.props.canEditCard) {
            return
        }
        this.setState({
            "editDescription": true,
        });

    }

  onCardNoteChange = (value) => {

      this.setState({"cardNoteValue": value, "isCardNoteValueEdit": true});
      if (value.getEditorState().getCurrentContent().getPlainText().trim()) {

          this.setState({"addNoteBtnDisabled": false});

      } else {

          this.setState({"addNoteBtnDisabled": true});

      }
      if (this.props.onChange) {

          this.props.onChange(value.toString("html"));

      }

  };

  onEditCardNoteChange = (value) => {

      this.setState({"editCardNoteValue": value});
      if (this.props.onChange) {

          this.props.onChange(value.toString("html"));

      }

  };

  updateCardNote(noteId, value) {

      if (value.getEditorState().getCurrentContent().getPlainText().trim()) {

          const commentDate = new Date();
          this.props.updateCardNote.call(
              this,
              value.toString("html"),
              noteId,
              commentDate,
              this.props.selectedCardDetail
          );

      }
      this.setState({
          "listId": null,
          "editComment": null,
      });

  }

  updateComment(commentId) {

      if (this.editCommentValue.input.value.trim()) {

          const commentDate = new Date();
          this.props.updateCardNote.call(
              this,
              this.editCommentValue.input.value,
              commentId,
              commentDate,
              this.props.selectedCardDetail
          );

      }
      this.setState({"editComment": null});

  }

  deleteCardNote(noteId, e) {

      e.stopPropagation();
      this.props.deleteCardNote.call(this, noteId, this.props.selectedCardDetail);
      this.setState({"isNoteActionOpen": false});

  }

  showEditCardName() {
      if(!this.props.canEditCard) {
          return
      }
      this.setState({
          "editCardName": true,
      });

  }

  showEditCardPhone() {
    if(!this.props.canEditCard) {
        return
    }
      this.setState({
          "editCardPhone": true,
      });

  }

  showEditCardEmail() {
    if(!this.props.canEditCard) {
        return
    }
      this.setState({
          "editCardEmail": true,
      });

  }

  showEditCardWebsite() {
    if(!this.props.canEditCard) {
        return
    }
      this.setState({
          "editCardWebsite": true,
      });

  }

  showEditCardGoal() {
    if(!this.props.canEditCard) {
        return
    }
      this.setState({
          "editCardGoal": true,
      });

  }

  showEditCardAddress() {
    if(!this.props.canEditCard) {
        return
    }
      this.setState({
          "editCardAddress": true,
      });

  }

  showEditCardNote() {}

  closeEditCardNote() {}

  selectTab(tabType) {

      this.setState({
          "selectedTab": tabType
      });

  }

  addTask() {

      const taskDate = new Date(this.taskDate.value + " " + this.taskTime.value);
      this.props.addTask.call(
          this,
          this.taskTitle.input.value,
          this.taskNote.input.value,
          taskDate,
          this.state.selectedTab,
          this.state.selectedBoardmember.email?.email,
          this.props.selectedCardDetail
      );
      this.taskTitle.input.value = null;
      this.taskNote.input.value = null;
      this.taskDate.value = formatDate(new Date()).date;
      this.taskTime.value = formatDate(new Date()).time;

  }

  addCardComment() {

      if (this.cardCommentNormal.input.value.trim()) {

          const commentDate = new Date();
          const formData = new FormData();
          formData.append("comment", this.cardCommentNormal.input.value);
          formData.append("date", commentDate);
          formData.append("card", JSON.stringify(this.props.selectedCardDetail));
          this.props.addCardComment.call(this, formData);

      }
      this.cardCommentNormal.input.value = null;

  }

  completeTask(task) {

      this.props.completeTask.call(this, task);

  }

  editCardNote() {
      if (
          this.state.cardNoteValue
              .getEditorState()
              .getCurrentContent()
              .getPlainText()
              .trim()
      ) {

          const commentDate = new Date();
          const formData = new FormData();
          Object.keys(this.state.addNoteAttachmentList).forEach((key) => {

              formData.append(
                  "attachment" + key,
                  this.state.addNoteAttachmentList[key]
              );

          });
          formData.append("comment", this.state.cardNoteValue.toString("html"));
          formData.append("date", commentDate);
          formData.append("card", JSON.stringify(this.props.selectedCardDetail));
          formData.append("type", "note");
          this.props.addCardComment.call(this, formData);

      }
      this.setState({
          "cardNoteValue": RichTextEditor.createEmptyValue(),
          "addNoteAttachmentList": [],
      });

  }

  editDescription(e) {

      if (e.currentTarget.value) {

          this.props.editCardDetail.call(this, this.props.selectedCardDetail, {
              "description": e.currentTarget.value,
          });

      } else {

          this.description.input.value = "";

      }
      this.setState({
          "editDescription": false,
      });

  }

  editCardName(e) {

      if (e.currentTarget.value) {

          this.props.editCardDetail.call(this, this.props.selectedCardDetail, {
              "title": e.currentTarget.value,
          });

      } else {

          this.cardName.value = "";

      }
      this.setState({
          "editCardName": false,
      });

  }

  editCardPhone(e) {

      if (e.currentTarget.value && e.currentTarget.value.length == 10) {

          this.props.editCardDetail.call(this, this.props.selectedCardDetail, {
              "phone": e.currentTarget.value,
          });

      } else {

          this.cardPhone.value = this.props.selectedCardDetail.phone;

      }
      this.setState({
          "editCardPhone": false,
      });

  }

  editCardEmail(e) {

      if (
          e.currentTarget.value &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          e.currentTarget.value
      )
      ) {

          this.props.editCardDetail.call(this, this.props.selectedCardDetail, {
              "email": e.currentTarget.value,
          });

      } else {

          this.cardEmail.value = this.props.selectedCardDetail.email;

      }
      this.setState({
          "editCardEmail": false,
      });

  }

  editCardWebsite(e) {

      if (
          e.currentTarget.value &&
      /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi.test(
          e.currentTarget.value
      )
      ) {

          this.props.editCardDetail.call(this, this.props.selectedCardDetail, {
              "website": e.currentTarget.value,
          });

      } else {

          this.cardWebsite.value = this.props.selectedCardDetail.website;

      }
      this.setState({
          "editCardWebsite": false,
      });

  }

  editCardGoal(e) {

      if (e.currentTarget.value) {

          this.props.editCardDetail.call(this, this.props.selectedCardDetail, {
              "goal": e.currentTarget.value,
          });

      } else {

          this.cardGoal.value = "";

      }
      this.setState({
          "editCardGoal": false,
      });

  }

  addCardAddress() {

      if (this.cardAddress.value) {

          this.props.editCardDetail.call(this, this.props.selectedCardDetail, {
              "address": this.cardAddress.value,
          });

      } else {

          this.cardAddress.value = "";

      }
      this.setState({
          "editCardAddress": false,
      });

  }

  cancelCardAddress() {

      this.cardAddress.value = "";
      this.setState({
          "editCardAddress": false,
      });

  }

  onBoardMemberSelect(selectedValue) {

      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.selectedBoardMember = selectedValue;

  }

  toggleNoteActions(noteId, noteValue, e) {

      e.stopPropagation();
      this.setState((prevState) => ({
          "isNoteActionOpen": !prevState.isNoteActionOpen,
          "editCardNoteValue": RichTextEditor.createValueFromString(
              noteValue ? noteValue : "",
              "html"
          ),
          "NoteActionTop":
        e.currentTarget.closest(".backdrop").scrollTop +
        document.body.scrollTop +
        e.clientY,
          "NoteActionLeft": e.clientX,
          noteId,
      }));

  }

  toggleLaterOptions(task) {

      if (
          !this.state.openLaterOptions ||
      (this.state.openLaterOptions &&
        this.state.openLaterOptions._id !== task._id)
      ) {

          this.setState({"openLaterOptions": task});

      } else {

          this.setState({"openLaterOptions": null});

      }

  }

  createBoardFromCard = async () => {

      await this.props.createBoardFromCard.call(
          this,
          this.props.selectedCardDetail._id
      );
      this.props.closeCardDetail.call(this);

  };

  editNote(editNote, e) {

      e.stopPropagation();
      this.setState({
          "listId": editNote._id,
          "editCardNoteValue": RichTextEditor.createValueFromString(
              editNote.comment,
              "html"
          ),
      });

  }

  toggleCommentActions(commentId, commentValue, e) {

      e.stopPropagation();
      this.setState((prevState) => ({
          "isCommentActionOpen": !prevState.isCommentActionOpen,
          "commentActionTop":
        e.currentTarget.closest(".backdrop").scrollTop +
        document.body.scrollTop +
        e.clientY,
          "commentActionLeft": e.clientX,
          commentId,
          "editComment": null,
      }));

  }

  editComment(editComment, e) {

      e.stopPropagation();
      this.setState({editComment, "isCommentActionOpen": false});

  }

  deleteComment(commentId, e) {

      e.stopPropagation();
      this.props.deleteCardNote.call(
          this,
          commentId,
          this.props.selectedCardDetail
      );
      this.setState({"isCommentActionOpen": false});

  }

  onAttachmentImageSelected(e) {
    const _this = this;
    e.persist();
    if (
        e.currentTarget.files &&
    e.currentTarget.files[0] &&
    e.currentTarget.files[0].size < 5 * 1024 * 1024
    ) {
        if (e.currentTarget.getAttribute("data-is-card-attachment")) {

            this.props.addCardAttachment.call(
                this,
                e.currentTarget.files[0],
                this.props.selectedCardDetail._id
            );

        } else if (e.currentTarget.getAttribute("data-is-card-image")) {

            this.props.addCardAttachment.call(
                this,
                e.currentTarget.files[0],
                this.props.selectedCardDetail._id,
                true
            );

        } else {

            const reader = new FileReader();
            reader.onload = function (evt) {

                _this.state.addNoteAttachmentList[
                    _this.state.addNoteAttachmentList.indexOf(e.target.files[0])
                ].dataUrl = evt.target.result;
                _this.setState({
                    "addNoteAttachmentList": _this.state.addNoteAttachmentList,
                });

            };
            this.state.addNoteAttachmentList.push(e.currentTarget.files[0]);
            this.setState((prevState) => ({
                "addNoteAttachmentList": prevState.addNoteAttachmentList,
            }));
            reader.readAsDataURL(e.currentTarget.files[0]);

        }

    }

  }

  removeSelectedAttachment(file) {

      this.state.addNoteAttachmentList.splice(
          this.state.addNoteAttachmentList.indexOf(file),
          1
      );
      this.setState((prevState) => ({"addNoteAttachmentList": prevState.addNoteAttachmentList}));

  }

  setNewDeadline(task, delay, e) {

      e.stopPropagation();
      task.date = new Date(new Date().getTime() + delay);
      this.props.delayTask.call(this, task);
      this.setState({"openLaterOptions": null});

  }

  onAddCommentChange() {}

  onAddressChange(e) {

      if (e.currentTarget.value) {

          this.setState({"addAddressBtnDisabled": false});

      } else {

          this.setState({"addAddressBtnDisabled": true});

      }

  }

  removeAttachment(card, attachment, comment, e) {

      e.currentTarget.closest(".backdrop").scrollTop = 0;
      window.scrollTo(0, 0);
      this.setState({
          "showConfirmDialog": true,
          "confirmTitle": "Are you sure you want to delete Attachment?",
          "confirm": this.props.removeAttachment.bind(
              this,
              card,
              attachment,
              comment
          ),
          "cancelConfirmDialog": this.cancelConfirmDialog.bind(this),
      });
      // this.props.removeAttachment.call(this,card,attachment,comment);

  }

  cancelConfirmDialog() {

      this.setState({"showConfirmDialog": false});

  }

  onEnter(callback, e) {

      if (e.which == 13) {

          callback.call(this, e);

      }

  }

  preventEvent(e) {

      e.stopPropagation();
      this.setState({
          "isNoteActionOpen": false,
          "isCommentActionOpen": false,
          "isCreateBoardFromCard": false,
      });

  }

  auto_grow(element) {

      if (element) {

          element.style.height = element.scrollHeight + 5 + "px";

      }

  }

  hideEmptySpan(e) {

      e.currentTarget.style.opacity = "0";

  }

  showEmptySpan(e) {

      e.currentTarget.style.opacity = "1";

  }

  _renderCreateBoardFromCard() {

      const {
          isCreateBoardFromCard,
          createBoardActionTop,
          createBoardActionLeft,
      } = this.state;
      if (isCreateBoardFromCard) {

          return (
              <CreateBoardFromCard
                  top={createBoardActionTop}
                  left={createBoardActionLeft}
                  closeModal={() => this.setState({"isCreateBoardFromCard": false})}
                  createBoardFromCard={this.createBoardFromCard.bind(this)}
              />
          );

      } else {

          return null;

      }

  }

  validateTaskFields() {}

  handleChangeDueDate(e) {

      this.setState(
          {
              "dueDate": e.target.value,
              "activeDropdown": "",
              "laterPopUp": false,
          },
          () => {

              this.saveRightPanelData();

          }
      );

  }

  removeDueDate() {

      this.setState(
          {
              "dueDate": "",
              "laterPopUp": false,
          },
          () => {

              this.saveRightPanelData();

          }
      );

  }

  rightPanelAction(callback, value = "") {

      let {activeDropdown} = this.state;
      activeDropdown = value === activeDropdown ? "" : value;
      if (activeDropdown) {

          document.addEventListener("click", callback, false);

      } else {

          document.removeEventListener("click", callback, false);

      }
      this.setState(
          {
              activeDropdown,
              "userListByName": [],
              "searchLabel": "",
              "laterPopUp": false,
              "labelLists": [],
              "assigneeName": "",
              "labelEditMode": false,
              "showColorPanel": false,
              "labelColor": defaultCreateLabelColor,
          },
          () => {

              if (value !== "dueDate") {

                  this.saveRightPanelData();

              }

          }
      );

  }

  handleOutsideClick(e) {

      if (this.node && this.node.contains(e.target)) {

          return;

      }
      this.rightPanelAction(this.handleOutsideClick);

  }

  dateOutsideClick(e) {

      if (
          (this.dueNode && this.dueNode.contains(e.target)) ||
      (e.target.id && e.target.id === "org_time_disp") ||
      (e.target.id && e.target.id.indexOf("org_due_repeat") !== -1)
      ) {

          return;

      }
      this.rightPanelAction(this.dateOutsideClick, "dueDate");

  }

  labelOutsideClick(e) {

      const {labelEditMode} = this.state;
      if (
          (this.labelNode && this.labelNode.contains(e.target)) ||
      labelEditMode
      ) {

          return;

      }
      this.rightPanelAction(this.labelOutsideClick);

  }

  handleDropdown(value, e) {

      e.stopPropagation();
      e.preventDefault();
      const {canEditCard} = this.props;
      if (!canEditCard) return;

      switch (value) {

      case "assignee":
          this.rightPanelAction(this.handleOutsideClick, value);
          break;
      case "dueDate":
          this.rightPanelAction(this.dateOutsideClick, value);
          break;
      case "label":
          this.getLabelByName();
          this.rightPanelAction(this.labelOutsideClick, value);
          break;

      }

  }

  saveRightPanelData() {

      const {
          activeDropdown,
          assigneeSelected,
          dueDate,
          labelSelected,
      } = this.state;
      const {selectedCardDetail, isPublic, isUserLoggedIn} = this.props;
      if (activeDropdown || !isUserLoggedIn || isPublic) {

          return;

      }
      const newAss = [...assigneeSelected];
      this.props.updateAssignee.call(
          this,
          selectedCardDetail,
          newAss.join(","),
          dueDate,
          labelSelected.join(",")
      );

  }

  assigneeSearch(e) {

      const assigneeName = e.target.value;
      const {selectedCardDetail = {}} = this.props;
      const {board} = selectedCardDetail;
      this.setState({assigneeName}, () => {

          this.props.getUserByName.call(this, assigneeName, board);

      });

  }

  assigneeSelection(user) {

      const {"_id": userId} = user;
      const {assigneeSelected} = this.state;
      if (assigneeSelected.indexOf(userId) >= 0) {

          const pos = assigneeSelected.indexOf(userId);
          assigneeSelected.splice(pos, 1);

      } else {

          assigneeSelected.push(userId);

      }
      this.setState({assigneeSelected});

  }

  _getAssigneeDropdownContent = () => {

      const {selectedCardDetail = {}} = this.props;
      const {assigneeSelected, userListByName} = this.state;
      const userLists = [];
      let clearAssigneeTxt = null;
      const tasks = selectedCardDetail.tasks ? selectedCardDetail.tasks : [];
      tasks.forEach((tsk) => {

          const checkStatus =
        assigneeSelected.indexOf(tsk.assignTo._id) >= 0
            ? " org-item-selected "
            : "";
          const initials =
        (tsk.assignTo.firstName
            ? tsk.assignTo.firstName[0].toUpperCase()
            : "") +
        (tsk.assignTo.lastName ? tsk.assignTo.lastName[0].toUpperCase() : "");
          let userImg = (
              <div className="org-assignee-list-img user_img">
                  {initials}
              </div>
          );
          if (tsk.assignTo.image) {

              userImg = (
                  <div
                      className="org-assignee-list-img cover-image"
                      style={{
                          "marginRight": "0px",
                          "backgroundImage": `url("${tsk.assignTo.image}")`,
                      }}
                  />
              );

          }
          userLists.push(
              <div
                  key={"user_" + tsk.assignTo._id}
                  onClick={this.assigneeSelection.bind(this, tsk.assignTo)}
                  className={
                      "border-bottom-lightgray org-item-block p-10 " + checkStatus
                  }
              >
                  {userImg}
                  <span className="p-5 w-perc-50">
                      <span className="fw-500">
                          {tsk.assignTo._id + " "}
                      </span>
                      <span>
                          {tsk.assignTo.firstName + " " + tsk.assignTo.lastName}
                      </span>
                  </span>
              </div>
          );

      });

      userListByName.forEach((user) => {

          const pos = tasks.findIndex((tsk) => user._id == tsk.assignTo._id);
          if (pos == -1) {

              const checkStatus =
          assigneeSelected.indexOf(user._id) >= 0 ? " org-item-selected " : "";
              const initials =
          (user.firstName ? user.firstName[0].toUpperCase() : "") +
          (user.lastName ? user.lastName[0].toUpperCase() : "");
              let userImg = (
                  <div className="org-assignee-list-img user_img">
                      {initials}
                  </div>
              );
              if (user.image) {

                  userImg = (
                      <div
                          className="org-assignee-list-img cover-image"
                          style={{
                              "marginRight": "0px",
                              "backgroundImage": `url("${user.image}")`,
                          }}
                      />
                  );

              }
              userLists.push(
                  <div
                      key={"user_" + user._id}
                      onClick={this.assigneeSelection.bind(this, user)}
                      className={
                          "border-bottom-lightgray org-item-block p-10 " + checkStatus
                      }
                  >
                      {userImg}
                      <span className="p-5 w-perc-50">
                          <span className="fw-500">
                              {user._id + " "}
                          </span>
                          <span>
                              {user.firstName + " " + user.lastName}
                          </span>
                      </span>
                  </div>
              );

          }

      });

      if (assigneeSelected.length > 0) {

          clearAssigneeTxt = (
              <div
                  className="border-bottom-lightgray org-clear-selection org-item-block p-10"
                  onClick={() => this.setState({"assigneeSelected": []})}
              >
                  <span className="edit-label">
                      Clear Assignees
                  </span>
              </div>
          );

      }

      return (
          <div>
              <div className="border-bottom-lightgray org-input-block p-10">
                  <p className="font-12 m-b-10">
                      Assign people to this card
                  </p>
                  <input
                      value={this.state.assigneeName}
                      type="text"
                      className="org-input-field p-5"
                      placeholder="Enter name..."
                      onChange={this.assigneeSearch.bind(this)}
                  />
              </div>
              {clearAssigneeTxt}
              <div className="org-list-block font-13">
                  {userLists}
              </div>
          </div>
      );

  };

  getLabelByName(searchLabel = "", labelEditMode = false) {

      const {selectedCardDetail = {}} = this.props;
      const {board} = selectedCardDetail;
      this.setState(
          {searchLabel, "showColorPanel": false, "showLabelColorPopup": null},
          () => {

              this.props.getLabelByName(searchLabel, board).then(() => {

                  if (labelEditMode) {

                      this.setState({labelEditMode});

                  }

              });

          }
      );

  }

  createNewLabel(e) {

      e.preventDefault();
      const {selectedCardDetail = {}} = this.props;
      const {board} = selectedCardDetail;
      const {labelColor, searchLabel} = this.state;
      const data = {"label": searchLabel.trim(), "color": labelColor, board};
      if (data.label) {

          this.props.createNewLabel.call(this, data);
          this.setState({"searchLabel": "", "labelColor": defaultCreateLabelColor});

      }

  }

  labelSelection(label, e) {

      e.preventDefault();
      e.stopPropagation();
      const {"_id": labelId} = label;
      const {labelSelected} = this.state;
      if (labelSelected.indexOf(labelId) >= 0) {

          const pos = labelSelected.indexOf(labelId);
          labelSelected.splice(pos, 1);

      } else {

          labelSelected.push(labelId);

      }
      this.setState({labelSelected});

  }

  handleSaveLabels(e) {

      e.preventDefault();
      e.stopPropagation();
      const {labelLists} = this.state;
      const {selectedCardDetail = {}} = this.props;
      const {board, "_id": cardId} = selectedCardDetail;
      this.setState({
          "labelEditMode": false,
          "activeDropdown": "",
      });
      if (board) {

          this.props.updateLabel.call(this, labelLists, board, cardId, selectedCardDetail);

      }

  }

  handleLabelDeletePopup(label, e) {

      e.preventDefault();
      e.stopPropagation();
      const {showLabelDeletePopup} = this.state;
      let value = null;
      if (label._id !== showLabelDeletePopup) {

          value = label._id;

      }
      this.setState({
          "showLabelDeletePopup": value,
      });

  }

  handleDeleteLabel(label, e) {

      e.preventDefault();
      e.stopPropagation();
      // eslint-disable-next-line prefer-const
      let {labelLists, labelSelected} = this.state;
      if (label._id) {

          labelLists = labelLists.filter((lbl) => lbl._id !== label._id);

      }
      const pos = labelSelected.indexOf(label._id);
      if (pos >= 0) {

          labelSelected.splice(pos, 1);

      }
      this.setState({
          "showLabelDeletePopup": null,
          labelSelected,
          labelLists,
      });

  }

  handleEditLabelColor(label, e) {

      e.preventDefault();
      e.stopPropagation();
      const {showLabelColorPopup} = this.state;
      let value = null;
      if (label._id !== showLabelColorPopup) {

          value = label._id;

      }
      this.setState({
          "showLabelColorPopup": value,
      });

  }

  labelUpdate(label, e) {
      e.preventDefault();
      e.stopPropagation();
      const {labelLists} = this.state;
      const {value} = e.target;
      label.labelName = value;
      const updatedList = labelLists.map((lbl) => {

          if (lbl._id == label._id) {

              return label;

          }
          return lbl;

      });
      this.setState({
          "labelLists": updatedList,
      });

  }

  updateLabelColor(label, color, e) {

      e.preventDefault();
      e.stopPropagation();
      const {labelLists} = this.state;
      const updatedList = labelLists.map((lbl) => {

          if (lbl._id == label._id) {

              lbl.color = color;

          }
          return lbl;

      });
      this.setState({
          "labelLists": updatedList,
          "showLabelColorPopup": null,
      });

  }

  _getLabelDropdownContent = () => {

      const {selectedCardDetail} = this.props;
      const labelAssigned =
      selectedCardDetail && selectedCardDetail.labelAssigned
          ? selectedCardDetail.labelAssigned
          : [];
      const {
          labelLists,
          searchLabel,
          colorList,
          labelColor,
          showColorPanel,
          labelSelected,
          labelEditMode,
          showLabelDeletePopup,
          showLabelColorPopup,
      } = this.state;
      const labelsList = [];
      if (!labelEditMode) {

          labelAssigned.forEach((lbl, idx) => {

              const isSelectedLabelCls =
          labelSelected.indexOf(lbl._id) >= 0 ? "org-item-selected" : "p-l-45";
              labelsList.push(
                  <div
                      key={`label_${lbl._id}_${idx}`}
                      className={`border-bottom-lightgray org-item-block p-16 ${isSelectedLabelCls}`}
                  >
                      <span
                          className="org-item-indicator m-r-10"
                          style={{"backgroundColor": lbl.color}}
                      />
                      <span>
                          {lbl.labelName}
                      </span>
                      {isSelectedLabelCls === "org-item-selected" ? (
                          <span
                              onClick={this.labelSelection.bind(this, lbl)}
                              className="org-icon org-cross-icon"
                          />
                      ) : null}
                  </div>
              );

          });

      }

      labelLists.forEach((label, idx) => {

          if (labelEditMode) {

              const isSelectedLabelCls =
          labelSelected.indexOf(label._id) >= 0
              ? "org-item-selected"
              : "p-l-45";
              labelsList.push(
                  <div
                      key={`label_${label._id}_${idx}`}
                      className={`border-bottom-lightgray org-item-block p-16 ${isSelectedLabelCls}`}
                  >
                      <span
                          className="org-edit-color-panel org-left-aligned-panel"
                          onClick={this.handleEditLabelColor.bind(this, label)}
                      >
                          <span
                              className="org-item-indicator"
                              style={{"backgroundColor": label.color}}
                          />
                          <span className="org-icon org-triangle-icon" />
                          {showLabelColorPopup === label._id && (
                              <div className="org-color-panel m-b-40 border-gray p-10">
                                  {colorList.map((color, color_idy) => {

                                      return (
                                          <span
                                              key={`color_${color_idy}`}
                                              onClick={(e) => this.updateLabelColor(label, color, e)}
                                              className="org-item-indicator m-b-6 m-l-5 m-r-5 org-disp-inline-block org-cursor-pointer"
                                              style={{"backgroundColor": color}}
                                          />
                                      );

                                  })}
                              </div>
                          )}
                      </span>
                      <span>
                          <input
                              type="text"
                              onChange={this.labelUpdate.bind(this, label)}
                              value={label.labelName}
                          />
                      </span>
                      <span
                          onClick={this.handleLabelDeletePopup.bind(this, label)}
                          className="org-icon org-delete-icon"
                      />
                      <div
                          className={`org-tooltip ${
                              showLabelDeletePopup === label._id ? "org-show-tooltip" : ""
                          }`}
                      >
                          <div className="org-tooltip-text-on-click center-align">
                              <div>
                                  Delete?
                              </div>
                              <div className="font-10 p-5">
                                  This will remove this label from all cards
                              </div>
                              <button
                                  onClick={this.handleDeleteLabel.bind(this, label)}
                                  className="blue-btn big font-12"
                              >
                                  Delete
                              </button>
                          </div>
                      </div>
                  </div>
              );

          } else {

              const pos = labelAssigned.findIndex((lbl) => lbl._id == label._id);
              if (pos == -1) {

                  const isSelectedLabelCls =
            labelSelected.indexOf(label._id) >= 0
                ? "org-item-selected"
                : "p-l-45";
                  labelsList.push(
                      <div
                          key={`label_${label._id}_${idx}`}
                          onClick={this.labelSelection.bind(this, label)}
                          className={`border-bottom-lightgray org-item-block p-16 ${isSelectedLabelCls}`}
                      >
                          <span
                              className="org-item-indicator m-r-10"
                              style={{"backgroundColor": label.color}}
                          />
                          <span>
                              {label.labelName}
                          </span>
                          {isSelectedLabelCls === "org-item-selected" ? (
                              <span
                                  onClick={this.labelSelection.bind(this, label)}
                                  className="org-icon org-cross-icon"
                              />
                          ) : null}
                      </div>
                  );

              }

          }

      });

      let listOrCreate = null;
      if (labelLists.length == 0 && searchLabel.trim().length > 0) {

          listOrCreate = (
              <div
                  onClick={this.createNewLabel.bind(this)}
                  className="border-bottom-lightgray org-item-block p-16"
              >
                  <span
                      onClick={(e) => {

                          e.preventDefault();
                          e.stopPropagation();
                          this.setState({"showColorPanel": !showColorPanel});

                      }}
                      className="org-item-block"
                  >
                      <span
                          className="org-item-indicator"
                          style={{"backgroundColor": labelColor}}
                      />
                      <span className="org-icon org-triangle-icon" />
                  </span>
                  <span>
                      Create new label "
                      {searchLabel.trim()}
                      "
                  </span>
              </div>
          );

      }

      let editOrSaveButton = (
          <div>
              <div
                  onClick={() => this.getLabelByName("", true)}
                  className="border-bottom-lightgray org-item-block p-16"
              >
                  <span className="org-edit-icon org-icon" />
                  <span className="edit-label">
                      Edit Labels
                  </span>
              </div>
              <div className="org-save-label p-10 org-flex-row-end">
                  <button
                      className="blue-btn"
                      onClick={(e) => {

                          this.createNewLabel(e);
                          this.handleDropdown("label", e);

                      }}
                  >
                      Save
                  </button>
              </div>
          </div>
      );

      if (labelEditMode) {

          editOrSaveButton = (
              <div className="org-save-label border-top-lightgray org-item-block p-10 org-flex-row-end">
                  <button
                      className="blue-btn"
                      onClick={this.handleSaveLabels.bind(this)}
                  >
                      Save
                  </button>
              </div>
          );

      }

      return (
          <div className={labelEditMode ? "org-label-editable" : ""}>
              <div className="border-bottom-lightgray org-input-block p-10">
                  <p className="font-12 m-b-10">
                      {labelEditMode
                          ? "Edit Labels for this board"
                          : "Add labels for this card"}
                  </p>
                  {!labelEditMode && (
                      <input
                          type="text"
                          value={searchLabel}
                          onChange={(e) => this.getLabelByName(e.target.value)}
                          className="org-input-field p-5"
                          placeholder="Search or add new label"
                      />
                  )}
              </div>
              <div className="org-list-block font-13">
                  {!labelEditMode && listOrCreate}
                  {labelsList}
              </div>
              {editOrSaveButton}
              {showColorPanel && (
                  <div className="org-color-panel m-b-40 border-gray p-10">
                      {colorList.map((color, idy) => {

                          return (
                              <span
                                  key={`color_${idy}`}
                                  onClick={(e) => {

                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.setState({"labelColor": color});

                                  }}
                                  className="org-item-indicator m-l-5 m-r-5 org-disp-inline-block org-cursor-pointer"
                                  style={{"backgroundColor": color}}
                              />
                          );

                      })}
                  </div>
              )}
          </div>
      );

  };

  doneTheTask() {

      const {selectedCardDetail = {}} = this.props;
      const {tasks = [], board, "_id": cardId} = selectedCardDetail;
      if (tasks.length > 0 && cardId) {

          this.setState({"laterPopUp": false});
          this.props.doneTheTask.call(this, tasks, cardId, board);

      }

  }

  extendDueDateHour() {

      const {selectedCardDetail = {}} = this.props;
      const {tasks = [], board, "_id": cardId} = selectedCardDetail;
      if (tasks.length > 0 && cardId) {

          const taskLists = tasks.map((task) => {

              const {date} = task;
              const newDate = moment(date).add(1, "hour");
              task.date = newDate;
              return task;

          });
          this.setState({"laterPopUp": false});
          this.props.activateTask.call(this, taskLists, cardId, board);

      }

  }

  extendDueDateDay() {

      const {selectedCardDetail = {}} = this.props;
      const {tasks = [], board, "_id": cardId} = selectedCardDetail;
      if (tasks.length > 0 && cardId) {

          const taskLists = tasks.map((task) => {

              const {date} = task;
              const newDate = moment(date).add(1, "day");
              task.date = newDate;
              return task;

          });
          this.setState({"laterPopUp": false});
          this.props.activateTask.call(this, taskLists, cardId, board);

      }

  }

  extendDueDateWeek() {

      const {selectedCardDetail = {}} = this.props;
      const {tasks = [], board, "_id": cardId} = selectedCardDetail;
      if (tasks.length > 0 && cardId) {

          const taskLists = tasks.map((task) => {

              const {date} = task;
              const newDate = moment(date).add(1, "week");
              task.date = newDate;
              return task;

          });
          this.setState({"laterPopUp": false});
          this.props.activateTask.call(this, taskLists, cardId, board);

      }

  }

  activateTask() {

      let {laterPopUp} = this.state;
      laterPopUp = !laterPopUp;
      this.setState({laterPopUp});

  }

  handleDueDateChange(date) {
      this.setState({
          "dueDatePicker": moment(date),
      });

  }

  renderDatePicker() {
      const {dueDatePicker} = this.state;
      return (
          <DatePicker
              inline
              selected={dueDatePicker}
              shouldCloseOnSelect={false}
              onChange={this.handleDueDateChange.bind(this)}
          />
      );

  }

  resetDueDateSelection() {
      this.setState({
          "dueDatePicker": moment(new Date()),
          "dueTimePicker": "00:00",
          "editDueTime": false,
      });

  }

  handleEditDueTime(e) {

      e.preventDefault();
      e.stopPropagation();
      this.setState({
          "editDueTime": true,
      });

  }

  handleDueTime(e) {

      const {name, value} = e.target;

      this.setState({
          [name]: value,
          "editDueTime": false,
      });

  }

  hoverDueDate(dateHover) {

      this.setState({
          dateHover,
      });

  }

  getTimeOptions(timeConfig) {

      const {minuteDiff} = timeConfig;
      const optionList = [];
      for (let hours = 0; hours < 23; hours++) {

          let mins = 0;
          while (mins < 60) {

              const hourValue = hours < 10 ? `0${hours}` : hours;
              const minuteValue = mins < 10 ? `0${mins}` : mins;
              optionList.push(
                  <option key={`hours_${hours}_mins_${mins}`}>
                      {moment(`${hourValue}:${minuteValue}`, "HH:mm").format("HH:mm A")}
                  </option>
              );
              mins += minuteDiff;

          }

      }
      return optionList;

  }

  saveDueDate(e) {

      e.preventDefault();
      const {dueTimePicker, dueDatePicker} = this.state;
      const dueDate =
      moment(dueDatePicker).format("YYYY-MM-DD") +
      `T${dueTimePicker.substring(0, 5)}`;
      const newDueDate = moment(dueDate).format("YYYY-MM-DDTHH:mm:ss");
      if (newDueDate) {

          this.setState(
              {
                  "dueDate": newDueDate,
                  "activeDropdown": "",
                  "laterPopUp": false,
              },
              () => {

                  this.saveRightPanelData();

              }
          );

      }

  }

  handleSelectRepeatOptions(repeatValue) {

      this.setState({
          "repeatDueDate": repeatValue,
          "showRepeatDueOption": false,
      });

  }

  fileSelectHandler(cardId, e) {

      const {files} = e.target;
      if (files.length > 0) {

          this.props.addCardAttachment.call(this, files[0], cardId, false, true);

      }

  }

  render() {

      let confirmDialog,
          profPic,
          additionalInfo,
          price,
          cardNotesEdit,
          noteActions,
          commentActions;
      // eslint-disable-next-line prefer-const
      let {boardMember, isUserLoggedIn, isPublic, canEditCard} = this.props;
      if (boardMember) {

          boardMember = boardMember
              .filter((member) => {

                  if (!member.inactive) {

                      return member;

                  }

              })
              .map((member) => {

                  member.id = member.email?.email;
                  member.display =
            (member.email.firstName ? member.email.firstName + " " : "") +
            (member.email.lastName ? member.email.lastName : "");
                  return member;

              });

      }

      const toolbarConfig = {
          "display": [
              "INLINE_STYLE_BUTTONS",
              "BLOCK_TYPE_BUTTONS",
              "LINK_BUTTONS",
              "HISTORY_BUTTONS",
          ],
          "INLINE_STYLE_BUTTONS": [
              {"label": "Bold", "style": "BOLD"},
              {"label": "Italic", "style": "ITALIC"},
              {"label": "Underline", "style": "UNDERLINE"},
          ],
          "BLOCK_TYPE_BUTTONS": [
              {"label": "UL", "style": "unordered-list-item"},
              {"label": "OL", "style": "ordered-list-item"},
          ],
      };
      if (this.state.showConfirmDialog) {

          confirmDialog = (
              <ConfirmDialog
                  title={this.state.confirmTitle}
                  confirm={this.state.confirm}
                  cancel={this.state.cancelConfirmDialog}
              />
          );

      }
      // eslint-disable-next-line prefer-const
      let {selectedCardDetail, activeBoard, loggedInUser} = this.props;

      const tasks =
      selectedCardDetail && selectedCardDetail.tasks
          ? selectedCardDetail.tasks
          : [];
      const selectedUserLists = [];
      tasks.forEach((user) => {

          const initials =
        (user.assignTo.firstName
            ? user.assignTo.firstName[0].toUpperCase()
            : "") +
        (user.assignTo.lastName ? user.assignTo.lastName[0].toUpperCase() : "");
          let userImg = (
              <div className="org-assignee-list-img user_img">
                  {initials}
              </div>
          );
          if (user.assignTo.image) {

              userImg = (
                  <div
                      className="org-assignee-list-img cover-image"
                      style={{
                          "marginRight": "0px",
                          "backgroundImage": `url("${user.assignTo.image}")`,
                      }}
                  />
              );

          }
          selectedUserLists.push(
              <div
                  key={"userselected_" + user._id}
                  className="org-item-block p-t-b-5"
              >
                  {userImg}
                  <span className="p-5 w-perc-50">
                      <span className="fw-500">
                          {user.assignTo.email + " "}
                      </span>
                      <span>
                          {user.assignTo.firstName + " " + user.assignTo.lastName}
                      </span>
                  </span>
              </div>
          );

      });

      if (activeBoard && selectedCardDetail) {

          if (activeBoard.columns && activeBoard.columns.length) {

              activeBoard.columns.forEach((column) => {

                  column.cards.forEach((card) => {

                      if (card._id === selectedCardDetail._id) {

                          selectedCardDetail = Object.create(card);

                      }

                  });

              });

          }

      }
      if (this.state.isNoteActionOpen) {

          noteActions = (
              <NoteActions
                  editNote={this.editNote.bind(this, this.state.noteId)}
                  deleteCardNote={this.deleteCardNote.bind(this, this.state.noteId)}
                  top={this.state.NoteActionTop}
                  left={this.state.NoteActionLeft}
              />
          );

      }

      if (this.state.isCommentActionOpen) {

          commentActions = (
              <CommentActions
                  editComment={this.editComment.bind(this, this.state.commentId)}
                  deleteComment={this.deleteComment.bind(this, this.state.commentId)}
                  top={this.state.commentActionTop}
                  left={this.state.commentActionLeft}
              />
          );

      }

      let taskDone = false;
      if (
          selectedCardDetail &&
      selectedCardDetail.tasks &&
      selectedCardDetail.tasks.length
      ) {

          const {tasks} = selectedCardDetail;
          let doneLaterBtn;
          const scheduledTasksList = [],
              historyTasksList = [];
          tasks.forEach((item, i) => {

              taskDone = !!item.isCompleted;
              const taskDate = item.date ? new Date(item.date) : "";
              const relativeTime = taskDate ? timeDifference(taskDate) : "";
              const time = taskDate ? formatTime(taskDate) : "";
              let task, taskPhoto;
              let connector;
              if (scheduledTasksList.length > 0 && !item.isCompleted) {

                  connector = <div className="connector scheduled" />;

              }
              if (historyTasksList.length > 0 && item.isCompleted) {

                  connector = <div className="connector history" />;

              }

              if (
                  !item.isCompleted &&
          loggedInUser &&
          loggedInUser.email == item.assignTo.email
              ) {

                  let laterActionCont, laterBtn;
                  if (
                      this.state.openLaterOptions &&
            this.state.openLaterOptions._id === item._id
                  ) {

                      laterActionCont = (
                          <LaterActions
                              task={item}
                              setNewDeadline={this.setNewDeadline.bind(this, item)}
                          />
                      );

                  }
                  if (item.date) {

                      laterBtn = (
                          <div
                              className="gray-btn big pull-right m-0 border-radius-0"
                              onClick={this.toggleLaterOptions.bind(this, item)}
                          >
                              <span>
                                  Later
                              </span>
                              <span className="fa fa-angle-down m-l-5" />
                              {laterActionCont}
                          </div>
                      );

                  }
                  doneLaterBtn = (
                      <div>
                          {laterBtn}
                          <div
                              className="gray-btn big pull-right m-0 border-radius-0"
                              onClick={this.completeTask.bind(this, item)}
                          >
                              Done
                          </div>
                          <div className="clear-all" />
                      </div>
                  );

              } else {

                  doneLaterBtn = null;

              }

              if (item.assignTo && item.assignTo.image) {

                  taskPhoto = (
                      <div
                          className="profile-small cover-image"
                          style={{
                              "backgroundImage": `url("${item.assignTo.image}")`,
                          }}
                      />
                  );

              } else if (item.email) {

                  taskPhoto = (
                      <span className="profile-small">
                          {(item.assignTo.firstName ? item.assignTo.firstName[0] : "") +
                (item.assignTo.lastName ? item.assignTo.lastName[0] : "")}
                      </span>
                  );

              } else {

                  taskPhoto = <span className="profile-small" />;

              }

              // eslint-disable-next-line prefer-const
              task = (
                  <div className="pos-rel m-b-15" key={i}>
                      {connector}
                      <div
                          className={
                              "steps-tab-body-content disp-table full-width p-0 " +
                (item.isCompleted ? "color-gray-light" : "")
                          }
                      >
                          <div className="vertical-center width-25px">
                              {taskPhoto}
                          </div>
                          <div className="text-cont disp-inline-block">
                              <div
                                  className={
                                      "font-14 m-b-6 " +
                    (!item.isCompleted && relativeTime.isPast
                        ? "color-red"
                        : "color-gray-light")
                                  }
                              >
                                  {relativeTime.value}
                                  {" "}
                                  {time ? "at " + time : time}
                              </div>
                              <div className="font-17 m-b-6 ">
                                  {item.title}
                              </div>
                          </div>
                          <div className="vertical-center width-30px">
                              <span
                                  className={
                                      "icon fa " +
                    (item.type === "comment"
                        ? "fa-comment"
                        : item.type === "task"
                            ? "fa-check-circle-o"
                            : item.type === "call"
                                ? "fa-phone"
                                : item.type === "email"
                                    ? "fa-envelope"
                                    : item.type === "meet"
                                        ? "fa-users"
                                        : "")
                                  }
                              />
                          </div>
                      </div>
                      {doneLaterBtn}
                  </div>
              );
              if (!item.isCompleted) {

                  scheduledTasksList.push(task);

              } else {

                  historyTasksList.push(task);

              }

          });

      }
      if (
          selectedCardDetail &&
      this.props.boardType &&
      this.props.boardType.id === "2"
      ) {

          if (selectedCardDetail.image && isUserLoggedIn && !isPublic) {

              profPic = (
                  <div className="imageCont">
                      <img src={selectedCardDetail.image} className="add-btn-big" />
                      {this.props.canEditCard &&
                      <>
                      <input
                          data-is-card-image="true"
                          ref={(input) => {

                              this.cardPic = input;

                          }}
                          className="uploadProfile"
                          type="file"
                          accept="*/*"

                          onChange={this.onAttachmentImageSelected.bind(this)}
                      />
                      <div className="color-blue m-t-10 font-14">
                          <a>
                              +Photo
                          </a>
                      </div>
                    </>}
                  </div>
              );

          } else if (isUserLoggedIn && !isPublic) {

              profPic = (
                  <div>
                      <div className="add-btn-big">
                          <span>
                              TD
                          </span>
                          {this.props.canEditCard && <input
                              data-is-card-image="true"
                              ref={(input) => {

                                  this.cardPic = input;

                              }}
                              className="uploadProfile"
                              type="file"
                              accept="*/*"
                              onChange={this.onAttachmentImageSelected.bind(this)}
                          />}
                      </div>
                      {this.props.canEditCard &&
                        <div className="color-blue m-t-10 font-14">
                          <a>
                              +Photo
                          </a>
                      </div>}
                  </div>
              );

          }

          let phoneCont, emailCont, websiteCont, addressCont;
          if (selectedCardDetail.phone) {

              phoneCont = (
                  <span
                      style={{"display": !this.state.editCardPhone ? "inline" : "none"}}
                      onClick={this.showEditCardPhone.bind(this)}
                  >
                      {selectedCardDetail && selectedCardDetail.phone
                          ? selectedCardDetail.phone
                          : "Add a phone number"}
                  </span>
              );

          } else {

              phoneCont = (
                  <span
                      style={{
                          "display":
                !selectedCardDetail.phone && !this.state.editCardPhone
                    ? "inline"
                    : "none",
                          "opacity": "0",
                      }}
                      onMouseOver={this.showEmptySpan.bind(this)}
                      onMouseLeave={this.hideEmptySpan.bind(this)}
                      onClick={this.showEditCardPhone.bind(this)}
                  >
                      {selectedCardDetail && selectedCardDetail.phone
                          ? selectedCardDetail.phone
                          : "Add a phone number"}
                  </span>
              );

          }
          if (selectedCardDetail.email) {

              emailCont = (
                  <span
                      style={{"display": !this.state.editCardEmail ? "inline" : "none"}}
                      onClick={this.showEditCardEmail.bind(this)}
                  >
                      {selectedCardDetail && selectedCardDetail.email
                          ? selectedCardDetail.email
                          : "Add a Email Address"}
                  </span>
              );

          } else {

              emailCont = (
                  <span
                      style={{
                          "display":
                !selectedCardDetail.email && !this.state.editCardEmail
                    ? "inline"
                    : "none",
                          "opacity": "0",
                      }}
                      onMouseOver={this.showEmptySpan.bind(this)}
                      onMouseLeave={this.hideEmptySpan.bind(this)}
                      onClick={this.showEditCardEmail.bind(this)}
                  >
                      {selectedCardDetail && selectedCardDetail.email
                          ? selectedCardDetail.email
                          : "Add a Email Address"}
                  </span>
              );

          }
          if (selectedCardDetail.website) {

              websiteCont = (
                  <span
                      style={{"display": !this.state.editCardWebsite ? "inline" : "none"}}
                      onClick={this.showEditCardWebsite.bind(this)}
                  >
                      {selectedCardDetail && selectedCardDetail.website
                          ? selectedCardDetail.website
                          : "Add a Website"}
                  </span>
              );

          } else {

              websiteCont = (
                  <span
                      style={{
                          "display":
                !selectedCardDetail.website && !this.state.editCardWebsite
                    ? "inline"
                    : "none",
                          "opacity": "0",
                      }}
                      onMouseOver={this.showEmptySpan.bind(this)}
                      onMouseLeave={this.hideEmptySpan.bind(this)}
                      onClick={this.showEditCardWebsite.bind(this)}
                  >
                      {selectedCardDetail && selectedCardDetail.website
                          ? selectedCardDetail.website
                          : "Add a Website"}
                  </span>
              );

          }
          if (selectedCardDetail.address && !this.state.editCardAddress) {

              addressCont = (
                  <div className="section">
                      <div className="title">
                          Address
                      </div>
                      <span onClick={this.showEditCardAddress.bind(this)}>
                          {selectedCardDetail.address}
                      </span>
                  </div>
              );

          } else if(canEditCard) {

              addressCont = (
                  <div className="m-b-25">
                      <textarea
                          ref={(input) => {

                              this.cardAddress = input;

                          }}
                          id="autocompleteAddress"
                          className="font-17 full-width"
                          defaultValue={selectedCardDetail.address}
                          placeholder="Address"
                          onChange={this.onAddressChange.bind(this)}
                      />
                      <div
                          style={{"marginTop": "-5"}}
                          className="steps-tab-body-content border-top-0"
                      >
                          <button
                              disabled={this.state.addAddressBtnDisabled}
                              className="blue-btn big pull-right"
                              onClick={this.addCardAddress.bind(this)}
                          >
                              Save
                          </button>
                          <div
                              className="gray-btn big pull-right"
                              onClick={this.cancelCardAddress.bind(this)}
                          >
                              Cancel
                          </div>
                          <div className="clear-all" />
                      </div>
                  </div>
              );

          }
          additionalInfo = (
              <div>
                  <div className="contact-detail">
                      <div className="contact-detail-body m-b-40">
                          <div className="heading">
                              Phone
                          </div>
                          <div className="text">
                              {phoneCont}
                              <input
                                  ref={(input) => {

                                      this.cardPhone = input;

                                  }}
                                  onKeyPress={this.isNumberKey.bind(this)}
                                  className={
                                      "edit-name-card-detail " +
                    (this.props.boardType && this.props.boardType.id === "2"
                        ? "text"
                        : "")
                                  }
                                  style={{
                                      "display": this.state.editCardPhone ? "inline-block" : "none",
                                  }}
                                  type="text"
                                  defaultValue={
                                      selectedCardDetail && selectedCardDetail.phone
                                          ? selectedCardDetail.phone
                                          : ""
                                  }
                                  onBlur={this.editCardPhone.bind(this)}
                                  onKeyUp={this.onEnter.bind(
                                      this,
                                      this.editCardPhone.bind(this)
                                  )}
                              />
                          </div>
                      </div>
                      <div className="contact-detail-body m-b-40">
                          <div className="heading">
                              Email
                          </div>
                          <div className="text">
                              {emailCont}
                              <input
                                  ref={(input) => {

                                      this.cardEmail = input;

                                  }}
                                  className={
                                      "edit-name-card-detail " +
                    (this.props.boardType && this.props.boardType.id === "2"
                        ? "text"
                        : "")
                                  }
                                  style={{
                                      "display": this.state.editCardEmail ? "inline-block" : "none",
                                  }}
                                  type="text"
                                  defaultValue={
                                      selectedCardDetail && selectedCardDetail.email
                                          ? selectedCardDetail.email
                                          : ""
                                  }
                                  onBlur={this.editCardEmail.bind(this)}
                                  onKeyUp={this.onEnter.bind(
                                      this,
                                      this.editCardEmail.bind(this)
                                  )}
                              />
                          </div>
                      </div>
                      <div className="contact-detail-body m-b-40">
                          <div className="heading">
                              Website
                          </div>
                          <div className="text">
                              {websiteCont}
                              <input
                                  ref={(input) => {

                                      this.cardWebsite = input;

                                  }}
                                  className={
                                      "edit-name-card-detail " +
                    (this.props.boardType && this.props.boardType.id === "2"
                        ? "text"
                        : "")
                                  }
                                  style={{
                                      "display": this.state.editCardWebsite
                                          ? "inline-block"
                                          : "none",
                                  }}
                                  type="text"
                                  defaultValue={
                                      selectedCardDetail && selectedCardDetail.website
                                          ? selectedCardDetail.website
                                          : ""
                                  }
                                  onBlur={this.editCardWebsite.bind(this)}
                                  onKeyUp={this.onEnter.bind(
                                      this,
                                      this.editCardWebsite.bind(this)
                                  )}
                              />
                          </div>
                      </div>
                  </div>
                  { addressCont}
              </div>
          );
          price = (
              <div className="text-gray-medium description m-t-5 p-b-15">
                  <span
                      style={{"display": this.state.editCardGoal ? "none" : "inline"}}
                      onClick={this.showEditCardGoal.bind(this)}
                  >
                      {selectedCardDetail && selectedCardDetail.goal
                          ? selectedCardDetail.goal
                          : this.props.boardType && this.props.boardType.id === "2"
                              ? "$ Goal"
                              : ""}
                  </span>
                  <input
                      ref={(input) => {

                          this.cardGoal = input;

                      }}
                      className={
                          "edit-name-card-detail " +
              (this.props.boardType && this.props.boardType.id === "2"
                  ? "text-gray-medium"
                  : "")
                      }
                      style={{
                          "display": this.state.editCardGoal ? "inline-block" : "none",
                      }}
                      type="text"
                      defaultValue={
                          selectedCardDetail && selectedCardDetail.goal
                              ? selectedCardDetail.goal
                              : ""
                      }
                      onBlur={this.editCardGoal.bind(this)}
                      onKeyUp={this.onEnter.bind(this, this.editCardGoal.bind(this))}
                  />
              </div>
          );

      }
      const selectedAttachmentList = this.state.addNoteAttachmentList.map(
          (item, i) => {

              let thumbnail;
              if (item.type.indexOf("image") != -1) {

                  thumbnail = (
                      <img
                          src={item.dataUrl}
                          className="profile-small attachment small"
                          onClick={this.props.openPhotoModal.bind(this)}
                      />
                  );

              } else if (item.type.indexOf("videoUrl") != -1) {

                  const parsedVideo = parseVideo(item.url);
                  let videoType = "youtube";
                  let videoUrl = `//img.youtube.com/vi/${parsedVideo.id}/0.jpg`;
                  if (parsedVideo.type === "vimeo") {

                      videoUrl = "../src/assets/images/rectangle_video.svg";
                      videoType = "vimeo";

                  }
                  thumbnail = (
                      <img
                          src={videoUrl}
                          className="profile-small attachment small"
                          onClick={this.props.openVideoModal.bind(
                              this,
                              parsedVideo.id,
                              videoType
                          )}
                      />
                  );

              } else if (item.type.indexOf("pdf") !== -1) {

                  thumbnail = (
                      <a href={item.url} download target="_blank" rel="noreferrer">
                          <img
                              src="../src/assets/images/text-document.svg"
                              alt="profile-small attachment small"
                          />
                      </a>
                  );

              } else if (item.type.indexOf("embedUrl") !== -1) {

                  thumbnail = (
                      <img
                          width="100%"
                          src="../src/assets/images/link.svg"
                          alt={item.url}
                          className="profile-small attachment small"
                          style={{
                              "backgroundColor": "white",
                              "padding": "5px",
                          }}
                      />
                  );

              }
              return (
                  <div key={i} className="disp-inline-block pos-rel">
                      {thumbnail}
                      <span
                          style={{"right": "0"}}
                          className="pos-abs fa fa-times"
                          onClick={this.removeSelectedAttachment.bind(this, item)}
                      />
                  </div>
              );

          }
      );

      const comments = get(selectedCardDetail, 'comments', []);
      let pastedAttachmentList = get(selectedCardDetail, 'attachment', []);
      const attachmentInComments = [];
      comments.forEach((comment) => {
        get(comment, 'attachment', []).forEach((att) => attachmentInComments.push(att))
      });
      pastedAttachmentList = pastedAttachmentList.concat(attachmentInComments);

      // eslint-disable-next-line prefer-const
      cardNotesEdit = (
          <div className="card-notes">
              <div>
                  <div
                      className="user-img cover-image"
                      style={{
                          "backgroundImage": `url("${
                              loggedInUser && loggedInUser.image
                                  ? loggedInUser.image
                                  : "src/assets/images/avatar.png"
                          }")`,
                      }}
                  />
              </div>
              <div className="section">
                  <div className="steps-tab-body-content p-0">
                        
                      <RichTextEditor
                          autoFocus="true"
                          value={this.state.cardNoteValue}
                          readOnly={!(isUserLoggedIn && !isPublic)}
                          onChange={this.onCardNoteChange.bind(this)}
                          toolbarConfig={toolbarConfig}
                      />
                  </div>
                  <div className="steps-tab-body-content border-top-0">
                      {isUserLoggedIn && !isPublic && (
                          <div className="disp-inline-block pos-rel">
                              <i
                                  className="fa fa-paperclip icon small-card"
                                  aria-hidden="true"
                              />
                              <input
                                  ref={(input) => {

                                      this.attachment = input;

                                  }}
                                  className="uploadProfile attachment"
                                  type="file"
                                  accept="*/*"
                                  onChange={this.onAttachmentImageSelected.bind(this)}
                              />
                          </div>
                      )}
                      {selectedAttachmentList}
                      <button
                          disabled={this.state.addNoteBtnDisabled}
                          className="blue-btn big pull-right"
                          onClick={this.editCardNote.bind(this)}
                      >
                          Save
                      </button>
                      <div className="clear-all" />
                  </div>
              </div>
          </div>
      );

      const { listId } = this.state;
      const cardUpdateLists = [];
      let cardItem = null;
      [...comments].reverse().forEach((list) => {

          const date = moment(list.date).format("DD/MM/YYYY HH:mm:ss");
          const b = moment(date, "DD/MM/YYYY HH:mm");
          const timeDiffTxt = moment(b).fromNow();
          if (list._id === listId) {

              cardItem = (
                  <div className="comment-list" key={"editor_list_" + list._id}>
                      <div
                          className="user-img cover-image"
                          style={{
                              "backgroundImage": `url("${
                                  list.commentBy && list.commentBy.image
                                      ? list.commentBy.image
                                      : "src/assets/images/avatar.png"
                              }")`,
                          }}
                      />
                      <div className="section m-b-25">
                          <div className="comment-desc">
                              <span className="font-14 user-nam">
                                  {list.commentBy.firstName + " " + list.commentBy.lastName}
                              </span>
                              <span className="font-12 text-gray m-t-5">
                                  {timeDiffTxt}
                              </span>
                          </div>
                          <div className="steps-tab-body-content p-0">
                              <RichTextEditor
                                  autoFocus="true"
                                  value={this.state.editCardNoteValue}
                                  readOnly={!(isUserLoggedIn && !isPublic)}
                                  onChange={this.onEditCardNoteChange.bind(this)}
                                  toolbarConfig={toolbarConfig}
                              />
                          </div>
                          <div className="steps-tab-body-content border-top-0">
                              <div className="disp-inline-block pos-rel">
                                  <i
                                      className="fa fa-paperclip icon small-card"
                                      aria-hidden="true"
                                  />
                                  {isUserLoggedIn && !isPublic && (
                                      <input
                                          type="file"
                                          accept="*/*"
                                          ref={(input) => {

                                              this.attachment = input;

                                          }}
                                          className="uploadProfile attachment"
                                          onChange={this.onAttachmentImageSelected.bind(this)}
                                      />
                                  )}
                              </div>
                              {selectedAttachmentList}
                              <button
                                  className="blue-btn big pull-right"
                                  onClick={this.updateCardNote.bind(
                                      this,
                                      list._id,
                                      this.state.editCardNoteValue
                                  )}
                              >
                                  Save
                              </button>
                              <div
                                  className="gray-btn big pull-right"
                                  onClick={() => this.setState({"listId": null})}
                              >
                                  Cancel
                              </div>
                              <div className="clear-all" />
                          </div>
                      </div>
                  </div>
              );

          } else {

              cardItem = (
                  <div
                      key={"card_list_" + list._id}
                      className="org-card-item border-gray p-16"
                  >
                      <div className="right-align">
                          {/* <span className="card-options fa fa-ellipsis-h" aria-hidden="true"></span> */}
                          {isUserLoggedIn && !isPublic && (
                              <span className="card-options org-tooltip org-options">
                                  <div className="org-tooltip-text">
                                      <div className="action-list">
                                          <div className="action">
                                              Pin to top
                                          </div>
                                          <div
                                              className="action"
                                              onClick={this.editNote.bind(this, list)}
                                          >
                                              Edit update
                                          </div>
                                          <div
                                              className="action"
                                              onClick={this.deleteCardNote.bind(this, list._id)}
                                          >
                                              Delete update
                                          </div>
                                      </div>
                                  </div>
                              </span>
                          )}
                      </div>
                      <div className="org-disp-flex">
                          <span className="disp-inline-block">
                              <div
                                  className="org-round-img user-img cover-image"
                                  style={{
                                      "backgroundImage": `url("${
                                          list.commentBy && list.commentBy.image
                                              ? list.commentBy.image
                                              : "src/assets/images/avatar.png"
                                      }")`,
                                  }}
                              />
                          </span>
                          <span className="disp-inline-block m-r-10 font-14">
                              <div className="text-black">
                                  {list.commentBy.firstName + " " + list.commentBy.lastName}
                              </div>
                              <div
                                  className="m-t-5 text-grey"
                                  dangerouslySetInnerHTML={{
                                      "__html": list.comment.replace(/<br \/>/g, ""),
                                  }}
                              />
                              <div className="font-12 text-gray m-t-5">
                                  {timeDiffTxt}
                              </div>
                          </span>
                      </div>
                  </div>
              );

          }
          cardUpdateLists.push(cardItem);

      });

      const labelAssigned = selectedCardDetail
          ? selectedCardDetail.labelAssigned
          : [];
      const cardLabels = [];
      labelAssigned.forEach((label, idy) => {

          cardLabels.push(
              <div
                  key={`card_label_${label._id}_${idy}`}
                  className="org-item-block"
                  style={{"backgroundColor": label.color, "borderRadius": "15px"}}
              >
                  <span className="p-5">
                      <span>
                          {label.labelName}
                      </span>
                  </span>
              </div>
          );

      });

      const {dueDate, laterPopUp, activeDropdown, dateHover} = this.state;
      let colorCode = dateHover ? "#4a6a59" : "#85b59c";
      if (dueDate) {

          let today = moment().format("YYYY-MM-DD HH:mm");
          today = moment(today, "YYYY-MM-DD HH:mm");
          const dueDateFormatted = moment(dueDate, "YYYY-MM-DD HH:mm");
          const dateDiff = dueDateFormatted.diff(today, "days");
          if (dateDiff >= 1 && dateDiff < 7) {

              colorCode = dateHover ? "#d19d47" : "#f4b959";

          } else if (dateDiff < 1) {

              colorCode = dateHover ? "#963e49" : "#e16b7a";

          }
          if (taskDone) {

              colorCode = dateHover ? "#7f7f7f" : "#9b9b9b";

          }

      }

      return (
          <div
              className="backdrop org-card-details"
              style={{"display": this.props.selectedCardDetail ? "block" : "none"}}
              onClick={this.closeCardDetail.bind(this)}
          >
              {confirmDialog}
              {noteActions}
              {commentActions}
              {this._renderCreateBoardFromCard()}
              <div
                  className="popup big org-card-details-content"
                  onClick={this.preventEvent.bind(this)}
              >
                  <div className="org-left-block org-border-right">
                      <div className="body center-align">
                          <div className="card-detail-1">
                              <span
                                  className="close org-cross-icon org-icon"
                                  aria-hidden="true"
                                  onClick={this.closeCardDetail.bind(this)}
                              />
                              {profPic}
                              <div
                                  className={
                                      "m-t-10 description " +
                    (this.props.boardType && this.props.boardType.id === "2"
                        ? "text-gray-big"
                        : "text-normal-card-title-detail")
                                  }
                              >
                               <span
                                 style={{
                                    "display": this.state.editCardName ? "none" : "inline",
                                }}
                                onClick={this.showEditCardName.bind(this)}
                                dangerouslySetInnerHTML={{__html : 
                                    selectedCardDetail && selectedCardDetail.title ? mentionText(selectedCardDetail.title)
                                    : this.props.boardType && this.props.boardType.id === "2"
                                              ? "Name"
                                              : "Enter Card Title"}}/>
                                  
                                  <MentionInput
                                      input
                                      ref={(input) => {

                                          this.cardName = input;

                                      }}
                                      className={
                                          "edit-name-card-detail " +
                      (this.props.boardType && this.props.boardType.id === "2"
                          ? "text-gray-big"
                          : "")
                                      }
                                      style={{
                                          "display": this.state.editCardName
                                              ? "inline-block"
                                              : "none",
                                      }}
                                      defaultValue={
                                          selectedCardDetail && selectedCardDetail.title
                                              ? selectedCardDetail.title
                                              : ""
                                      }
                                      onBlur={this.editCardName.bind(this)}
                                      onKeyUp={this.onEnter.bind(
                                          this,
                                          this.editCardName.bind(this)
                                      )}
                                      isMember
                                      isShowPhoto
                                      options={
                                          this.props.boardMember && this.props.boardMember
                                              ? this.props.boardMember
                                              : []
                                      }
                                      toDisplayProp={["email", "id"]}
                                  />
                              </div>
                              <div
                                  className={
                                      "m-t-5 description " +
                    (this.props.boardType && this.props.boardType.id === "2"
                        ? "text-gray-medium"
                        : "text-normal-card-desc-detail")
                                  }
                              >
                                  <span
                                      style={{
                                          "display": this.state.editDescription ? "none" : "inline",
                                      }}
                                      onClick={this.showEditDescription.bind(this)}
                                      dangerouslySetInnerHTML={{
                                          "__html":
                        selectedCardDetail && selectedCardDetail.description
                            ?
                            mentionText(selectedCardDetail.description)
                            : this.props.boardType &&
                            this.props.boardType.id === "2"
                                ? "Title"
                                : "some description here",
                                      }}
                                  />
                                  <MentionInput
                                      style={{
                                          "display": this.state.editDescription
                                              ? "inline-block"
                                              : "none",
                                      }}
                                      ref={(input) => {

                                          this.description = input;

                                      }}
                                      onBlur={this.editDescription.bind(this)}
                                      defaultValue={
                                          selectedCardDetail && selectedCardDetail.description
                                              ? selectedCardDetail.description
                                              : ""
                                      }
                                      placeholder="Enter description..."
                                      isMember
                                      isShowPhoto
                                      className={
                                          "edit-desc-card-detail " +
                                          (this.props.boardType && this.props.boardType.id === "2"
                                              ? "text-gray-medium"
                                              : "")
                                      }
                                      options={
                                          this.props.boardMember && this.props.boardMember
                                              ? this.props.boardMember
                                              : []
                                      }
                                      toDisplayProp={["email", "id"]}
                                  />
                              </div>

                              {price}
                              <div
                                  className="horizontal-line"
                                  style={{
                                      "display":
                      this.props.boardType && this.props.boardType.id === "2"
                          ? "block"
                          : "none",
                                  }}
                              />
                          </div>
                      </div>
                      <div className="card-detail-2">
                          {additionalInfo}
                          {canEditCard && cardNotesEdit}

                          {/** **** Begin new UI Left Block */}

                          <div className="updates title">
                              <span>
                                  Updates
                              </span>
                          </div>
                          {pastedAttachmentList.length > 0 && (
                              <div className="org-card-item border-gray p-16">
                                  <div className="p-5 text-gray-medium font-14">
                                      <span>
                                          Description Attachments
                                      </span>
                                      <span className="right-align">
                                          {isUserLoggedIn && !isPublic && (
                                              <span className="card-options org-tooltip org-options">
                                                  <div className="org-tooltip-text">
                                                      <div className="action-list">
                                                          <div className="action">
                                                              Pin to top
                                                          </div>
                                                          <div
                                                              className="action"
                                                              onClick={() => this.fileInput.click()}
                                                          >
                                                              <input
                                                                  hidden
                                                                  onChange={this.fileSelectHandler.bind(
                                                                      this,
                                                                      selectedCardDetail._id
                                                                  )}
                                                                  ref={(fileInput) =>
                                                                      (this.fileInput = fileInput)
                                                                  }
                                                                  type="file"
                                                              />
                                                              Edit update
                                                          </div>
                                                          <div
                                                              className="action"
                                                              onClick={() =>
                                                                  this.props.deleteAttachments.call(
                                                                      this,
                                                                      selectedCardDetail._id
                                                                  )
                                                              }
                                                          >
                                                              Delete update
                                                          </div>
                                                      </div>
                                                  </div>
                                              </span>
                                          )}
                                      </span>
                                  </div>
                                  <div className="card-details-list-attachements">
                                  {pastedAttachmentList.map((item, i) => {
                                      let thumbnail;
                                      if (item.type.indexOf("image") != -1) {
                                          thumbnail = (
                                            <a href={item.url} target="_blank" rel="noreferrer">
                                              <img
                                                  src={item.url}
                                                  className="profile-small attachment small"
                                                  onClick={this.props.openPhotoModal.bind(this)}
                                              />

                                            </a>
                                          );

                                      } else if (item.type.indexOf("videoUrl") != -1) {

                                          const parsedVideo = parseVideo(item.url);
                                          let videoType = "youtube";
                                          let videoUrl = `//img.youtube.com/vi/${parsedVideo.id}/0.jpg`;
                                          if (parsedVideo.type === "vimeo") {

                                              videoUrl = "../src/assets/images/rectangle_video.svg";
                                              videoType = "vimeo";

                                          }
                                          thumbnail = (
                                              <img
                                                  src={videoUrl}
                                                  className="profile-small attachment small"
                                                  onClick={this.props.openVideoModal.bind(
                                                      this,
                                                      parsedVideo.id,
                                                      videoType
                                                  )}
                                              />
                                          );

                                      } else if (item.type.indexOf("pdf") !== -1) {
                                          thumbnail = (
                                              <a href={item.url} download target="_blank" rel="noreferrer">
                                                  <img
                                                      src="../../../../assets/images/text-document.svg"
                                                      className="profile-small attachment small"
                                                  />
                                              </a>
                                          );

                                      } else if (item.type.indexOf("embedUrl") !== -1) {

                                          thumbnail = (
                                              <a href={item.url} target="_blank" rel="noreferrer">
                                                  <img
                                                      src="../../../../assets/images/link.svg"
                                                      alt={item.url}
                                                      className="profile-small attachment small"
                                                      style={{
                                                          "backgroundColor": "white",
                                                          "padding": "5px",
                                                      }}
                                                  />
                                              </a>
                                          );

                                      } else {
                                        thumbnail = (
                                            <a href={item.url} download target="_blank" rel="noreferrer">
                                                <img
                                                    src="../../../../assets/images/text-document.svg"
                                                    className="profile-small attachment small"
                                                />
                                            </a>
                                        );
                                      }
                                      return (
                                          <div key={i} className="pos-rel">
                                              {thumbnail}
                                              <span className="mr-2">
                                                  {item.fileName}
                                              </span>
                                          </div>
                                      );

                                  })}
                                  </div>
                              </div>
                          )}
                          <div className="update-lists">
                              {cardUpdateLists}
                          </div>
                      </div>
                  </div>
                  <div className="org-right-block p-t-45 m-t-45 p-24">
                      <div>
                          <div ref={(node) => (this.node = node)}>
                              <div
                                  className={`org-assignee-block cursor-pointer org-item-block p-10 org-on-hover-active ${
                                      this.state.activeDropdown === "assignee" ? "org-active" : ""
                                  }`}
                                  onClick={this.handleDropdown.bind(this, "assignee")}
                              >
                                  <span className="org-assignee-user-icon org-icon" />
                                  <div className="org-item-text">
                                      Assignees
                                  </div>
                                  {canEditCard && (
                                      <span
                                          className="org-dropdown-icon"
                                      />
                                  )}
                              </div>

                              {/** **** Multi select dropdown select list */}
                              {this.state.activeDropdown === "assignee" ? (
                                  <div className="org-pos-absolute-container border-gray">
                                      <MultiSelectDropdown>
                                          {this._getAssigneeDropdownContent()}
                                      </MultiSelectDropdown>
                                      <div
                                          className="org-due-dropdown-btm"
                                          style={{"padding": "10px", "float": "right"}}
                                      >
                                          <button
                                              onClick={() => {

                                                  this.setState(
                                                      {
                                                          "activeDropdown": "",
                                                      },
                                                      () => {

                                                          this.saveRightPanelData();

                                                      }
                                                  );

                                              }}
                                              className="blue-btn"
                                              style={{"padding": "0px 20px"}}
                                          >
                                              Save
                                          </button>
                                      </div>
                                  </div>
                              ) : null}
                              <div className="org-item-list no-one-assigned">
                                  {tasks.length == 0 ? (
                                      <a
                                          className="font-12"
                                          onClick={() => {
                                              const {assigneeSelected} = this.state;
                                              if (!canEditCard) return;
                                              if (
                                                  assigneeSelected.findIndex(
                                                      (assignee) => assignee === loggedInUser._id
                                                  ) === -1
                                              ) {

                                                  assigneeSelected.push(loggedInUser._id);
                                                  this.setState({assigneeSelected}, () =>
                                                      this.saveRightPanelData()
                                                  );

                                              }

                                          }}
                                          style={{"cursor": "pointer"}}
                                      >
                                          No one {canEditCard && "- assign yourself"}
                                      </a>
                                  ) : (
                                      <div className="font-13 text-gray-big">
                                          {selectedUserLists}
                                      </div>
                                  )}
                              </div>
                          </div>

                          <div className="org-due-block org-border-top p-t-b-20">
                              <div
                                  className={`org-item-block cursor-pointer p-10 org-on-hover-active ${
                                      this.state.activeDropdown === "dueDate" ? "org-active" : ""
                                  }`}
                                  onClick={(e) => {

                                      if (tasks.length == 0) {

                                          return;

                                      }
                                      this.handleDropdown("dueDate", e);

                                  }}
                              >
                                  <span className="org-calendar-icon org-icon" />
                                  <span className="org-item-text">
                                      {tasks.length == 0 && canEditCard ? "Add Assignee First" : "Due Date"}
                                  </span>
                                  {canEditCard && (
                                      <span
                                          className="org-dropdown-icon"
                                          // style={{
                                          //     "cursor": tasks.length == 0 ? "default" : "cursor",
                                          // }}
                                      />
                                  )}
                              </div>

                              {/** **** Multi select dropdown select list */}
                              {activeDropdown === "dueDate" ? (
                                  <div
                                      ref={(dueNode) => (this.dueNode = dueNode)}
                                      className="org-pos-absolute-container border-gray"
                                  >
                                      <MultiSelectDropdown>
                                          <div className="org-input-block p-10">
                                              <p className="font-12 m-b-10">
                                                  Select a Due Date
                                              </p>
                                              <span className="m-b-15 font-13">
                                                  <span className="border-gray p-5 m-r-10">
                                                      {moment(this.state.dueDatePicker).format(
                                                          "MMM D, YYYY"
                                                      )}
                                                  </span>
                                                  <span className="border-gray p-t-b-5">
                                                      {!this.state.editDueTime && (
                                                          <p
                                                              id="org_time_disp"
                                                              className="org-time-disp"
                                                              onClick={this.handleEditDueTime.bind(this)}
                                                          >
                                                              {moment(
                                                                  moment(this.state.dueTimePicker, "h:mm A")
                                                              ).format("h:mm A")}
                                                          </p>
                                                      )}
                                                      {/** Uncomment if using select option */}
                                                      {this.state.editDueTime && (
                                                          <select
                                                              className="border-0 org-plain-select font-13"
                                                              name="dueTimePicker"
                                                              defaultValue={this.state.dueTimePicker}
                                                              onChange={this.handleDueTime.bind(this)}
                                                          >
                                                              {this.getTimeOptions({"minuteDiff": 15})}
                                                          </select>
                                                      )}
                                                  </span>
                                                  <span
                                                      onClick={this.resetDueDateSelection.bind(this)}
                                                      className="org-cross-icon org-icon"
                                                  />
                                              </span>
                                              {
                                                this.renderDatePicker()
                                                }
                                              <span className="org-due-dropdown-btm">
                                                  <span className="font-12">
                                                      Repeat
                                                  </span>
                                                  {this.state.showRepeatDueOption ? (
                                                      <span className="action-list">
                                                          {[
                                                              "None",
                                                              "Every Day",
                                                              "Every Week",
                                                              "Every Month",
                                                              "Every Year",
                                                          ].map((repeatValue, idx) => (
                                                              <div
                                                                  id={`org_due_repeat_option_${idx}`}
                                                                  key={`dueRepeatOption_${idx}`}
                                                                  className="action"
                                                                  onClick={this.handleSelectRepeatOptions.bind(
                                                                      this,
                                                                      repeatValue
                                                                  )}
                                                              >
                                                                  {repeatValue}
                                                              </div>
                                                          ))}
                                                      </span>
                                                  ) : (
                                                      <span
                                                          id="org_due_repeat_value"
                                                          onClick={() =>
                                                              this.setState({"showRepeatDueOption": true})
                                                          }
                                                          className="p-16 font-13 cursor-pointer"
                                                      >
                                                          {this.state.repeatDueDate}
                                                          <span
                                                              id="org_due_repeat_value_dropdown"
                                                              className="fa middle fa-angle-down"
                                                          />
                                                      </span>
                                                  )}
                                                  <span className="due-save-btn m-t-5">
                                                      <button
                                                          onClick={this.saveDueDate.bind(this)}
                                                          className="blue-btn"
                                                      >
                                                          Save
                                                      </button>
                                                  </span>
                                              </span>
                                          </div>
                                      </MultiSelectDropdown>
                                  </div>
                              ) : null}
                              <div className="org-item-list">
                                  {dueDate ? (
                                      <div className="font-13 text-gray-big">
                                          <div className="org-item-block p-t-b-5">
                                              <span className="p-t-b-5">
                                                  <span
                                                      onMouseEnter={this.hoverDueDate.bind(this, true)}
                                                      onMouseLeave={this.hoverDueDate.bind(this, false)}
                                                      className="p-5 org-round-edges-15 text-white"
                                                      style={{"backgroundColor": colorCode}}
                                                  >
                                                      {taskDone ? (
                                                          <img
                                                              className="middle tick-shape done-task"
                                                              src="../../../../assets/images/shape.svg"
                                                          />
                                                      ) : null}
                                                      {moment(dueDate).format("MMM DD, YYYY  HH:mm A")}
                                                  </span>
                                                  {isUserLoggedIn && !isPublic && (
                                                      <span
                                                          onClick={this.removeDueDate.bind(this)}
                                                          className="org-cross-icon org-icon"
                                                      />
                                                  )}
                                              </span>
                                          </div>
                                          {!taskDone && isUserLoggedIn && !isPublic && (
                                              <div>
                                                  Weekly
                                              </div>
                                          )}
                                          <div>
                                              {!taskDone && isUserLoggedIn && !isPublic && (
                                                  <div className="disp-inline-block">
                                                      <button
                                                          onClick={this.doneTheTask.bind(this)}
                                                          className="due-date blue-btn big"
                                                      >
                                                          Done
                                                      </button>
                                                  </div>
                                              )}
                                              {canEditCard && (
                                                  <div className="disp-inline-block">
                                                      <button
                                                          onClick={this.activateTask.bind(this)}
                                                          className="due-date later blue-btn big"
                                                      >
                                                          Later
                                                          <span className="fa middle fa-angle-down" />
                                                      </button>
                                                      <div
                                                          className="later-action-list p-b-10 p-r-10"
                                                          style={{
                                                              "display": laterPopUp ? "block" : "none",
                                                          }}
                                                      >
                                                          <div
                                                              onClick={this.extendDueDateHour.bind(this)}
                                                              className="action-item"
                                                          >
                                                              Remind me in an hour
                                                          </div>
                                                          <div
                                                              onClick={this.extendDueDateDay.bind(this)}
                                                              className="action-item"
                                                          >
                                                              Remind me tomorrow
                                                          </div>
                                                          <div
                                                              onClick={this.extendDueDateWeek.bind(this)}
                                                              className="action-item"
                                                          >
                                                              Remind me in a week
                                                          </div>
                                                          <div
                                                              onClick={this.handleDropdown.bind(
                                                                  this,
                                                                  "dueDate"
                                                              )}
                                                              className="action-item"
                                                          >
                                                              Set new due date
                                                          </div>
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  ) : (
                                      <div className="font-12">
                                          No Due Date
                                      </div>
                                  )}
                              </div>
                          </div>

                          <div
                              ref={(labelNode) => (this.labelNode = labelNode)}
                              className="org-label-block org-border-top p-t-b-20"
                          >
                              <div
                                  className={`org-item-block p-10 cursor-pointer org-on-hover-active ${
                                      this.state.activeDropdown === "label" ? "org-active" : ""
                                  }`}
                                  onClick={this.handleDropdown.bind(this, "label")}
                              >
                                  <span className="org-label-icon org-icon" />
                                  <span className="org-item-text">
                                      Add Label
                                  </span>
                                  {canEditCard && (
                                      <span
                                          className="org-dropdown-icon"
                                      />
                                  )}
                              </div>

                              {/** **** Multi select dropdown select list */}
                              {this.state.activeDropdown === "label" ? (
                                  <div className="org-pos-absolute-container org-pos-absolute-container-label border-gray">
                                      <MultiSelectDropdown>
                                          {this._getLabelDropdownContent()}
                                      </MultiSelectDropdown>
                                  </div>
                              ) : null}
                              <div className="org-item-list label-list">
                                  {/** ***** show the below code if there are labels ******/}
                                  {cardLabels.length > 0 ? (
                                      <div className="font-12 text-gray-big">
                                          {cardLabels}
                                      </div>
                                  ) : (
                                      <div className="font-12">
                                          No lables yet
                                      </div>
                                  )}
                              </div>
                          </div>

                          {/* <div className="border-t p-t-b-20">*/}
                          {/*    /!*<div className="org-item-block p-10 cursor-pointer org-on-hover-active">*!/*/}
                          {/*    /!*    <i*!/*/}
                          {/*    /!*        className="fa fa-paperclip mx-5 icon small-card"*!/*/}
                          {/*    /!*        aria-hidden="true"*!/*/}
                          {/*    /!*    />*!/*/}
                          {/*    /!*    <span className="org-item-text">Links</span>*!/*/}
                          {/*    /!*</div>*!/*/}
                          {/*    <div className="">*/}
                          {/*        {pastedAttachmentList.map((item, i) =>*/}
                          {/*            <Microlink key={i.toString()} style={{marginTop: "10px"}}*/}
                          {/*                       url={item.url} contrast lazy={{ threshold: 0.5 }} media={['image', 'logo']} />*/}
                          {/*        )}*/}
                          {/*    </div>*/}
                          {/* </div>*/}
                      </div>
                  </div>
              </div>
          </div>
      );

  }

}
