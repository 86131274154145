import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FilterMemberItem from "./filterMembersItem";

const FilterMember = ({ filterMemberArr = {}, removeFilter }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const setFilters = () => {
      const items = [];
      for (const member in filterMemberArr) {
        const memberInfo = JSON.parse(member);
        items.push(
          <FilterMemberItem
            key={memberInfo?._id}
            memberInfo={memberInfo}
            filters={filterMemberArr[member]}
            index={member}
            removeFilter={removeFilter}
          />
        );
      }
      setItems(items)
    };
    setFilters();
  }, [filterMemberArr]);

  return (
    <>
      {items}
    </>
  );
};

FilterMember.propTypes = {
  filterMemberArr: PropTypes.object,
  removeFilter: PropTypes.func,
};
export default FilterMember;
