import { useState } from "react";
import PropTypes from "prop-types";
import { parseVideo } from "../../../../../helper";

const VideoUrl = ({
  card,
  attachment,
  isLoggedIn = false,
  isPublic = false,
  canEditCard = false,
  deleteContent,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [videoEmbedUrl, setVideoEmbedUrl] = useState(false);
  const handleDueDateClick = (id) => {
    const ele = document.getElementById(id);
    if (ele.className.indexOf("due-show-tooltip") === -1) {
      ele.className += " due-show-tooltip";
    } else {
      ele.className = ele.className.replace(" due-show-tooltip", "");
    }
  };
  const parsedVideo = parseVideo(attachment.url);
  let videoUrl = `//img.youtube.com/vi/${parsedVideo.id}/0.jpg`;
  if (parsedVideo.type === "vimeo") {
    videoUrl = "../../../../../assets/images/rectangle_video.svg";
  }

  const openVideoModal = () => {

    if (openModal) {
      document.getElementById("sticky-header").style.zIndex = 10;
      document.getElementById("boards-filters").style.zIndex = 5;
      setOpenModal(false);
    } else {
      document.getElementById("sticky-header").style.zIndex = 1;
      document.getElementById("boards-filters").style.zIndex = 1;
      let videoUrl = `//youtube.com/embed/${parsedVideo.id}`;
      if (parsedVideo.type === "vimeo") {
        videoUrl = `//player.vimeo.com/video/${parsedVideo.id}`;
      }
      setVideoEmbedUrl(videoUrl)
      setOpenModal(true);
    }
  };
  return (
    <div key={`attachment${attachment._id}`}>
      <div className="org-upload-doc-container left-align org-inline-flex-col-center p-r-10">
        <img
          height="100%"
          width="100%"
          src={videoUrl}
          onClick={()=>openVideoModal()}
        />
      </div>
      <div className="desc-cont normal org-flex-row-end org-action-container">
        {isLoggedIn && !isPublic && canEditCard && (
          <span
            onClick={() =>
              handleDueDateClick(`delete_media_option_${attachment._id}`)
            }
            id={`delete_media_option_${attachment._id}`}
            className="org-card-options org-tooltip org-options"
          >
            <div className="org-tooltip-due-date">
              <div className="action-list">
                <div
                  className="action"
                  onClick={() => deleteContent(card._id, attachment._id)}
                >
                  Delete
                </div>
              </div>
            </div>
          </span>
        )}
      </div>
      {openModal && (
        <div>
          <div
            className="backdrop center-align"
            style={{
              display: "block",
              zIndex: "35",
              top: "0",
              position: "fixed",
              left: "0",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            onClick={() => openVideoModal()}
          >
            <div className="pos-rel file-attached m-t-10">
              <span
                style={{ right: 10, top: 10, color: "white" }}
                className="pos-abs close fa fa-times"
                aria-hidden="true"
                onClick={() => openVideoModal()}
              />
              <iframe height="100%" width="70%" src={videoEmbedUrl} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

VideoUrl.propTypes = {
  attachment: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isPublic: PropTypes.bool,
  canEditCard: PropTypes.bool,
  card: PropTypes.object,
  deleteContent: PropTypes.func,
};

export default VideoUrl;
