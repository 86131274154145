import { useCallback, useEffect, useState } from "react";
import MultiSelectDropdown from "../../../../../widget/Dropdown/multiSelectDropdown";
import {
  createNewLabel,
  getLabelByName,
  updateAssignee,
  updateLabel,
} from "../../../../../store/actions/lists";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { usePrevious } from "../../../../../hooks";

function LabelList({ selectedCardDetail }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.user,
  }));

  const [labelLists, setLabelLists] = useState([]);
  const [searchLabel, setSearchLabel] = useState("");

  const colorList = [
    "#f44336",
    "#FF9800",
    "#FFEB3B",
    "#795548",
    "#2196f3",
    "#00bcd4",
    "#aed581",
    "#000000",
    "#2e7d32",
    "#546e7a",
    "#81d4fa",
  ];
  const [labelColor, setLabelColor] = useState("#7ed321");
  const [showColorPanel, setShowColorPanel] = useState(false);
  const [labelSelected, setLabelSelected] = useState(selectedCardDetail.labelAssigned.map(v => v._id) || []);
  const [labelEditMode, setLabelEditMode] = useState(false);
  const [showLabelDeletePopup, setShowLabelDeletePopup] = useState(null);
  const [showLabelColorPopup, setShowLabelColorPopup] = useState(null);
  const prevCreatedLabel = usePrevious(user.labelCreate);

  const updateLableAssignee = useCallback(
    (labels) => {
      dispatch(
        updateAssignee(selectedCardDetail, "", null, labels.join(","), false)
      );
    },
    [selectedCardDetail]
  );

  const updateLabling = useCallback(
    (labelLists, board, _id, selectedCardDetail) => {
      dispatch(updateLabel(labelLists, board, _id, selectedCardDetail, false));
    },
    [selectedCardDetail]
  );

  useEffect(() => {
    if (
      user.labelCreate &&
      prevCreatedLabel !== user.labelCreate &&
      prevCreatedLabel?._id !== user.labelCreate._id &&
      user.labelCreate._id
    ) {
      setLabelSelected((prev) => {
        if (prev.includes(user.labelCreate._id)) return prev;
        const updatedLabels = [...new Set([...prev, user.labelCreate._id])];
        updateLableAssignee(updatedLabels);
        return updatedLabels;
      });
      dispatch({ type: "SET_STATE", payload: { labelCreate: null } });
    }
  }, [user.labelCreate, setLabelSelected]);

  useEffect(() => {
    if (user.labelLists) {
      setLabelLists(user.labelLists);
    }
  }, [user.labelLists]);

  const getLabels = (value) => {
    const { board } = selectedCardDetail;
    return dispatch(getLabelByName(value || "", board));
  };

  useEffect(() => {
    getLabels("");
  }, []);

  const createLabel = () => {
    const { board } = selectedCardDetail;
    if (!searchLabel) {
      updateLableAssignee(labelSelected);
      return;
    }
    setLabelLists([
      ...labelLists,
      {
        board: board,
        color: labelColor,
        labelName: searchLabel,
      },
    ]);
    return dispatch(
      createNewLabel({
        board: board,
        color: labelColor,
        label: searchLabel,
      })
    );
  };

  const updateLables = () => {
    const { board } = selectedCardDetail;
    updateLabling(
      labelLists,
      board,
      selectedCardDetail._id,
      selectedCardDetail
    );
  };

  const getLabelBySearch = (value) => {
    const { board } = selectedCardDetail;
    setSearchLabel(value);
    setShowColorPanel(false);
    setShowLabelColorPopup(null);
    getLabels(value, board);
  };

  const handleLabelDeletePopup = (label, e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowLabelDeletePopup(label._id);
  };

  const handleDeleteLabel = (label, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (label._id) {
      setLabelLists(labelLists.filter((lbl) => lbl._id !== label._id));
    }
    const pos = labelSelected.indexOf(label._id);
    if (pos >= 0) {
      labelSelected.splice(pos, 1);
    }
    setShowLabelDeletePopup(null);
    setLabelSelected(labelSelected);
  };

  const handleEditLabelColor = (label, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (label._id !== showLabelColorPopup) {
      setShowLabelColorPopup(label._id);
    }
  };

  const updateLabelColor = (label, color, e) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedList = labelLists.map((lbl) => {
      if (lbl._id == label._id) {
        lbl.color = color;
      }
      return lbl;
    });
    setLabelLists(updatedList);
    setShowLabelColorPopup(null);
  };

  const labelSelection = useCallback(
    (label, e) => {
      e.preventDefault();
      setLabelSelected((prevState) => {
        const { _id: labelId } = label;
        const updatedLabels = [...prevState];
        if (updatedLabels.indexOf(labelId) >= 0) {
          const pos = updatedLabels.indexOf(labelId);
          updatedLabels.splice(pos, 1);
        } else {
          updatedLabels.push(labelId);
        }
        return updatedLabels;
      });
    },
    [setLabelSelected]
  );

  const labelUpdate = (label, e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    label.labelName = value;
    const updatedList = labelLists.map((lbl) => {
      if (lbl._id == label._id) {
        return label;
      }
      return lbl;
    });
    setLabelLists(updatedList);
  };

  const getLabelDropdownContent = () => {
    const labelAssigned =
      selectedCardDetail && selectedCardDetail.labelAssigned
        ? selectedCardDetail.labelAssigned
        : [];

    const labelsList = [];
    if (!labelEditMode) {
      labelAssigned.forEach((lbl, idx) => {
        const isSelectedLabelCls =
          labelSelected.indexOf(lbl._id) >= 0 ? "org-item-selected" : "p-l-45";
        labelsList.push(
          <div
            key={`label_${lbl._id}_${idx}`}
            className={`border-bottom-lightgray org-item-block p-16 ${isSelectedLabelCls}`}
          >
            <span
              className="org-item-indicator m-r-10"
              style={{ backgroundColor: lbl.color }}
            />
            <span>{lbl.labelName}</span>
            {isSelectedLabelCls === "org-item-selected" ? (
              <span
                onClick={(e) => labelSelection(lbl, e)}
                className="org-icon org-cross-icon"
              />
            ) : null}
          </div>
        );
      });
    }

    labelLists.forEach((label, idx) => {
      if (labelEditMode) {
        const isSelectedLabelCls =
          labelSelected.indexOf(label._id) >= 0
            ? "org-item-selected"
            : "p-l-45";
        labelsList.push(
          <div
            key={`label_${label._id}_${idx}`}
            className={`border-bottom-lightgray org-item-block p-16 ${isSelectedLabelCls}`}
          >
            <span
              className="org-edit-color-panel org-left-aligned-panel"
              onClick={(e) => handleEditLabelColor(label, e)}
            >
              <span
                className="org-item-indicator"
                style={{ backgroundColor: label.color }}
              />
              <span className="org-icon org-triangle-icon" />
              {showLabelColorPopup === label._id && (
                <div className="org-color-panel m-b-40 border-gray p-10">
                  {colorList.map((color, color_idy) => {
                    return (
                      <span
                        key={`color_${color_idy}`}
                        onClick={(e) => updateLabelColor(label, color, e)}
                        className="org-item-indicator m-b-6 m-l-5 m-r-5 org-disp-inline-block org-cursor-pointer"
                        style={{ backgroundColor: color }}
                      />
                    );
                  })}
                </div>
              )}
            </span>
            <span>
              <input
                type="text"
                onChange={(e) => labelUpdate(label, e)}
                value={label.labelName}
              />
            </span>
            <span
              onClick={(e) => handleLabelDeletePopup(label, e)}
              className="org-icon org-delete-icon"
            />
            <div
              className={`org-tooltip ${
                showLabelDeletePopup === label._id ? "org-show-tooltip" : ""
              }`}
            >
              <div className="org-tooltip-text-on-click center-align">
                <div>Delete?</div>
                <div className="font-10 p-5">
                  This will remove this label from all cards
                </div>
                <button
                  onClick={(e) => handleDeleteLabel(label, e)}
                  className="blue-btn big font-12"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        );
      } else {
        const pos = labelAssigned.findIndex((lbl) => lbl._id == label._id);
        if (pos == -1) {
          const isSelectedLabelCls =
            labelSelected.indexOf(label._id) >= 0
              ? "org-item-selected"
              : "p-l-45";
          labelsList.push(
            <div
              key={`label_${label._id}_${idx}`}
              onClick={(e) => labelSelection(label, e)}
              className={`border-bottom-lightgray org-item-block p-16 ${isSelectedLabelCls}`}
            >
              <span
                className="org-item-indicator m-r-10"
                style={{ backgroundColor: label.color }}
              />
              <span>{label.labelName}</span>
              {isSelectedLabelCls === "org-item-selected" ? (
                <span
                  onClick={(e) => labelSelection(label, e)}
                  className="org-icon org-cross-icon"
                />
              ) : null}
            </div>
          );
        }
      }
    });

    let listOrCreate = null;
    if (labelLists.length == 0 && searchLabel.trim().length > 0) {
      listOrCreate = (
        <div
          onClick={(e) => createLabel(e)}
          className="border-bottom-lightgray org-item-block p-16"
        >
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowColorPanel(!showColorPanel);
            }}
            className="org-item-block"
          >
            <span
              className="org-item-indicator"
              style={{ backgroundColor: labelColor }}
            />
            <span className="org-icon org-triangle-icon" />
          </span>
          <span>Create new label "{searchLabel.trim()}"</span>
        </div>
      );
    }

    let editOrSaveButton = (
      <div>
        <div
          onClick={() => {
            getLabels("", true);
            setLabelEditMode(!labelEditMode);
          }}
          className="border-bottom-lightgray org-item-block p-16"
        >
          <span className="org-edit-icon org-icon" />
          <span className="edit-label">Edit Labels</span>
        </div>
        <div className="org-save-label p-10 org-flex-row-end">
          <button
            className="blue-btn"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              createLabel();
            }}
          >
            Save
          </button>
        </div>
      </div>
    );

    if (labelEditMode) {
      editOrSaveButton = (
        <div className="org-save-label border-top-lightgray org-item-block p-10 org-flex-row-end">
          <button
            className="blue-btn"
            onClick={(e) => {
              e.preventDefault();
              updateLables();
            }}
          >
            Save
          </button>
        </div>
      );
    }

    return (
      <div className={labelEditMode ? "org-label-editable" : ""}>
        <div className="border-bottom-lightgray org-input-block p-10">
          <p className="font-12 m-b-10">
            {labelEditMode
              ? "Edit Labels for this board"
              : "Add labels for this card"}
          </p>
          {!labelEditMode && (
            <input
              type="text"
              value={searchLabel}
              onChange={(e) => getLabelBySearch(e.target.value)}
              className="org-input-field p-5"
              placeholder="Search or add new label"
            />
          )}
        </div>
        <div className="org-list-block font-13">
          {!labelEditMode && listOrCreate}
          {labelsList}
        </div>
        {editOrSaveButton}
        {showColorPanel && (
          <div className="org-color-panel m-b-40 border-gray p-10">
            {colorList.map((color, idy) => {
              return (
                <span
                  key={`color_${idy}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLabelColor(color);
                  }}
                  className="org-item-indicator m-l-5 m-r-5 org-disp-inline-block org-cursor-pointer"
                  style={{ backgroundColor: color }}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="border-gray">
      <MultiSelectDropdown>{getLabelDropdownContent()} </MultiSelectDropdown>
    </div>
  );
}

export default withRouter(LabelList);
