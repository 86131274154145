import React from "react";
import NewLogo from "../../../assets/images/logo-new.png";
import "./styles.css";

const FormHeader = ({ headerText }) => {
  return (
    <div className="center-align">
      <img src={NewLogo} alt="orgzee logo" />
      <p className="header-heading">{headerText}</p>
    </div>
  );
};

export default FormHeader;
