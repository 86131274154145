const initialState = {
    currentStep: 1,
    data: {},
    showErrorWarning: false,
    invoices: [],
    customer: {},
    plan: {}
  };

  export const actions = {
    SET_STATE: "profile/SET_STATE",
    GET_CURRENT_CUSTOMER: "profile/GET_CURRENT_CUSTOMER",
    GET_PORTAL_URL: "profile/GET_PORTAL_URL",
    GET_UPDATE_PAYMENT_URL: "profile/GET_UPDATE_PAYMENT_URL",
  };

  function profileReducer(state = initialState, action) {
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }

  export const setGloabalLoading = (v) => ({
      type: "SET_STATE",
      payload: {isLoading: v}
  });

  export const resetNotifications = () => ({
      type: actions.SET_STATE,
      payload: {
          showErrorMessage: false,
          showSuccessMessage: false,
          errorMessage: null,
          successMessage: null
      }
  });

  export default profileReducer;
