import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { searchCard } from "../../store/actions/lists";
import RMH from "../../assets/images/right-menu-history-icon.svg";
import NewLogo from "../../assets/images/logo-new.svg";
import robot from "../../assets/images/robot.png";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import SearchBtn from "./searchBtn";
import RightSideHeader from "./rightSideHeader";
import Profile from "../profile/profile";
import FilterMember from "./filterMember";
import { actions as filterActions } from "../../store/reducers/filters";
import { getFilterCard, addCheckList } from "../../store/actions/lists";
import { getBoardDetail } from "../../store/actions/lists";
import { labelToggle } from "../../store/actions/UIActions";
import { updateUser, changePassword } from "../../store/actions/user";
import { useAddCheckList } from "../../hooks"

const Header = ({
  user,
  isUserLoggedIn,
  isPublic,
  filterMemberArr,
  currentCard = {},
  checkListAdded = false,
  enableAddCheckList,
  isSideMenuOpen,
  labelToggleDetails = []
}) => {
  const dispatch = useDispatch();
  const [showCancelSearchBtn, setShowCancelSearchBtn] = useState(false);
  const [searchCardValue, setSearchCardValue] = useState("");
  const [labels, setLabels] = useState([]);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const [currentStateCard, setCurrentStateCard] = useState({});

  useEffect(() => {
    setCurrentStateCard(currentCard);
  }, []);

  useEffect(() => {
    if (user.labelToggleDetails) {
      setLabels(user.labelToggleDetails || []);
    }
  }, [user.labelToggleDetails]);

  const removeLabelFilter = (labelId) => {
    const { boardList = [], activeBoard = {} } = user;
    if (activeBoard && activeBoard._id) {
      const position = labelToggleDetails.findIndex((lbl) => lbl._id === labelId);
      if (position >= 0) {
        labelToggleDetails.splice(position, 1);
      }
      dispatch(getBoardDetail(activeBoard, boardList, labelToggleDetails));
      dispatch(labelToggle(labelToggleDetails));
    }
  };
  const openSideBar = () => {
    dispatch({
      type: "SET_STATE",
      payload: {
        isSideMenuOpen: !isSideMenuOpen,
      }
    })
  }
  const onSearchCard = (e) => {
    e.preventDefault();
    if (searchCardValue.trim().length === 0) return;
    if (!showCancelSearchBtn) {
      setShowCancelSearchBtn(true);
      dispatch(searchCard(searchCardValue, get(user, "activeBoard._id")));
      return;
    }
    setSearchCardValue("");
    setShowCancelSearchBtn(false);
    dispatch(searchCard("", get(user, "activeBoard._id")));
  };

  const onReturnKeyPress = (e) => {
    if (e.key !== 'Enter' || searchCardValue.trim().length === 0) return;
    onSearchCard(e);
  };

  const removeFilter = (member, filterIndex) => {
    const fma = { ...filterMemberArr };
    fma[member].splice(filterIndex, 1);
    dispatch(getFilterCard(fma, user.activeBoard._id));
    if (!fma[member].length) {
      delete fma[member];
    }
    dispatch({
      type: filterActions.SET_STATE,
      payload: { filterMemberArr: fma },
    });
  };

  const clearAllFilters = () => {
    dispatch(getFilterCard({}, user.activeBoard._id));
    dispatch({
      type: filterActions.SET_STATE,
      payload: { filterMemberArr: [] },
    });
    dispatch(labelToggle([]));
  };

  const onUpdateUser = (data) => {
    dispatch(updateUser(data));
  };

  const onUpdatePassword = (oldPassword, newPassword) => {
    dispatch(changePassword(user.loggedInUser.email, oldPassword, newPassword));
  };

  const func = (c) => {
    dispatch(addCheckList(c));
  }
  useAddCheckList(func, currentCard, checkListAdded && enableAddCheckList)

  return (
    <div
      id="sticky-header"
      style={{
        position: "fixed",
        left: "0",
        top: "0",
        zIndex: 2,
        width: "100%"
      }}
    >
      <div className="header flex-row-block align-items justify-content">
        <div className="subheader1">
          <div className="disp-inline-block hamburger">
            <img
              className="menu"
              src={RMH}
              style={{
                width: "25px",
                height: "30px",
              }}
              onClick={() => openSideBar()}
            />
          </div>
          <img className="logo logo-mobile" src={robot} />
            <img className="logo logo-large" src={NewLogo} />
          <div
            className="disp-inline-block label-list"
            style={{ width: "46%" }}
          >
            <div className="search">
              <input
                type="text"
                placeholder="Search cards..."
                className="search-box"
                onChange={(e) => setSearchCardValue(e.target.value)}
                onKeyDown={(e) => onReturnKeyPress(e)}
                value={searchCardValue}
              />
              <SearchBtn
                showCancelSearchBtn={showCancelSearchBtn}
                onSearchCard={onSearchCard}
              />
            </div>
            {labels.length > 0 && (
              <div
                className="font-13 text-gray-big"
                style={{ paddingBottom: 10 }}
              >
                {labels.map((lbl) => (
                  <div
                    key={`card_label_${lbl._id}`}
                    className="org-item-block"
                    style={{ backgroundColor: lbl.color, borderRadius: "15px" }}
                  >
                    <span
                      style={{
                        paddingLeft: "14px",
                        paddingRight: "0px",
                      }}
                    >
                      {lbl.labelName}
                    </span>
                    <span
                      onClick={() => removeLabelFilter(lbl._id)}
                      style={{ padding: "0px" }}
                      className="org-icon org-cross-icon"
                    />
                  </div>
                ))}
              </div>
            )}
            <FilterMember
              filterMemberArr={filterMemberArr}
              removeFilter={removeFilter}
            />
          </div>
          {(!isEmpty(filterMemberArr) ||
            (user.labelToggleDetails &&
              user.labelToggleDetails?.length > 0)) && (
            <a className="clearFilters" onClick={() => clearAllFilters()}>
              Clear All Filters
            </a>
          )}
        </div>
        <div>
          {isUserLoggedIn &&
          <RightSideHeader
            isUserLoggedIn={isUserLoggedIn}
            loggedInUser={user.loggedInUser}
            isPublic={isPublic}
            setProfileOpen={() => setProfileOpen(true)}
          />}
        </div>
      </div>
      {isUserLoggedIn && (
        <Profile
          isProfileOpen={isProfileOpen}
          loggedInUser={user.loggedInUser}
          closeProfile={() => setProfileOpen(false)}
          updateUser={onUpdateUser}
          changePassword={onUpdatePassword}
          data={user.passwordChangeMessage || {}}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  getBoardDetail: PropTypes.string.isRequired,
  labelToggle: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  hideChangePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  isUserLoggedIn: get(state.user, "isUserLoggedin", false),
  isPublic: get(state.user, "isPublic", false),
  filterMemberArr: state.filters.filterMemberArr,
  currentCard: state.cards.currentCard,
  checkListAdded: state.cards.checkListAdded,
  enableAddCheckList: state.cards.enableAddCheckList,
  isSideMenuOpen: get(state.user, "isSideMenuOpen", false),
  labelToggleDetails: get(state.user.labelToggleDetails, "isSideMenuOpen", [])
})

export default connect(mapStateToProps)(Header);
