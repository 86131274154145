import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Column from "./column";
import { get } from "lodash";
import {
  moveList,
  addColumn,
  setBoard,
  moveCard,
  addCardAttachment,
} from "../../../store/actions/lists";
import { canEditBoard } from "../../../helper";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function getDocumentPositionRef(id) {
  const visibleXArea = window.innerWidth;
  const visibleYArea = window.innerHeight;
  const card = document.getElementById(id);
  const rect = card.getBoundingClientRect();
  return {
    x: (rect.x + rect.width / 2) / visibleXArea,
    y: (rect.y + rect.height / 2) / visibleYArea,
  };
}

const ColumnContainer = (props) => {
  const { user } = useSelector((state) => ({ user: state.user }));
  const dispatch = useDispatch();
  const isUserLoggedIn = get(user, "isUserLoggedin", false);
  const isPublic = get(user, "isPublic", false);
  const isSharedView = get(user, "isSharedView", false);
  const [scrollHeightOffset, setScrollHeightOffset] = useState(140);
  const [columns, setColumns] = useState([]);
  const [canDragAndDrop, setCanDragAndDrop] = useState(false);

  useEffect(() => {
    if (user.activeBoard) {
      setColumns(user.activeBoard.columns || []);
      setCanDragAndDrop(
        canEditBoard(user.currentMember, user.isUserLoggedin, isSharedView)
      );
    }
  }, [user.activeBoard?._id, user.activeBoard?.columns]);

  const onCardMove = (result) => {
    const { activeBoard, unFilteredList, labelAssignedId } = user;

    const sourceColumn = unFilteredList.columns.find(
      (column) => column._id === result.source.droppableId
    );
    const destinationColumn = unFilteredList.columns.find(
      (column) => column._id === result.destination.droppableId
    );

    if (sourceColumn && destinationColumn) {
      const draggedCard = sourceColumn.cards.splice(result.source.index, 1);
      destinationColumn.cards.splice(
        result.destination.index,
        0,
        draggedCard[0]
      );
      if (destinationColumn.confetti) {
        const origin = getDocumentPositionRef(draggedCard[0]._id);
        window.confetti({
          angle: randomInRange(55, 125),
          spread: randomInRange(50, 70),
          particleCount: 100,
          origin: origin,
        });
      }

      dispatch(setBoard(activeBoard));
      dispatch(moveCard(unFilteredList.columns, unFilteredList));
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const isSharedView = get(user, "isSharedView", false);
    if (!result.destination || !isUserLoggedIn || isPublic || isSharedView) {
      return;
    }

    if (result.type === "COLUMN") {
      const items = reorder(
        columns,
        result.source.index,
        result.destination.index
      );
      setColumns(items);
      dispatch(moveList(items));
      return;
    }
    if (result.type === "CARD") {
      result.destination.index =
        result.destination.index === -1 ? 0 : result.destination.index;
      onCardMove(result);
      return;
    }
  };

  const addNewColumn = () => {
    const { activeBoard, boardList } = user;
    dispatch(addColumn(activeBoard, boardList));
  };

  const getElementsByClassName = (node, classname) => {
    const a = [];
    const re = new RegExp("(^| )" + classname + "( |$)");
    const els = node.getElementsByTagName("*");
    for (let i = 0, j = els.length; i < j; i++) {
      if (re.test(els[i].className)) a.push(els[i]);
    }
    return a;
  };

  const handleUpdateUI = () => {
    const columnLists = getElementsByClassName(
      document,
      "inner-droppable-area"
    ).map((column) => column.scrollHeight);
    const maxHeight =
      columnLists.length > 0 ? Math.max.apply(Math, columnLists) : 0;
    const droppableAreas = getElementsByClassName(
      document,
      "droppable-area-cl"
    );
    const addColumnBtn = document.getElementById("add-column-btn");
    for (let i = 0; i < droppableAreas.length; i++) {
      droppableAreas[i].style.height = `${maxHeight}px`;
    }
    if (addColumnBtn) {
      addColumnBtn.style.height = `${maxHeight}px`;
    }
  };

  const addCardAttachments = (image, cardId, isCardImage, isUpdate) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("card", cardId);
    formData.append("isCardImage", isCardImage);
    dispatch(addCardAttachment(formData));
  };

  const onImagePaste = (e) => {
    const cardID = get(user, "cardId");
    if (!cardID) {
      return;
    }
    const items = e.clipboardData?.items || [];
    for (const i in items) {
      const item = items[i];
      if (get(item, "type", "").includes("image")) {
        const blob = item.getAsFile();
        const reader = new FileReader();
        reader.onload = function () {};
        reader.readAsDataURL(blob);
        addCardAttachments(blob, cardID);
      }
    }
  };

  return (
    <div onPaste={onImagePaste} className="cmain-content">
      <div
        className="board-cont"
        style={{
          marginTop: props.marginTop,
          padding: "10px",
          position: "static",
        }}
      >
        <main>
          <div style={{ padding: "5px" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="board"
                type="COLUMN"
                direction="horizontal"
                ignoreContainerClipping
                isDropDisabled={!canDragAndDrop}
                isDragDisabled={!canDragAndDrop}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="columns board-container"
                    {...provided.droppableProps}
                    // style={{minHeight: "85vh", width: isSideMenuOpen ? (columns.length * 302 + 380) : columns.length * 302 + 100}}
                    style={{
                      margin: "0 0 -5px 0",
                      width: "fit-content",
                      display: "inline-flex",
                    }}
                    onScroll={() => {
                      const scrollHeight =
                        document.getElementsByClassName("board-container")[0]
                          .scrollHeight;
                      if (scrollHeight !== scrollHeightOffset) {
                        handleUpdateUI();
                      }
                      setScrollHeightOffset(scrollHeight);
                    }}
                  >
                    {columns.map((column, index) => {
                      return (
                        <Draggable
                          key={column._id}
                          draggableId={column._id}
                          index={index}
                          isDragDisabled={!canDragAndDrop}
                        >
                          {(dragProvided) => (
                            <div
                              ref={dragProvided.innerRef}
                              className="column-card-cont ccolumn-content"
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                            >
                              <Column
                                column={column}
                                canDragAndDrop={canDragAndDrop}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    {canDragAndDrop && (
                      <div id="add-column-btn" style={{ paddingRight: "22px" }}>
                        <div
                          className="add-btn add-col-btn"
                          onClick={addNewColumn}
                        >{` + `}</div>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ColumnContainer;
