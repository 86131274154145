import { actions } from "../reducers/sharedBoards";

import { all, takeEvery, put, call } from "redux-saga/effects";
import * as services from "../services/sharedBoards";
import { setGloabalLoading } from "../reducers/profile";

export function* GET_SHARED_BOARD({payload}) {
  yield put(setGloabalLoading(true));
  const response = yield call(services.getSharedBoard, payload.id);
  if (response.status !== "error") {
    yield put(setGloabalLoading(false));
    if(!response.data.isPublic){
        window.location.replace(window.location.origin);
    }
    let boards = [];
    let notExists = true
    if(payload.isLoggedIn) {
      boards = yield call(services.getBoardList, payload.id) || [];
      notExists = boards.findIndex((item) => item._id === payload.id && !item.isDeleted) === -1
    }
    const board = yield call(sortColumn, response.data)
    yield put({
      type: "SET_STATE",
      payload: {
        activeBoard: board,
        isSharedView: notExists,
        boardList: boards,
        reloadList: false
      }
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        board: board,
      },
    });
  } else {
    yield put(setGloabalLoading(false));
    yield call(payload.history.push, "/")
  }
}

function sortColumn(board) {
  if (board) {
    board.columns.sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });
  }
  return board;
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SHARED_BOARD, GET_SHARED_BOARD),
  ]);
}
