export const CARD_HEIGHT = 137; // height of a single card(excluding marginBottom/paddingBottom)
export const CARD_MARGIN = 10; // height of a marginBottom+paddingBottom
export const OFFSET_HEIGHT = 228; // height offset from the top of the page
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const BASE_URL = process.env.NODE_ENV === "production"?"http://localhost:8080/v1/":"http://localhost:9090/v1/";
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
// export const SOCKET_URL = process.env.NODE_ENV === "production"?"http://localhost:7777/":"http://localhost:8888/";
export const colors = {
    "blue": {
        "deep": "rgb(0, 121, 191)",
        "light": "lightblue",
        "lighter": "#d9fcff",
        "soft": "#E6FCFF",
    },
    "black": "#4d4d4d",
    "shadow": "rgba(0,0,0,0.2)",
    "grey": {
        "darker": "#C1C7D0",
        "dark": "#E2E4E6",
        "medium": "#DFE1E5",
        "N30": "#EBECF0",
        "light": "#F4F5F7",
    },
    "green": "rgb(185, 244, 188)",
    "white": "white",
    "purple": "rebeccapurple",
};

export const grid = 8;

export const borderRadius = 2;

export const GOOGLE_CLIENT_ID = "1065234372362-m2lmjlfvh1k3jfedkqps6v1kep18gl70.apps.googleusercontent.com";
