import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const RenderBreadCrum = ({ user }) => {
  const [parentBoardDetail, setParentBoardDetail] = useState("");

  useEffect(() => {
    if (user.boardList && user.boardList && user.activeBoard) {
      for (let i = 0; i < user.boardList.length; i++) {
        if (
          !user.boardList[i].isDeleted &&
          user.activeBoard.createdFrom &&
          user.boardList[i]._id === user.activeBoard.createdFrom
        ) {
          setParentBoardDetail(user.boardList[i]);
          break;
        }
      }
    }
  }, [user.boardList]);

  if (parentBoardDetail) {
    return (
      <span>
        <div className="parent-board-name">
          <a
            onClick={() =>
              this.getBoardDetail(
                { _id: parentBoardDetail._id },
                user.boardList
              )
            }
          >
            {parentBoardDetail.name}
          </a>
        </div>
        <span className="breadcrum fa fa-chevron-right" />
      </span>
    );
  } else {
    return null;
  }
};

RenderBreadCrum.propTypes = {
  user: PropTypes.object.isRequired,
};

export default RenderBreadCrum;
