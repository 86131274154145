import { connect } from "react-redux";
import Spinner from "./spinner/spinner";
import { getBoardList } from "../store/actions/lists";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const DefaultPage = ({user, dispatch, history}) => {
    useEffect(() => {
        dispatch(getBoardList())
    }, [])

    useEffect(() => {
        const {boardList} = user
        if(boardList && boardList.length > 0) {
            const first = boardList.find((item) => !item.isDeleted);
            if(first) {
                history.push(`/board/${first._id}`)
            }else {
                history.push(`/board/${boardList[0]._id}`)
            }
        }
    }, [user.boardList])
    return (
        <div className="container">
            <Spinner show/>
        </div>
    )
}
const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps)(withRouter(DefaultPage));