import React from "react";
import { FcGoogle } from "react-icons/fc";
import FormHeader from "../FormHeader";
import { GOOGLE_CLIENT_ID } from "../../../../constants";
import { GoogleLogin as GoogleLoginComponent } from "react-google-login";
import axios from "axios";
import { withRouter } from "react-router";
import { get } from "lodash";
import "./styles.css";

const GoogleLogin = ({ userEmail, propss, history, setRegistrationState }) => {
  const responseGoogle = async (resp) => {
    await axios({
      method: "post",
      url: "auth/login/google",
      data: {
        ...resp,
      },
    })
      .then((response) => {
        const jwt_token = response.data.token;
        localStorage.setItem("authorization", jwt_token);
        propss.getUser().then((res) => {
          const boardId = get(res, "data.data.boards[0]", null);
          if (boardId) {
            history?.push(`/board/${boardId}`);
          }
        });
      })
      .catch((error) => {
        console.log("error on social : ", error);
      });
  };

  return (
    <div className="google-login-wrapper">
      <FormHeader
        headerText={"You logged in with your Google account before"}
      />
      <div className="google-container">
        <div className="container-header">
          <div>
            <FcGoogle fontSize={"2rem"} />
          </div>
          <h3>{userEmail}</h3>
        </div>
        <GoogleLoginComponent
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Sign in with Google"
          onSuccess={responseGoogle}
          onFailure={() => {}}
          render={(renderProps) => (
            <button className="google-btn" onClick={renderProps.onClick}>
              Continue with Google
            </button>
          )}
        />
        <div
          className="forgot-pwd"
          onClick={() =>
            setRegistrationState((prev) => ({
              ...prev,
              loginUsingGoogle: false,
              checkEmailRegistered: true,
            }))
          }
        >
          Use a different login method
        </div>
      </div>
    </div>
  );
};

export default withRouter(GoogleLogin);
