import { useState } from "react";
import { withRouter } from "react-router-dom";
import CopyBoardComponent from "../../containers/Board/boardHeader/copyBoard";
import NewLogo from "../../assets/images/robot-white-complete.png";
import styles from "./style.module.scss"

function SharedHeader({isUserLoggedin = false, history = {}, isCopying = false, match = {}}) {
    const [openCopyBoard, setOpenCopyBoard] = useState(false)
    const login = () => {
        window.localStorage.setItem("shared-board-id", match.params.id)
        history.push("/login")
    }
    const register = () => {
        window.localStorage.setItem("shared-board-id", match.params.id)
        history.push("/register")
    }
    const backtohome = () => {
        history.push("/")
    }
    return (
        <div
            id="sticky-header"
            className={styles.SharedHeader}
        >
            {
                !isUserLoggedin && 
                <div className={styles.left}>
                    <div>
                        <img className="logo" src={NewLogo} onClick={backtohome} />
                    </div>
                </div>
            }
            <div>
                <p className={styles.make}>Make this board your own!</p>
            </div>
            <div>
                {!isUserLoggedin && 
                    <div className={styles.login}>
                        <button onClick={login} className={styles.loginBtn}>Login</button>
                        <button onClick={register} className={styles.signupBtn} >Signup</button>
                    </div>}

                {(isUserLoggedin && isCopying) && <button className={styles.rightButton} onClick={() => setOpenCopyBoard(true)}>
                    Make This Board Mine
                </button>
                }
            </div>
            {openCopyBoard && <CopyBoardComponent isOpen={openCopyBoard} handleClose={() => setOpenCopyBoard(false)} />}
        </div>
    )
}

export default withRouter(SharedHeader);