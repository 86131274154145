import React, {Component} from "react";

export default class CommentActions extends Component {
    render() {

        const {top, left} = this.props;
        return (
            <div
                className="action-cont"
                style={{"display": "block", "top": top, "left": left, "zIndex": "5"}}
            >
                <div className="action-list">
                    <div className="action" onClick={this.props.editComment.bind(this)}>
                        Edit Comment
                    </div>
                    <div className="action" onClick={this.props.deleteComment.bind(this)}>
                        Delete Comment
                    </div>
                </div>
            </div>
        );

    }

}
