export function getHeaders(jsonContentType = true) {

    const headers = {
        "Accept": "application/json",
    };
    if (jsonContentType) {

        headers["Content-Type"] = "application/json";
    } else {

        headers["Content-Type"] = "multipart/form-data; boundary=\"--\"";
    }
    if (window.localStorage.jwt) {

        headers.Authorization = `JWT ${window.localStorage.jwt}`;
    }
    return headers;

}
export function daysFromCurrentDate(prevDate) {

    const prevTimestamp = (new Date(prevDate)).getTime();
    const currentTimestamp = (new Date()).getTime();
    const daysFromCurrentDate = Math.ceil(Math.abs(currentTimestamp - prevTimestamp) / 86400000);
    return daysFromCurrentDate;

}

export function timeDifference(prev) {

    const current = (new Date()).getTime();
    const previous = (new Date(prev)).getTime();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;
    let value;

    const elapsed = current - previous;

    if (elapsed > 0) {
        if (elapsed < msPerMinute) {
            value = Math.round(elapsed / 1000) + " seconds ago";
        }

        else if (elapsed < msPerHour) {
            value = Math.round(elapsed / msPerMinute) + " minutes ago";
        }

        else if (elapsed < msPerDay ) {
            value = Math.round(elapsed / msPerHour ) + " hours ago";
        }

        else if (elapsed < msPerMonth) {
            value = Math.round(elapsed / msPerDay) + " days ago";
        }

        else if (elapsed < msPerYear) {
            value = Math.round(elapsed / msPerMonth) + " months ago";
        }

        else {
            value = Math.round(elapsed / msPerYear ) + " years ago";
        }
        return {"value": value, "isPast": true};

    } else {
        const elapsedAbs = Math.abs(elapsed);
        if (elapsedAbs < msPerMinute) {
            value = Math.round(elapsedAbs / 1000) + " seconds to go";
        }

        else if (elapsedAbs < msPerHour) {
            value = Math.round(elapsedAbs / msPerMinute) + " minutes to go";
        }

        else if (elapsedAbs < msPerDay ) {
            value = Math.round(elapsedAbs / msPerHour ) + " hours to go";
        }

        else if (elapsedAbs < msPerMonth) {
            value = Math.round(elapsedAbs / msPerDay) + " days to go";
        }

        else if (elapsedAbs < msPerYear) {
            value = Math.round(elapsedAbs / msPerMonth) + " months to go";
        }

        else {
            value = Math.round(elapsedAbs / msPerYear ) + " years to go";
        }
        return {"value": value, "isPast": false};

    }


}
export function formatTime(date) {

    const formatDate = new Date(date);
    let hour = formatDate.getHours();
    const ampm = hour > 12 ? "PM" : "AM";
    hour = (hour > 12 ? (hour - 12) : hour).toString();
    hour = hour.length < 2 ? ("0" + hour) : hour;
    let min = (formatDate.getMinutes()).toString();
    min = min.length < 2 ? ("0" + min) : min;
    return hour + ":" + min + " " + ampm;

}

export function formatDate(date) {

    const formatDate = new Date(date);
    const year = formatDate.getUTCFullYear();
    let month = (formatDate.getUTCMonth() + 1).toString();
    month = month.length < 2 ? ("0" + month) : month;
    let day = (formatDate.getUTCDate()).toString();
    day = day.length < 2 ? ("0" + day) : day;
    let hour = (formatDate.getHours()).toString();
    hour = hour.length < 2 ? ("0" + hour) : hour;
    let min = (formatDate.getMinutes()).toString();
    min = min.length < 2 ? ("0" + min) : min;
    return {"date": year + "-" + month + "-" + day, "time": hour + ":" + min};

}
export function checkHTMLContent(string) {

    const p = document.createElement("p");
    p.innerHTML = string;
    return p.textContent;

}


export function parseVideo (url) {

    url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);
    let type;
    if (RegExp.$3.indexOf("youtu") > -1) {
        type = "youtube";
    } else if (RegExp.$3.indexOf("vimeo") > -1) {
        type = "vimeo";
    }

    return {
        "type": type,
        "id": RegExp.$6
    };

}

export function emailValidation(email) {

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);

}

export function canEditBoard(currentMember = {}, isUserLoggedIn = false, isSharedView = false) {
    const roles = ["ADMIN", "MEMBER", "VIEWER"];
    return roles.findIndex((role) => role === currentMember.role) <= 1 && isUserLoggedIn && !isSharedView;
}
