import React, {Component} from "react";

export default class Spinner extends Component {


    componentWillMount() {

    }

    render() {

        return (
            <div className="loader-wrapper" style={{"display": this.props.show ? "block" : "none"}}>
                <div className="loader">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    
    }

}
