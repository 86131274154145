import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoardDetail } from "../../../../../store/actions/lists";
import { labelToggle } from "../../../../../store/actions/UIActions";
import PropTypes from "prop-types";

const LabelAssigned = ({
  labelAssigned = [],
  labelToggleDetails = [],
}) => {
  const dispatch = useDispatch();
  const { activeBoard, boardList} = useSelector(
    (state) => ({
      activeBoard: state.user.activeBoard,
      boardList: state.user.boardList,
    })
  );
  const [labels, setLabels] = useState(labelAssigned);
  useEffect(() => {
    setLabels(labelAssigned);
  }, [labelAssigned]);

  const filterCardsByLabel = (label) => {
    if (activeBoard && activeBoard._id) {
      dispatch(getBoardDetail(activeBoard, boardList, [...labelToggleDetails, label]));
      dispatch(labelToggle([...labelToggleDetails, label]));
    }
  };
  return (
    <div style={{ paddingBottom: "10px" }} className="font-12 text-gray-big">
      {labels.map((label, index) => (
        <div
          key={`card_label_${label._id}_${index}`}
          className="org-item-block"
          onClick={() => filterCardsByLabel(label)}
          style={{ backgroundColor: label.color, borderRadius: "15px" }}
        >
          <span className="p-5">
            <span>{label.labelName}</span>
          </span>
        </div>
      ))}
    </div>
  );
};

LabelAssigned.propTypes = {
  labelAssigned: PropTypes.array,
  labelToggleDetails: PropTypes.array,
};

export default LabelAssigned;
