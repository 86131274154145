import { combineReducers } from "redux";
import {
    routerReducer
} from "react-router-redux";

import user from "./user";
import filters from './filters'
import register from "./register"
import cards from "./cards"
import profile from "./profile";
import boards from "./boards";
import sharedBoards from "./sharedBoards";


const rootReducer = combineReducers({
    "routing": routerReducer,
    user,
    filters,
    register,
    cards,
    profile,
    boards,
    sharedBoards
});

export default rootReducer;
