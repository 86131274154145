import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, connect } from "react-redux";
import HomePageContainer from "./homePageContainer";
import DeleteNotification from "./deleteBoard/deleteNotification/deleteNotification";
import ColumnContainer from './columnsContainer'
import * as uiActions from "../../store/actions/UIActions";

const Index = ({ history, user = {} }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.showDeleteNotification) {
      const { board } = user;
      if (user.data.status !== "error") {
        dispatch(uiActions.showDeleteNotification(user.board));
        if (board._id === user.activeBoard._id) {
          const filterBoardList = user.boardList.filter((item) => {
            if (!item.isDeleted) return item;
          });
          if (filterBoardList.length) {
            history.push(`/board/${filterBoardList[0]._id}`);
          } else {
            dispatch(uiActions.showBlankBoard());
            history.push("/login");
          }
        }
      }
    }
  }, [user.showDeleteNotification]);

  return (
    <>
      <HomePageContainer />
      <ColumnContainer/>
      {user.showDeleteNotification && (
        <DeleteNotification
          board={user.board}
          showDeleteNotification={user.showDeleteNotification}
        />
      )}
    </>
  );
};

Index.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};
const mapStateToProps = (state) => ({user: state.user});
export default connect(mapStateToProps)(withRouter(Index));
