import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {changePassword} from "../../../store/actions/user"
import styles from "./styles.module.scss"
import PropTypes from 'prop-types';

const regGood = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .required("Required Field"),
    password: Yup.string()
        .min(8, "Type 8 characters or more. Use letters, numbers and symbols.")
        .required("Required Field")
        .matches(
            regGood,
            "Type 8 characters or more. Use letters, numbers and symbols."
        ),
    confirmPassword: Yup.string().required("Required Field").oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
});

const UpdatePassword = ({handleClose}) => {
    const dispatch = useDispatch()
    const { loggedInUser = {}, isLoading = false } = useSelector((state) => ({ ...state.user }));
    const initialValues = {
        currentPassword: "",
        password: "",
        confirmPassword: "",
    };
    const onResetPassword = (values) => {
        dispatch(changePassword(loggedInUser.email, values.currentPassword, values.password));
    };

    const validateConfirmPassword = (pass, value) => {
        let error = "";
        if (pass && value) {
            if (pass !== value) {
                error = "Password not matched";
            }
        }
        return error;
    };
    return (
        <div className={styles.UpdatePassword}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onResetPassword}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                }) => {
                    return (
                        <Form className={`${styles.formContainer}`} onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="currentPasswor">
                                    Current Password{" "}
                                </label>
                                <input
                                    type="password"
                                    name="currentPassword"
                                    className={`${errors.currentPassword && touched.currentPassword && "error"
                                        }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.currentPassword}
                                />
                                <span className="field-error">
                                    {errors.currentPassword && touched.currentPassword && errors.currentPassword}
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">
                                    Password{" "}
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    className={`${errors.password && touched.password && "error"
                                        }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                                <span className="field-error">
                                    {errors.password && touched.password && errors.password}
                                </span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <Field
                                    type="password"
                                    name="confirmPassword"
                                    className={`${errors.confirmPassword && touched.confirmPassword && "error"
                                        }`}
                                    validate={(value) =>
                                        validateConfirmPassword(values.password, value)
                                    }
                                />
                                <span className="field-error">
                                    {errors.confirmPassword &&
                                        touched.confirmPassword &&
                                        errors.confirmPassword}
                                </span>
                            </div>

                            <div className={styles.footer}>
                                <button
                                    className={`btn default-button`}
                                    type="button"
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={`btn submit-button`}
                                    type="submit"
                                    disabled={!isValid || isLoading}
                                >
                                    Change Password
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}

UpdatePassword.propTypes = {
    handleClose: PropTypes.func.isRequired
}
export default UpdatePassword;