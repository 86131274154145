import { Formik, Form } from "formik";
import { Link, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { get } from "lodash";
import { connect } from "react-redux";
import * as UserActions from "../../../store/actions/user";
import Spinner from "../../spinner/spinner";
import Header from "../../header/credentialModalHeader";
import "./style.scss";

const forgetSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required Field"),
});

const initialValues = {
  email: "",
};

function ForgotPassword({history,...props}) {
  const onUserForget = (values) => {
    props
      .sendOTP(values.email)
      .then(() => {
        history.push("/forget-password/email-sent")
      })
  };
  return (
    <div className="forgot-password-container">
      <Header />
      <div>
        <div className="page-title">Forgot your password?</div>
        <div className="page-description">
          Don’t worry, it happens to the best of us.
        </div>
        <div className="form form-container">
          <Formik
            initialValues={initialValues}
            validationSchema={forgetSchema}
            onSubmit={onUserForget}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form className="formik-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      className={`${errors.email && touched.email && "error"}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <span className="field-error">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <button disabled={props.user.isLoading} className={`submit-button`} type="submit">
                    Email me a reset link
                  </button>
                </Form>
              );
            }}
          </Formik>
          <div className="infos">
            <div className="content">
              <p>Return to </p>
              <Link to="/login">{" "}login</Link>
            </div>
            <div className="content">
              <p onClick={() => history.push("/register")}>Don’t have an account? </p>
            </div>
          </div>
        </div>
      </div>
      <Spinner show={props.user.isLoading} />
    </div>
  );
}

const mapDispatchToProps = {
  ...UserActions,
};

const mapStateToProps = (state) => ({
  user: state.user,
  sendOTPSuccess: get(state, "user.sendOTPSuccess", false),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
