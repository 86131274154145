import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MentionInput from "../../../../../widget/mentions/mentions";
import { editCardDetail } from "../../../../../store/actions/lists";
import { useOutsideAlerter } from "../../../../../hooks";
import { mentionText } from "../../../../../components/common";

const CardDesc = ({ card, editCardDesc, setEditCardDesc, isHasPermissionToEdit = false }) => {
  const { user, activeBoard, boardType } = useSelector((state) => ({
    user: state.user,
    activeBoard: state.user.activeBoard,
    boardType: state.user.activeBoard.boardType,
  }));
  const dispatch = useDispatch();
  const inputRef = useRef();
  const descRef = useRef();
  const [cardDesc, setCardDesc] = useState(card.description);
  useEffect(() => {
    if (editCardDesc) {
      inputRef.current.input.focus();
    } else if (!editCardDesc && card._id === descRef.current.id){
      onEditCardDetails();
    }
  }, [editCardDesc]);

  const showEmptyDesc = (e) => {
    const { isUserLoggedin, isPublic } = user;
    if (!isUserLoggedin || isPublic || !isHasPermissionToEdit) {
      return;
    }
    e.currentTarget.style.opacity = "1";
    e.currentTarget.parentNode.style.height = "100%";
  };

  const onEditCardDetails = () => {
    setEditCardDesc(false);
    if (card.description === cardDesc) {
      return ;
    }
    dispatch(
      editCardDetail(card, {
        description: cardDesc,
      })
    );
    setEditCardDesc(false);
  };

  const hideEmptyDesc = (e) => {
    if (!editCardDesc && !card.description) {
      e.currentTarget.style.opacity = "0";
      e.currentTarget.parentNode.style.height = "0px";
    }
  };

  useOutsideAlerter(descRef, setEditCardDesc);

  return (
    <div
      className={`desc-cont ${
        boardType && boardType.id === "1" ? "normal" : "sales"
      }`}
    >
      <div
        style={{
          height: !card.description && !editCardDesc ? "0px" : "100%",
        }}
        className="name"
        ref={descRef}
        id={`${card._id}`}
      >
        {!editCardDesc ? (
          <span
            className={!card.description ? "text-gray" : ""}
            onClick={() => setEditCardDesc(true)}
            style={{ display: "inline-block", opacity: card.description ? 1 : 0, width: "100%" }}
            onMouseOver={showEmptyDesc}
            onMouseLeave={hideEmptyDesc}
          >
            {!card.description? <pre style={{marginBottom: 0}}>Add a description</pre>: <span dangerouslySetInnerHTML={{
              __html :  mentionText(card.description)
            }}/>}
          </span>
        ) : (
          <MentionInput
            isMember
            isShowPhoto
            options={activeBoard.members || []}
            toDisplayProp={["email", "id"]}
            ref={inputRef}
            key={`${card._id} ${card.description}`}
            className="edit-text-column"
            defaultValue={card.description}
            onBlur={() => onEditCardDetails()}
            onChange={(e) => setCardDesc(e.target.value)}
            style={{ display: editCardDesc ? "inline-block" : "none" }}
          />
        )}
      </div>
      <div className="cost"> {card.prize ? card.prize : ""} </div>
    </div>
  );
};

CardDesc.propTypes = {
  card: PropTypes.object,
  editCardDesc: PropTypes.bool,
  setEditCardDesc: PropTypes.func,
  isHasPermissionToEdit: PropTypes.bool
};

export default CardDesc;
