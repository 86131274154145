import {useState} from "react";
import PropTypes from "prop-types";

const Image = ({ card, attachment, isLoggedIn = false, isPublic = false, canEditCard = false, deleteContent }) => {
  const [openModal, setOpenModal] = useState(false)
  const handleDueDateClick = (id) => {
    const ele = document.getElementById(id);
    if (ele.className.indexOf("due-show-tooltip") === -1) {
      ele.className += " due-show-tooltip";
    } else {
      ele.className = ele.className.replace(" due-show-tooltip", "");
    }
  };

  const openImageModal = ()=>{
    if (openModal) {
      document.getElementById("sticky-header").style.zIndex = 10
      document.getElementById("boards-filters").style.zIndex = 5
      setOpenModal(false)
    }else {
      document.getElementById("sticky-header").style.zIndex = 1
      document.getElementById("boards-filters").style.zIndex = 1
      setOpenModal(true)
    }
  }

  return (
    <div key={`attachment${attachment._id}`}>
      <img
        className="attachment-cont"
        src={attachment.url}
        onClick={()=>openImageModal()}
      />
      <div className="desc-cont normal org-flex-row-end org-action-container">
        {isLoggedIn && !isPublic && canEditCard && (
          <span
            onClick={() =>
              handleDueDateClick(`delete_media_option_${attachment._id}`)
            }
            id={`delete_media_option_${attachment._id}`}
            className="org-card-options org-tooltip org-options"
          >
            <div className="org-tooltip-due-date">
              <div className="action-list">
                <div
                  className="action"
                  onClick={() => deleteContent(card._id, attachment._id)}
                >
                  Delete
                </div>
              </div>
            </div>
          </span>
        )}
      </div>
      {openModal &&
        <div>
        <div
        className="backdrop center-align"
        style={{
          display: "block",
          zIndex: "35",
          top: "0",
          position: "fixed",
          left: "0",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        onClick={()=>openImageModal()}
      >
        <div className="pos-rel file-attached m-t-10">
          <span
            style={{ right: 10, top: 10, color: "white" }}
            className="pos-abs close fa fa-times"
            aria-hidden="true"
            onClick={()=>openImageModal()}
          />
          <img src={attachment.url} />
        </div>
      </div>
      </div>}
    </div>
  );
};

Image.propTypes = {
  attachment: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isPublic: PropTypes.bool,
  canEditCard: PropTypes.bool,
  card: PropTypes.object,
  deleteContent: PropTypes.func,
};

export default Image;
