const initialState = {
  filterMemberArr: [],
};

export const actions = {
  SET_STATE: "filters/SET_STATE"
}

export default function filters(state = initialState, action) {
  switch (action.type) {
    case "filters/SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
