import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from 'antd';
import CurrentPlan from "./plan";
import PaymentMethod from "./paymentMethod";
import Invoices from "./invoices";
import { actions, resetNotifications } from "../../../store/reducers/profile";
import styles from "./styles.module.scss";

const Subscription = () => {
    const { showErrorMessage, errorMessage = false, customer = {}, plan = {}, invoices = [] } = useSelector((state) => ({ ...state.profile }));
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: actions.GET_CURRENT_CUSTOMER
        });
        return () => {
            dispatch(resetNotifications());
        }
    }, []);

    return (
        <div className={styles.Subscription}>
            {showErrorMessage === "success" && <Alert message={errorMessage} type="success" showIcon />}
            {showErrorMessage && <Alert message={errorMessage} type="error" showIcon />}
            <CurrentPlan plan={plan} />
            {customer._id && <PaymentMethod customer={customer} />}
            <Invoices invoices={invoices} />
        </div>
    );
};

export default Subscription;