import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types"
import Spinner from "../spinner/spinner";
import Plans from "./steps/plans";
import Payment from "./steps/payment";
import SuccessSubsription from "./steps/success";
import CheckToken from "./steps/confirm"
import CheckEmailPage from "./steps/checkEmail"
import { LoginStartup } from "../loginUser";

const Register = ({ match }) => {
  const { register } = useSelector((state) => ({ user: state.user, register: state.register }));
  useEffect(() => {
    document.getElementsByTagName("BODY")[0].style["overflow-y"] = "auto";
    document.getElementsByTagName("BODY")[0].style["overflow-x"] = "auto";
    return () => {
      document.getElementsByTagName("BODY")[0].style["overflow-y"] = "hidden";
      document.getElementsByTagName("BODY")[0].style["overflow-x"] = "hidden";
    };
  }, []);

  return (
    <>
    <Spinner show={register.loading} />
    <Switch>
      <Route
        exact
        path={`${match.url}/new-account`}
        component={LoginStartup}
      />
      <Route
        exact
        path={`${match.url}/email-sent`}
        component={CheckEmailPage}
      />
      <Route path={`${match.url}/confirm`} component={CheckToken} />
      <Route
        exact
        path={`${match.url}/plans`}
        component={Plans}
      />
      <Route
        exact
        path={`${match.url}/payment`}
        component={Payment}
      />
      <Route
        exact
        path={`${match.url}/welcome`}
        component={SuccessSubsription}
      />
      <Redirect to={`${match.url}/new-account`} />
    </Switch>
    </>
  );
};

Register.propTypes = {
  match: PropTypes.object,
}

export default withRouter(Register);
