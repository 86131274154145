const initialState = {
  currentCard: {},
  checkListAdded: false,
};

export const actions = {
  SET_STATE: "cards/SET_STATE",
};

function cardsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default cardsReducer;
