import React, {Component} from "react";
import Dropdown from "../../../widget/Dropdown/dropdown";
import OutsideAlerter from "../../../widget/Modal";
import {get} from "lodash";
const INIT_STATE = {
    "boardSelected": {},
    "columnLists": [],
    "columnSelected": {},
    "isCopy": false,
};

export default class MoveCardToBoard extends Component {

    constructor(props) {

        super(props);
        this.state = {
            ...INIT_STATE,
        };

    }

    onBoardSelect(boardSelected) {

        if (!boardSelected) {

            return;
        }
        const {boardDetails = [], getBoardDetail} = this.props;
        getBoardDetail(boardSelected, boardDetails).then((resp) => {

            const columnLists = get(resp, "data.data.columns", []).map(
                (column, index) => ({
                    "_id": column._id,
                    "value": column.name || `Column ${column.position + 1}`,
                    "id": index + 1,
                })
            );
            this.setState({
                boardSelected,
                "columnSelected": {},
                columnLists,
            });

        });

    }

    onColumnChange(columnSelected) {

        if (!columnSelected) {

            return;

        }
        this.setState({columnSelected});

    }

  submitAction = async () => {

      const thisState = this.state;
      const boardId = get(thisState, "boardSelected._id", "");
      const columnId = get(thisState, "columnSelected._id", "");
      const {card, moveCardToBoard, activeBoard} = this.props;
      const cardId = get(card, "_id");
      if (!cardId || !columnId || !boardId) {

          return;

      }
      await moveCardToBoard(
          cardId,
          columnId,
          boardId,
          thisState.isCopy,
          activeBoard
      );
      this.closeModal();

  };

  closeModal() {

      this.setState(INIT_STATE, () => this.props.handleClose());

  }

  render() {

      const {boardDetails = []} = this.props;
      const boardList = boardDetails.map((board, index) => ({
          "_id": board._id,
          "value": board.name,
          "id": index + 1,
      }));
      const submitBtnDisabled =
      get(this.state, "boardSelected._id") &&
      get(this.state, "columnSelected._id");
      return (
          <div
              className="backdrop"
              style={{"display": this.props.showHideMoveCardModal ? "block" : "none"}}
          >
              <div className="popup small p-b-20">
                  <OutsideAlerter handleClose={this.closeModal.bind(this)}>
                      <div className="header b-b-0 p-30">
                          <span className="move-card">
                              Move Card to Board
                          </span>
                          <div className="sub-head p-t-b-5">
                              Select the board to move or copy this card to.
                          </div>
                      </div>
                      <div className="body p-t-b-20-imp">
                          <div className="control-box">
                              <div className="m-b-25">
                                  <div className="text-black-bold-small zambezi-color m-b-6">
                                      Select Board
                                  </div>
                                  <Dropdown
                                      fullWidth
                                      options={boardList}
                                      onChange={this.onBoardSelect.bind(this)}
                                  />
                              </div>
                              <div className="m-b-15">
                                  <div className="text-black-bold-small zambezi-color m-b-6">
                                      Select Column
                                  </div>
                                  <Dropdown
                                      fullWidth
                                      options={this.state.columnLists}
                                      onChange={this.onColumnChange.bind(this)}
                                  />
                              </div>
                              <div className="flex-row-block m-b-50">
                                  <input
                                      type="checkbox"
                                      onChange={() => {

                                          const {isCopy} = this.state;
                                          this.setState({"isCopy": !isCopy});

                                      }}
                                      checked={this.state.isCopy}
                                      className="checkbox"
                                  />
                                  <div className="m-l-20">
                                      <span className="text-grey font-14 bold">
                                          Copy Card
                                      </span>
                                      <div className="text-grey font-14 m-t-5">
                                          (Selecting this will leave the original card in place and
                                          make a copy of it on the board you want to move it to.)
                                      </div>
                                  </div>
                              </div>
                              <div>
                                  <button
                                      disabled={!submitBtnDisabled}
                                      className="blue-btn small m-l-0"
                                      onClick={this.submitAction.bind(this)}
                                  >
                                      Move Card
                                  </button>
                              </div>
                          </div>
                      </div>
                  </OutsideAlerter>
              </div>
          </div>
      );

  }

}
