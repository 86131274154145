import axios from "axios";
export async function getCurrentCustomer() {
  return axios({
    method: "get",
    url: "auth/customer/current",
    headers: {
      Authorization: localStorage.getItem("authorization"),
    }
  }).then((resp) => resp.data).catch((error)=> {
    return error.response.data;
  })
}

export async function getustomerPortalUrl() {
  return axios({
    method: "get",
    url: "payment/get-customer-portal-url",
    headers: {
      Authorization: localStorage.getItem("authorization"),
    }
  }).then((resp) => resp.data).catch((error)=> {
    return error.response.data;
  })
}