import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../../../header/credentialModalHeader";
import { actions as registerActions } from "../../../../store/reducers/register";
import PlanItem from "./item";
import "./styles.scss";

const Plans = ({ history }) => {
  const dispatch = useDispatch();
  const { chosenPlan = {}, plans = [], newUser = {} } = useSelector((state) => ({
    chosenPlan: state.register.chosenPlan,
    plans: state.register.plans,
    newUser: state.register.newUser
  }));

  useEffect(() => {
    if (newUser._id) {
      dispatch({
        type: registerActions.GET_ALL_PLANS,
        payload: {
          history,
        },
      });
    } else {
      history.push("/register");
    }
  }, []);

  const nextStep = () => {
    if (!chosenPlan.id) {
      return;
    }
    if (chosenPlan.id === 1) {
      dispatch({
        type: registerActions.CREATE_SUBSCRIPTION,
        payload: {
          chosenPlan,
          history
        }
      })
    } else {
      dispatch({
        type: registerActions.SET_STATE,
        payload: {
          chosenPlan: {
            ...chosenPlan,
            totalPrice: chosenPlan.price,
            chosenMethod: "monthly",
          },
          chosenMethod: "monthly"
        },
      });
      history.push("/register/payment");
    }
  };

  return (
    <div style={{ display: "block" }}>
      <Header />
      <div className="register-plans-container">
        <div className="page-title m-b-10 m-t-10 center-align text-black font-25 text-bold">
            Welcome to Orgzee, {`${newUser.firstName} ${newUser.lastName}`}
        </div>
        <div className="m-b-10 m-t-10 center-align font-20" style={{color: "#5F5F5F"}} >
            Choose a plan to get started:
        </div>
        <div className="register-plans-list">
          {plans && plans.map((item) => (
            <PlanItem key={item.title} plan={item} nextStep={nextStep} />
          ))}
        </div>
      </div>
    </div>
  );
};

Plans.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Plans);
