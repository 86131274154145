import PropTypes from "prop-types"
import styles from "./styles.module.scss"
const Tabs = ({list = [], current, open}) => {
    return (
        <div className={styles.tabsContainer}>
            {list.map((item) => 
                <div
                    key={item.key}
                    className={`${styles.element} ${item.key === current.key ? styles.current : ""}`}
                    onClick={() => open(item)}
                >
                    {item.title}
                </div>
            )}
        </div>
    );
};

Tabs.propTypes = {
    list: PropTypes.array.isRequired,
    current: PropTypes.object.isRequired,
    open: PropTypes.func.isRequired
};

export default Tabs;