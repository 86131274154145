import React, { Component } from "react";

export default class InviteMemberNotification extends Component {
  hideNotification() {
    this.props.hideNotification.call(this);
  }

  handleNotificationAction() {
    const { itemsDeleted = {}, board = {} } = this.props;
    const { item = [] } = itemsDeleted;
    const { _id: boardId } = board;
    if (item.length > 0 && boardId) {
      if (itemsDeleted.type == "label") {
        this.props.undoDelete.call(this, item, boardId);
      } else if (itemsDeleted.type == "card") {
        this.props.undoDeleteCard.call(this, item, boardId);
      } else if (itemsDeleted.type == "column") {
        this.props.undoDeleteColumn.call(this, item, boardId);
      } else if (itemsDeleted.type == "allCards") {
        const { data = [] } = itemsDeleted;
        this.props.undoDeleteAllCards.call(this, item, data, boardId);
      }
    }
    this.props.hideNotification.call(this);
  }

  render() {
    const { notificationActionTitle } = this.props;
    return (
      <div
        className="action-cont p-b-10 p-r-10 width-240px"
        style={{
          zIndex: 11,
          display: this.props.showInviteNotification ? "block" : "none",
          top: "0",
          right: "0",
        }}
      >
        <div className="header">
          <div className="disp-inline-block pull-left m-l-10">
            <div className="disp-inline-block dot dot-1" />
            <div className="disp-inline-block dot dot-2" />
            <div className="disp-inline-block dot dot-3" />
            <div className="disp-inline-block dot dot-4" />
          </div>
          <span
            className="close fa fa-times"
            onClick={this.hideNotification.bind(this)}
          />
        </div>
        <div className="message">
          <img
            style={{ width: "20px", verticalAlign: "sub" }}
            src="../../assets/images/robot.png"
            alt="robot-img"
          />
          {this.props.title}
        </div>
        {notificationActionTitle && (
          <div className="right-align">
            <button
              className="blue-btn small font-12 height-auto line-height-20"
              onClick={() => this.handleNotificationAction()}
            >
              {notificationActionTitle}
            </button>
          </div>
        )}
      </div>
    );
  }
}
