import React, {Component} from "react";

export default class ConfirmDialog extends Component {



    static showModel() {
        this.setState({"showConfirmDialog": true});
    }

    cancel(e) {

        e.stopPropagation();
        this.props.cancel.call(this);

    }

    confirm(e) {

        e.stopPropagation();
        this.props.confirm.call(this);
        this.props.cancel.call(this);

    }

    render() {

        const confirmDialogCont =
            <div className="backdrop" style={{"display": "block", "position": "fixed", "left": 0, "zIndex": "15"}}>
                <div className="popup small p-24">
                    <div className="header">
                        <span>
                            {this.props.title ? this.props.title : ""}
                            {" "}
                        </span>
                        <i className="close fa fa-times" aria-hidden="true" onClick={this.cancel.bind(this)} />
                    </div>
                    <div className="body">
                        <div className="control-box" style={{"marginTop": 15}}>
                            <div>
                                <button className="blue-btn small pull-right" onClick={this.confirm.bind(this)}>
                                    Remove
                                </button>
                            </div>
                            <div>
                                <button className="blue-btn small transparent pull-right" onClick={this.cancel.bind(this)}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        return (
            <div>
                {confirmDialogCont}
            </div>
        );

    }

}
