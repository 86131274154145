import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import MemberIcon from "../../member/memberIcon/MemberIcon";
import { useSelector, useDispatch } from "react-redux";
import * as listsActions from "../../../store/actions/lists";
import { actions as filterActions } from "../../../store/reducers/filters";
import { actions } from "../../../store/reducers/boards";

const BoardMembers = ({
  selectedMember,
  setSelectedMember,
  setMemberProfilOpen,
}) => {
  const { user, filters } = useSelector((state) => ({
    user: state.user,
    filters: state.filters,
  }));
  const dispatch = useDispatch();
  const [filterMemberArr, setFilterMemberArr] = useState([]);

  useEffect(() => {
    setFilterMemberArr(filters.filterMemberArr);
  }, [filters.filterMemberArr]);

  const showFilter = (member, type) => {
    if (type) {
      if (filterMemberArr[JSON.stringify(member.email)]) {
        if (
          filterMemberArr[JSON.stringify(member.email)].indexOf(type) === -1
        ) {
          filterMemberArr[JSON.stringify(member.email)].push(type);
        }
      } else {
        filterMemberArr[JSON.stringify(member.email)] = [];
        filterMemberArr[JSON.stringify(member.email)].push(type);
      }
      dispatch(
        listsActions.getFilterCard(filterMemberArr, user.activeBoard._id)
      );
      setFilterMemberArr(filterMemberArr);
      dispatch({
        type: filterActions.SET_STATE,
        payload: {
          filterMemberArr: { ...filters.filterMemberArr, ...filterMemberArr },
        },
      });
    } else {
      setMemberProfilOpen(true);
    }
  };

  const removeMember = (member) => {
    if(member.email && member.email.email) {
      dispatch({
        type: actions.REMOVE_MEMBER,
        payload: {member: member.email.email, board: user.activeBoard}
      })
    }

  };

  const removeMe = () => {
    console.log("removeMe")
  }

  return (
    <>
      {user.activeBoard &&
        user.activeBoard.members.map((member, i) => (
          <MemberIcon
            showFilter={showFilter}
            loggedInUser={user.activeBoard && user.loggedInUser}
            isOwner={
              user.activeBoard &&
              user.loggedInUser &&
              user.activeBoard.owner === user.loggedInUser.email
            }
            owner={user.activeBoard && user.activeBoard.owner}
            member={member}
            removeMember={() => removeMember(member)}
            removeMe={() => removeMe()}
            key={i}
            selectedMember={selectedMember}
            isUserLoggedIn={user.isUserLoggedin}
            isPublic={user.isPublic}
            onMemberSelect={() => setSelectedMember(member)}
          />
        ))}
    </>
  );
};

BoardMembers.propTypes = {
  selectedMember: PropTypes.object,
  setSelectedMember: PropTypes.func,
  isMemberProfileOpen: PropTypes.bool,
  setMemberProfilOpen: PropTypes.func,
};

export default BoardMembers;
