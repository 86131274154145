import React, { Component } from "react";
import { connect } from "react-redux";
import * as UserActions from "../../store/actions/user";
// import {hashHistory} from "react-router";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { emailValidation } from "../../helper";
import ResetPasswordC from "./resetComponents"

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordValid: true,
      isConfirmPasswordValid: true,
      email: "",
      btnDisable: false,
      newPassword: "",
    };
  }

  componentWillMount() {
    const { location, history } = this.props;
    if (
      location.search &&
      location.search.includes("email=") &&
      location.search.includes("code=")
    ) {
      const search = location.search.substring(1).split("&");
      let code = search.find((a) => a.includes("code="));
      code = code?.substring("code=".length) || "";
      let email = search.find((a) => a.includes("email="));
      email = email?.substring("email=".length) || "";
      const valid = emailValidation(email);
      if (!code || !valid) {
        history.push("/login");
        return;
      }
      this.setState({ OTP: code, email });
    } else {
      history.push("/login");
    }
  }

  confirmOTP() {
    const { email, OTP, newPassword } = this.state;
    const { history } = this.props;
    this.props.confirmOTP(email, OTP, newPassword).then((resp) => {
      const apiStatus = get(resp, "data.status");
      const statusCode = get(resp, "status");
      if (apiStatus !== "error" && statusCode === 200) {
        history.push("/login");
      }
    });
  }

  _renderForm = () => {
    return (
      <div>
        <div className="center-align m-t-20">
          <img src="../../assets/images/robot.png" />{" "}
        </div>
        <div className="m-b-10 m-t-10 center-align text-black font-25 text-bold">
          Change Your Password
        </div>
        <div style={{ width: "70%", margin: "auto" }}>
          <div className="center-align m-b-10">
            <input
              className={
                "inputbox full-width " +
                (this.state.isPasswordValid ? "" : "error")
              }
              type="password"
              placeholder="New Password"
              id="newPassword"
              onKeyUp={this.onPasswordChange.bind(this)}
            />
          </div>
          <div className="center-align m-b-10">
            <input
              className={
                "inputbox full-width " +
                (this.state.isConfirmPasswordValid ? "" : "error")
              }
              type="password"
              placeholder="Confirm Password"
              id="confirmPassword"
              onKeyUp={this.onPasswordChange.bind(this)}
            />
          </div>
          <div
            className={
              "blue-btn full-width m-l-0 m-r-0 p-l-0 p-r-0 sign-in " +
              (this.state.isFormSubmittable ? " " : "disabled")
            }
            onClick={this.confirmOTP.bind(this)}
          >
            Change Password
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <ResetPasswordC />
    );
  }
}

const mapDispatchToProps = {
  ...UserActions,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPassword));
