import React, { Component } from "react";
import ConfirmDialog from "../../confirmDialog/ConfirmDialog";

export default class MemberIcon extends Component {
  constructor() {
    super();
    this.state = {
      isFilterOpen: false,
    };
  }

  toggleFilterOptions(member) {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
    this.props.onMemberSelect.call(this, member);
  }

  showFilter(member, type, e) {
    this.props.showFilter.call(this, member, type, e);
    this.setState({ isFilterOpen: false });
  }

  removeMember = (selectedMember) => {
    this.setState({
      showConfirmDialog: true,
      isFilterOpen: false,
      confirmTitle: "Are you sure you want remove member from board?",
      confirm: this.props.removeMember.bind(this, selectedMember),
      cancelConfirmDialog: this.cancelConfirmDialog.bind(this),
    });
  };

  removeMe = (selectedMember) => {
    this.setState({
      showConfirmDialog: true,
      isFilterOpen: false,
      confirmTitle: "Are you sure you want to exit from team?",
      confirm: this.props.removeMe.bind(this, selectedMember),
      cancelConfirmDialog: this.cancelConfirmDialog.bind(this),
    });
  };

  cancelConfirmDialog() {
    this.setState({ showConfirmDialog: false });
  }

  _renderDeleteOption = () => {
    const { selectedMember, owner } = this.props;
    if (
      this.props.isOwner &&
      selectedMember.email &&
      selectedMember.email?.email != owner
    ) {
      return (
        <div
          className="action"
          onClick={this.removeMember.bind(this, selectedMember)}
        >
          Remove Member
        </div>
      );
    }
  };

  _renderExitOption = () => {
    const { selectedMember, owner } = this.props;
    if (
      !this.props.isOwner &&
      this.props.loggedInUser &&
      selectedMember.email &&
      selectedMember.email?.email == this.props.loggedInUser.email
    ) {
      return (
        <div
          className="action"
          onClick={this.removeMe.bind(this, selectedMember)}
        >
          Exit Team
        </div>
      );
    }
  };

  _renderConfirmDialog = () => {
    if (this.state.showConfirmDialog) {
      return (
        <ConfirmDialog
          title={this.state.confirmTitle}
          confirm={this.state.confirm}
          cancel={this.state.cancelConfirmDialog}
        />
      );
    }
  };

  render() {
    let icon, filterOption;
    const { member, selectedMember, isUserLoggedIn, isPublic } = this.props;
    if (member.email?.image) {
      icon = (
        <div
          className={
            "user-profile-image cover-image " +
            (member.inactive ? "inactive" : "")
          }
          onClick={() =>
            isUserLoggedIn && !isPublic && this.toggleFilterOptions(member)
          }
          style={{ backgroundImage: `url("${member.email?.image}")` }}
        />
      );
    } else {
      icon = (
        <div
          className={"member-prof " + (member.inactive ? "inactive" : "")}
          onClick={() =>
            isUserLoggedIn && !isPublic && this.toggleFilterOptions(member)
          }
        >
          {(member.email?.firstName ? member.email.firstName[0] : "") +
            (member.email?.lastName ? member.email.lastName[0] : "")}
        </div>
      );
    }
    if (
      this.state.isFilterOpen &&
      selectedMember &&
      selectedMember.email == member.email
    ) {
      filterOption = (
        <div
          className="action-cont memberIcon profile left-align"
          style={{
            display: "block",
            right: "-1px",
            zIndex: "10",
            boxShadow: "2px 4px 3px 0px rgb(204,204,204)",
            borderTop: "none",
          }}
        >
          <div className="action-list">
            <div
              className="action"
              onClick={this.showFilter.bind(this, member, "SA")}
            >
              Show Tasks Assigned
            </div>
            <div
              className="action"
              onClick={this.showFilter.bind(this, member, "SM")}
            >
              Show Mentioned
            </div>
            <div
              className="action"
              onClick={this.showFilter.bind(this, member, "SC")}
            >
              Show Created
            </div>
            <div
              className="action"
              onClick={this.showFilter.bind(this, member, null)}
            >
              View Profile
            </div>
            {this._renderDeleteOption()}
            {this._renderExitOption()}
          </div>
        </div>
      );
    } else {
      this.state.isFilterOpen = false;
    }
    return (
      <div
        className={
          "disp-inline-block pos-rel " +
          (this.state.isFilterOpen &&
          selectedMember &&
          selectedMember.email == member.email
            ? "memberIcon-highlight"
            : "")
        }
      >
        {icon}
        {filterOption}
        {this._renderConfirmDialog()}
      </div>
    );
  }
}
