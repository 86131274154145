export function userLoggedin() {

    return (dispatch) => {

        dispatch({"type": "USER_LOGGED_IN"});

    };

}
export function closeLogin() {

    return (dispatch) => {

        dispatch({"type": "CLOSE_LOGIN"});

    };

}
export function userLoggedOut() {

    return (dispatch) => {

        dispatch({"type": "USER_LOGGED_OUT"});

    };

}
export function showRegisterModal(email) {

    return (dispatch) => {

        dispatch({"type": "SHOW_REGISTER_MODAL", "payload": email});

    };

}
export function hideRegisterModal() {

    return (dispatch) => {

        dispatch({"type": "HIDE_REGISTER_MODAL"});

    };

}
export function showForgetPassword() {

    return (dispatch) => {

        dispatch({"type": "SHOW_FORGET_PASSWORD"});

    };

}
export function hideForgetPassword() {

    return (dispatch) => {

        dispatch({"type": "HIDE_FORGET_PASSWORD"});

    };

}
export function showChangePassword() {

    return (dispatch) => {

        dispatch({"type": "SHOW_CHANGEPASSWORD_MODAL"});

    };

}
export function hideChangePassword() {

    return (dispatch) => {

        dispatch({"type": "HIDE_CHANGEPASSWORD_MODAL"});

    };

}
export function openCardDetail(cardInfo, openDueDate) {

    return (dispatch) => {

        dispatch({"type": "SHOW_CARD_DETAIL", "payload": cardInfo, openDueDate});

    };

}
export function hideCardDetail() {

    return (dispatch) => {

        dispatch({"type": "HIDE_CARD_DETAIL"});

    };

}
export function openBoardOptions(left, top, board) {

    return (dispatch) => {

        dispatch({"type": "OPEN_BOARD_ACTION", top, left, board});

    };

}
export function closeBoardOptions() {

    return (dispatch) => {

        dispatch({"type": "HIDE_BOARD_ACTION"});

    };

}
export function openColumnOptions(left, top, board, column) {

    return (dispatch) => {

        dispatch({"type": "OPEN_COLUMN_ACTION", top, left, board, column});

    };

}
export function closeColumnOptions() {

    return (dispatch) => {

        dispatch({"type": "HIDE_COLUMN_ACTION"});

    };

}
export function openCardOptions(left, top, card) {

    return (dispatch) => {

        dispatch({"type": "OPEN_CARD_ACTION", top, left, card});

    };

}
export function closeCardOptions() {

    return (dispatch) => {

        dispatch({"type": "HIDE_CARD_ACTION"});

    };

}
export function openAddBoard() {

    return (dispatch) => {

        dispatch({"type": "OPEN_ADD_BOARD"});

    };

}
export function hideAddBoard() {

    return (dispatch) => {

        dispatch({"type": "HIDE_ADD_BOARD"});

    };

}
export function showDeleteBoard(board) {

    return (dispatch) => {

        dispatch({"type": "OPEN_DELETE_BOARD", board});

    };

}
export function hideDeleteBoard(board) {

    return (dispatch) => {

        dispatch({"type": "HIDE_DELETE_BOARD"});

    };

}
export function showDeleteNotification(board) {

    return (dispatch) => {

        dispatch({"type": "SHOW_DELETE_NOTIFICATION", board});

    };

}
export function hideDeleteNotification() {

    return (dispatch) => {

        dispatch({"type": "HIDE_DELETE_NOTIFICATION"});

    };

}

export function showEditBoard(board) {

    return (dispatch) => {

        dispatch({"type": "EDIT_BOARD_NAME", board});

    };

}
export function showEditColumnName(board, column) {

    return (dispatch) => {

        dispatch({"type": "EDIT_COLUMN_NAME", board, column});

    };

}
export function showEditCardName(card, e) {

    e.stopPropagation();
    return (dispatch) => {

        dispatch({"type": "EDIT_CARD_NAME", card});

    };

}

export function showEditBoardMain(board) {

    return (dispatch) => {

        dispatch({"type": "EDIT_BOARD_NAME_MAIN", board});

    };

}
export function showBlankBoard() {

    return (dispatch) => {

        dispatch({"type": "SHOW_BLANK_BOARD"});

    };

}
export function labelToggle(label) {

    return (dispatch) => {

        dispatch({"type": "LABEL_TOGGLE_DETAIL", "payload": label});

    };

}

export function showHideMoveCardToBoardModal(status = false, card = {}) {

    return (dispatch) => {

        dispatch({"type": "SHOW_HIDE_MOVE_CARD_MODAL", "payload": {status, card}});

    };

}

export function toggleBoardShareModal(status = false) {

    return (dispatch) => {

        dispatch({"type": "TOGGLE_SHARE_BOARD_MODAL", "payload": status});

    };

}

export function toggleBoardSettings(status = false) {

    return (dispatch) => {

        dispatch({"type": "TOGGLE_BOARD_SETTINGS_MODAL", "payload": status});

    };

}

export function toggleCardSelection(cardId = '') {
    return (dispatch) => {
        dispatch({"type": "SET_STATE", "payload": { cardId }});
    };
}
