import React, {Component} from "react";
import OutsideAlerter from "../Modal";

export default class Dropdown extends Component {

    constructor(props) {

        super(props);
        this.state = {
            "openOptions": false,
            "selectedValue": this.props.selectedValue,
        };

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if ("selectedValue" in nextProps) {

            this.setState({"selectedValue": nextProps.selectedValue});

        }

    }

    openOptions(e) {

        e.stopPropagation();
        this.setState((prevState) => ({
            openOptions: !prevState.openOptions
        }));

    }

    onSelect(e) {

        const selectedValue = JSON.parse(e.currentTarget.getAttribute("data-value"));
        this.props.onChange.call(this, selectedValue);
        this.setState({
            "selectedValue": selectedValue.value
                ? selectedValue.value
                : this.props.isMember && selectedValue["email"]["email"]
                    ? selectedValue["email"]["email"]
                    : "",
            "openOptions": false,
        });

    }

    render() {

        let options, photo;
        const {isShowPhoto, isMember, fullWidth} = this.props;

        const optionsList = this.props.options.map((item, i) => {

            if (isShowPhoto) {

                if (item.email && item.email.image) {

                    photo = <img src={item.email.image} className="profile-small" />;

                } else if (item.email) {

                    photo = (
                        <span className="profile-small">
                            {(item.email.firstName ? item.email.firstName[0] : "") +
                (item.email.lastName ? item.email.lastName[0] : "")}
                        </span>
                    );

                } else {

                    photo = <span className="profile-small" />;

                }

            }
            return (
                <div
                    className="left-align"
                    key={i}
                    data-value={JSON.stringify(item ? item : null)}
                    onClick={this.onSelect.bind(this)}
                >
                    {photo}
                    <span className="text">
                        {item.value
                            ? item.value
                            : isMember && item["email"]["email"]
                                ? item["email"]["email"]
                                : ""}
                    </span>
                </div>
            );

        });
        if (this.state.openOptions) {

            options = (
                <OutsideAlerter
                    handleClose={() => this.setState({"openOptions": false})}
                >
                    <div className={"options " + (fullWidth ? "full-width" : "width-55")}>
                        {optionsList}
                    </div>
                </OutsideAlerter>
            );

        }
        return (
            <div className="drop-down-cont pos-rel">
                <div
                    className={
                        "drop-down " +
            (fullWidth ? "full-width" : "width-55") +
            (isShowPhoto ? "" : " small")
                    }
                    onClick={this.openOptions.bind(this)}
                >
                    {this.state.selectedValue ? photo : ""}
                    <span className="text">
                        {this.state.selectedValue ? this.state.selectedValue : ""}
                    </span>
                    <i className="icon fa fa-angle-down" aria-hidden="true" />
                </div>
                {options}
            </div>
        );

    }

}
