import {useEffect, useState} from "react";
import { Formik, Form, Field } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { get } from "lodash";
import { connect } from "react-redux";
import * as UserActions from "../../../store/actions/user";
import Spinner from "../../spinner/spinner";
import Header from "../../header/credentialModalHeader";
import { emailValidation } from "../../../helper";
import "./style.scss";

const regGood = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
const regMedium = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/

const forgetSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Type 8 characters or more. Use letters, numbers and symbols.")
    .required("Required Field")
    .matches(
      regGood,
      "Type 8 characters or more. Use letters, numbers and symbols."
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

function ResetPassword({ history, location, ...props }) {
  const [code, setCode] = useState()
  const [email, setEmail] = useState()

  useEffect(()=>{
    if (
      location.search &&
      location.search.includes("email=") &&
      location.search.includes("code=")
    ) {
      const search = location.search.substring(1).split("&");
      let code = search.find((a) => a.includes("code="));
      code = code?.substring("code=".length) || "";
      let email = search.find((a) => a.includes("email="));
      email = email?.substring("email=".length) || "";
      const valid = emailValidation(email);
      if (!code || !valid) {
        history.push("/login");
        return;
      }
      setCode(code)
      setEmail(email)
    } else {
      history.push("/login");
    }
  }, [])
  const onUserForget = (values) => {
    props.confirmOTP(email, code, values.password).then((resp) => {
      const apiStatus = get(resp, "data.status");
      const statusCode = get(resp, "status");
      if (apiStatus !== "error" && statusCode === 200) {
        history.push("/login");
      }
    });
  };

  const validateConfirmPassword = (pass, value) => {
    let error = "";
    if (pass && value) {
      if (pass !== value) {
        error = "Password not matched";
      }
    }
    return error;
  };

  const checkPasswordStrength = (val) => {
    if (!val) {
      return null;
    }
    if (regGood.test(val)) {
      return (
        <div className="password-strength password-strength__good">
          <div className="elem" />
          <div className="elem" />
          <div className="elem" />
        </div>
      );
    } else if (regMedium.test(val)) {
      return (
        <div className="password-strength password-strength__medium">
          <div className="elem" />
          <div className="elem" />
          <div className="elem" />
        </div>
      );
    }else {
      return (
        <div className="password-strength password-strength__weak">
          <div className="elem" />
          <div className="elem" />
          <div className="elem" />
        </div>
      );
    }
  };

  return (
    <div className="forgot-password-container">
      <Header />
      <div>
        <div className="page-title">Forgot your password?</div>
        <div className="page-description">
          Don’t worry, it happens to the best of us.
        </div>
        <div className="form form-container">
          <Formik
            initialValues={initialValues}
            validationSchema={forgetSchema}
            onSubmit={onUserForget}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <Form className="formik-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="email">
                      Password{" "}
                      <small>
                        Type 8 characters or more. Use letters, numbers and
                        symbols.
                      </small>
                    </label>
                    <input
                      type="password"
                      name="password"
                      className={`${
                        errors.password && touched.password && "error"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {checkPasswordStrength(values.password)}
                    <span className="field-error">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      validate={(value) =>
                        validateConfirmPassword(values.password, value)
                      }
                    />
                    <span className="field-error">
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword}
                    </span>
                  </div>

                  <button
                    disabled={props.user.isLoading}
                    className={`submit-button`}
                    type="submit"
                  >
                    Reset Password
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <Spinner show={props.user.isLoading} />
    </div>
  );
}

const mapDispatchToProps = {
  ...UserActions,
};

const mapStateToProps = (state) => ({
  user: state.user,
  sendOTPSuccess: get(state, "user.sendOTPSuccess", false),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPassword));
