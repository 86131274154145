import styles from "./styles.module.scss";
import masterCard from "../../../../assets/images/master-card.png";
import visaCard from "../../../../assets/images/visa-card.png";
import PropTypes from "prop-types";
import moment from "moment";
import {useDispatch} from "react-redux";
import {actions as profileActions} from "../../../../store/reducers/profile";

const PaymentMethod = ({customer = {}}) => {
    const dispatch = useDispatch();

    const getustomerUpdatePaymentUrl = () => {
        dispatch({
            type: profileActions.GET_UPDATE_PAYMENT_URL
        })
    }

    if (customer?.sources?.data?.length === 0) {
        return null;
    }
    const source = customer.sources.data[0];
    const getExpMonth = (exp) => {
        if (exp<10) {
            return `0${exp}`;
        }
        return exp;
    };
    const getExpYear = (exp = "") => {
        return `${exp}`.substring(2);
    };

    const getDate = () => {
        return moment(customer.subscriptions.current_period_end * 1000).format('MMM DD, YYYY');
    };

    return (
        <div>
            <div className={styles.PaymentMethod} >
            <div className={styles.header}>
                Payment Method
            </div>
            <div className={styles.content} >
                <p className={styles.name}>
                    {customer.name}
                </p>
                <p className={styles.last4}>
                    {`xxxx xxxx xxxx ${source.last4}`}
                </p>
                <div className={styles.dateAndType}>
                    <div>
                        {`${getExpMonth(source.exp_month)}/${getExpYear(source.exp_year)}`}
                    </div>
                    <div>
                        {source.brand === "MasterCard" && <img src={masterCard} /> }
                        {source.brand === "VisaCard" && <img src={visaCard} /> }
                    </div>
                </div>
            </div>
            <div className={styles.updatePaymentMethod} >
                <a href="javascript:void(0);" onClick={getustomerUpdatePaymentUrl}>Update Payment Method</a>
            </div>
        </div>
        <div className={styles.nextPayment}>
            <span>Next Payment</span>
            <span>Your next payment is due on {getDate()}.</span>
        </div>

        </div>
    );
};

PaymentMethod.propTypes = {
    customer: PropTypes.object,
};


export default PaymentMethod;
