import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MentionInput from "../../../../../widget/mentions/mentions";
import { editCardDetail } from "../../../../../store/actions/lists";
import { openCardDetail } from "../../../../../store/actions/UIActions";
import { useOutsideAlerter, useClickEnter } from "../../../../../hooks";
import { get } from 'lodash';
import { withRouter } from "react-router-dom";
import { mentionText } from "../../../../../components/common";

const CardTitle = ({ card, canEditBoard = false}) => {
  const { activeBoard, boardType, user } = useSelector((state) => ({
    user: state.user,
    activeBoard: state.user.activeBoard,
    boardType: state.user.activeBoard.boardType,
  }));
  const titleRef = useRef();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [cardName, setCardName] = useState(card.title);
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.input.focus();
    } else if (!isEditing && card._id === titleRef.current.id){
      onEditCardDetails();
    }
    useClickEnter(isEditing && card._id === titleRef.current.id, setEditing)
  }, [isEditing]);

  const openEdit = () => {
    if(canEditBoard) {
      setEditing(true)
    }
  }

  const onEditCardDetails = () => {
    setEditing(false);
    if (card.title === cardName) {
      return ;
    }
    if (cardName) {
      dispatch(
        editCardDetail(card, {
          title: cardName,
        })
      );
    } else {
      const title = card.title
        ? card.title
        : boardType.id === "2"
        ? "Add Name"
        : "Enter Card Title";
      setCardName(title);
    }

  };

  const onOpenCardDetail = () => {
    const boardId = get(card, 'board');
    const columnId = get(card, 'column');
    const cardId = get(card, '_id');
    if (!boardId || !columnId || !cardId) return;
    window.history.pushState(null, null, `${user.ishSharedView ? "/#/shared/":"/#/"}board/${boardId}/column/${columnId}/card/${cardId}`);
    dispatch(openCardDetail(card, true));
  };

  useOutsideAlerter(titleRef, setEditing);

  return (
    <div
      className={`name-cont ${
        boardType && boardType.id === "1" ? "normal" : "sales"
      }`}
    >
      <div className="name" ref={titleRef} id={`${card._id}`}>
        {!isEditing ? (
          <span
            className={!card.title ? "text-gray" : ""}
            onClick={() => openEdit()}
            style={{ display: !isEditing ? "inline-block" : "none" }}
          >
            {!card.title? <span style={{marginBottom: 0}}>{boardType.id === "2"
              ? "Add Name1234"
              : "Enter Card Title"}</span>: <span dangerouslySetInnerHTML={{
             __html :  mentionText(card.title)
           }}/>}
         </span>
         
        ) : (
          <MentionInput
            isMember
            isShowPhoto
            options={activeBoard.members || []}
            toDisplayProp={["email", "id"]}
            ref={inputRef}
            key={`${card._id} ${card.title}`}
            className="edit-text-column"
            defaultValue={card.title}
            onBlur={() => onEditCardDetails()}
            onChange={(e) => setCardName(e.target.value)}
            style={{ display: isEditing ? "inline-block" : "none" }}
          />
        )}
      </div>
      <i
        className="open-detail"
        aria-hidden="true"
        onClick={onOpenCardDetail}
      />
      <div className="cost"> {card.prize ? card.prize : ""} </div>
    </div>
  );
};

CardTitle.propTypes = {
  card: PropTypes.object,
  canEditBoard: PropTypes.bool,
  history: PropTypes.object
};

export default withRouter(CardTitle);
