import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AntSwitch from "../../../components/antSwitch";
import { updateUser } from '../../../store/actions/user';
import styles from "./styles.module.scss";

const Notifications = () => {
    const dispatch = useDispatch();
    const { loggedInUser = {} } = useSelector((state) => ({ loggedInUser: state.user.loggedInUser }))
    const [currentUser, setCurrentUser ] = useState(loggedInUser);
    const handleChange = (n , v) => {
        setCurrentUser({...currentUser, [n]: v});
        const formData = new FormData();
        formData.append("firstName", currentUser.firstName);
        formData.append("lastName", currentUser.lastName);
        formData.append("notifyAssigned", n === "notifyAssigned" ? v : currentUser.notifyAssigned);
        formData.append("notifymentioned", n === "notifymentioned" ? v : currentUser.notifymentioned);
        formData.append("notifyReminder", n === "notifyReminder" ? v : currentUser.notifyReminder);
        formData.append("notifyDailyDigest", n === "notifyDailyDigest" ? v : currentUser.notifyDailyDigest);
        formData.append("notifyDesktop", n === "notifyDesktop" ? v : currentUser.notifyDesktop);
        dispatch(updateUser(formData));
    };
    return (
        <div className={styles.Notifications}>
            <p className={styles.categoryTitle}>
                Email Notifications
            </p>
            <div className={styles.elements}>
                <AntSwitch
                    checked={currentUser.notifyAssigned}
                    onChange={handleChange}
                    name="notifyAssigned"
                    label="Email me everytime a task is assigned to me"
                />
                <AntSwitch
                    checked={currentUser.notifymentioned}
                    onChange={handleChange}
                    name="notifymentioned"
                    label="Email me everytime my name is mentioned"
                />
                <AntSwitch
                    checked={currentUser.notifyReminder}
                    onChange={handleChange}
                    name="notifyReminder"
                    label="Email me a reminder when a task is due thatʼs assigned to me"
                />
                <AntSwitch
                    checked={currentUser.notifyDailyDigest}
                    onChange={handleChange}
                    name="notifyDailyDigest"
                    label="Email me a daily digest of the above notifications"
                />
            </div>
            {/* <p className={styles.categoryTitle}>
                Desktop Notifications
            </p>
            <div className={styles.elements}>
                <AntSwitch
                    checked={currentUser.notifyDesktop}
                    onChange={handleChange}
                    name="notifyDesktop"
                    label="Enable desktop notifications to see any of the above email notifications directly in your browser"
                />
            </div> */}
        </div>
    )
}

export default Notifications;