import React, { Component } from "react";
import Header from "../header/credentialModalHeader";
import { resendOTP } from "./service";
import { connect } from "react-redux";
import * as UserActions from "../../store/actions/user";
import Spinner from "../spinner/spinner";
import { get } from "lodash";
import PropTypes from "prop-types";

class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onOTPEnter = (event) => {
    if (event.currentTarget.value.length === 1) {
      if (event.currentTarget.nextSibling) {
        event.currentTarget.nextSibling.focus();
      } else {
        const list = document.getElementsByName("otp");
        const otp = Object.values(list)
          .map((ele) => ele.value)
          .join("");
        this.props.fetchUser(this.props.email, otp).then((otp) => {
          const status = get(otp, "status");
          const token = get(otp, "data.token");
          if (status === 200 && token) {
            const { history } = this.props;
            history.push("/change-password");
          }
        });
      }
    }
  };

  resendOTP = () => {
    resendOTP(this.props.email);
  };

  render() {
    return (
      <div className="backdrop" style={{ display: "block" }}>
        <div className="popup fullscreen">
          <div
            style={{
              display: "block",
              backgroundColor: "#2A985B",
              color: "white",
              width: "80%",
              lineHeight: "25px",
            }}
            className="remember-me m-b-10 m-t-20 p-10 center-align"
          >
            <div className="font-25">Check Your Email</div>
            <div className="font-15">
              {`We sent a 4 digit confirmation code to ${this.props.email}`}
            </div>
            <div className="font-15">
              Enter your code below to unlock Orgzee.
            </div>
            <div className="font-13">
              Did'nt receive email? Check your spam folder.
            </div>
            <div
              style={{ textDecoration: "underline" }}
              onClick={this.resendOTP.bind(this)}
              className="font-15 cursor-pointer"
            >
              Send again
            </div>
          </div>
          <div className="body center-align fullscreen">
            <Header />
            <div className="control-box">
              <div>
                <div className="center-align m-t-20">
                  <img src="../../assets/images/robot.png" />{" "}
                </div>
                <div className="m-b-10 m-t-10 center-align text-black font-25 text-bold">
                  Enter 4 Digit Code
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: 200,
                    margin: "auto"
                  }}
                >
                  <input
                    style={{ width: 35, height: 35 }}
                    name="otp"
                    maxLength="1"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    tabIndex="1"
                    className="inputbox"
                    type="text"
                    id="box-1"
                    onChange={this.onOTPEnter.bind(this)}
                  />
                  <input
                    style={{ width: 35, height: 35 }}
                    name="otp"
                    maxLength="1"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    tabIndex="2"
                    className="inputbox"
                    type="text"
                    id="box-2"
                    onChange={this.onOTPEnter.bind(this)}
                  />
                  <input
                    style={{ width: 35, height: 35 }}
                    name="otp"
                    maxLength="1"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    tabIndex="3"
                    className="inputbox"
                    type="text"
                    id="box-3"
                    onChange={this.onOTPEnter.bind(this)}
                  />
                  <input
                    style={{ width: 35, height: 35 }}
                    name="otp"
                    maxLength="1"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                    tabIndex="4"
                    className="inputbox"
                    type="text"
                    id="box-4"
                    onChange={this.onOTPEnter.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer" style={{ borderTop: "none" }}>
            <div className="remember-me m-b-10">
              <span> Didn't receive it? </span>
              <span className="color-blue">
                <a onClick={this.resendOTP.bind(this)}>Send again</a>
              </span>
            </div>
          </div>
        </div>
        <Spinner show={this.props.user.isLoading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  email: get(state, "user.data.user.email"),
});
const mapDispatchToProps = { ...UserActions };

Otp.propTypes = {
  fetchUser: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.user,
  email: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
