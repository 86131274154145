export default function user(
  state = {
    isLoading: false,
    loadingCurrentUser: true,
    error: false,
    boardList: [],
    isUserLoggedin: false,
    scrollHeight: 140,
  },
  action
) {
  var state = Object.assign(state, {
    isLoading: false,
    error: false,
    message: null,
  });
  switch (action.type) {
    case "GET_USER_START":
      return { ...state, isLoading: true, loadingCurrentUser: true };
    case "REGISTER_USER_START":
    case "INVITE_MEMBER_START":
    case "UPDATE_USER_START":
    case "MOVE_TO_TOP_BOTTOM":
    case "UPDATE_CHECKLIST":
    case "DELETE_CHECKLIST":
    case "ADD_CHECKLIST":
    case "UPDATE_BOARD": {
      return { ...state, isLoading: true };
    }
    case "REGISTER_USER_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isRegistered: true,
        registeredEmail: action.registeredEmail,
        showRegister: false,
      };
    }
    case "REGISTER_USER_FAIL": {
      return { ...state, error: action.payload, isLoading: false };
    }
    case "SEND_OTP_START": {
      return { ...state, isLoading: true };
    }
    case "SEND_OTP_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        message: action.payload.message,
        isLoading: false,
        sendOTPSuccess: true,
      };
    }
    case "SEND_OTP_FAIL": {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        sendOTPSuccess: false,
      };
    }
    case "CONFIRM_OTP_START": {
      return { ...state, isLoading: true };
    }
    case "CONFIRM_OTP_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        confirmOTPSuccess: true,
        showForgetPassword: false,
      };
    }
    case "CONFIRM_OTP_FAIL": {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        confirmOTPSuccess: false,
      };
    }
    case "UPDATE_USER_SUCCESS": {
      state.activeBoard.members.forEach((member, i) => {
        if (member.email?.email === action.payload.data.email) {
          state.activeBoard.members[i].email.image = action.payload.data.image;
        }
      });
      return {
        ...state,
        loggedInUser: action.payload.data,
        isLoading: false,
        activeBoard: state.activeBoard,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case "REMOVE_MEMBER_SUCCESS": {
      state.activeBoard.members = state.activeBoard.members.filter((member) => {
        if (member.email?.email !== action.email) {
          return member;
        }
      });
      return { ...state };
    }
    case "INVITE_MEMBER_SUCCESS":
    case "INVITE_MEMBER_FAIL":
    case "UPDATE_USER_ERROR": {
      return {
        ...state,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case "FETCH_USER_START": {
      return { ...state, isLoading: true };
    }
    case "FETCH_USER_END": {
      return { ...state, isLoading: false };
    }
    case "FETCH_USER_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        isRegistered: false,
        isLoading: false,
        loggedInUser: action.payload.user,
      };
    }
    case "GET_USER_SUCCESS": {
      return {
        ...state,
        isUserLoggedin: true,
        loadingCurrentUser: false,
        loggedInUser: action.payload.data,
      };
    }
    case "GET_ALL_USER_START": {
      return { ...state, isLoading: true };
    }
    case "GET_ALL_USER_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        registeredAppUsers: action.payload.data.users,
      };
    }
    case "GET_ALL_USER_ERROR": {
      return { ...state, isLoading: false };
    }
    case "GET_USER_ERROR": {
      return { ...state, isLoading: false, loadingCurrentUser: false };
    }
    case "FETCH_USER_FAIL": {
      return { ...state, error: action.payload, isLoading: false };
    }
    case "CHANGE_PASSWORD_START": {
      return { ...state, isLoading: true };
    }
    case "CHANGE_PASSWORD_SUCCESS": {
      return {
        ...state,
        isUserLoggedin: true,
        data: action.payload,
        passwordChangeMessage: action.payload,
        isLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case "CHANGE_PASSWORD_FAIL": {
      return {
        ...state,
        error: action.payload,
        passwordChangeMessage: action.payload,
        sLoading: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case "CLOSE_LOGIN": {
      return { ...state, isLoginClose: true };
    }
    case "USER_LOGGED_IN": {
      return {
        ...state,
        isUserLoggedin: true,
        isRegistered: false,
        isGoogleAuthSuccess: false,
        tokenId: "",
      };
    }
    case "SHOW_REGISTER_MODAL": {
      localStorage.setItem("authorization", "");
      return {
        ...state,
        showRegister: true,
        isUserLoggedin: false,
        toBeRegisteredEmail: action.payload,
      };
    }
    case "HIDE_REGISTER_MODAL": {
      return { ...state, showRegister: false };
    }
    case "SHOW_FORGET_PASSWORD": {
      return { ...state, showForgetPassword: true };
    }
    case "HIDE_FORGET_PASSWORD": {
      return { ...state, showForgetPassword: false };
    }
    case "SHOW_CHANGEPASSWORD_MODAL": {
      return { ...state, showPasswordChange: true };
    }
    case "HIDE_CHANGEPASSWORD_MODAL": {
      return { ...state, showPasswordChange: false };
    }
    case "SHOW_CARD_DETAIL": {
      return {
        ...state,
        selectedCardDetail: action.payload,
        openDueDate: action.openDueDate,
      };
    }
    case "UPDATE_CARD_DETAIL": {
      return {
        ...state,
        selectedCardDetail: action.payload,
      };
    }
    case "HIDE_CARD_DETAIL": {
      return { ...state, selectedCardDetail: null, openDueDate: false };
    }
    case "OPEN_BOARD_ACTION": {
      return {
        ...state,
        boardActionTop: action.top + 10 + "px",
        boardActionLeft: action.left - 10 + "px",
        isBoardActionOpen: true,
        board: action.board,
      };
    }
    case "HIDE_BOARD_ACTION": {
      return { ...state, isBoardActionOpen: false };
    }
    case "OPEN_COLUMN_ACTION": {
      return {
        ...state,
        columnActionTop: action.top + 10 + "px",
        columnActionLeft: action.left - 10 + "px",
        isColumnActionOpen: true,
        board: action.board,
        column: action.column,
      };
    }
    case "HIDE_COLUMN_ACTION": {
      return { ...state, isColumnActionOpen: false };
    }
    case "OPEN_CARD_ACTION": {
      return {
        ...state,
        cardActionTop: action.top + 10 + "px",
        cardActionLeft: action.left - 10 + "px",
        isCardActionOpen: true,
        card: action.card,
      };
    }
    case "HIDE_CARD_ACTION": {
      return { ...state, isCardActionOpen: false };
    }
    case "OPEN_ADD_BOARD": {
      return { ...state, showAddBoard: true };
    }
    case "HIDE_ADD_BOARD": {
      return { ...state, showAddBoard: false };
    }
    case "CREATE_BOARD_START":
    case "UPDATE_BOARD_START": {
      return { ...state, isLoading: true };
    }
    case "CREATE_BOARD_SUCCESS": {
      const newboards = action.payload.data.boards;
      const newboard = newboards[newboards.length - 1];
      return {
        ...state,
        data: action.payload,
        boardList: newboards,
        isLoading: false,
        showAddBoard: false,
        newboard,
      };
    }
    case "CREATE_BOARD_FAIL":
    case "UPDATE_BOARD_FAIL": {
      return { ...state, error: action.payload, isLoading: false };
    }
    case "ADD_COLUMN_START": {
      return { ...state, isLoading: true };
    }
    case "ADD_COLUMN_SUCCESS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        data: action.payload,
        activeBoard: activeBoard,
        isLoading: false,
        lists: action.lists,
        unFilteredList: activeBoard,
      };
    }
    case "SET_ACTIVE_BOARD": {
      return { ...state, activeBoard: action.board };
    }
    case "ADD_COLUMN_FAIL": {
      return { ...state, error: action.payload, isLoading: false };
    }
    case "ADD_CARD_START": {
      return { ...state, isLoading: true };
    }
    case "ADD_CARD_SUCCESS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        unFilteredList: activeBoard,
        data: action.payload,
        activeBoard: activeBoard,
        isLoading: false,
        lists: action.lists,
      };
    }

    case "BOARD_SETTINGS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        data: action.payload,
        activeBoard: activeBoard,
        isLoading: false,
        lists: action.lists,
        unFilteredList: activeBoard
      };
    }
    case "ADD_CARD_FAIL": {
      return { ...state, error: action.payload, isLoading: false };
    }
    case "ADD_TASK_START":
    case "EDIT_NOTE_START":
    case "ADD_COMMENT_START":
    case "DELETE_NOTE_START":
    case "DELAY_TASK_START":
    case "ADD_CARD_ATTACHMENT_START":
    case "DELETE_ATTACHMENT_START":
    case "SEARCH_CARD_START":
    case "FILTER_CARD_START":
    case "GET_BOARD_HISTORY_START":
    case "SEARCH_LABEL_START":
    case "CREATE_NEW_LABEL":
    case "COMPLETE_TASK_START": {
      return { ...state, isLoading: true };
    }
    case "ADD_TASK_SUCCESS":
    case "ADD_CHECKLIST_SUCCESS":
    case "UPDATE_CHECKLIST_SUCCESS":
    case "DELETE_CHECKLIST_SUCCESS":
    case "ADD_COMMENT_SUCCESS":
    case "EDIT_NOTE_SUCCESS":
    case "DELETE_NOTE_SUCCESS":
    case "DELAY_TASK_SUCCESS":
    case "DELETE_ATTACHMENT_SUCCESS":
    case "ADD_CARD_ATTACHMENT_SUCCESS":
    case "SEARCH_CARD_SUCCESS":
    case "FILTER_CARD_SUCCESS":
    case "COMPLETE_TASK_SUCCESS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        data: action.payload,
        activeBoard: activeBoard,
        isLoading: false,
        lists: action.lists,
        
      };
    }
    case "ADD_TASK_FAIL":
    case "UPDATE_CHECKLIST_FAIL":
    case "DELETE_CHECKLIST_FAIL":
    case "ADD_CHECKLIST_FAIL":
    case "ADD_COMMENT_FAIL":
    case "EDIT_NOTE_FAIL":
    case "DELETE_NOTE_FAIL":
    case "DELAY_TASK_FAIL":
    case "ADD_CARD_ATTACHMENT_FAIL":
    case "DELETE_ATTACHMENT_FAIL":
    case "SEARCH_CARD_FAIL":
    case "FILTER_CARD_FAIL":
    case "COMPLETE_TASK_FAIL": {
      return { ...state, error: action.payload, isLoading: false };
    }
    case "GET_BOARD_START": {
      return { ...state, isLoading: true };
    }
    case "GET_BOARD_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        boardList: action.payload.data.boards,
        isLoading: false,
      };
    }
    case "GET_BOARD_FAIL": {
      return { ...state, data: action.payload, isLoading: false };
    }
    case "GET_BOARD_HISTORY_SUCCESS": {
      return {
        ...state,
        boardHistoryList: action.payload.data,
        isLoading: false,
      };
    }
    case "GET_BOARD_HISTORY_FAIL": {
      return { ...state, boardHistoryList: action.payload, isLoading: false };
    }
    case "GET_BOARD_TYPES_START": {
      return { ...state, isLoading: true };
    }
    case "GET_BOARD_TYPES_SUCCESS": {
      return { ...state, boardTypes: action.payload.data, isLoading: false };
    }
    case "GET_BOARD_TYPES_FAIL": {
      return { ...state, data: action.payload, isLoading: false };
    }
    case "OPEN_DELETE_BOARD": {
      return { ...state, board: action.board, showDeleteBoard: true };
    }
    case "HIDE_DELETE_BOARD": {
      return { ...state, showDeleteBoard: false };
    }
    case "DELETE_BOARD_START": {
      return { ...state, isLoading: true };
    }
    case "DELETE_BOARD_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        boardList: action.payload.data.boards,
        isLoading: false,
        showDeleteBoard: false,
        isBoardActionOpen: false,
      };
    }
    case "DELETE_BOARD_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        showDeleteBoard: false,
        isBoardActionOpen: false,
      };
    }
    case "DELETE_COLUMN_START": {
      return { ...state, isLoading: true };
    }
    case "DELETE_COLUMN_SUCCESS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        data: action.payload,
        boardList: action.boardList,
        isLoading: false,
        activeBoard: activeBoard,
        unFilteredList: activeBoard,
        showDeleteBoard: false,
        isColumnActionOpen: false,
        lists: action.lists,
      };
    }
    case "DELETE_COLUMN_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isColumnActionOpen: false,
      };
    }
    case "DELETE_ALL_CARDS_SUCCESS": {
      const activeBoard = Object.assign({}, state.activeBoard);
      activeBoard.columns = activeBoard.columns.map((item) => {
        if (action.data._id == item._id) {
          item.cards = [];
        }
        return item;
      });
      return { ...state, isLoading: false };
    }
    case "DELETE_CARD_START": {
      return { ...state, isLoading: true };
    }
    case "DELETE_CARD_SUCCESS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        activeBoard: activeBoard,
        unFilteredList: activeBoard,
        isCardActionOpen: false,
        lists: action.lists,
      };
    }
    case "DELETE_CARD_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isCardActionOpen: false,
      };
    }
    case "UNDO_DELETE_BOARD_START": {
      return { ...state, isLoading: true };
    }
    case "UNDO_DELETE_BOARD_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        boardList: action.payload.data.boards,
        isLoading: false,
        showDeleteNotification: false,
        isBoardActionOpen: false,
      };
    }
    case "UNDO_DELETE_BOARD_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        showDeleteNotification: false,
        isBoardActionOpen: false,
      };
    }
    case "EDIT_BOARD_NAME_START": {
      return { ...state, isLoading: true };
    }
    case "EDIT_BOARD_NAME_SUCCESS": {
      const activeBoard = sortColumn(action.payload.data);
      return {
        ...state,
        data: action.payload,
        activeBoard: activeBoard,
        unFilteredList: activeBoard,
        boardList: action.boardList,
        isLoading: false,
        editBoard: null,
        editBoardMain: null,
        lists: action.lists,
      };
    }
    case "EDIT_BOARD_NAME_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        editBoard: null,
        editBoardMain: null,
      };
    }
    case "EDIT_COLUMN_NAME_START": {
      return { ...state, isLoading: true };
    }
    case "EDIT_COLUMN_NAME_SUCCESS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        data: action.payload,
        activeBoard: activeBoard,
        unFilteredList: activeBoard,
        isLoading: false,
        editColumn: null,
        lists: action.lists,
      };
    }
    case "EDIT_COLUMN_NAME_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        editColumn: null,
      };
    }
    case "EDIT_CARD_NAME_START": {
      return { ...state, isLoading: true };
    }
    case "EDIT_CARD_NAME_SUCCESS": {
      const activeBoard = sortColumn(action.board);
      return {
        ...state,
        data: action.payload,
        activeBoard: activeBoard,
        isLoading: false,
        editCard: null,
        lists: action.lists,
        unFilteredList: activeBoard
      };
    }
    case "EDIT_CARD_NAME_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        editCard: null,
      };
    }
    case "SHOW_BOARD_DETAIL_START": {
      return { ...state, isLoading: true };
    }
    case "SHOW_BOARD_DETAIL_SUCCESS": {
      const activeBoard = sortColumn(action.payload.data);
      const obj = {
        ...state,
        data: action.payload,
        activeBoard: activeBoard,
        boardList: action.boardList,
        isLoading: false,
        editBoard: null,
        editBoardMain: null,
        lists: action.lists,
        currentMember: action.currentMember,
        labelAssignedId: action.labelAssignedId,
      };
      if (!action.labelAssignedId.length) {
        obj.unFilteredList = activeBoard;
      }
      return obj;
    }
    case "SHOW_BOARD_DETAIL_FAIL": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        editBoard: null,
        editBoardMain: null,
      };
    }
    case "SHOW_DELETE_NOTIFICATION": {
      return { ...state, board: action.board, showDeleteNotification: true };
    }
    case "HIDE_DELETE_NOTIFICATION": {
      return { ...state, showDeleteNotification: false };
    }
    case "SHOW_BLANK_BOARD": {
      return { ...state, activeBoard: null };
    }
    case "EDIT_BOARD_NAME": {
      return { ...state, editBoard: action.board };
    }
    case "EDIT_COLUMN_NAME": {
      return { ...state, editColumn: action.column };
    }
    case "EDIT_CARD_NAME": {
      return { ...state, editCard: action.card };
    }
    case "EDIT_BOARD_NAME_MAIN": {
      return { ...state, editBoardMain: action.board };
    }
    case "MOVE_CARD": {
      const activeBoard = sortColumn(action.board);
      const unFilteredList = JSON.parse(JSON.stringify(activeBoard));

      if (state.labelAssignedId.length > 0) {
        activeBoard.columns = activeBoard.columns.map((column) => {
          const filteredCards = column.cards.filter((card) =>
            card.labelAssigned.some((label) =>
              state.labelAssignedId.includes(label._id)
            )
          );
          return { ...column, cards: filteredCards };
        });
      }
      return {
        ...state,
        activeBoard:
          state.labelAssignedId.length > 0 ? activeBoard : unFilteredList,
        unFilteredList: unFilteredList,
        lists: action.lists,
      };
    }
    case "MOVE_LIST": {
      return { ...state, lists: action.lists };
    }
    case "TOGGLE_DRAGGING": {
      return { ...state, isDragging: action.isDragging };
    }

    case "CHANGE_BOARD":
      {
        const { activeBoard } = state;
        if (activeBoard._id === action.payload._id) {
          const activeBoard = sortColumn(action.payload);
          return { ...state, activeBoard: activeBoard };
        }
      }
      break;
    case "SEARCH_LABEL_SUCCESS": {
      return { ...state, labelLists: action.payload, isLoading: false };
    }
    case "LABEL_CREATE_SUCCESS": {
      return { ...state, labelCreate: action.payload, isLoading: false };
    }
    case "DELETED_ITEMS_SUCCESS": {
      return { ...state, itemsDeleted: action.payload, isLoading: false };
    }
    case "HIDE_LOADER": {
      return { ...state, isLoading: false };
    }
    case "NOTIFY_DELETE_BOARD_SUCCESS": {
      let { activeBoard } = state;
      const { boardList } = state;
      const filterBoardList = boardList.filter((board) => {
        if (!board.isDeleted && action.payload.data !== board._id) return board;
      });
      if (activeBoard._id === action.payload.data) {
        activeBoard = filterBoardList.length ? filterBoardList[0] : null;
      }
      return {
        ...state,
        data: action.payload,
        boardList: filterBoardList,
        activeBoard: activeBoard,
      };
    }
    case "GET_ALL_USER_BY_NAME": {
      return { ...state, isLoading: false, userListByName: action.payload };
    }

    case "TOGGLE_SHOW_TITLE_ONLY": {
      const { activeBoard } = state;
      activeBoard.showTitleOnly = action.payload;
      return { ...state, activeBoard: activeBoard };
    }

    case "ADD_CHECK_LIST": {
      const { activeBoard } = state;
      activeBoard.showTitleOnly = action.payload;
      return { ...state, activeBoard: activeBoard, isLoading: false };
    }

    case "LABEL_TOGGLE_DETAIL": {
      return { ...state, labelToggleDetails: action.payload };
    }

    case "GOOGLE_AUTH_SUCCESS": {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isGoogleAuthSuccess: true,
        registeredEmail: action.registeredEmail,
        tokenId: action.tokenId,
      };
    }

    case "SHOW_HIDE_MOVE_CARD_MODAL": {
      return {
        ...state,
        showHideMoveCardModal: action.payload.status,
        card: action.payload.card,
      };
    }

    case "TOGGLE_SHARE_BOARD_MODAL": {
      return { ...state, toggleShareBoardModal: action.payload };
    }

    case "TOGGLE_BOARD_SETTINGS_MODAL": {
      return { ...state, toggleBoardSettings: action.payload };
    }

    case "ENABLED_BOARD_PUBLIC": {
      return { ...state, isPublic: action.payload };
    }

    case "SET_STATE":
      return { ...state, ...action.payload };

    default:
      return state;
  }
  return state;
}

function sortColumn(board) {
  if (board) {
    board.columns.sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });
  }
  return board;
}
