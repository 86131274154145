import React, {Component} from "react";
import OutsideAlerter from "../../../widget/Modal";

export default class ShareBoardModal extends Component {

    constructor(props) {

        super(props);
        this.state = {
            "isCopy": false
        };  
    
    }

    copyBoardUrl() {

        const textField = document.createElement("textarea");
        textField.innerText = `${window.location.origin}/#/shared/board/${this.props.activeBoard._id}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        this.setState({"isCopy": true}, () => {

            setTimeout(() => {

                this.setState({"isCopy": false});
            
            }, 1000);
        
        });
    
    }

    render() {

        const {handleClose, isPublic} = this.props;
        const {isCopy} = this.state;
        return (
            <div className="backdrop" style={{"display": this.props.toggleShareBoardModal ? "block" : "none"}}>
                <div className="popup share-board small p-b-20">
                    <OutsideAlerter handleClose={() => handleClose()}>
                        <div className="header b-b-0 p-t-b-20 m-b-25">
                            <span className="move-card">
                                Share Board
                            </span>
                        </div>
                        <div className="body">
                            <div className="control-box">
                                <div className="share-board-desc">
                                    The URL below will allow anyone to view this board without being able to edit it. If you want the viewer to be able to edit the board, add them as a team member instead.
                                </div>
                                <div className="center-align m-t-20 m-b-10">
                                    <input 
                                        type="text" 
                                        disabled 
                                        className="inputbox full-width " 
                                        placeholder="Board url"
                                        value={`${window.location.origin}/#/shared/board/${this.props.activeBoard._id}`}
                                    />
                                </div>
                                {
                                    isPublic && <div>
                                        <button 
                                            className="blue-btn small m-l-0 m-t-20" 
                                            onClick={this.copyBoardUrl.bind(this)}
                                        >
                                            {isCopy ? "Copied!" : "Copy"}
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </OutsideAlerter>
                </div>
            </div>
        );
    
    }

}
