import React, { Component } from "react";
import Switch from "../../widget/switch/Switch";
import PropTypes from "prop-types";
import toggleOn from "../../assets/images/toggle-button.svg";
import toggleOff from "../../assets/images/toggle-button-off.svg";
import { connect } from "react-redux";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "1",
      isProfilePicture: null,
      taskAssignedEmailSwitch: props.loggedInUser.notifyAssigned,
      taskMentionedEmailSwitch: props.loggedInUser.notifymentioned,
      taskReminderEmailSwitch: props.loggedInUser.notifyReminder,
      dailyDigestEmailSwitch: props.loggedInUser.notifyDailyDigest,
      desktopNotificationSwitch: props.loggedInUser.notifyDesktop,
      isPasswordValid: true,
      isConfirmPasswordValid: true,
      isFormSubmittable: false,
    };
  }

  selectTab(tab) {
    this.setState({
      selectedTab: tab,
      isPasswordValid: true,
      isConfirmPasswordValid: true,
      isFormSubmittable: false,
      message: null,
    });
  }

  updateUser() {
    const formData = new FormData();
    if (
      this.profilePic &&
      this.profilePic.files.length &&
      this.profilePic.files[0]
    ) {
      formData.append("picture", this.profilePic.files[0]);
    }
    if (this.firstName && this.firstName.value) {
      formData.append("firstName", this.firstName.value);
    }
    if (this.lastName && this.lastName.value) {
      formData.append("lastName", this.lastName.value);
    }

    formData.append("notifyAssigned", this.state.taskAssignedEmailSwitch);
    formData.append("notifymentioned", this.state.taskMentionedEmailSwitch);
    formData.append("notifyReminder", this.state.taskReminderEmailSwitch);
    formData.append("notifyDailyDigest", this.state.dailyDigestEmailSwitch);
    formData.append("notifyDesktop", this.state.desktopNotificationSwitch);

    this.props.updateUser.call(this, formData);
  }

  closeProfile() {
    this.props.closeProfile.call(this);
  }

  onProfileImageSelected(e) {
    const _this = this;
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const reader = new FileReader();
      _this.setState({ isProfilePicture: true });
      reader.onload = function (event) {
        const profilePicture = document.getElementById("profilePicture");
        profilePicture.setAttribute("src", event.target.result);
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
    } else {
      _this.setState({ isProfilePicture: false });
    }
  }

  changePassword() {
    const _this = this;
    this.props.changePassword.call(
      this,
      this.state.oldPassword,
      this.state.newPassword,
      (response) => {
        if (response.data && response.data.message) {
          _this.setState({ message: response.data.message });
        }
      }
    );
  }

  onPasswordChange(e) {
    if (e.currentTarget.id === "old-pass") {
      this.setState({ oldPassword: e.currentTarget.value });
      this.state.oldPassword = e.currentTarget.value;
    } else if (e.currentTarget.id === "new-pass") {
      this.setState({ newPassword: e.currentTarget.value });
      this.state.newPassword = e.currentTarget.value;
      this.setState({
        isPasswordValid: /[0-9a-zA-Z!@#$%^&*]{6,}$/.test(e.currentTarget.value),
      });
    } else if (e.currentTarget.id === "confirm-pass") {
      this.setState({ confirmPassword: e.currentTarget.value });
      this.state.confirmPassword = e.currentTarget.value;
      this.state.isConfirmPasswordValid =
        e.currentTarget.value === this.state.newPassword;
    }
    if (
      this.state.oldPassword &&
      this.state.newPassword &&
      this.state.confirmPassword &&
      this.state.isConfirmPasswordValid &&
      this.state.isPasswordValid
    ) {
      this.setState({ isFormSubmittable: true });
      this.state.isFormSubmittable = true;
    } else {
      this.state.isFormSubmittable = false;
    }
  }

  render() {
    let profileCont = null,
      profilePicture,
      body,
      messageBody;
    const {
      isProfilePicture,
      taskAssignedEmailSwitch,
      desktopNotificationSwitch,
      dailyDigestEmailSwitch,
      taskReminderEmailSwitch,
      taskMentionedEmailSwitch,
      isConfirmPasswordValid,
      isPasswordValid,
      message,
    } = this.state;

    const { loggedInUser, data = {} } = this.props;
    // if (this.props.data.status === "success" || this.props.data.status === "error") {
    //   messageBody = (
    //     <div className={`remember-me m-b-10 m-t-20 p-10 ${data.status === "success" ? "border-success":"border-error"} center-align`}>
    //       {" "}
    //       {this.props.data.message}
    //     </div>
    //   );
    // }
    if (isProfilePicture) {
      profilePicture = (
        <div>
          <img id="profilePicture" className="add-btn-big" />
        </div>
      );
    } else {
      if (loggedInUser && loggedInUser.image) {
        profilePicture = (
          <div
            className="loggedin-user-image cover-image"
            style={{
              backgroundImage: `url("${loggedInUser.image}")`,
            }}
          />
        );
      } else {
        const initials =
          (loggedInUser && loggedInUser.firstName
            ? loggedInUser.firstName[0].toUpperCase()
            : "") +
          (loggedInUser && loggedInUser.lastName
            ? loggedInUser.lastName[0].toUpperCase()
            : "");
        profilePicture = <div className="add-btn-big">{initials}</div>;
      }
    }
    if (this.state.selectedTab === "1") {
      body = (
        <div className="body overflow-auto">
          <div className="pos-rel disp-inline-block m-t-20 center-align">
            {profilePicture}
            <div className="color-blue m-t-10 font-14">
              <a>Add Photo</a>
            </div>
            <input
              ref={(input) => {
                this.profilePic = input;
              }}
              className="uploadProfile"
              type="file"
              accept="image/*"
              onChange={this.onProfileImageSelected.bind(this)}
            />
          </div>
          <div className="control-box width-70 pull-right">
            <div>
              <div className="text-gray-bold-small m-t-20 m-b-6">
                First Name
              </div>
              <div className="center-align m-b-10">
                <input
                  ref={(input) => {
                    this.firstName = input;
                  }}
                  className="inputbox full-width "
                  type="text"
                  defaultValue={loggedInUser ? loggedInUser.firstName : ""}
                  placeholder="First Name"
                />
              </div>
            </div>
            <div>
              <div className="text-gray-bold-small m-t-10 m-b-6">Last Name</div>
              <div className="center-align m-b-10">
                <input
                  ref={(input) => {
                    this.lastName = input;
                  }}
                  className="inputbox full-width "
                  type="text"
                  defaultValue={loggedInUser ? loggedInUser.lastName : ""}
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div>
              <div className="text-gray-bold-small m-t-10 m-b-6">
                Email Address
              </div>
              <div className="center-align m-b-10">
                <input
                  disabled="true"
                  className="inputbox full-width "
                  type="text"
                  value={loggedInUser ? loggedInUser.email : ""}
                  placeholder="Email Address"
                />
              </div>
            </div>
            <div>
              <button
                className="blue-btn small pull-right"
                onClick={this.updateUser.bind(this)}
              >
                Save
              </button>
            </div>
            <div>
              <button
                className="blue-btn small transparent pull-right"
                onClick={this.closeProfile.bind(this)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.selectedTab === "2") {
      body = (
        <div className="body overflow-auto">
          <div className="control-box">
            {messageBody}
            <div>
              <div className="text-gray-bold-small m-t-20 m-b-6">
                Current Password
              </div>
              <div className="center-align m-b-10">
                <input
                  ref={(input) => {
                    this.currentPassword = input;
                  }}
                  className="inputbox full-width "
                  type="password"
                  placeholder=""
                  onKeyUp={this.onPasswordChange.bind(this)}
                  id="old-pass"
                />
              </div>
            </div>
            <div>
              <div className="text-gray-bold-small m-t-10 m-b-6">
                New Password
              </div>
              <div className="center-align m-b-10">
                <input
                  ref={(input) => {
                    this.newPassword = input;
                  }}
                  className={`inputbox full-width ${
                    isPasswordValid ? "" : "error"
                  }`}
                  type="password"
                  placeholder=""
                  onKeyUp={this.onPasswordChange.bind(this)}
                  id="new-pass"
                />
              </div>
            </div>
            <div>
              <div className="text-gray-bold-small m-t-10 m-b-6">
                Confirm New Password
              </div>
              <div className="center-align m-b-10">
                <input
                  ref={(input) => {
                    this.confirmPassword = input;
                  }}
                  className={`inputbox full-width ${
                    isConfirmPasswordValid ? "" : "error"
                  }`}
                  type="password"
                  placeholder=""
                  onKeyUp={this.onPasswordChange.bind(this)}
                  id="confirm-pass"
                />
              </div>
            </div>
            <div>
              <button
                className={`blue-btn small pull-right ${
                  this.state.isFormSubmittable ? " " : "disabled"
                }`}
                onClick={this.changePassword.bind(this)}
              >
                Change Password
              </button>
            </div>
            <div>
              <button
                className="blue-btn small transparent pull-right"
                onClick={this.closeProfile.bind(this)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.selectedTab === "3") {
      body = (
        <div className="body overflow-auto">
          <div className="control-box">
            <div className="border-bottom-gray text-gray-bold-small m-t-20 m-b-10">
              Email Notifications
            </div>
            <div className="m-b-25 m-t-10 align-items org-disp-flex">
              <img
                src={
                  taskAssignedEmailSwitch
                    ? toggleOn
                    : toggleOff
                }
                className="fa-toggle-on"
                onClick={() => {
                  const isAssi = this.state.taskAssignedEmailSwitch;
                  this.setState({
                    taskAssignedEmailSwitch: !isAssi,
                  });
                  this.updateUser.call(this);
                }}
              />
              <div className=" disp-inline-block vertical-center width-70 m-l-10 text-gray-bold-small">
                Email me everytime a task is assigned to me
              </div>
            </div>
            <div className="m-b-25 m-t-10 align-items org-disp-flex">
              <img
                src={
                  taskMentionedEmailSwitch
                    ? toggleOn
                    : toggleOff
                }
                className="fa-toggle-on"
                onClick={() => {
                  const isAssi = this.state.taskMentionedEmailSwitch;
                  this.setState({
                    taskMentionedEmailSwitch: !isAssi,
                  });
                  this.updateUser.call(this);
                }}
              />
              <div className=" disp-inline-block vertical-center width-70  m-l-10 text-gray-bold-small">
                Email me everytime my name is mentioned
              </div>
            </div>
            <div className="m-b-25 m-t-10 align-items org-disp-flex">
              <img
                src={
                  taskReminderEmailSwitch
                    ? toggleOn
                    : toggleOff
                }
                className="fa-toggle-on"
                onClick={() => {
                  const isAssi = this.state.taskReminderEmailSwitch;
                  this.setState({
                    taskReminderEmailSwitch: !isAssi,
                  });
                  this.updateUser.call(this);
                }}
              />
              <div className=" disp-inline-block vertical-center width-70 m-l-10 text-gray-bold-small">
                Email me a reminder when a task is due thatʼs assigned to me
              </div>
            </div>
            <div className="m-b-25 m-t-10 align-items org-disp-flex">
              <img
                src={
                  dailyDigestEmailSwitch
                    ? toggleOn
                    : toggleOff
                }
                className="fa-toggle-on"
                onClick={() => {
                  const isAssi = this.state.dailyDigestEmailSwitch;
                  this.setState({
                    dailyDigestEmailSwitch: !isAssi,
                  });
                  this.updateUser.call(this);
                }}
              />
              <div className="disp-inline-block vertical-center width-70 m-l-10  text-gray-bold-small">
                Email me a daily digest of the above notifications
              </div>
            </div>
          </div>
          <div className="border-bottom-gray text-gray-bold-small m-t-20 m-b-6">
            desktop Notifications
          </div>
          <div className="m-b-25 m-t-10 align-items org-disp-flex">
            <img
              src={
                desktopNotificationSwitch
                  ? toggleOn
                  : toggleOff
              }
              className="fa-toggle-on"
              onClick={() => {
                const isAssi = this.state.desktopNotificationSwitch;
                this.setState({
                  desktopNotificationSwitch: !isAssi,
                });
                this.updateUser.call(this);
              }}
            />
            <div className=" disp-inline-block vertical-center width-70 m-l-10 text-gray-bold-small">
              Enable desktop notifications to see any of the above email
              notifications directly in your browser
            </div>
          </div>
        </div>
      );
    }

    if (this.props.isProfileOpen) {
      profileCont = (
        <div className="backdrop">
          <div className="popup small p-b-20">
            <div className="header height-auto">
              <span>My Profile</span>
              <i
                className="close fa fa-times"
                aria-hidden="true"
                onClick={this.closeProfile.bind(this)}
              />
              <div className="tabs">
                <div
                  className={`text-gray-bold-small tab ${
                    this.state.selectedTab === "1" ? "selected" : ""
                  }`}
                  onClick={this.selectTab.bind(this, "1")}
                >
                  Profile
                </div>
                <div
                  className={`text-gray-bold-small tab ${
                    this.state.selectedTab === "2" ? "selected" : ""
                  }`}
                  onClick={this.selectTab.bind(this, "2")}
                >
                  Password
                </div>
                <div
                  className={`text-gray-bold-small tab ${
                    this.state.selectedTab === "3" ? "selected" : ""
                  }`}
                  onClick={this.selectTab.bind(this, "3")}
                >
                  Notifications
                </div>
              </div>
            </div>
            {body}
          </div>
        </div>
      );
    }

    return <div>{profileCont}</div>;
  }
}

Profile.propTypes = {
  loggedInUser: PropTypes.object,
  isProfileOpen: PropTypes.bool,
  changePassword: PropTypes.func,
};

export default Profile;
