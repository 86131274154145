import { all } from 'redux-saga/effects'
import register from './register'
import profile from "./profile"
import board from "./boards";
import sharedBoards from './sharedBoards';

export default function* rootSaga() {
  yield all([
    register(),
    profile(),
    board(),
    sharedBoards()
  ])
}
