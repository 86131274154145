import { useSelector, useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa6";
import PropTypes from "prop-types";
import { actions as registerActions } from "../../../../store/reducers/register";

const PlanItem = ({ plan = {}, nextStep }) => {
  const dispatch = useDispatch();
  const { chosenPlan = {} } = useSelector((state) => ({
    chosenPlan: state.register.chosenPlan,
  }));
  const chosePlan = () => {
    dispatch({
      type: registerActions.SET_STATE,
      payload: {
        chosenPlan: plan,
        totalPrice: plan.price,
      },
    });
  };
  return (
    <div
      className={`register-plan-item ${
        plan.id === chosenPlan.id ? "chosen-plan" : ""
      }`}
      onMouseEnter={chosePlan}
      style={{ border: `1px solid ${plan.color}` }}
    >
      <div className="header" style={{ background: plan.color }}>
        {plan.title}
      </div>
      <div className="content">
        <div className="title">
          <p>{plan.price === "Free" ? plan.price : "$"+`${plan.price}`}</p>
          {plan.price !== "Free" ? (
            <small>per user/month</small>
          ) : (
            <div style={{ height: 20, width: "100%" }} />
          )}
        </div>
        <div className="hint">{plan.for}</div>
        <ul className="features">
          {plan.features.map((feature, index) => (
            <li key={index}>
              <FaCheck /> {feature}
            </li>
          ))}
        </ul>
        <button
          className="submit-button choose"
          style={{
            border: `1px solid ${plan.color}`,
            backgroundColor: plan.id === chosenPlan.id ? plan.color : "white",
            color: plan.id === chosenPlan.id ? "white" : plan.color,
          }}
          onClick={nextStep}
        >
          Choose {plan.title}
        </button>
      </div>
    </div>
  );
};

PlanItem.propTypes = {
  plan: PropTypes.object,
  nextStep: PropTypes.func.isRequired
};

export default PlanItem;
