import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { fetchUserViaEmail, getUser } from "../../store/actions/user";
import EmailChecker from "./form/EmailChecker";
import "./styles.css";
import { connect } from "react-redux";
import UserRegistration from "./form/UserRegistration";
import PasswordLogin from "./form/PasswordLogin";
import GoogleLogin from "./form/GoogleLogin";

const LoginStartup = ({ history, user, ...props }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [registrationState, setRegistrationState] = useState({
    checkEmailRegistered: true,
    registerNewUser: false,
    loginUsingPassword: false,
    loginUsingGoogle: false,
    passwordLoginFromRegister: false,
    userEmail: "",
  });
  const [currentPath, setCurrentPath] = useState(history.location.pathname);

  useEffect(() => {
    setCurrentPath(history.location.pathname);
    if (history.location.pathname.includes("/login")) {
      setRegistrationState((prev) => ({
        ...prev,
        checkEmailRegistered: true,
        registerNewUser: false,
        loginUsingPassword: false,
        loginUsingGoogle: false,
        userEmail: "",
      }));
    }
  }, [history.location.pathname]);

  const onLogin = (values) => {
    props.fetchUserViaEmail(values?.email).then((responce) => {
      if (responce.data.status === "success") {
        if (responce.data.data?.isGoogle) {
          setRegistrationState((prev) => ({
            ...prev,
            checkEmailRegistered: false,
            registerNewUser: false,
            loginUsingPassword: false,
            userEmail: responce.data.data?.email,
            loginUsingGoogle: true,
          }));
        } else if (history.location.pathname.includes("/register")) {
          setRegistrationState((prev) => ({
            ...prev,
            checkEmailRegistered: false,
            registerNewUser: false,
            loginUsingPassword: true,
            userEmail: responce.data.data?.email,
            passwordLoginFromRegister: true,
          }));
        } else {
          setRegistrationState((prev) => ({
            ...prev,
            checkEmailRegistered: false,
            registerNewUser: false,
            loginUsingPassword: true,
            userEmail: responce.data.data?.email,
          }));
        }
      } else if (responce.status === 500) {
        if (currentPath.includes("/register")) {
          setRegistrationState((prev) => ({
            ...prev,
            checkEmailRegistered: false,
            registerNewUser: true,
          }));
        } else {
          history.push("/register");
        }
      } else {
        setErrorMsg("Something went wrong");
      }
    });
  };
  return (
    <div style={{ display: "block" }}>
      <div className="login-form-wrapper" style={{ position: "relative" }}>
        <div className="wrapper">
          {registrationState.checkEmailRegistered && (
            <EmailChecker
              onLogin={onLogin}
              isLoading={user.isLoading}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              propss={props}
              history={history}
              currentPath={currentPath}
              setRegistrationState={setRegistrationState}
            />
          )}
          {registrationState.registerNewUser && (
            <UserRegistration
              propss={props}
              history={history}
              setRegistrationState={setRegistrationState}
            />
          )}
          {registrationState.loginUsingPassword && (
            <PasswordLogin
              userEmail={registrationState.userEmail}
              propss={props}
              setRegistrationState={setRegistrationState}
              pwdLoginWithRegister={registrationState.passwordLoginFromRegister}
            />
          )}
          {registrationState.loginUsingGoogle && (
            <GoogleLogin
              userEmail={registrationState.userEmail}
              propss={props}
              history={history}
              setRegistrationState={setRegistrationState}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  fetchUserViaEmail,
  getUser,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginStartup));
