import axios from "axios";

export async function getSharedBoard(id) {
  return axios({
    method: "get",
    url: `board/get-board/${id}`,
  }).then((resp) => resp.data).catch((error)=> {
    return error.response.data;
  })
}

export async function getBoardList(id) {
  return axios({
    method: "GET",
    url: `board/?board=${id}`,
    headers: { Authorization: localStorage.getItem("authorization") },
  }).then((res) => res.data.data.boards).catch(() => [])
}