import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
// import Dropdown from "../../../widget/Dropdown/dropdown";
import { hideAddBoard } from "../../../store/actions/UIActions";
import { createNewBoard, getBoardDetail } from "../../../store/actions/lists";
import { get, last } from 'lodash';

const AddNewBoard = ({ history }) => {
  const { user, showAddBoard } = useSelector((state) => ({
    user: state.user,
    showAddBoard: state.user.showAddBoard,
  }));
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState(true);
  const [boardLength, setBoardLength] = useState(user.boardList.length);

  useEffect(() => {
    if (boardLength < user.boardList.length) {
      history.push(`/board/${user.boardList[user.boardList.length - 1]._id}`);
      setBoardLength(user.boardList.length);
    }
  }, [user.boardList.length]);

  const onHideAddBoard = () => {
    setName(null);
    dispatch(hideAddBoard());
  };

  const onCreateNewBoard = async () => {
    const normalType = get(user, 'boardTypes', []).find((usr) => usr.value === "normal");
    const type = get(normalType, '_id');
    if (name && type) {
      setName(null);
      const result = await dispatch(createNewBoard(name, type, history));
      const boards = get(result, 'data.data.boards', []);
      if (last(boards)) {
        dispatch(getBoardDetail(last(boards), boards))
      }
    }
  };

  return (
    <div
      className="backdrop"
      style={{ display: showAddBoard ? "block" : "none" }}
    >
      <div className="popup small p-b-20">
        <div className="header">
          <span>Add New Board</span>
          <i
            className="close fa fa-times"
            aria-hidden="true"
            onClick={onHideAddBoard}
          />
        </div>
        <div className="body">
          <div className="control-box">
            <div className="center-align m-t-20 m-b-10">
              <input
                className="inputbox full-width "
                type="text"
                placeholder="Board Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <button
                disabled={!name || !type}
                className="blue-btn small pull-right"
                onClick={() => onCreateNewBoard()}
              >
                Create Board
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddNewBoard.propTypes = {
  history: PropTypes.object,
};

export default withRouter(AddNewBoard);
