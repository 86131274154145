import Switch from "react-switch";
import PropTypes from 'prop-types';
import "./styles.scss";

const AntSwitch = ({ label, checked, onChange, name }) => {
  const handleChange = (nextChecked) => {
    onChange(name, nextChecked);
  };
  return (
      <label className="switch-button">
        <Switch
          onChange={handleChange}
          checked={checked}
          onColor="#0D9FEA"
          handleDiameter={15}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={35}
          name={name}
          id={name}
        />
        <span>{label}</span>
      </label>
  );
};


AntSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default AntSwitch;