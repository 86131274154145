import React, { Component } from "react";
import OutsideAlerter from "../../../widget/Modal";
import { get } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateBoardSettings } from "../../../store/actions/lists";

class BoardSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublic: false,
      canCopy: false,
      boardTitle: "",
    };
  }

  componentDidMount() {
    const { activeBoard } = this.props;
    this.setState({
      boardTitle: get(activeBoard, "name", ""),
      isPublic: get(activeBoard, "isPublic", false),
      canCopy: get(activeBoard, "isCopying", false),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const boardVisible = nextProps.toggleBoardSettings;
    const prevBoardVisible = this.props.toggleBoardSettings;
    if (prevBoardVisible !== boardVisible && boardVisible) {
      const { activeBoard } = nextProps;
      this.setState({
        boardTitle: get(activeBoard, "name", ""),
        isPublic: get(activeBoard, "isPublic", false),
        canCopy: get(activeBoard, "isCopying", false),
      });
    }
  }

  saveBoardSettings() {
    const { activeBoard, handleClose } = this.props;
    const { isPublic, canCopy, boardTitle } = this.state;
    const boardId = get(activeBoard, "_id");
    if (!boardId || boardTitle.trim().length === 0) {
      return;
    }
    this.props.updateBoardSettings(boardId, boardTitle, isPublic, canCopy);
    handleClose();
  }

  onBoardTitleChange(e) {
    const value = get(e, "target.value", "");
    this.setState({ boardTitle: value });
  }

  render() {
    const { isPublic, canCopy, boardTitle } = this.state;
    const { handleClose, activeBoard, loggedInUser } = this.props;
    const loggedInUserId = get(loggedInUser, "_id");
    const boardOwnerId = get(activeBoard, "owner");
    const isOwner = loggedInUserId === boardOwnerId;
    return (
      <div
        className="backdrop"
        style={{ display: this.props.toggleBoardSettings ? "block" : "none" }}
      >
        <div className="popup board-settings small p-b-20">
          <OutsideAlerter handleClose={() => handleClose()}>
            <div className="header b-b-0 p-t-b-20">
              <span className="move-card">Board Settings</span>
            </div>
            <div className="body">
              <div className="control-box">
                <div className="share-board-desc">Board Title</div>
                <div className="m-t-20 m-b-10">
                  <input
                    type="text"
                    disabled={!isOwner}
                    value={boardTitle}
                    className="inputbox full-width"
                    placeholder="Board Title"
                    onChange={this.onBoardTitleChange.bind(this)}
                  />
                  <div className="flex-row-block align-items m-t-30">
                    <span
                      className="cover-image cover-image-big"
                      style={{
                        backgroundImage: `url("${get(loggedInUser, "image")}")`,
                      }}
                    />
                    <div>
                      <div className="black-font-14 bold">
                        {isOwner ? "Owner" : "Member"}
                      </div>
                      <div className="black-font-14 m-t-5 f-w-100">
                        {`${get(loggedInUser, "firstName")} ${get(
                          loggedInUser,
                          "lastName"
                        )}`}
                      </div>
                    </div>
                  </div>
                  <div className="flex-row-block align-items-start m-t-30">
                    <img
                      src={
                        isPublic
                          ? "../../assets/images/toggle-button.svg"
                          : "../../assets/images/toggle-button-off.svg"
                      }
                      className="fa-toggle-on"
                      onClick={() => {
                        const { isPublic } = this.state;
                        // eslint-disable-next-line no-unused-expressions
                        isOwner && this.setState({ isPublic: !isPublic });
                      }}
                    />
                    <div className="m-l-10">
                      <div className="board-settings-font fw-500">
                        Make Board Public
                      </div>
                      <div className="board-settings-font m-t-5 f-w-100">
                        This board can be shared with anyone for viewing.
                      </div>
                    </div>
                  </div>
                  <div className="flex-row-block align-items-start m-t-60">
                    <img
                      src={
                        canCopy
                          ? "../../assets/images/toggle-button.svg"
                          : "../../assets/images/toggle-button-off.svg"
                      }
                      className="fa-toggle-on"
                      onClick={() => {
                        const { canCopy } = this.state;
                        // eslint-disable-next-line no-unused-expressions
                        isOwner && this.setState({ canCopy: !canCopy });
                      }}
                    />
                    <div className="m-l-10">
                      <div className="board-settings-font fw-500">
                        Allow Board to be Copied
                      </div>
                      <div className="board-settings-font m-t-5 f-w-100">
                        Turning this on will allow anyone to copy this board as
                        their own including all associated files. Copied boards
                        do not change the original.
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: isOwner ? "block" : "none" }}>
                  <button
                    disabled={boardTitle.trim().length === 0}
                    onClick={this.saveBoardSettings.bind(this)}
                    className="blue-btn small m-l-10 m-t-20 pull-right"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => handleClose()}
                    className="blue-btn transparent small m-l-0 m-t-20 pull-right"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </OutsideAlerter>
        </div>
      </div>
    );
  }
}

BoardSettings.propTypes = {
  user: PropTypes.object,
  loggedInUser: PropTypes.object,
  activeBoard: PropTypes.object,
  toggleBoardSettings: PropTypes.bool,
  handleClose: PropTypes.func,
  updateBoardSettings: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.user,
  loggedInUser: state.user.loggedInUser,
  activeBoard: state.user?.activeBoard || {},
  toggleBoardSettings: state.user.toggleBoardSettings,
});

const mapDispatchToProps = {
  updateBoardSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardSettings);
